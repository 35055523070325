/* eslint-disable no-undef */
import {
    JOB_CUSTOM,
    JOB_EXCLUDE,
    LIST_JOB_STATUS_ACTIVE_NOTIFY_CUSTOMER,
    LIST_JOB_STATUS_SEND_CONFIRMATIONS,
    TYPE_CONFIRMATIONS_JOB
} from 'app/const/Job';
import store from 'common/redux/store/configureStore';
import { DEFAULT_CONTRAST_COLOR } from 'app/modules/addons/jobStatus/utils';
import { OPTION_TIMEOFF_STATUS, TIMEOFF_STATUS_ID } from 'app/const/addons';
import { DEFAULT_ALL, DEFAULT_ALL_STRING } from 'app/const/App';

/**
 *
 * @param filter array - type status
 * @param isInclude boolean - return data include type need to get
 * @param isIncludeDeleted boolean - return data include status deleted
 */
export const getListJobStatus = ({ filter = JOB_EXCLUDE.DEFAULT, isInclude = false, isIncludeDeleted = false }) => {
    const isDisableCustomJobStatus = !store.getState()?.auth?.user?.settings?.addons?.custom_job_status;
    const stateListJob = global.listJobStatus;
    const finalFilter = isInclude ? filter : Object.keys(stateListJob).filter((key) => !filter.includes(key));
    let finalList = finalFilter.reduce((array, type) => {
        const statusesItem = stateListJob[type];
        if (!!statusesItem) return array.concat(statusesItem);
        return array;
    }, []);

    if (isDisableCustomJobStatus || !isIncludeDeleted)
        finalList = finalList.filter(({ id, deleted, type }) => {
            if ((!isIncludeDeleted && deleted) || (isDisableCustomJobStatus && id !== type)) return false;
            return true;
        });

    return finalList.sort((a, b) => a.index - b.index);
};

export const getJobStatus = (id) => {
    if (id === TIMEOFF_STATUS_ID) return OPTION_TIMEOFF_STATUS;
    return global.jobStatus[id?.toString()] || null;
};

export const setJobStatusGlobal = (jobStatus = []) => {
    global.jobStatus = {};
    global.listJobStatus = {};
    jobStatus.forEach((item, index) => {
        global.jobStatus[item.id] = item;
        global.listJobStatus[item.type] = [...(global.listJobStatus[item.type] || []), { ...item, index }];
    });
};

export const getContrastColor = (hexColor = DEFAULT_CONTRAST_COLOR) => {
    const contrastHexColor = hexColor.replace('#', '');
    const red = parseInt(contrastHexColor.substring(0, 2), 16);
    const green = parseInt(contrastHexColor.substring(2, 4), 16);
    const blue = parseInt(contrastHexColor.substring(4, 6), 16);
    const luma = (red + green + blue) / 3;
    return luma < 186 ? '#FFFFFF' : '#000000';
};

export const handleOrderRetrieve = ({ item = {}, groups = {} }) => {
    const { status_id: currentId, type: currentType } = item;

    const afterIndex = groups[currentType].findIndex((status) => status.status_id === currentId) - 1;
    const afterItem = groups[currentType][afterIndex] || {};

    const { status_id: afterId, type: afterType } = afterItem;
    const newList = [...getListJobStatus({ isIncludeDeleted: true })];
    const index = { start: -1, end: -1, isParent: false };

    for (let i = 0; i < newList.length; i++) {
        const { id: statusId, type: statusType } = newList[i];

        if (index.start < 0 && statusId === currentId) {
            index.start = i;
        } else if (index.end < 0) {
            const isDifferentType = afterType !== currentType;

            if (isDifferentType && statusId === currentType) {
                index.isParent = true;
                index.end = i;
            } else if (statusType === JOB_CUSTOM && currentType === JOB_CUSTOM && afterType !== JOB_CUSTOM) {
                index.end = i - 1;
            } else if (!isDifferentType && statusId === afterId) {
                index.end = i;
            }
        }

        if (index.start >= 0 && index.end >= 0) {
            break;
        }
    }

    const { start: finalStart, end: finalEnd, isParent } = index;
    const [removed] = newList.splice(finalStart, 1);
    newList.splice(finalEnd + (finalStart - finalEnd <= 1 && !isParent ? 0 : 1), 0, removed);
    return newList;
};

export const convertParamsJobStatus = (jobStatus = []) => {
    if (!Array.isArray(jobStatus)) {
        if (jobStatus?.toString() === DEFAULT_ALL_STRING || jobStatus === '') {
            return jobStatus;
        }
        return [];
    }

    const result = [];
    const listJobStatus = getListJobStatus({ filter: JOB_EXCLUDE.REPORT_TABLE, isIncludeDeleted: true });

    for (let index = 0; index < listJobStatus.length; index++) {
        const statusId = listJobStatus[index].id;
        const statusIndex = jobStatus.indexOf(statusId);

        if (statusIndex === DEFAULT_ALL) continue;
        result.push(statusId);
        jobStatus.splice(statusIndex, 1);
        if (!jobStatus.length) break;
    }

    return result;
};

export const getTypeConfirmation = (status) => {
    return LIST_JOB_STATUS_SEND_CONFIRMATIONS.includes(getJobStatus(status)?.type)
        ? TYPE_CONFIRMATIONS_JOB.CONFIRMATIONS
        : TYPE_CONFIRMATIONS_JOB.REMINDERS;
};

export const isShowNotifyCustomer = (status) => {
    return LIST_JOB_STATUS_ACTIVE_NOTIFY_CUSTOMER.includes(getJobStatus(status)?.type);
};
