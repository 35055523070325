import React from 'react';
import { useTranslation } from 'react-i18next';

import GDIframe from 'app/components/iframe';
import { LIST_VIDEO_LIBRARY } from '../../constant';

const OnBoardingVideoLibrary = () => {
    const { t } = useTranslation('onboarding');

    return (
        <div className="onboarding-items onboarding-library">
            <h5 className="black fw-500 fs-15 mb-3">{t('video_library')}</h5>
            <div className="library-list flextop gap-16">
                {LIST_VIDEO_LIBRARY.map((item) => (
                    <div key={item.id} className="library-items">
                        <GDIframe
                            className="library-iframe"
                            link={item.link}
                            title={item.title}
                            loadingComponent={() => <div className="library-iframe mb-2 loading --animation" />}
                        />
                        <p className="black fs-14 fw-500 mt-1" title={item.title}>
                            {item.title}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OnBoardingVideoLibrary;
