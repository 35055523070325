import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import GdSwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import GDModalWarning from 'app/components/modal/ModalWarning';
import GdRichText from 'app/components/richtext';
import GDStatusBar from 'app/components/status/statusbar';
import { CHATBOT_CONTENT } from 'app/const/api/V2';
import { LIST_STATUS } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import { showStatusBar } from 'common/utils/FunctionUtils';
import { TOOLBAR_CONFIG_RESTORE, TYPE_DATA_CHATBOT } from '../constants';
import LoadingModalAddContent from './LoadingModalAddContent';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';

const ModalAddContent = ({ onUpdateData = () => {} }, ref) => {
    const { t } = useTranslation(['addons']);

    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false,
        item: {},
        isEdit: false,
        matchedTemplate: null
    });
    const { isOpen = false, item = {}, isEdit = false, isLoading = false, matchedTemplate } = state;
    const { content = '', label = '', status = false, id = '', is_script: finalIsScript = false } = item || {};

    const refStatus = useRef(null);
    const refContentLabel = useRef(null);
    const refContent = useRef(null);
    const refWarning = useRef(null);
    const refAlert = useRef(null);
    const refModalFooter = useRef(null);

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = ({ id = null, availableTemplates }) => {
        if (!id) {
            dispatchState((prev) => ({
                ...prev,
                isOpen: true,
                item: { status: true, label: '', content: '', is_script: false }
            }));
        } else {
            _handleGetDetail(id, availableTemplates);
        }
    };

    const _handleGetDetail = (id, availableTemplates) => {
        dispatchState((prev) => ({ ...prev, isOpen: true, isLoading: true }));

        const _handleSuccess = ({ data = {} }) => {
            dispatchState((prev) => ({
                ...prev,
                isLoading: false,
                isEdit: true,
                item: { ...prev.item, ...(data || {}) },
                matchedTemplate: availableTemplates?.find((item) => item.template_id === data.template_id)
            }));
        };

        const _handleFail = ({ message }) => {
            refWarning.current?._open({ title: t('addons:confirm'), description: message });
        };

        clientQuery(
            `${CHATBOT_CONTENT}/${id}`,
            { data: {}, method: 'GET', toFormData: false },
            _handleSuccess,
            _handleFail
        );
    };

    const _close = () => {
        dispatchState({ isOpen: false, item: {}, isEdit: false, isLoading: false, availableTemplates: null });
    };

    const _handleChange = (checked, idElement) => {
        dispatchState((prev) => ({ ...prev, item: { ...prev.item, [idElement]: checked ? 1 : 0 } }));
    };

    const _handleConfirmWarning = (_, idDelete) => {
        refWarning.current?._clearStatusAlert();
        if (!idDelete) {
            refWarning.current?._close();
            _close();
            return;
        }

        const _handleSuccess = () => {
            onUpdateData({ type: TYPE_DATA_CHATBOT.DELETE, data: idDelete });
            _close();
        };
        const _handleFail = ({ message }) => {
            _onShowStatusAlert({ message });
        };
        const _handleFinally = () => {
            _handleResetLoading();
        };

        clientQuery(
            `${CHATBOT_CONTENT}/${idDelete}`,
            { data: {}, method: 'DELETE' },
            _handleSuccess,
            _handleFail,
            _handleFinally
        );
    };

    const _handleResetLoading = () => {
        refWarning.current?._setLoadingBtnSave(false);
        refModalFooter.current?._setLoadingBtnSave(false);
    };

    const _onShowStatusAlert = ({ message = '', status = LIST_STATUS.ERROR }) => {
        refWarning.current?._setStatusAlert(message, status);
    };

    const _handleSave = () => {
        const errors = [];
        const contentLabelValue = (refContentLabel.current?.value || '').trim();

        if (!contentLabelValue) errors.push(t('common:can_not_be_blank', { name: t('addons:content_label') }));
        if (refContent.current.isEmpty()) errors.push(t('common:can_not_be_blank', { name: t('addons:content') }));
        if (!!errors.length) {
            _handleShowStatusBar({ message: errors });
            return;
        }

        const _handleSuccess = ({ data }) => {
            onUpdateData({ type: TYPE_DATA_CHATBOT.UPDATE, data });
            _close();
        };

        const _handleFail = (err) => {
            _handleShowStatusBar(err);
        };

        const _handleFinally = () => {
            _handleResetLoading();
        };

        let endpoint = CHATBOT_CONTENT;
        if (isEdit) endpoint += `/${id}`;
        clientQuery(
            endpoint,
            {
                data: {
                    status: !!status ? 1 : 0,
                    label: contentLabelValue,
                    content: refContent.current.getValue(),
                    is_script: !!finalIsScript ? 1 : 0
                },
                method: isEdit ? 'PUT' : 'POST',
                toFormData: false
            },
            _handleSuccess,
            _handleFail,
            _handleFinally
        );
    };

    const _handleShowStatusBar = ({ message, success }) => {
        showStatusBar({ id: 'content_answer', message, success, refAlert });
        _handleResetLoading();
    };

    const _handleDelete = (id) => {
        refWarning.current?._open({
            dataConfirm: id,
            title: t('addons:title_delete_content'),
            description: t('addons:desc_delete_content')
        });
    };

    const _handleCloseWarning = () => {
        if (!isLoading) return;
        _close();
    };

    const _handleRestore = () => {
        dispatchState((prev) => ({ ...prev, item: { ...prev.item, ...(prev.matchedTemplate || {}), status: true } }));
        refContentLabel.current.value = matchedTemplate.label;
        refContent.current.setValue(matchedTemplate.content);
        refStatus.current.setIsActive(true);
    };

    if (!isOpen) return null;

    return (
        <ReactModal
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal modal-chatbot open"
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            {isLoading ? (
                <LoadingModalAddContent />
            ) : (
                <div className="modal__container --preview-r">
                    <ModalHeader title={t('addons:content')} onClose={_close} />
                    <div className="body-modal flex-column p-0 scrolls">
                        <GDStatusBar ref={refAlert} />
                        <div className={classNames('rows flexcenter', { 'is-disable': finalIsScript })}>
                            <div className="rows__title fw-500 black-darker3 fs-14 mr-2">{t('common:status')}</div>
                            <GdSwitchCheckbox
                                ref={refStatus}
                                id="status"
                                wrapClassName="switch large"
                                textUnChecked={t('common:active')}
                                textChecked={t('common:active')}
                                textPosition="right"
                                defaultChecked={status}
                                onChangeImmediately={_handleChange}
                            />
                        </div>
                        <div className="rows flex-column gap-16 flex-1 scrolls">
                            <div className="flex-column gap-4">
                                <div className="fw-500 black-darker3 fs-14">{t('content_label')}</div>
                                <input
                                    ref={refContentLabel}
                                    className={classNames('field-input', { 'is-disable': finalIsScript })}
                                    type="text"
                                    name="field-input"
                                    placeholder={t('content_label')}
                                    spellCheck
                                    defaultValue={label}
                                    autoFocus={!finalIsScript}
                                    disabled={finalIsScript}
                                />
                            </div>
                            <div className="flex-column gap-4 flex-1">
                                <div className="fw-500 black-darker3 fs-14">{t('content')}</div>
                                <GdRichText
                                    ref={refContent}
                                    wrapperClass="wrap-content flex-1"
                                    placeholder={t('content_placeholder')}
                                    isShowAction={!!matchedTemplate}
                                    toolbarConfig={TOOLBAR_CONFIG_RESTORE}
                                    isBreakLine
                                    defaultContent={content}
                                    onRestore={_handleRestore}
                                />
                            </div>
                        </div>
                    </div>
                    <ModalFooter
                        ref={refModalFooter}
                        isEdit={isEdit}
                        isShowDelete={!finalIsScript}
                        onClose={_close}
                        onSave={_handleSave}
                        onDelete={() => _handleDelete(id)}
                    />
                </div>
            )}
            <GDModalWarning
                ref={refWarning}
                isLargeContent={false}
                buttonSave
                titleButtonConfirm={t('addons:confirm')}
                onConfirm={_handleConfirmWarning}
                onClose={_handleCloseWarning}
            />
        </ReactModal>
    );
};

export default forwardRef(ModalAddContent);
