import classNames from 'classnames';
import React, { Fragment, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ADDONS_CHAT_BOT_PLAN } from 'app/config/routes';
import { ADDONS_CHATBOT_AI, UPDATE_ADDONS_CHATBOT_AI_OPTIONS } from 'app/const/Api';
import { ACCOUNT_ROLE } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import AddonHeaderDetail from '../components/AddonHeaderDetail';
import AddonsBoxHeader from '../components/AddonsBoxHeader';
import AddonsOptions from '../components/AddonsOptions';
import ChatbotAddonsGetStarted from './components/ChatbotAddonsGetStarted';
import LoadingAddonsChatbotAI from './components/LoadingChatbotAI';

const AddonsChatBotAI = () => {
    const history = useHistory();
    const {
        profile: { role: userRole }
    } = useSelector(({ auth }) => auth.user);
    const [state, dispatchState] = useReducer(reducer, { chatbotDetail: {}, isLoading: true });
    const { chatbotDetail: finalChatbotDetail, isLoading: finalIsLoading } = state;
    const {
        status,
        options: finalOptions = [],
        upgrade: finalUpgrade,
        name: finalName,
        keyword: finalKeyword,
        level: finalLevel,
        sub_title: finalSubTitle,
        account,
        tutorial
    } = finalChatbotDetail;
    const isSupperAdmin = userRole === ACCOUNT_ROLE.SUPERADMIN;
    const isAccountActive = !!account;

    useEffect(() => {
        _getChatbotDetail();
    }, []);

    const _getChatbotDetail = () => {
        const _handleSuccess = ({ data }) => dispatchState({ chatbotDetail: data || {}, isLoading: false });
        clientQuery(ADDONS_CHATBOT_AI, { method: 'GET' }, _handleSuccess, checkAccessFail);
    };

    const _handleUpdateSuccess = (checked) => {
        dispatchState((prev) => ({ ...prev, chatbotDetail: { ...prev.chatbotDetail, status: checked ? 1 : 0 } }));
    };

    const _handleActivePlan = () => {
        history.push(addBranchPath(ADDONS_CHAT_BOT_PLAN));
    };

    return (
        <div className="addons-wrap__container addons-chatbot">
            <AddonHeaderDetail isLoading={finalIsLoading} tutorial={tutorial} />
            <div className="scrolls">
                <div className="boxs-wrapper">
                    {finalIsLoading ? (
                        <LoadingAddonsChatbotAI />
                    ) : (
                        <Fragment>
                            <div className="boxs boxs--no-border --hasline --mr-line">
                                <AddonsBoxHeader
                                    upgrade={finalUpgrade}
                                    keyword={finalKeyword}
                                    name={finalName}
                                    status={status}
                                    level={finalLevel}
                                    shouldConfirm={!isAccountActive}
                                    urlUpdateStatus={UPDATE_ADDONS_CHATBOT_AI_OPTIONS}
                                    onUpdateStatusSuccess={_handleUpdateSuccess}
                                    onConfirmChange={_handleActivePlan}
                                />
                                {finalSubTitle ? (
                                    <div className="boxs__contents mt-3">
                                        <p className="description">{finalSubTitle}</p>
                                    </div>
                                ) : null}
                            </div>
                            {isSupperAdmin ? (
                                <ChatbotAddonsGetStarted
                                    chatBot={finalChatbotDetail}
                                    onUpdateSuccess={_getChatbotDetail}
                                />
                            ) : null}
                            <div className={classNames({ 'is-disable': !status || !isAccountActive })}>
                                <AddonsOptions
                                    data={finalOptions}
                                    status={status}
                                    upgrade={finalUpgrade}
                                    urlUpdate={UPDATE_ADDONS_CHATBOT_AI_OPTIONS}
                                />
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddonsChatBotAI;
