import React, { Fragment, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import GdConfirm from 'app/components/confirm';
import { ADDONS_SMS } from 'app/config/routes';
import { PLAN_REDIRECT_TIMEOUT } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { SETTINGS_PLANS } from 'app/const/Route';
import MainHeaderSettings from '../../components/MainHeaderSettings';
import UpgradeSuccess from '../components/UpgradeSuccess';

const ThanksPage = () => {
    const location = useLocation();
    const promptSMSPlan = location.state?.promptSMSPlan;
    const { t } = useTranslation();

    const refConfirm = useRef(null);

    useEffect(() => {
        if (promptSMSPlan && refConfirm.current) {
            refConfirm.current.open();
        } else {
            _handleReloadWithDelay();
        }
    }, [promptSMSPlan]);

    const _handleReloadWithDelay = () => {
        setTimeout(() => {
            window.location.href = addBranchPath(SETTINGS_PLANS);
        }, PLAN_REDIRECT_TIMEOUT);
    };

    const _handleConfirm = () => {
        window.location.href = addBranchPath(ADDONS_SMS);
    };

    return (
        <Fragment>
            <MainHeaderSettings />
            <div className="wrapper-columns">
                <UpgradeSuccess />
            </div>
            <GdConfirm
                ref={refConfirm}
                title={t('activate_sms_plan')}
                isLargeContent
                listButton={{ confirm: true, close: true }}
                titleConfirm={t('yes')}
                titleClose={t('not_right_now')}
                message={t('upgrade_plan_from_free_trial')}
                onConfirm={_handleConfirm}
                onClose={_handleReloadWithDelay}
                onCancel={_handleReloadWithDelay}
            />
        </Fragment>
    );
};

export default ThanksPage;
