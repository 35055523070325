import IconClose from 'assets/icon/IconClose';
import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { reducer } from 'app/const/Reducer';

const GdConfirm = forwardRef(
    (
        {
            title,
            message,
            listButton,
            onConfirm = () => {},
            onCancel = () => {},
            onClose = () => {},
            className,
            isDisableConfirm = false,
            isLargeContent = false,
            renderContent = null,
            alertContent = null,
            titleConfirm = '',
            titleClose = '',
            titleCancel = '',
            footer = ''
        },
        ref
    ) => {
        const { t } = useTranslation(['common']);
        const [state, dispatchState] = useReducer(reducer, {
            visible: false,
            value: null,
            message
        });

        useImperativeHandle(ref, () => ({
            open,
            close,
            visible: state.visible
        }));

        const open = (value = '', newMessage = null, title = null) => {
            dispatchState({ visible: true, value, message: newMessage || state.message, title });
        };

        const close = () => {
            dispatchState({ visible: false, value: null, title: null });
        };

        const handleConfirm = () => {
            close();
            onConfirm(state.value);
        };

        const handleCancel = () => {
            onCancel(state.value);
            close();
        };

        const handleClose = () => {
            onClose();
            close();
        };

        if (!state.visible) return null;

        return (
            <div
                ref={ref}
                className={classNames({ 'modal container-modal modal-confirm-delete open': !className }, className)}
            >
                <div className="modal__overlay bg-fixed" onClick={handleClose} />
                <div className={classNames('modal__container', { '--lg': isLargeContent })}>
                    <div className="header-modal">
                        <h3 className="header-modal__label">{state.title || title}</h3>
                        <div className="v2-btn-default --icon-lg --transparent" onClick={handleClose} tabIndex="0">
                            <IconClose />
                        </div>
                    </div>

                    <div className="body-modal">
                        {alertContent}
                        <div className="text">{renderContent || state.message}</div>
                    </div>
                    {footer || (
                        <div className="footer-modal footer-hasbtn btn-close">
                            {listButton?.cancel && (
                                <span className="v2-btn-default --transparent" onClick={handleCancel} tabIndex="0">
                                    {titleCancel || t('common:cancel')}
                                </span>
                            )}
                            {listButton?.close && (
                                <span className="v2-btn-default --transparent" onClick={handleClose} tabIndex="0">
                                    {titleClose || t('common:close')}
                                </span>
                            )}
                            {listButton?.confirm && (
                                <div
                                    className={classNames('v2-btn-main', { 'is-disable': isDisableConfirm })}
                                    onClick={handleConfirm}
                                    tabIndex="0"
                                >
                                    {titleConfirm || t('common:confirm')}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
);
export default GdConfirm;
