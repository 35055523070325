import React from 'react';

export default function Loading() {
    return (
        <div className="detail-plan__items flex-column gap-16">
            <div className="flexcenter gap-8">
                <div className="flexcenter gap-4 flex-1">
                    <div className="numerical-order flex-centeritem">
                        <div className="loading --animation --line --threefourth"></div>
                    </div>
                    <div className="v2-btn-default loading field-h24 bg-white-cultured btn-sm"></div>
                </div>
                <div className="item-right flexcenter gap-8">
                    <div className="list-techs flexcenter gap-4 flex-1">
                        <div className="loading --animation --line --half"></div>
                        <div className="wrap-member-group flexcenter flex-1">
                            <div className="avt-img"></div>
                            <div className="avt-img"></div>
                            <div className="avt-img"></div>
                        </div>
                    </div>
                    <div className="date">
                        <div className="loading --animation --line --full"></div>
                    </div>
                    <div className="time">
                        <div className="loading --animation --line --full"></div>
                    </div>
                    <div className="v2-btn-default loading field-h24 bg-white-cultured btn-x-sm"></div>
                    <div className="v2-btn-default loading bg-white-cultured --icon-sm"></div>
                </div>
            </div>
        </div>
    );
}
