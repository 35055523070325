export const DecoratorLinkEntity = (props) => {
    const { contentState, entityKey } = props;
    if (!entityKey) return null;
    const { url, isBlank } = contentState.getEntity(entityKey).getData();
    return (
        <a href={url || props.decoratedText} target={isBlank ? '_blank' : '_self'} rel="noreferrer">
            {props.children}
        </a>
    );
};

export const DecoratorLink = (props) => {
    const { contentState, entityKey, decoratedText, children } = props;
    if (!entityKey)
        return (
            <a href={decoratedText} title={decoratedText}>
                {children}
            </a>
        );
    const { url, isBlank } = contentState.getEntity(entityKey).getData();
    const finalHref = url || decoratedText;
    return (
        <a href={finalHref} target={isBlank ? '_blank' : '_self'} rel="noreferrer" title={finalHref}>
            {children}
        </a>
    );
};
