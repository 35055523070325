import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import IconArrowDown from 'assets/icon/IconArrowDown';
import IconCircleClose from 'assets/icon/IconCircleClose';
import IconSearch from 'assets/icon/IconSearch';

let timer;

const SearchOption = ({
    onFocus = () => {},
    onCloseClick = () => {},
    onKeyDown = () => {},
    autoFocus = false,
    placeholder,
    onSearch = () => {},
    defaultValue = '',
    style = '',
    parentStyle = 'flex-auto header-search',
    isSearchIcon = false,
    isDisable = false,
    isAlwaysDisplayClose = false,
    triggerFocus = 0,
    timeDdebound = 500,
    spellCheck = true,
    isShowClose = true
}) => {
    const searchInputRef = useRef(null);

    const [state, setState] = useState({
        term: '',
        typing: false
    });

    const { term: finalTerm, typing } = state;

    useEffect(() => {
        if (defaultValue !== finalTerm) {
            setState({ ...state, term: defaultValue, typing: false });
        }
    }, [defaultValue]);

    useEffect(() => {
        if (triggerFocus) {
            searchInputRef.current.focus();
        }
    }, [triggerFocus]);

    useEffect(() => {
        const timeOut = !!finalTerm ? timeDdebound : 0;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            if (typing) {
                onSearch(finalTerm);
            }
        }, timeOut);

        return () => {
            clearTimeout(timer);
        };
    }, [finalTerm]);

    const _handleSearchTerm = (e) => {
        setState({ term: e.target.value, typing: true });
    };

    const _handleClearTerm = () => {
        !!finalTerm && setState({ term: '', typing: true });
        onCloseClick();
    };

    const _handleOnClickWrapperSearchInput = (e) => {
        e.preventDefault();
        onFocus(e);
    };

    return (
        <div className={classNames(parentStyle, { 'is-disable': isDisable })}>
            <div className={'search-input'} onClick={_handleOnClickWrapperSearchInput}>
                {isSearchIcon && (
                    <span className="svg-search-absolute">
                        <IconSearch />
                    </span>
                )}
                <input
                    ref={searchInputRef}
                    type="text"
                    className={style}
                    value={finalTerm}
                    placeholder={placeholder}
                    onChange={_handleSearchTerm}
                    autoFocus={autoFocus}
                    disabled={isDisable}
                    spellCheck={spellCheck}
                    onKeyDown={onKeyDown}
                />
                {((finalTerm.length !== 0 && !isDisable) || isAlwaysDisplayClose) && isShowClose && (
                    <span className="close-icon" onClick={_handleClearTerm}>
                        <IconCircleClose />
                    </span>
                )}
                {!isShowClose && (
                    <span className="arrow">
                        <IconArrowDown />
                    </span>
                )}
            </div>
        </div>
    );
};

export default SearchOption;
