import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonSave from 'app/components/button/ButtonSave';
import GDStatusBar from 'app/components/status/statusbar';
import { REPORT_TYPE } from 'app/const/Reports';
import { GET_CHATBOT_TRAIN_AI } from 'app/const/api/V2';
import { CHATBOT_TRAIN_AI_FILTER } from 'app/const/report/ReportFilter';
import ErrorPage from 'app/modules/error';
import IconSync from 'assets/icon/IconSync';
import { clientQuery } from 'common/utils/ApiUtils';
import { showStatusBar } from 'common/utils/FunctionUtils';
import TableChatbot from '../components/TableChatbot';
import { getColumnTrainAI } from '../constants/gridColumnTrainAI';
import ModalTrainAI from './ModalTrainAI';

const ChatbotTrainAI = () => {
    const { t } = useTranslation('addons');

    const typeReport = REPORT_TYPE.CHATBOT_TRAIN_AI;
    // hide train ai for now
    const havePermission = false;

    const refTable = useRef(null);
    const refAlert = useRef('');
    const refModal = useRef(null);
    const refButtonSave = useRef(null);

    const _handleGetData = () => {
        refTable.current?.getData();
    };

    const _handleClickRow = ({ row }) => {
        refModal.current.open({
            id: row.id,
            filter: refTable.current.getFilter(),
            commonQuestion: row.question
        });
    };

    const _handleShowAlert = (alert = {}) => {
        showStatusBar({ id: 'chat_bot_train_ai', ...alert, refAlert });
    };

    const _handlePullQuestion = () => {
        const _handleSuccess = () => {
            _handleGetData();
            _removeLoading();
        };

        const _handleFail = ({ message }) => {
            _handleShowAlert(message);
            _removeLoading();
        };

        clientQuery(GET_CHATBOT_TRAIN_AI, { data: {}, method: 'PUT' }, _handleSuccess, _handleFail);
    };

    const _removeLoading = () => {
        refButtonSave.current?.removeLoading();
    };

    if (!havePermission) return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;

    return (
        <div className="wrapper-chatbot flex-column">
            <div className="flex-betweenitems">
                <h3 className="wrapper-chatbot__title fs-600 fs-18 black">{t('train_ai')}</h3>
                <ButtonSave
                    ref={refButtonSave}
                    wrapClass="v2-btn-main svg-white-stroke has-icon btn-question-sms justify-center"
                    onSave={_handlePullQuestion}
                >
                    <IconSync />
                    {t('update_question_from_sms')}
                </ButtonSave>
            </div>
            <div className="container-print container-wrap">
                <GDStatusBar ref={refAlert} />
                <TableChatbot
                    ref={refTable}
                    reportType={typeReport}
                    endpoint={GET_CHATBOT_TRAIN_AI}
                    gridColumnReport={getColumnTrainAI}
                    isShowDateRange
                    isShowCheckbox={false}
                    filters={CHATBOT_TRAIN_AI_FILTER}
                    onClickRow={_handleClickRow}
                    onShowAlert={_handleShowAlert}
                    onGetData={_handleGetData}
                />
            </div>
            <ModalTrainAI ref={refModal} />
        </div>
    );
};

export default ChatbotTrainAI;
