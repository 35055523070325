import React from 'react';
import Pagination from 'app/components/pagination';
import ReportFilterTabs from 'app/modules/report/components/ReportTabsFilter';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { KEY_REPORT_LOCAL_STORAGE, LIST_PAGE_SIZE } from 'app/const/App';
import { getDefaultParams } from 'app/const/report/ReportParams';
import { TABS_FILTER_BOTTOM } from 'app/const/report/ReportFilter';

function ReportPagination({
    totalPage = 0,
    reportType = '',
    onSelect,
    children,
    isCustomerPage = false,
    isMultipleBranchPage = false,
    classTabList = 'tab-selectors btn-item ml-0',
    classWrapper = 'header-items tabs'
}) {
    const paramsLocal = getLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', reportType));
    let params = paramsLocal ? paramsLocal : getDefaultParams(reportType);

    const handleChangePageOrPageSize = (data) => {
        params = { ...params, ...data };
        setLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', reportType), params);
        onSelect && onSelect(params, false);
    };

    function _handleTab(params) {
        onSelect && onSelect(params, true);
    }

    const renderListTabsFilter = () => {
        if (TABS_FILTER_BOTTOM[reportType]) {
            return (
                <ReportFilterTabs
                    list={TABS_FILTER_BOTTOM[reportType]}
                    reportType={reportType}
                    onSelect={_handleTab}
                    classWrapper={classWrapper}
                    classTabList={classTabList}
                />
            );
        }
        return null;
    };

    return (
        <Pagination
            currentPageSize={params.limit}
            pageSize={LIST_PAGE_SIZE}
            currentPage={params?.currentPage}
            totalPage={totalPage}
            isCustomerPage={isCustomerPage}
            isMultipleBranchPage={isMultipleBranchPage}
            onChange={handleChangePageOrPageSize}
        >
            {children || renderListTabsFilter()}
        </Pagination>
    );
}

export default ReportPagination;
