import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { ADDONS_VOIP_PLAN } from 'app/config/routes';
import { LIST_STATUS } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { LINK_LEARN_MORE_VOIP } from 'app/const/Voip';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import { updateAddonStatus } from 'common/redux/actions/authAction';
import AddonGetStarted from '../../components/AddonGetStarted';
import ModalManagePlan from './plan/ModalManagePlan';

const VOIPAddonsGetStarted = ({ voip, onChangePlan = () => {} }, ref) => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const refAlert = useRef(null);

    const { openManage } = location.state || {};
    const { account, upgrade, status } = voip;

    const [state, dispatchState] = useReducer(reducer, {
        isVisibleModal: !!openManage,
        account
    });

    const { isVisibleModal, account: finalAccount } = state;
    const { balance, user_quantity, credit, usage_this_month } = finalAccount || {};
    const isActivePlan = !!user_quantity;

    useEffect(() => {
        if (location.state?.isActiveSuccess)
            refAlert.current.showStatusBar({
                id: 'index_addon_voip',
                message: t('addons:your_voip_plan_has_been_activated'),
                type: LIST_STATUS.SUCCESS
            });
    }, []);

    useImperativeHandle(ref, () => ({
        _openManage: _handleClickManage
    }));

    const _handleClickActivated = () => {
        history.push(addBranchPath(ADDONS_VOIP_PLAN));
    };

    const _handleClickManage = () => {
        dispatchState({ isVisibleModal: true });
    };

    const _handleCloseModal = () => {
        dispatchState({ isVisibleModal: false });
    };

    const _handleUpdatePlan = ({ account }) => {
        dispatchState({ account });
    };

    const _handleChangePlan = () => {
        onChangePlan();
        !status && dispatch(updateAddonStatus({ keyword: 'voip', data: {} }));
    };

    return (
        <Fragment>
            <AlertCustomer ref={refAlert} />
            <AddonGetStarted
                upgrade={upgrade}
                isActivePlan={isActivePlan}
                textGettingStarted="getting_started_with_voip"
                textActive="desc_getting_started_voip_active"
                textInActive="desc_getting_started_voip"
                textLearnMore="learn_more_about_voip"
                linkLearnMore={LINK_LEARN_MORE_VOIP}
                balance={Number(balance - (credit >= usage_this_month ? 0 : usage_this_month - credit)).toFixed(2)}
                userQuantity={user_quantity}
                textButton={isActivePlan ? 'manage_plan' : 'activate_a_plan'}
                onClickButton={isActivePlan ? _handleClickManage : _handleClickActivated}
            />
            {isVisibleModal && (
                <ModalManagePlan
                    onClose={_handleCloseModal}
                    onUpdateSuccess={_handleUpdatePlan}
                    onChangePlan={_handleChangePlan}
                    isCanceled={!status}
                />
            )}
        </Fragment>
    );
};

export default forwardRef(VOIPAddonsGetStarted);
