import { call, put } from 'redux-saga/effects';

import { fetchWithToken } from 'common/utils/ApiUtils';
import { SETTINGS_GET_LIST_SERVICE, SETTINGS_GET_LIST_SERVICE_PLAN } from 'app/const/Api';
import { TYPE_SETTING_SERVICE } from 'common/redux/actions/settings/serviceAction';

export function* getListService({ params, actionSuccess = () => {}, actionFailed = () => {} }) {
    try {
        const response = yield call(fetchWithToken, SETTINGS_GET_LIST_SERVICE, params);
        if (response && response.success) {
            actionSuccess(response);
            if (params?.localSaveStore && response.data) {
                yield put({
                    type: TYPE_SETTING_SERVICE.GET_LIST_SUCCESS,
                    data: response,
                    resetData: params.localResetData
                });
            }
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListServicePlan({ params, actionSuccess = () => {}, actionFailed = () => {} }) {
    try {
        const response = yield call(fetchWithToken, SETTINGS_GET_LIST_SERVICE_PLAN, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
