import classNames from 'classnames';
import React, { useEffect, useReducer, useRef } from 'react';

import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { reducer } from 'app/const/Reducer';
import IconLoading from 'assets/icon/IconLoading';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { isScrollToEndBottom } from 'common/utils/FunctionUtils';

const DropdownCustomerContact = ({
    id = '',
    options = [],
    selected = {},
    isLoading = false,
    isLoadMore = false,
    onSelect = () => {},
    onScroll = () => {},
    onVisible = () => {}
}) => {
    const [state, dispatchState] = useReducer(reducer, { isVisible: false });
    const { isVisible: finalIsVisible } = state;
    const { id: idSelected, full_name: fullNameSelected = '' } = selected;

    const refDropdown = useRef(null);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', _handleClickOutside, true);
            document.addEventListener('keydown', _handleHideDropdown, true);
            onVisible();
        } else {
            document.removeEventListener('click', _handleClickOutside, true);
            document.removeEventListener('keydown', _handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', _handleClickOutside, true);
            document.removeEventListener('keydown', _handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    const _handleHideDropdown = (event) => {
        const elPrevent = document.getElementById(id);
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeDropdown();
        }
    };

    const _handleClickOutside = (event) => {
        const elPrevent = document.getElementById(id);
        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeDropdown();
        }
    };

    const _closeDropdown = () => {
        finalIsVisible && dispatchState((prev) => ({ ...prev, isVisible: false }));
    };

    const _toggleDropdown = () => {
        dispatchState((prev) => ({ ...prev, isVisible: !prev.isVisible }));
    };

    const _renderLoading = () => {
        return (
            <li className="items justify-center">
                <div className="loading -ajaxbar">
                    <IconLoading />
                </div>
            </li>
        );
    };

    const _handleSelect = (id) => {
        onSelect(id);
        _closeDropdown();
    };

    const _renderList = () => {
        return options.map(({ id, full_name = '' }) => (
            <li
                key={id}
                className={classNames('items', { active: id === idSelected })}
                onClick={() => _handleSelect(id)}
            >
                {full_name}
            </li>
        ));
    };

    const _handleOnScrollContent = (e) => {
        if (!isLoadMore && isScrollToEndBottom(e.target)) {
            onScroll();
        }
    };

    return (
        <div ref={refDropdown} id={id} className={classNames('v2-dropdown', { active: finalIsVisible })}>
            <div className="dropbtn items" onClick={_toggleDropdown}>
                <div className="txt-ellipsis">{fullNameSelected}</div>
                <div className="arrow">
                    <IconArrowDown />
                </div>
            </div>
            <div className="v2-dropdown__menu content-full scrolls" onScroll={_handleOnScrollContent}>
                {isLoading && !isLoadMore ? _renderLoading() : null}
                {finalIsVisible && !isLoading ? <ul>{_renderList()}</ul> : null}
                {isLoadMore ? _renderLoading() : null}
            </div>
        </div>
    );
};

export default DropdownCustomerContact;
