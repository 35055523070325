import { reducer } from 'app/const/Reducer';
import IconPlus from 'assets/icon/IconPlus';
import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import GridEmpty from 'app/components/grid/components/GridEmpty';
import { VOIP_AGENT_ROUTING } from 'app/const/Voip';
import IconCodeView from 'assets/icon/IconCodeView';
import { DigitsAutoAttendant } from './DigitsAutoAttendant';
import { EditAutoAttendant } from './EditAutoAttendant';
import { ForwardAutoAttendant } from './ForwardAutoAttendant';

const ExtensionsAutoAttendant = ({ extensions = [] }, ref) => {
    const { t } = useTranslation('addons');
    const refData = useRef([]);

    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        extensions: []
    });

    useEffect(() => {
        dispatchState((prev) => {
            const newExtensions = extensions.map((item) => ({ id: uuidv4(), ...item }));
            refData.current = newExtensions;
            return { ...prev, extensions: newExtensions, isLoading: false };
        });
    }, [extensions]);

    const { extensions: finalExtensions } = state;

    useImperativeHandle(ref, () => ({
        getExtensions: () => refData.current
    }));

    const _renderContent = () => {
        const _renderListItem = () => {
            if (!finalExtensions.length) return <GridEmpty isFlat />;

            return finalExtensions.map((item) => (
                <div key={item.id} className="rows">
                    <div className="col --sm p-0">
                        <DigitsAutoAttendant item={item} onChangeDigits={_handleChangeDigits} />
                    </div>
                    <div className="col --lg p-0">
                        <ForwardAutoAttendant
                            item={item}
                            onChangeForward={_handleChangeForward}
                            onSelectPhone={_handleSelectPhone}
                            onChangeAgentRouting={_handleChangeAgentRouting}
                            onChangeNumbers={_handleChangeNumbers}
                        />
                    </div>
                    <div className="col --action flex-centeritem">
                        <EditAutoAttendant item={item} onDelete={_handleDelete} />
                    </div>
                </div>
            ));
        };

        return (
            <div className="container-print gap-12">
                <div className="btn-add v2-btn-default has-icon" onClick={_handleAddExtension}>
                    <IconPlus />
                    {t('add_extension')}
                </div>

                <div className="tables wrap-border tables flex-none">
                    <div className="rows --fixed --header">
                        <div className="col --sm px-0 text-center ">
                            <p className="col-label">{t('extension')}</p>
                        </div>
                        <div className="col --lg px-3 ">
                            <p className="col-label">{t('forward_to')}</p>
                        </div>
                        <div className="col --action px-0 ">
                            <p className="col-label" />
                        </div>
                    </div>
                    <div className="tables-list">{_renderListItem()}</div>
                </div>
            </div>
        );
    };

    const _handleAddExtension = () => {
        dispatchState((prev) => {
            const extensions = [
                ...prev.extensions,
                { id: uuidv4(), digits: null, numbers: [], agent_routing: VOIP_AGENT_ROUTING.DEFAULT_VALUE }
            ];
            refData.current = extensions;
            return { ...prev, extensions };
        });
    };

    const _handleDelete = (id) => {
        dispatchState((prev) => {
            const extensions = prev.extensions.filter((item) => item.id !== id);
            refData.current = extensions;
            return { ...prev, extensions };
        });
    };

    const _handleChangeDigits = ({ id, value }) => {
        dispatchState((prev) => {
            const extensions = prev.extensions.map((item) => {
                if (item.id === id) return { ...item, digits: value };
                return item;
            });
            refData.current = extensions;
            return { ...prev, extensions };
        });
    };

    const _handleChangeForward = () => {
        dispatchState((prev) => ({ ...prev, extensions: refData.current }));
    };

    const _handleSelectPhone = ({ id, selected = [] }) => {
        refData.current = refData.current.map((item) => {
            if (item.id === id) return { ...item, numbers: selected };
            return item;
        });
    };

    const _handleChangeAgentRouting = ({ id = '', value = VOIP_AGENT_ROUTING.DEFAULT_VALUE }) => {
        dispatchState((prev) => {
            const extensions = prev.extensions.map((item) =>
                item.id === id ? { ...item, agent_routing: value } : item
            );
            refData.current = extensions;
            return { ...prev, extensions };
        });
    };

    const _handleChangeNumbers = ({ id = '', numbers = [] }) => {
        dispatchState((prev) => {
            const extensions = prev.extensions.map((item) => (item.id === id ? { ...item, numbers } : item));
            refData.current = extensions;
            return { ...prev, extensions };
        });
    };

    return (
        <div className="box-st-hour flex-column gap-8">
            <h3 className="flexcenter gap-8 black fs-14 svg-green">
                <IconCodeView />
                {t('extensions')}
            </h3>
            <p>{t('desc_extensions_auto_attendant_settings')}</p>
            {_renderContent()}
        </div>
    );
};

export default forwardRef(ExtensionsAutoAttendant);
