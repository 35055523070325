export default function IconYoutube({ isOnBoarding = false }) {
    if (isOnBoarding) {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    opacity="0.4"
                    d="M19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5Z"
                    fill="#8D4AFC"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.0239 11.781C14.5243 11.3807 14.5243 10.6196 14.0239 10.2192L11.6247 8.29987C10.9699 7.77606 10 8.24223 10 9.08073L10 12.9195C10 13.758 10.9699 14.2242 11.6247 13.7004L14.0239 11.781ZM21 17.0001H3C3 18.1047 3.89543 19.0001 5 19.0001H19C20.1046 19.0001 21 18.1047 21 17.0001Z"
                    fill="#8D4AFC"
                ></path>
            </svg>
        );
    }

    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.94304 6.30761L8.56379 5.95818C10.8445 5.65408 13.1556 5.65408 15.4363 5.95818L18.057 6.30761C18.9172 6.42229 19.6052 7.07855 19.7605 7.93229L20 9.25001C20.3307 11.0684 20.3307 12.9316 20 14.75L19.7605 16.0677C19.6052 16.9215 18.9172 17.5777 18.057 17.6924L15.4363 18.0418C13.1556 18.3459 10.8445 18.3459 8.56379 18.0418L5.94304 17.6924C5.08291 17.5777 4.39485 16.9215 4.23963 16.0677L4.00004 14.75C3.66942 12.9316 3.66942 11.0684 4.00004 9.25001L4.23963 7.93229C4.39485 7.07855 5.08291 6.42229 5.94304 6.30761Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />{' '}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5 10.4207V13.5794C10.5 14.1317 10.9477 14.5794 11.5 14.5794C11.7052 14.5794 11.9054 14.5163 12.0735 14.3986L14.3297 12.8193C14.7821 12.5025 14.8922 11.879 14.5754 11.4266C14.5085 11.3309 14.4253 11.2477 14.3297 11.1808L12.0735 9.60145C11.621 9.28473 10.9975 9.39477 10.6808 9.84722C10.5631 10.0153 10.5 10.2155 10.5 10.4207Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
