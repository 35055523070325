import classNames from 'classnames';
import moment from 'moment';
import React, { useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import GDIframe from 'app/components/iframe';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { SETTINGS_COMPANY, SETTINGS_PLANS } from 'app/const/Route';
import { CUSTOMER_STATUS } from 'app/modules/customer/const';
import NewDocument from 'app/modules/quickadd/document';
import NewInvoice from 'app/modules/quickadd/invoice';
import IconPlay from 'assets/icon/IconPlay';
import { createCustomer } from 'common/redux/actions/customer';
import { actionCreateJob } from 'common/redux/actions/job/create';
import {
    ADD_CUSTOMER,
    ADD_DOCUMENT,
    ADD_INVOICE,
    ADD_JOB,
    NEXT_STEP_ADD_CUSTOMER,
    NEXT_STEP_DEMO,
    ONBOARDING_POPULAR_VIDEOS,
    PLANS_AND_PRICING,
    UPLOAD_YOUR_LOGO
} from '../../constant';

const OnBoardingVideoPopular = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation('onboarding');
    const [state, dispatchState] = useReducer(reducer, { videoSelected: ONBOARDING_POPULAR_VIDEOS[0] });
    const { videoSelected } = state;
    const {
        id: videoSelectedId,
        type: videoSelectedType,
        title: videoSelectedTitle,
        isNextStep = false
    } = videoSelected || {};

    const refFormNewInvoice = useRef(null);
    const refFormNewDocument = useRef(null);

    const _handleSelectVideo = (data) => {
        if (videoSelectedId === data.id) return;
        dispatchState((prev) => ({ ...prev, videoSelected: data }));
    };

    const roundMinutesToNearestInterval = (minutes) => {
        const intervals = [0, 15, 30, 45];
        return intervals.find((item) => minutes <= item) || 0;
    };

    const _handleStartOnBoarding = (type) => {
        const finalType = type || videoSelectedType;
        switch (finalType) {
            case ADD_CUSTOMER:
                dispatch(createCustomer({ defaultStatus: CUSTOMER_STATUS[1].type, isVisibleModal: true }));
                break;
            case ADD_JOB:
                const finalMinute = roundMinutesToNearestInterval(parseInt(moment().format('mm')));
                dispatch(
                    actionCreateJob({
                        start: `${moment().format(`yyyy-MM-DDTHH:${finalMinute > 0 ? finalMinute : '0'.concat(finalMinute)}:ss`)}Z`
                    })
                );
                break;
            case ADD_INVOICE:
                refFormNewInvoice.current._handleOpenForm();
                break;
            case PLANS_AND_PRICING:
                history.push(addBranchPath(SETTINGS_PLANS));
                break;
            case NEXT_STEP_DEMO:
            case NEXT_STEP_ADD_CUSTOMER:
                _handleSelectVideo({
                    ...ONBOARDING_POPULAR_VIDEOS.find(
                        (item) => item.type === (finalType === NEXT_STEP_DEMO ? NEXT_STEP_ADD_CUSTOMER : ADD_CUSTOMER)
                    ),
                    isAutoPlay: true
                });
                break;
            case ADD_DOCUMENT:
                refFormNewDocument.current._handleOpenForm();
                break;
            case UPLOAD_YOUR_LOGO:
                history.push(addBranchPath(SETTINGS_COMPANY));
                break;
            default:
                break;
        }
    };

    const _handleSelectStartOnBoarding = (e, item) => {
        e.stopPropagation();
        _handleSelectVideo(item);
        _handleStartOnBoarding(item.type);
    };

    return (
        <div className="onboarding-items flextop gap-16">
            <div className="onboarding-steps">
                <h5 className="black fw-500 fs-15">{t('common:get_started')}</h5>
                <div className="flex-column gap-8 mt-3">
                    {ONBOARDING_POPULAR_VIDEOS.map((item) => {
                        const { id, icon, title, isHideStartOnBoarding } = item;
                        return (
                            <div
                                key={id}
                                className={classNames('step-items flexcenter gap-8', {
                                    active: videoSelectedId === id
                                })}
                                onClick={() => _handleSelectVideo(item)}
                            >
                                <div className="step-items__icon">{icon}</div>
                                <div className="flex-1 black fw-500 txt-ellipsis" title={title}>
                                    {title}
                                </div>
                                {!isHideStartOnBoarding ? (
                                    <div
                                        className="v2-btn-main --icon-r fs-14"
                                        onClick={(e) => _handleSelectStartOnBoarding(e, item)}
                                    >
                                        {t('start_onboarding')}
                                        <IconPlay isTracking />
                                    </div>
                                ) : null}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="onboarding-video flex-column gap-15">
                <h5 className="black fw-500 fs-15" title={videoSelectedTitle}>
                    {videoSelectedTitle}
                </h5>
                <GDIframe
                    {...videoSelected}
                    className="onboarding-video__iframe"
                    loadingComponent={() => <div className="onboarding-video__iframe loading --animation" />}
                />
                <div className="text-right">
                    <div
                        className="v2-btn-main --icon-r --large btn-onboarding fs-14"
                        onClick={() => _handleStartOnBoarding()}
                    >
                        {t(isNextStep ? 'next_step' : 'start_onboarding')}
                        <IconPlay isTracking />
                    </div>
                </div>
            </div>
            <NewInvoice ref={refFormNewInvoice} />
            <NewDocument ref={refFormNewDocument} />
        </div>
    );
};

export default OnBoardingVideoPopular;
