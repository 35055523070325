import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { ACCESS_SETTINGS_TAB } from 'app/const/Permissions';
import { checkPermission } from 'common/utils/PermissionUtils';
import AddonsOptionsFooterManage from './AddonsOptionsFooterManage';

const FooterManageServicePlan = ({ pathManage = '' }) => {
    const servicePlanAddon = useSelector(({ auth }) => auth.user.settings.addons.service_plan);
    const permissionsList = useSelector(({ auth }) => auth.user?.permissions?.enabled || []);

    const havePermissionSetting = !!servicePlanAddon && checkPermission(permissionsList, ACCESS_SETTINGS_TAB);
    return (
        <div className="boxs__footer">
            <AddonsOptionsFooterManage
                path={pathManage}
                className={classNames('v2-btn-default svg-purple btn-bg-purple --icon-r --sm', {
                    'is-disable': !havePermissionSetting
                })}
            />
        </div>
    );
};

export default FooterManageServicePlan;
