import React, { Fragment, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonSave from 'app/components/button/ButtonSave';
import { MATERIAL_USE_SELECT_OPTIONS } from 'app/const/App';
import {
    COLUMNS,
    COLUMNS_NY,
    MATERIAL_NEW_YORK_LIST_FILTER,
    MATERIAL_USE_DETAIL_LIST_FILTER,
    REVENUE_BY_CLIENT_LIST_FILER,
    SORT_BY
} from 'app/const/report/ReportFilter';
import { EXCLUDE_REPORT_LOADING, REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import ReviewDateRangePicker from 'app/modules/review/components/ReviewDateRangePicker';
import SalesTaxSummaryFilter from '../salestaxsummary/components/SalesTaxSummaryFilter';
import FilterReport from './FilterReport';
import ReportDatePicker from './ReportDatePicker';
import ReportDateRangePicker from './ReportDateRangePicker';
import RevenuePagesFilter from './RevenuePagesFilter';

const HeaderBottom = ({
    classNameHeader = 'header',
    typeReport = '',
    filters = [],
    currentTab,
    forceRerender,
    forceRerenderFilter,
    isShowAvatar = false,
    updateReportByFilter = null,
    companyUsers = '',
    isNotShowDateRange = false,
    isLoading = false,
    params = null,
    keyLocalStore = null,
    isShowBtnUpdate = true,
    handleChangeFilter = () => {},
    handleUpdate = () => {}
}) => {
    const { t } = useTranslation();
    const refButtonSave = useRef(true);
    const isOptionNYPRL = params?.county === MATERIAL_USE_SELECT_OPTIONS.NY_PRL;

    useEffect(() => {
        if (isShowBtnUpdate && isLoading) _setDisabledButtonSave(true);
    }, [isLoading]);

    const _setDisabledButtonSave = (isDisable = false) => refButtonSave.current.setDisable(isDisable);

    const renderDateRangePicker = () => {
        if (isNotShowDateRange) return null;

        const classNameMappings = {
            [REPORT_TYPE.EMAIL_HISTORY]: {
                wrapperClassName: 'item-report header-items',
                customInputClassName: 'field-input field-date'
            }
        };

        const { wrapperClassName, customInputClassName } = classNameMappings[typeReport] || {
            wrapperClassName: 'header-items',
            customInputClassName: ''
        };

        switch (typeReport) {
            case REPORT_TYPE.ACCOUNTS_AGING:
                return (
                    <Fragment>
                        <div className="header-items">
                            <p className="header-label fs-14">{t('report:period_ending')}</p>
                        </div>
                        <ReportDatePicker
                            reportType={typeReport}
                            keyLocal="period_ending"
                            onSelect={() => _setDisabledButtonSave(false)}
                        />
                    </Fragment>
                );
            case REPORT_TYPE.REVIEW_PAGE_PARAMS_LIKELY:
            case REPORT_TYPE.REVIEW_PAGE_PARAMS_SERVICE:
                return <ReviewDateRangePicker activeTab={currentTab} onSelectDate={handleUpdate} />;
            case REPORT_TYPE.MATERIAL_USE:
                return (
                    <ReportDateRangePicker
                        reportType={typeReport}
                        onSelect={() => handleUpdate({ currentPage: 1, offset: 0 })}
                        wrapperClassName={wrapperClassName}
                        customInputClassName={customInputClassName}
                    />
                );
            default:
                return (
                    <ReportDateRangePicker
                        reportType={typeReport}
                        onSelect={handleUpdate}
                        forceRender={forceRerender || forceRerenderFilter}
                        wrapperClassName={wrapperClassName}
                        customInputClassName={customInputClassName}
                        isAddMonthWhenOpen={typeReport !== REPORT_TYPE.SERVICE_LOOKUP}
                    />
                );
        }
    };

    const _handleChangeFilter = (params, mode) => {
        handleChangeFilter(params, mode);

        if (
            !isLoading &&
            !!mode &&
            mode !== COLUMNS &&
            mode !== COLUMNS_NY &&
            (EXCLUDE_REPORT_LOADING.includes(typeReport) ? mode !== SORT_BY : true)
        )
            _setDisabledButtonSave(false);
    };

    return (
        <div className={classNameHeader}>
            {renderDateRangePicker()}
            {typeReport === REPORT_TYPE.REVENUE_BY_CLIENT && (
                <RevenuePagesFilter
                    initListFilter={REVENUE_BY_CLIENT_LIST_FILER.ROW_1}
                    initSale={params?.sale}
                    currentTab={currentTab}
                    keyReport={keyLocalStore}
                    forceRerender={forceRerenderFilter}
                    handleChangeFilter={_handleChangeFilter}
                />
            )}
            {typeReport === REPORT_TYPE.SALES_TAX_SUMMARY && (
                <SalesTaxSummaryFilter
                    initRevenue={params?.revenue}
                    onChangeDisableButtonSave={_setDisabledButtonSave}
                />
            )}
            <FilterReport
                reportType={typeReport}
                filters={filters}
                tab={currentTab}
                companyUsers={companyUsers}
                forceRerender={
                    typeReport === REPORT_TYPE.MATERIAL_USE
                        ? forceRerenderFilter && params?.county !== MATERIAL_USE_SELECT_OPTIONS.NY_PRL
                        : forceRerenderFilter
                }
                isShowAvatar={isShowAvatar}
                onSelect={_handleChangeFilter}
            />
            {(params?.county === MATERIAL_USE_SELECT_OPTIONS.ALL_DETAILED || isOptionNYPRL) && (
                <FilterReport
                    reportType={REPORT_TYPE.MATERIAL_USE}
                    filters={isOptionNYPRL ? MATERIAL_NEW_YORK_LIST_FILTER : MATERIAL_USE_DETAIL_LIST_FILTER}
                    onSelect={_handleChangeFilter}
                    forceRerender={forceRerenderFilter}
                />
            )}
            {isShowBtnUpdate ? (
                <ButtonSave
                    ref={refButtonSave}
                    title={t('common:update')}
                    wrapClass="v2-btn-main header-items"
                    isNotLoading
                    onSave={updateReportByFilter || handleUpdate}
                />
            ) : null}
        </div>
    );
};

export default HeaderBottom;
