import React, { Fragment, useEffect, useMemo, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ButtonSave from 'app/components/button/ButtonSave';
import GdConfirm from 'app/components/confirm';
import GDModalWarning from 'app/components/modal/ModalWarning';
import { handleClosePhoneDropdown } from 'app/components/phone/PhoneDropdown';
import InsertJobNotes from 'app/components/templatenotes/insert';
import { getDataJobDetail } from 'app/const/Api';
import { PERMISSIONS } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import { TYPE_OPEN_SMS } from 'app/const/Sms';
import SmsJobDetail from 'app/modules/jobdetail/components/sms/Sms';
import { TABS } from 'app/modules/jobdetail/const';
import Header from 'app/modules/jobdetail/header';
import Services from 'app/modules/jobdetail/services';
import RealTimeJobService from 'app/modules/jobdetail/services/RealTimeJob';
import SideBar from 'app/modules/jobdetail/SideBar';
import TabContent from 'app/modules/jobdetail/tabs';
import { useEnterKeydownClick } from 'common/hooks';
import { actionOpenJobDetail } from 'common/redux/actions/job/detail';
import { checkAddon } from 'common/utils/AddonUtils';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkPermission } from 'common/utils/PermissionUtils';
import { handleRemoveTimeWindowEl } from '../calendar/ultil/Calendar';
import InsertImage from './tabs/invoice/images/Insert';
import ServiceNotifyCustomer from '../addjob/ServiceNotifyCustomer';
import { DEFAULT_TIME_TIMEOUT, LIST_STATUS } from 'app/const/App';
import { getTypeConfirmation } from 'common/utils/JobStatusUtils';

const JobDetail = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation(['customers', 'jobDetail']);
    const isFirstTime = useRef(true);
    const refTabContent = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        isVisible: false,
        tab: TABS.DETAIL,
        reloadJobDetail: 0,
        reloadInvoiceDetail: 0,
        reloadSoldBy: 0,
        reloadSchedule: 0,
        activeTabInvoice: false,
        activeTabEstimate: false,
        activeTabInvoiceDetail: false,
        activeTabEstimateDetail: false,
        activeTabScheduling: false,
        activeTabWorkOrder: false,
        onUpdateJobData: () => {}
    });
    const { permissions: permissionsList, settings } = useSelector(({ auth }) => auth?.user);
    const addons = settings?.addons || {};
    const refConfirm = useRef(null);
    const refConfirmSendReminder = useRef(null);
    const refButtonSave = useRef(null);
    const refSidebar = useRef(null);
    const refServiceNotifyCustomer = useRef(null);

    const {
        isLoading: finalIsLoading,
        isVisible: finalIsVisible,
        onUpdateJobData,
        job: jobData,
        schedule: finalSchedule,
        reloadSchedule: finalReloadSchedule,
        event: finalEvent
    } = state;

    const jobId = jobData?.id;

    const editCheckTime = useMemo(() => {
        return checkPermission(permissionsList?.enabled || [], PERMISSIONS.editCheckTime);
    }, []);

    const activeAddons = useMemo(() => {
        return {
            materials: checkAddon(addons.material),
            documents: checkAddon(addons.documents),
            units: checkAddon(addons.mdu_tracking),
            devices: checkAddon(addons.device_tracking)
        };
    }, [finalIsVisible]);

    useEffect(() => {
        const { isNotifyCustomer } = refSidebar.current?.getValue() || {};
        if (finalReloadSchedule && isNotifyCustomer) {
            _handleOpenNotifyCustomer();
        }
    }, [finalReloadSchedule]);

    useEffect(() => {
        return () => {
            _closeJobDetail();
        };
    }, []);

    useEnterKeydownClick(finalIsVisible);

    function _handleOpenJob(jobData) {
        if (!jobData) {
            return false;
        }

        const jobId = jobData?.id || 0;

        dispatchState({
            isVisible: true,
            isLoading: true,
            onUpdateJobData: jobData?.onUpdateJobData,
            tabType: jobData.type
        });

        _getDataJobDetail(jobId, jobData.type, jobData?.unitId, jobData?.buildingId);
    }

    function _getDataJobDetail(jobId, type = 0, unitId = '', buildingId = '') {
        clientQuery(
            getDataJobDetail(jobId),
            { data: { type, fields: 'name' } },
            (response) => _getDataJobDetailSuccess(response, type, unitId, buildingId),
            _getDataJobDetailFailed
        );
    }

    function _getDataJobDetailSuccess(response, tabType, unitId, buildingId) {
        let newData = {
            isLoading: false,
            reloadJobDetail: new Date().getTime(),
            ...response.data
        };

        /**
         * With case user touch notification job have unit id.
         * With case == 7 will change to tab Unit
         */
        if (tabType === 7) {
            newData = {
                ...newData,
                tab: TABS.UNIT,
                unitId,
                buildingId
            };
        }

        isFirstTime.current ? dispatchState(newData) : _handleUpdateJobData(newData);

        isFirstTime.current = false;
    }

    function _getDataJobDetailFailed(response) {
        refConfirm.current.open(null, response?.message?.toString() || 'Not Found');
    }

    function _closeJobDetail() {
        handleClosePhoneDropdown();
        isFirstTime.current = true;
        _closeJob();
        dispatch(actionOpenJobDetail(null));
        handleRemoveTimeWindowEl();
    }

    function _closeJob() {
        dispatchState({
            isLoading: true,
            isVisible: false,
            tab: TABS.DETAIL,
            job: null,
            customer: null,
            activeTabInvoice: false,
            activeTabEstimate: false,
            activeTabInvoiceDetail: false,
            activeTabEstimateDetail: false,
            activeTabScheduling: false,
            activeTabWorkOrder: false
        });
    }

    function _handleChangeTab(tabChange, moreParams = {}) {
        dispatchState((prev) => {
            return {
                ...prev,
                tab: tabChange,
                ...moreParams
            };
        });
    }

    function _handleChangeJobData(dataChange) {
        dispatchState((prev) => {
            return {
                ...prev,
                job: dataChange
            };
        });
    }

    function _handleChangeJobDetail(newData) {
        dispatchState((prev) => {
            return {
                ...prev,
                ...newData
            };
        });
    }

    function _handleAddSuccess(isInvoice, invoiceData) {
        const newState = isInvoice
            ? {
                  ...state,
                  tab: TABS.INVOICE,
                  invoice: invoiceData,
                  activeTabInvoice: false,
                  activeTabInvoiceDetail: true
              }
            : {
                  ...state,
                  tab: TABS.ESTIMATE,
                  estimate: invoiceData,
                  activeTabEstimate: false,
                  activeTabEstimateDetail: true
              };
        _handleUpdateJobData(newState);
    }

    function _handleUpdateJobData(jobUpdate) {
        dispatchState((prev) => {
            return {
                ...prev,
                ...jobUpdate
            };
        });
        onUpdateJobData && onUpdateJobData(jobUpdate);
    }

    const _handleGetJobData = () => {
        return refTabContent.current.getValue();
    };

    function _renderJobDetail() {
        if (!finalIsVisible) {
            return false;
        }

        return (
            <div className="modal container-modal c-job-details open">
                <div className="modal__overlay bg-fixed" />
                <div id={'modal_job_detail_content'} className="modal__container">
                    <Header
                        jobData={state}
                        editCheckTime={editCheckTime}
                        onClose={_closeJobDetail}
                        onChangeJobData={_handleChangeJobData}
                        isLoading={finalIsLoading}
                    />
                    <div className="body-modal">
                        <div className="contents accessible-tabs-container">
                            <TabContent
                                ref={refTabContent}
                                isLoading={finalIsLoading}
                                jobData={state}
                                onAddSuccess={_handleAddSuccess}
                                onUpdate={_handleUpdateJobData}
                                activeAddons={activeAddons}
                            />
                            <SideBar
                                ref={refSidebar}
                                activeAddons={activeAddons}
                                isLoading={finalIsLoading}
                                onChangeTab={_handleChangeTab}
                                jobData={state}
                                onUpdateJobDetail={_handleChangeJobDetail}
                                onGetData={_handleGetJobData}
                            />
                        </div>
                    </div>
                    {!finalIsLoading && <InsertJobNotes jobId={jobId} />}
                    {!finalIsLoading && <InsertImage jobId={jobId} />}
                    {!finalIsLoading && (
                        <SmsJobDetail
                            smsTo={state?.messaging_preferences?.sms_to || []}
                            customer={state.customer || {}}
                            jobId={jobId}
                            keyTriggerOpen={TYPE_OPEN_SMS.DETAIL}
                        />
                    )}
                    {!finalIsLoading && (
                        <RealTimeJobService jobData={state} onUpdate={_handleUpdateJobData} onClose={_closeJobDetail} />
                    )}
                </div>
            </div>
        );
    }

    const _handleConfirmNotifyCustomer = () => {
        refConfirmSendReminder.current?._clearStatusAlert();
        const { template = {} } = refSidebar.current?.getValue() || {};
        const { id, type } = template;
        const { start, end } = finalEvent || {};
        const _success = ({ message }) => {
            _removeLoading();
            refConfirmSendReminder.current?._setStatusAlert(message, LIST_STATUS.SUCCESS);
            setTimeout(() => {
                _handleCloseNotifyCustomer();
            }, DEFAULT_TIME_TIMEOUT);
        };
        const _fail = ({ message }) => {
            _removeLoading();
            refConfirmSendReminder.current?._setStatusAlert(message);
        };
        const _removeLoading = () => {
            refButtonSave.current?.removeLoading();
        };

        refServiceNotifyCustomer.current?.onNotifyCustomer(
            {
                job_ids: [jobId],
                jobStatus: jobData?.status,
                template_id: id,
                template_type: type,
                start,
                end,
                schedule: finalSchedule?.map((item) => item.id)?.toString()
            },
            _success,
            _fail
        );
    };

    const _handleOpenNotifyCustomer = () => {
        refConfirmSendReminder.current?._open({
            description: t('jobDetail:desc_confirm_send_reminder', {
                name: t(`jobDetail:${getTypeConfirmation(jobData?.status)}`)
            })
        });
    };

    const _handleCloseNotifyCustomer = () => {
        refConfirmSendReminder.current?._close();
    };

    return (
        <Fragment>
            <Services onOnpenJobDetail={_handleOpenJob} onCloseJobDetail={_closeJob} />
            <ServiceNotifyCustomer ref={refServiceNotifyCustomer} />
            {_renderJobDetail()}
            <GdConfirm
                ref={refConfirm}
                title={t('customers:confirm')}
                listButton={{ confirm: true, cancel: false }}
                titleConfirm={t('customers:confirm')}
                onConfirm={_closeJobDetail}
                onClose={_closeJobDetail}
            />
            <GDModalWarning
                ref={refConfirmSendReminder}
                title={t('jobDetail:notify_customer_title')}
                footer={
                    <div className="footer-modal">
                        <div className="v2-btn-default --transparent" onClick={_handleCloseNotifyCustomer}>
                            {t('jobDetail:cancel')}
                        </div>
                        <ButtonSave
                            ref={refButtonSave}
                            disabled={!jobId}
                            wrapClass="v2-btn-main ml-2"
                            title={t('jobDetail:send')}
                            onSave={_handleConfirmNotifyCustomer}
                        />
                    </div>
                }
            />
        </Fragment>
    );
};

export default JobDetail;
