import queryStrings from 'query-string';
import React, { useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { STRIPE } from 'app/const/addons';
import { ADDONS_STRIPE_DETAIL, STRIPE_ADDONS_CONNECT } from 'app/const/Api';
import { KEY_LOCAL_STORAGE_STRIPE } from 'app/const/Payments';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import { mixpanelManageAddons } from 'app/modules/mixpanel/MixpanelManageAddons';
import { updateAddonStatus, updateSettings } from 'common/redux/actions/authAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { getLocalStorageValue } from 'common/utils/LocalStorageUtils';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import AddonHeaderDetail from '../components/AddonHeaderDetail';
import AddonsOptions from '../components/AddonsOptions';
import StripeAddonsContent from './components/StripeAddonsContent';
import StripeAddonsLoading from './components/StripeAddonsLoading';

const AddonsStripe = () => {
    const dispatch = useDispatch();
    const [state, dispatchState] = useReducer(reducer, { stripe: {}, isLoading: true });
    const oldStripeData = useSelector(({ auth }) => auth?.user?.settings?.stripe || {});
    const profileId = useSelector(({ auth }) => auth.user.profile.id);
    const { search } = useLocation();
    const history = useHistory();

    const { code: authorization_code, state: stripeState } = queryStrings.parse(search);
    const { isLoading, stripe } = state;
    const refAlert = useRef(null);

    useEffect(() => {
        !authorization_code ? _getDetailStripeAddons() : _handleConnectWithStripe();
    }, []);

    const _handleConnectWithStripe = () => {
        const stripeStateLocal = getLocalStorageValue(KEY_LOCAL_STORAGE_STRIPE);

        if (stripeStateLocal !== stripeState) {
            history.replace({ search: '' });
            _getDetailStripeAddons();
        } else {
            const _handleConnectSuccess = ({ data }) => {
                history.replace({ search: '' });
                handleTrackingEvent(mixpanelManageAddons({ id: profileId }));
                dispatch(updateAddonStatus({ keyword: STRIPE, data: data?.addons || {} }));
                dispatch(updateSettings({ stripe: { ...(oldStripeData || {}), key: data.key } }));
                _getDetailStripeAddons();
            };

            clientQuery(STRIPE_ADDONS_CONNECT, { data: { authorization_code }, method: 'POST' }, _handleConnectSuccess);
        }
    };

    const _getDetailStripeAddons = () => {
        clientQuery(ADDONS_STRIPE_DETAIL, { data: {}, method: 'GET' }, _handleGetSuccess, checkAccessFail);
    };

    const _handleGetSuccess = ({ data }) => {
        dispatchState({ stripe: data, isLoading: false });
    };

    const _handleDisconnect = () => {
        dispatchState({ stripe: { ...stripe, status: 0 } });
    };

    const _handleDisableUpdate = () => {
        refAlert.current.showStatusBar({
            id: 'disable_update',
            message: 'You have to connect to Stripe to activate Stripe addons.',
            type: LIST_STATUS.ERROR
        });
    };

    return (
        <div className="addons-wrap__container">
            <AddonHeaderDetail isLoading={isLoading} tutorial={stripe.tutorial} />
            <div className="scrolls">
                <div className="boxs-wrapper --stripe">
                    <AlertCustomer ref={refAlert} />
                    {isLoading ? (
                        <StripeAddonsLoading />
                    ) : (
                        <>
                            <StripeAddonsContent
                                {...stripe}
                                onDisableUpdate={_handleDisableUpdate}
                                onDisconnect={_handleDisconnect}
                            />
                            {!!stripe.status && <AddonsOptions data={stripe.options} />}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddonsStripe;
