import React, { useEffect, useReducer, useRef } from 'react';

import { ADDONS_DEVICE_TRACKING_DETAIL, UPDATE_ADDONS_DEVICE_TRACKING_OPTIONS } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import AddonHeaderDetail from '../components/AddonHeaderDetail';
import AddonsOptions from '../components/AddonsOptions';
import DeviceAddonsContent from './components/DeviceAddonsContent';
import DeviceAddonsLoading from './components/DeviceAddonsLoading';

const AddonsDevice = () => {
    const [state, dispatchState] = useReducer(reducer, {
        device: {},
        isLoading: true
    });
    const refAlert = useRef(null);
    const { isLoading, device } = state;

    useEffect(() => {
        _getDetailDeviceAddons();
    }, []);

    const _getDetailDeviceAddons = () => {
        clientQuery(ADDONS_DEVICE_TRACKING_DETAIL, { method: 'GET' }, _handleGetSuccess, checkAccessFail);
    };

    const _handleGetSuccess = ({ data }) => {
        dispatchState({ device: data, isLoading: false });
    };

    return (
        <div className="addons-wrap__container">
            <AddonHeaderDetail isLoading={isLoading} tutorial={device.tutorial} />
            <AlertCustomer ref={refAlert} />
            <div className="scrolls">
                <div className="boxs-wrapper">
                    {isLoading ? (
                        <DeviceAddonsLoading />
                    ) : (
                        <>
                            <DeviceAddonsContent {...device} />
                            <AddonsOptions data={device.options} urlUpdate={UPDATE_ADDONS_DEVICE_TRACKING_OPTIONS} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddonsDevice;
