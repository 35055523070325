import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ONBOARDING } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import QuestLog from 'app/modules/questLog';
import IconRocket from 'assets/icon/IconRocket';
import useClickOutside from 'common/hooks/useClickOutside';
import { getQuestLogs } from 'common/redux/actions/questlogAction';
import { getTimestamp } from 'common/utils/DateTimeUtils';
import { getPlanUser } from 'common/utils/PermissionUtils';

const QuestlogHeader = (props, ref) => {
    const { t } = useTranslation(['header']);
    const history = useHistory();
    const questLogActive = useSelector(({ auth }) => auth.user.settings.questlog.question_active);
    const userProfile = useSelector(({ auth }) => auth.user.profile);
    const { isTrial } = getPlanUser(userProfile);
    const [state, dispatchAction] = useReducer(reducer, {
        questLogInfo: null,
        lastTimeClicked: 0,
        isLoading: false,
        isShow: !!questLogActive
    });
    const [refMenu, isMenuVisible, setIsMenuVisible] = useClickOutside(false);
    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({
        setActive: handleActive
    }));

    const handleActive = (value) => {
        dispatchAction({ isShow: value });
    };

    const handleActiveMenu = () => {
        if (isTrial) {
            history.push(addBranchPath(ONBOARDING));
            return;
        }
        const now = getTimestamp();
        if (!isMenuVisible) {
            if (now - state.lastTimeClicked > 60 || !state.questLogInfo) {
                handleGetQuestLog(now);
            }
        }
        setIsMenuVisible(!isMenuVisible);
    };

    const handleGetQuestLog = (latestTimeClicked) => {
        const handleOnSuccess = (resp) => {
            dispatchAction({
                lastTimeClicked: latestTimeClicked,
                questLogInfo: resp,
                isLoading: false
            });
        };
        const handleOnFailed = () => {
            dispatchAction({ isLoading: false });
        };
        dispatchAction({ isLoading: true });
        dispatch(getQuestLogs(handleOnSuccess, handleOnFailed));
    };

    const _handleCloseQuestLog = () => {
        setIsMenuVisible(false);
    };

    if (!state.isShow) {
        return null;
    }

    return (
        <div className={classNames('v2-dropdown questlog-menu', { active: isMenuVisible || isTrial })} ref={refMenu}>
            <div className="dropbtn v2-btn-default --icon-lg tooltip" onClick={handleActiveMenu}>
                <IconRocket />
                <p className="tooltiptext bottom">{t('questlog')}</p>
            </div>
            {!isTrial ? (
                <QuestLog
                    info={state.questLogInfo}
                    isLoading={state.isLoading && isMenuVisible}
                    onClose={_handleCloseQuestLog}
                />
            ) : null}
        </div>
    );
};

export default forwardRef(QuestlogHeader);
