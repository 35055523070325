import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ADDONS_ROUTING } from 'app/config/routes';
import { ADVANCE_ROUTING_OPTIONS_KEYWORDS } from 'app/const/addons';
import { addBranchPath } from 'app/const/Branch';
import ErrorPage from 'app/modules/error';
import IconArrow from 'assets/icon/IconArrow';
import { getPlanUser, isActiveFeature } from 'common/utils/PermissionUtils';
import BoxDefaultSetup from '../components/BoxDefaultSetup';
import ManageUsersTable from '../components/ManageUsersTable';
import { TYPE_ADVANCE_ROUTING } from '../constants';

const AddonsBestAvailableTime = () => {
    const { t } = useTranslation('addons');
    const userProfile = useSelector(({ auth }) => auth.user.profile);
    const availableSetup = useSelector(({ auth }) => auth.user.settings.addons.routing?.best_available_time);
    const typeOption = TYPE_ADVANCE_ROUTING.AVAILABLE;

    const { isGrowthPlan, isTrial } = getPlanUser(userProfile);
    const havePermission =
        (isTrial || isGrowthPlan) &&
        availableSetup &&
        isActiveFeature(ADVANCE_ROUTING_OPTIONS_KEYWORDS.BEST_AVAILABLE_TIME);

    if (!havePermission) return <ErrorPage errorMessage={t('auth:no_permission_access')} />;
    return (
        <div className="addons-wrap__container">
            <div className="header">
                <Link to={addBranchPath(ADDONS_ROUTING)} className="v2-btn-default has-icon --grey">
                    <IconArrow isPrev />
                    {t('route_optimization')}
                </Link>
            </div>
            <div className="scrolls">
                <div className="boxs-wrapper --pipeline --custom-field container-print">
                    <BoxDefaultSetup
                        title={t('title_best_available_time')}
                        description={t('desc_best_available_time')}
                        defaultData={availableSetup || {}}
                        type={typeOption}
                    />
                    <ManageUsersTable
                        title={t('manage_users')}
                        description={t('desc_manage_users_best_available_time')}
                        type={typeOption}
                    />
                </div>
            </div>
        </div>
    );
};
export default AddonsBestAvailableTime;
