import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';

import { DEFAULT_ALL, DIVIDER } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { ACTIVE_HOURS, REPLY_BEHAVIOR_OPTIONS } from '../constants';

const SettingReplyBehavior = (
    { title = '', defaultValue = DEFAULT_ALL, hidden = false, behaviorHour = ACTIVE_HOURS },
    ref
) => {
    const refDropdown = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        selected: REPLY_BEHAVIOR_OPTIONS.find((item) => item.id === +defaultValue) || REPLY_BEHAVIOR_OPTIONS[0]
    });
    const { selected: finalSelected } = state;

    useImperativeHandle(ref, () => ({
        getValue: () => finalSelected
    }));

    const _handleSelect = (item = {}) => {
        dispatchState((prev) => ({ ...prev, selected: item }));
        refDropdown.current?._closeDropdown();
    };

    const _renderOptions = () => {
        return (
            <ul className="scrolls">
                {REPLY_BEHAVIOR_OPTIONS.map((item, index) => {
                    const { id, title, hideWhenActiveHours } = item;
                    if (behaviorHour === ACTIVE_HOURS && hideWhenActiveHours) return null;
                    if (id === DIVIDER)
                        return <div key={`${index}-${DIVIDER}`} className="is-divider --horizontal w-100" />;
                    return (
                        <li
                            key={id}
                            className={classNames('items', { active: id === finalSelected.id })}
                            onClick={() => _handleSelect(item)}
                        >
                            {title}
                        </li>
                    );
                })}
            </ul>
        );
    };

    return (
        <div className={classNames('flex-column gap-4 flex-1', { 'dp-hide': hidden })}>
            <p className="fw-500 black-darker3">{title}</p>
            <CalendarDropdown
                ref={refDropdown}
                buttonClassName="dropbtn items"
                wrapperListClass="v2-dropdown__menu"
                selected={finalSelected.title}
            >
                {_renderOptions()}
            </CalendarDropdown>
        </div>
    );
};

export default forwardRef(SettingReplyBehavior);
