import classNames from 'classnames';
import React, { forwardRef, Fragment, useImperativeHandle, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import Input from 'app/components/input/Input';
import { reducer } from 'app/const/Reducer';
import SearchOption from 'app/modules/report/filter/SearchOption';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconCheck from 'assets/icon/IconCheck';
import { DEFAULT_INDUSTRY, INDUSTRY, LIST_INDUSTRY, LIST_TEAM_SIZE, TEAMSIZE } from './const';

const TrialStepOne = ({
    fileTranslation = 'auth',
    infoTrial = {},
    error = {},
    handleFocus = () => {},
    handleSetValue = () => {},
    onChangeInput = () => {},
    onKeyPress = () => {}
}) => {
    const { t } = useTranslation(fileTranslation);
    const [state, dispatchState] = useReducer(reducer, {
        team_size: infoTrial?.team_size || '',
        industry: infoTrial?.industry || ''
    });
    const { team_size, industry } = state;

    const refDropdownTeamSize = useRef(null);
    const refDropdownIndustry = useRef(null);
    const refTrigger = useRef(null);

    const _renderListTeamSize = () => {
        return (
            <ul>
                {LIST_TEAM_SIZE.map(({ id, value }) => {
                    const onClickItem = () => {
                        handleSetValue({ key: TEAMSIZE, value });
                        dispatchState((prev) => ({ ...prev, [TEAMSIZE]: value }));
                        refDropdownTeamSize.current?._close();
                        handleFocus();
                    };

                    return (
                        <li
                            key={id}
                            className={classNames('items', { active: team_size === value })}
                            onClick={onClickItem}
                        >
                            {value}
                        </li>
                    );
                })}
            </ul>
        );
    };

    const _renderButtonDropdownTeamSize = () => {
        return (
            <Fragment>
                <p className="txt-ellipsis">{team_size || t('what_your_team_size')}</p>
                <div className="arrow">
                    <IconArrowDown />
                </div>
            </Fragment>
        );
    };

    const handleSelectIndustry = (value) => {
        handleSetValue({ key: INDUSTRY, value });
        dispatchState((prev) => ({ ...prev, [INDUSTRY]: value }));
        refDropdownIndustry.current?._close();
        handleFocus();
    };

    return (
        <Fragment>
            <div className="box-input">
                <Input
                    autoFocus
                    placeholder={t('auth:company_name')}
                    type="text"
                    name="company_name"
                    initValue={infoTrial?.company_name}
                    classWrapper="user-box rows"
                    error={error?.company_name}
                    isShowIconError={false}
                    onFocus={handleFocus}
                    onChange={onChangeInput}
                    handleKeypress={onKeyPress}
                />
            </div>

            <DropdownPopper
                ref={refDropdownIndustry}
                wrapperClassName="v2-dropdown"
                wrapperListClass="v2-dropdown__menu content-search flex-column"
                buttonClassName="dropbtn items"
                isUseToggle
                isCalculateWidth
                customButton={
                    <Fragment>
                        <p className="txt-ellipsis">{industry || t('choose_your_industry')}</p>
                        <div className="arrow">
                            <IconArrowDown />
                        </div>
                    </Fragment>
                }
                error={error?.industry}
                isShowIconError={false}
                onOpen={() => refTrigger.current?.focusTrigger()}
            >
                <ListIndustry ref={refTrigger} activeIndustry={industry} onSelectIndustry={handleSelectIndustry} />
            </DropdownPopper>
            <DropdownPopper
                ref={refDropdownTeamSize}
                wrapperClassName="v2-dropdown"
                wrapperListClass="v2-dropdown__menu scrolls"
                buttonClassName="dropbtn items"
                isUseToggle
                isCalculateWidth
                customButton={_renderButtonDropdownTeamSize()}
                error={error?.team_size}
                isShowIconError={false}
            >
                {_renderListTeamSize()}
            </DropdownPopper>
        </Fragment>
    );
};

const ListIndustry = forwardRef(({ activeIndustry, onSelectIndustry = () => {} }, ref) => {
    const { t } = useTranslation('auth');
    const [industries, setIndustries] = useState(LIST_INDUSTRY);
    const [focusTrigger, setFocusTrigger] = useState(0);

    useImperativeHandle(ref, () => ({ focusTrigger: () => setFocusTrigger(new Date().getTime()) }));

    const handleSearch = (searchText) => {
        const keysIndustry = Object.keys(LIST_INDUSTRY).filter((industry) => {
            const industryMatches = industry.toLowerCase().includes(searchText.toLowerCase());
            const nestedMatches = LIST_INDUSTRY[industry].filter(({ value: nestedValue }) =>
                nestedValue.toLowerCase().includes(searchText.toLowerCase())
            );
            return industryMatches || nestedMatches;
        });
        const newIndustries = keysIndustry.reduce((acc, key) => {
            acc[key] = LIST_INDUSTRY[key].filter(({ value }) => value.toLowerCase().includes(searchText.toLowerCase()));
            return acc;
        }, {});
        setIndustries(newIndustries);
    };

    return (
        <>
            <SearchOption
                parentStyle="flex-auto header-search"
                isSearchIcon
                autoFocus
                triggerFocus={focusTrigger}
                timeDdebound={0}
                placeholder={t('search_industry')}
                onSearch={handleSearch}
            />
            <div className="drop-container">
                {Object.keys(industries).map((industry) => {
                    if (!industries[industry].length) return null;
                    return (
                        <div key={industry} className="drop-items">
                            {industry !== DEFAULT_INDUSTRY ? (
                                <div className="flex-betweenitems drop-items__title">
                                    <span className="fw-600">{industry}</span>
                                </div>
                            ) : null}
                            <ul className="drop-items__content">
                                {industries[industry].map(({ id, value }) => {
                                    return (
                                        <li
                                            key={id}
                                            className={classNames('items --ticked', {
                                                active: activeIndustry === value
                                            })}
                                            onClick={() => onSelectIndustry(value)}
                                        >
                                            <IconCheck />
                                            <span className="txt-ellipsis">{value}</span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    );
                })}
            </div>
        </>
    );
});

export default TrialStepOne;
