import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { ERROR_CODE } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { INBOX } from 'app/const/Route';
import { LIST_STATUS } from 'app/const/Status';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { TYPE_FEATURE_SETTING } from 'app/modules/inbox/const';
import ServiceSmartDialer from 'app/services/voip/ServiceSmartDialer';
import IconDropUpDown from 'assets/icon/IconDropUpDown';
import { updateAddonStatus } from 'common/redux/actions/authAction';
import { updateIndexDialer } from 'common/redux/actions/voipAction';
import { SMART_VIEW_CUSTOM_ACTIONS } from '../../constants';
import { useSmartView } from '../../context';
import { UPDATE_SMART_VIEW_MUTATION } from '../../graphql/mutation/filters';
import { convertColumnToQuery } from '../utils/columns';
import { convertToQuery } from '../utils/query';
import { sortToQuery } from '../utils/sortQuery';
import SaveAsModal from './SaveAsModal';

const SaveAsButton = ({ onAlert = () => {} }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const { id } = useParams();
    const { t } = useTranslation('smartView');

    const customFields = useSelector(({ customFields }) => customFields.data);
    const {
        permission,
        filtered,
        sort,
        columns,
        limit,
        filterChanged,
        isColumnsChanged,
        handleSaveMutationSuccess,
        loading: isLoadingSmartView
    } = useSmartView();

    const _handleErrorSmartView = (error = {}) => {
        if (error.networkError?.statusCode === ERROR_CODE.PERMISSION_DENIED) {
            dispatch(updateAddonStatus({ keyword: TYPE_FEATURE_SETTING.SMART_VIEWS, data: 0 }));
            if (history.location.pathname.includes(INBOX)) history.replace(addBranchPath(INBOX));
        }
    };

    const [updateSmartView, { loading }] = useMutation(UPDATE_SMART_VIEW_MUTATION, {
        onCompleted: (data) => {
            refDropdown.current?._closeDropdown();
            onAlert({ id: 'save', message: t('save_success'), status: LIST_STATUS.SUCCESS });
            const listSmartView = document.getElementById('list-wrapper-smart-view');
            if (listSmartView) {
                const event = new CustomEvent(SMART_VIEW_CUSTOM_ACTIONS.UPDATE_SMART_VIEW_QUERY, { detail: { data } });
                listSmartView.dispatchEvent(event);
            }
            handleSaveMutationSuccess(data);
            refDialer.current.onResetDialer(data.updateSmartView);
        },
        onError: _handleErrorSmartView
    });
    const refDropdown = useRef(null);
    const refSaveAs = useRef(null);
    const refDialer = useRef(null);

    const _handleSave = () => {
        refDropdown.current._closeDropdown();
        const data = {
            q: { bool: { queries: [{ bool: { queries: convertToQuery(filtered) } }] } },
            sort: sortToQuery(sort),
            columns: convertColumnToQuery(columns, customFields),
            limit
        };
        updateSmartView({ variables: { id, data } });
        dispatch(updateIndexDialer({ id, index: 0 }));
    };

    const _handleSaveAs = () => {
        refDropdown.current?._closeDropdown();
        refSaveAs.current?.open();
    };

    if (!filtered.length) return null;
    if ((!filterChanged || !filtered.length) && !isColumnsChanged) return null;
    return (
        <>
            <CalendarDropdown
                ref={refDropdown}
                id="save_as_button"
                wrapperClassName="header-items"
                buttonClassName={classNames('dropbtn v2-btn-main svg-white', {
                    'is-disable': loading || isLoadingSmartView
                })}
                wrapperListClass="v2-dropdown__menu content-full --right"
                customDropButton={() => (
                    <>
                        <div className="txt-ellipsis fs-14">{t('save')}</div>
                        <span className="arrow">
                            <IconDropUpDown />
                        </span>
                    </>
                )}
            >
                <ul>
                    {permission.modify && (
                        <li className="items" onClick={_handleSave}>
                            <div className="txt-ellipsis">{t('save_and_update')}</div>
                        </li>
                    )}
                    {permission.add && (
                        <li className="items" onClick={_handleSaveAs}>
                            <div className="txt-ellipsis">{t('save_as')}</div>
                        </li>
                    )}
                </ul>
            </CalendarDropdown>
            <SaveAsModal ref={refSaveAs} onErrorSmartView={_handleErrorSmartView} />
            <ServiceSmartDialer ref={refDialer} />
        </>
    );
};

export default SaveAsButton;
