import classNames from 'classnames';
import React, { Fragment, useEffect, useReducer } from 'react';

import { reducer } from 'app/const/Reducer';

const GDIframe = ({
    isAutoPlay = false,
    link = '',
    title = '',
    className = '',
    loadingComponent: LoadingComponent = LoadingDefault
}) => {
    const [state, dispatchState] = useReducer(reducer, { isLoadingIframe: true, link });
    const { isLoadingIframe, link: finalLink } = state;

    useEffect(() => {
        if (!!link) dispatchState((prev) => ({ ...prev, link, isLoadingIframe: true }));
    }, [link]);

    const _onIframeLoadComplete = () => {
        dispatchState((prev) => ({
            ...prev,
            isLoadingIframe: false,
            link: isAutoPlay ? `${link}?autoplay=true` : prev.link
        }));
    };

    if (!link) return null;

    return (
        <Fragment>
            {isLoadingIframe ? <LoadingComponent /> : null}
            <iframe
                key={title}
                src={finalLink}
                title={title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
                className={classNames(className, { 'dp-hide': isLoadingIframe })}
                onLoad={_onIframeLoadComplete}
            />
        </Fragment>
    );
};

export default GDIframe;

const LoadingDefault = () => {
    return (
        <div className="wrap-loading mb-3">
            <div className="field-iframe loading --animation"></div>
        </div>
    );
};
