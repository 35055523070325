import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import GDInputRange from 'app/components/input/InputRange';
import { DEFAULT_CURRENCY_SYMBOL } from 'app/const/App';

export function AddonBoxRange({
    currencySymbol = DEFAULT_CURRENCY_SYMBOL,
    wrapperClassName = 'wrap-plans__cost flexcenter gap-24',
    listClassDivide = [],
    isFourValue = false,
    keyLeftRange = '',
    valueLeftRange = 0,
    listLeftRange = [],
    keyRightRange = '',
    valueRightRange = 0,
    listRightRange = [],
    onChangeRange = () => {}
}) {
    const { t } = useTranslation('addons');

    const _renderDefaultValue = (options = [], value = 0) => {
        return options.findIndex((item) => item === value);
    };

    const defaultValueLeftRange = _renderDefaultValue(listLeftRange, valueLeftRange);
    const defaultValueRightRange = _renderDefaultValue(listRightRange, valueRightRange);

    const _handleChangeRange = (value, options = [], key = '') => {
        const data = { [key]: options[value] };
        onChangeRange(data);
    };

    return (
        <div className={wrapperClassName}>
            <div className="flex-column gap-4 flex-1">
                <span className="flex-betweenitems gap-2">
                    {t('when_your_balance_goes_below')}
                    <span className="blue-default fw-600 fs-14">{`${currencySymbol}${valueLeftRange}`}</span>
                </span>
                <div className="box-plans pb-6">
                    <GDInputRange
                        step={1}
                        min={0}
                        max={listLeftRange.length - 1}
                        debounceTime={0}
                        displayOverlay
                        defaultValue={defaultValueLeftRange}
                        classWrapper={`progress-bar --md relative is-${listClassDivide[defaultValueLeftRange]}`}
                        classSlider="slider-main"
                        divideBarComponent={() => <DivideBar options={listLeftRange} />}
                        onChange={(value) => _handleChangeRange(value, listLeftRange, keyLeftRange)}
                    />
                    <ProgressValue currencySymbol={currencySymbol} values={listLeftRange} />
                </div>
            </div>
            <div className="flex-column gap-4 flex-1">
                <span className="flex-betweenitems gap-2">
                    {t('recharge_credit_balance_to')}
                    <span className="blue-default fw-600 fs-14">{`${currencySymbol}${valueRightRange}`}</span>
                </span>
                <div className={classNames('box-plans pb-6', { 'has-four-value': isFourValue })}>
                    <GDInputRange
                        step={1}
                        min={0}
                        max={listRightRange.length - 1}
                        debounceTime={0}
                        displayOverlay
                        defaultValue={defaultValueRightRange}
                        classWrapper={`progress-bar --md relative is-${listClassDivide[defaultValueRightRange]}`}
                        classSlider="slider-main"
                        divideBarComponent={() => <DivideBar options={listRightRange} />}
                        onChange={(value) => _handleChangeRange(value, listRightRange, keyRightRange)}
                    />
                    <ProgressValue currencySymbol={currencySymbol} values={listRightRange} />
                </div>
            </div>
        </div>
    );
}

export default AddonBoxRange;

const DivideBar = ({ options = [] }) => {
    return options.map((item, i) => <div key={i.toString()} className="divide-bar" />);
};

const ProgressValue = ({ currencySymbol = DEFAULT_CURRENCY_SYMBOL, values = [] }) => {
    return (
        <div className="progress-value relative black">
            {values.map((item, i) => (
                <span key={i.toString()} className="values absolute">
                    {`${currencySymbol}${item}`}
                </span>
            ))}
        </div>
    );
};
