import classNames from 'classnames';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from 'app/components/checkbox';
import { SOURCE_PHONE_NUMBER_VOIP, TYPE_PHONE_VOIP } from 'app/const/addons';
import { getUserInfo } from 'common/utils/CompanyUserUtils';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';
import VoipDragAndDrop from '../VoipDragAndDrop';
import AvatarAutoAttendant from './AvatarAutoAttendant';

const ItemPhoneAutoAttendant = ({
    isAgentRoutingPhone = true,
    isShowDrag = false,
    listPhone = [],
    numberSelected = [],
    onSelectOption = () => {},
    onDragEnd = () => {}
}) => {
    if (!listPhone.length) return null;
    const _handleSelectOption = (data = {}) => {
        onSelectOption(data);
    };

    return (
        <Fragment>
            {isAgentRoutingPhone ? (
                <VoipDragAndDrop
                    options={listPhone}
                    isShowCheckbox
                    isShowDrag={isShowDrag}
                    onSelectOption={_handleSelectOption}
                    onDragEnd={onDragEnd}
                />
            ) : (
                <ul className="scrolls">
                    {listPhone.map((item, index) => {
                        const { id: number_id, phone_number, assignees, source, type, isCustom } = item;
                        if (!phone_number) return null;
                        const isGroup = type === TYPE_PHONE_VOIP.GROUP;
                        const assigneeId = assignees?.[0];
                        if ((!assigneeId && !isGroup && !isCustom) || source === SOURCE_PHONE_NUMBER_VOIP.EXTERNAL)
                            return null;

                        const isActive = numberSelected.some((number) => number.phone_number === phone_number);
                        const idElement = index.toString() + phone_number;

                        const _handleChangeValue = () => {
                            _handleSelectOption({ value: { number_id, phone_number }, isChecked: !isActive });
                        };

                        return (
                            <li key={idElement} className={classNames('items', { active: isActive })}>
                                <ItemCheckbox
                                    idElement={idElement}
                                    checked={isActive}
                                    isCustom={isCustom}
                                    isGroup={isGroup}
                                    {...item}
                                    {...(getUserInfo(assigneeId) || {})}
                                    onChangeValue={_handleChangeValue}
                                />
                            </li>
                        );
                    })}
                </ul>
            )}
        </Fragment>
    );
};

export default ItemPhoneAutoAttendant;

export const ItemCheckbox = ({
    idElement = '',
    checked = false,
    isCustom = false,
    isGroup = false,
    avatar = '',
    full_name = '',
    phone_number = '',
    onChangeValue = () => {}
}) => {
    const { t } = useTranslation('addons');
    return (
        <Checkbox id={idElement} classLabelChild="user-name" checked={checked} onChangeValue={onChangeValue}>
            <AvatarAutoAttendant isCustom={isCustom} isGroup={isGroup} avatar={avatar} />
            <span className="txt-ellipsis fw-500">
                {`${isGroup ? t('group_number') : full_name} ${formatPhoneNumberVoip(phone_number)}`.trim()}
            </span>
        </Checkbox>
    );
};
