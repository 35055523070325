import classNames from 'classnames';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ButtonSave from 'app/components/button/ButtonSave';
import { ADDONS_VOIP } from 'app/config/routes';
import { ADDONS_AI_AGENTS } from 'app/const/addons';
import { ADDONS_SMS_ACTIVE } from 'app/const/Api';
import { ACCOUNT_DETAIL_CHATBOT } from 'app/const/api/V2';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { SETTINGS_PLANS } from 'app/const/Route';
import IconClose from 'assets/icon/IconClose';
import { activeSmsCharge } from 'common/redux/actions/authAction';
import { clientQuery } from 'common/utils/ApiUtils';

function SmsChargeFailed() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation('notifications');
    const { notifications, profile } = useSelector((state) => state.auth.user);
    const {
        sms: smsFailed,
        payment_failed: paymentFailed,
        voip_payment_failed: voipFailed,
        ai_payment_failed: aiPaymentFail
    } = notifications;
    const accountNumber = profile?.plan?.account_number;

    const LIST_FAILED = [
        {
            id: 'sms',
            title: t('sms_charge_failed', { sms_numbers: smsFailed?.sms_numbers }),
            desc: t('uh_oh_your_card_on_file_failed_to_renew_your_sms_pack'),
            textBtn: t('use_current_card', { account_number: accountNumber }),
            onUpdate: () => {
                const _handleSuccess = (res) => {
                    dispatch(activeSmsCharge());
                    dispatchState((prev) => ({ ...prev, isActive: true, smsAmount: res.data.sms_amount }));
                };
                clientQuery(ADDONS_SMS_ACTIVE, { method: 'PUT' }, _handleSuccess, _handleFail);
            }
        },
        {
            id: 'payment',
            title: t('gorillaDesk_subscription_failed'),
            desc: t('message_payment_charge_failed'),
            textBtn: t('update_subscription'),
            onUpdate: () => {
                _handleClose(true);
                history.replace(addBranchPath(SETTINGS_PLANS));
            }
        },
        {
            id: 'voip',
            title: t('voip_payment_failed'),
            desc: t('uh_oh_failed_voip'),
            textBtn: t('update_subscription'),
            onUpdate: () => {
                _handleClose(true);
                history.replace({ pathname: addBranchPath(ADDONS_VOIP), state: { openManage: true } });
            }
        },
        {
            id: ADDONS_AI_AGENTS,
            title: t('ai_agents_payment_failed'),
            desc: t('uh_oh_failed_ai_agents'),
            textBtn: t('use_current_card', { account_number: accountNumber }),
            onUpdate: () => {
                refButtonSave.current.setLoading(true);
                const _handleSuccess = () => {
                    _handleClose(true);
                    _removeLoading();
                };
                clientQuery(
                    ACCOUNT_DETAIL_CHATBOT,
                    { data: {}, method: 'POST', toFormData: false },
                    _handleSuccess,
                    (error) => _handleFail(error, true)
                );
            }
        }
    ];

    const [state, dispatchState] = useReducer(reducer, {
        isActive: false,
        showSmsChargeFail: false,
        smsAmount: 0,
        messageChargeFail: '',
        isSmsFailed: !!smsFailed,
        listFailed: [!!smsFailed, paymentFailed, voipFailed, aiPaymentFail]
    });
    const {
        isActive: finalIsActive,
        showSmsChargeFail: finalShowSmsChargeFail,
        messageChargeFail: finalMessageChargeFail,
        isSmsFailed: finalIsSmsFailed,
        listFailed: finalListFailed,
        smsAmount: finalSmsAmount
    } = state;
    const failedIndex = finalListFailed?.findIndex((item) => !!item);

    const refButtonSave = useRef(null);

    useEffect(() => {
        dispatchState((prev) => ({ ...prev, showSmsChargeFail: prev.listFailed.some((item) => !!item) }));
    }, []);

    const _handleClose = (isCloseAll = false) => {
        dispatchState((prev) => {
            const newList = [...prev.listFailed];
            newList[failedIndex] = false;

            return {
                ...prev,
                isSmsFailed: false,
                showSmsChargeFail: isCloseAll ? false : newList.some((item) => !!item),
                listFailed: newList
            };
        });
        return;
    };

    const _handleFail = (res, isRemoveLoading = false) => {
        isRemoveLoading && _removeLoading();
        dispatchState((prev) => ({ ...prev, messageChargeFail: res.message }));
    };

    const _removeLoading = () => {
        refButtonSave.current.removeLoading();
    };

    const _renderModalFailed = () => {
        if (failedIndex < 0 && !finalMessageChargeFail) return null;
        const { title, desc, textBtn, onUpdate = () => {} } = LIST_FAILED[failedIndex] || {};

        return (
            <Fragment>
                <div className="header-modal">
                    <div className="header-modal__label">{!!finalMessageChargeFail ? t('uh_oh') : title}</div>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={() => _handleClose()}>
                        <IconClose />
                    </div>
                </div>

                <div className={classNames('body-modal elm-parent-box', { 'has-message': finalIsActive })}>
                    <div className="content-form --info">
                        <div className="inform">{finalMessageChargeFail || desc}</div>
                        {!finalMessageChargeFail ? <div className="retry">{t('please_retry_processing')}</div> : null}
                        <ButtonSave
                            ref={refButtonSave}
                            wrapClass="v2-btn-main current-card js-charge"
                            onSave={onUpdate}
                            title={textBtn}
                            isNotLoading={!finalIsSmsFailed}
                        />
                    </div>
                </div>
            </Fragment>
        );
    };

    if (!finalShowSmsChargeFail) return null;
    return (
        <div id="smsChargeFailed" className="modal container-modal modal-sms-charge-failed open">
            <div className="modal__container">
                {!finalIsActive ? (
                    _renderModalFailed()
                ) : (
                    <>
                        <div className="header-modal">
                            <h3 className="header-modal__label">{t('success')}</h3>
                            <div className="v2-btn-default --icon-lg --transparent" onClick={_handleClose}>
                                <IconClose />
                            </div>
                        </div>

                        <div className={classNames('body-modal elm-parent-box', { 'has-message': finalIsActive })}>
                            <div className="content-form --success">
                                <div className="retry black pb-6">
                                    {t('your_pack_has_been_successfully_renewed')} <br />
                                    {t('sms_locked_and_loaded', { sms_numbers: finalSmsAmount })}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default SmsChargeFailed;
