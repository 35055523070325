import { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import ButtonSave from 'app/components/button/ButtonSave';
import GDInputRange from 'app/components/input/InputRange';
import { ATTACHMENT_PRESIGN } from 'app/const/Api';
import { COMMON } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';
import IconMinus from 'assets/icon/IconMinus';
import IconMove from 'assets/icon/IconMove';
import IconPlus from 'assets/icon/IconPlus';
import { clientQuery } from 'common/utils/ApiUtils';
import { base64ToFile, uploadToS3 } from 'common/utils/FileUtils';

const ModalResizeImage = forwardRef(({ onCancel = () => {}, onSave = () => {} }, ref) => {
    const { t } = useTranslation(['common']);
    const [state, dispatchState] = useReducer(reducer, {
        scale: 1,
        isOpen: false,
        imageFile: '',
        type: COMMON.AVATAR,
        fileName: 'web-'
    });
    const { scale, imageFile, fileName, type, isOpen } = state;

    const refAvatarEditor = useRef(null);
    const refButtonSave = useRef(null);

    /**
     * Handle open modal resize with image
     * @param {imageFile} File - Base64 image or URL image
     */
    const handleOpen = ({ imageFile, fileName, type = COMMON.AVATAR }) => {
        dispatchState({ isOpen: true, imageFile, fileName, type });
    };

    const handleClose = () => {
        dispatchState({ isOpen: false, scale: 1 });
        onCancel();
    };

    useImperativeHandle(ref, () => ({
        open: handleOpen,
        close: () => dispatchState({ isOpen: false })
    }));

    const handleScale = (scale) => {
        dispatchState((prevState) => ({ ...prevState, scale }));
    };

    const handleSave = () => {
        const dataPresign = {
            name: `web-${fileName}`,
            item_id: new Date().getTime(),
            type: type || 'item'
        };

        const handlePresignSuccess = async ({ data }) => {
            // Convert image to file object
            const imageFile = await base64ToFile(refAvatarEditor.current.getImage().toDataURL(), 'avatar', 'png');
            const { presigned_url, object_key, object_tag, public_url, thumbnail_url } = data || {};

            const handleUploadS3Success = () => {
                onSave({ public_url, object_key, object_tag, thumbnail_url });
                handleClose();
            };

            // Upload image to S3
            uploadToS3(
                presigned_url,
                { 'x-amz-tagging': object_tag },
                imageFile,
                { object_key },
                handleUploadS3Success
            );
        };
        clientQuery(ATTACHMENT_PRESIGN, { data: dataPresign, toFormData: false, method: 'POST' }, handlePresignSuccess);
    };

    if (!isOpen) return null;
    return (
        <ReactModal
            isOpen
            className="modal modal-resize container-modal open"
            style={{ overlay: { background: 'transparent' } }}
        >
            <div className="modal__overlay bg-fixed" />
            <div className="modal__container large">
                <div className="header-modal">
                    <h3 className="header-modal__label">{t('common:resize_avatar')}</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={handleClose}>
                        <IconClose />
                    </div>
                </div>
                <div className="body-modal flex-column gap-16 scrolls">
                    <div className="v2-btn-default has-bg-grey has-icon w-100 --transparent --large black pointer-events-none">
                        <IconMove />
                        {t('move_to_change_image_position')}
                    </div>
                    <div className="wrap-image">
                        <AvatarEditor
                            ref={refAvatarEditor}
                            disableHiDPIScaling
                            image={imageFile}
                            className="box-image"
                            scale={scale}
                            width={320}
                            height={320}
                            borderRadius={320}
                            color={[0, 0, 0, 0.6]}
                            crossOrigin="anonymous"
                        />
                    </div>
                    <GDInputRange
                        displayOverlay
                        min={0}
                        max={100}
                        step={1}
                        debounceTime={0}
                        defaultValue={0}
                        classWrapper="resize-image flexcenter gap-4 wrap-plans"
                        classContainer="progress-bar relative flex-1"
                        classSlider="flex-1 slider-main"
                        onChange={(value) => handleScale(1 + value / 100)}
                        leftComponent={({ onClick }) => (
                            <span onClick={onClick} className="v2-btn-default --transparent --icon-sm">
                                <IconMinus />
                            </span>
                        )}
                        rightComponent={({ onClick }) => (
                            <span onClick={onClick} className="v2-btn-default --transparent --icon-sm">
                                <IconPlus />
                            </span>
                        )}
                    />
                </div>
                <div className="footer-modal justify-end">
                    <span className="v2-btn-default --transparent" onClick={handleClose}>
                        {t('cancel')}
                    </span>
                    <ButtonSave
                        ref={refButtonSave}
                        wrapClass="v2-btn-main ml-2"
                        title={t('save')}
                        onSave={handleSave}
                    />
                </div>
            </div>
        </ReactModal>
    );
});
export default ModalResizeImage;
