import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Recaptcha from 'react-recaptcha';

import { CAPTCHA_SITE_KEY } from 'app/const/Keys';
import IconError from 'assets/icon/IconError';

const TrialStepThree = ({ isErrorCaptcha = false, onVerifyCaptcha = () => {} }) => {
    const { t } = useTranslation(['common', 'auth']);

    const handleLoaded = () => {};
    const handleVerify = (response) => {
        if (response) onVerifyCaptcha();
    };

    return (
        <div className="g-recaptcha">
            <Recaptcha
                sitekey={CAPTCHA_SITE_KEY}
                render="explicit"
                onloadCallback={handleLoaded}
                verifyCallback={handleVerify}
            />
            <input type="text" hidden className={classNames('field-input', { error: isErrorCaptcha })} />
            {isErrorCaptcha ? (
                <span className="flex-centeritem txt-incorrect">
                    <IconError />
                    &nbsp;{t('auth:please_verify_you_are_not_a_robot')}
                </span>
            ) : null}
        </div>
    );
};

export default TrialStepThree;
