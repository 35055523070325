import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const ServiceTrigger = ({ onOpenServicePlan, onCloseServicePlan }) => {
    const { servicePlanDetail } = useSelector(({ servicePlanReducer }) => servicePlanReducer);

    useEffect(() => {
        if (servicePlanDetail) {
            onOpenServicePlan(servicePlanDetail);
        } else {
            onCloseServicePlan();
        }
    }, [servicePlanDetail]);

    return false;
};

export default ServiceTrigger;
