import React, { Fragment, useContext, useEffect, useRef } from 'react';
import FormTimeWindow from 'app/modules/jobdetail/tabs/detail/timewindow/form';
import IconCogWheel from 'assets/icon/IconCogWheel';
import { convertTimeNumber } from 'common/utils/TimeUtils';
import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';
import IconTime from 'assets/icon/IconTime';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { convertTimeToISO } from 'common/utils/DateUtils';
import { getListTimeWindow } from 'common/redux/actions/timeWindowAction';

export default function TimeWindow({ isLoading, isAddJob }) {
    const { t } = useTranslation();
    const { repeatSettings: jobData, onChangeException } = useContext(DataRepeatContext);
    const { settings: userSetting, company: companySetting } = useSelector(({ auth }) => auth.user);
    // Fetch time window template
    const isFirstTime = useSelector(({ timeWindowReducer }) => timeWindowReducer.isFirstTime);
    const dispatch = useDispatch();

    const isActiveTimeWindow = userSetting?.addons?.time_window || false;

    const refFormTimeWindow = useRef(null);

    useEffect(() => {
        if (isActiveTimeWindow && isFirstTime) dispatch(getListTimeWindow());
    }, []);

    const returnTime = (timeNumber) => {
        if (!timeNumber) {
            return '12:00 AM';
        }
        const { hour, minute, midDay } = convertTimeNumber(timeNumber);
        return `${hour}:${minute} ${midDay}`;
    };

    const dataTime = jobData?.time_window || {};

    const timeStart = returnTime(dataTime.start);
    const timeEnd = returnTime(dataTime.end);

    const _handleOpen = () => {
        refFormTimeWindow.current.openForm({
            ...dataTime,
            timeStart,
            timeEnd,
            jobInfo: {
                name: jobData.serviceData.name,
                start: jobData.timeStart,
                end: convertTimeToISO(moment(jobData.timeStart).utc().add(jobData.length, 'minutes'))
            }
        });
    };

    const _handleSave = (newData, callbackSuccess) => {
        onChangeException({ time_window: newData, changedTimeWindow: true });
        callbackSuccess();
    };

    if (!!!isActiveTimeWindow) {
        return false;
    }

    if (isLoading) {
        return (
            <div className="wrap-loading details-job mt-3">
                <div className="rows">
                    <div className="txt flex-auto">
                        <div className="loading --animation --line --half"></div>
                    </div>
                    <div className="details flexcenter">
                        <div className="loading --animation --line --twothird mr-2"></div>
                        <div className="loading v2-btn-default --grey --icon-lg">
                            <div className="loading --animation --line --half"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const _contentTime = () => {
        if (dataTime.id === '-1') {
            const { start, end } = companySetting?.business_hours || {};
            return `${moment(start, 'HH:mm').format('hh:mm A')} to ${moment(end, 'HH:mm').format('hh:mm A')}`;
        }
        return `${timeStart} to ${timeEnd}`;
    };

    return (
        <Fragment>
            <div className="rows">
                <div className="txt">
                    <IconTime isTimeJob />
                    {t('time_window')}
                </div>
                <div className="details flexcenter">
                    <div className="mr-3">{_contentTime()}</div>
                    <div onClick={_handleOpen} className="v2-btn-default --icon-lg btn-modal">
                        <IconCogWheel />
                    </div>
                </div>
            </div>
            <FormTimeWindow isAddJob={isAddJob} ref={refFormTimeWindow} onSubmit={_handleSave} />
        </Fragment>
    );
}
