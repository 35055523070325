import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

function ReportTabs({
    list = [],
    tabActive,
    classTabList = 'tab-selectors btn-item ml-0',
    classWrapper = 'header-items tabs',
    onChange = () => {}
}) {
    const { t } = useTranslation(['report']);

    const handleOnChange = (value) => {
        if (value !== tabActive) onChange(value);
    };

    const renderlistTab = (list) => {
        return list.map((item) => {
            return (
                <button
                    key={item.id}
                    className={`tab-items ${item.value === tabActive && 'active-tab-selector'}`}
                    type="button"
                    onClick={() => {
                        handleOnChange(item.value);
                    }}
                >
                    {t(`report:${item.name}`)}
                </button>
            );
        });
    };

    return (
        <div className={classWrapper}>
            <div className={classTabList}>{renderlistTab(list)}</div>
        </div>
    );
}

const compare = (prevProps, nextProps) => {
    return prevProps.tabActive === nextProps.tabActive;
};

export default memo(ReportTabs, compare);
