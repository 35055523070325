import classNames from 'classnames';
import React, { Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import { VOIP_AGENT_ROUTING } from 'app/const/Voip';
import IconArrowDown from 'assets/icon/IconArrowDown';
import VoipDragAndDrop from './VoipDragAndDrop';

const VoipAgentRouting = ({
    id = '',
    agentRouting = VOIP_AGENT_ROUTING.DEFAULT_VALUE,
    assignees = [],
    onChangeAgentRouting = () => {},
    onChangeAssignees = () => {}
}) => {
    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);
    const { t } = useTranslation();

    const listVoipAgentRouting = VOIP_AGENT_ROUTING.LIST_DATA;
    const finalUsers = assignees.reduce((result, assigneeId) => {
        const user = companyUsers.find((user) => parseInt(user.id) === assigneeId);
        return !!user ? [...result, user] : result;
    }, []);

    const refDropdown = useRef(null);

    const _handleChangeAgentRouting = (value) => {
        onChangeAgentRouting({ id, value });
        refDropdown.current._close();
    };

    const _onDragEnd = (data = {}) => {
        onChangeAssignees({ id, ...data });
    };

    return (
        <div className="box-voip__rows">
            <div className="flexcenter gap-8">
                <p className="flex-1">{t('addons:agent_routing')}</p>
                <DropdownPopper
                    id={`agent_routing_${id}`}
                    ref={refDropdown}
                    buttonClassName="dropbtn items"
                    wrapperClassName="v2-dropdown flex-1 dropdown-routing"
                    isUseToggle
                    isCalculateWidth
                    customButton={
                        <Fragment>
                            <div className="txt-ellipsis mr-1">
                                {listVoipAgentRouting.find((item) => item.value === agentRouting).name}
                            </div>
                            <div className="arrow">
                                <IconArrowDown />
                            </div>
                        </Fragment>
                    }
                >
                    <ul>
                        {listVoipAgentRouting.map((item) => (
                            <li
                                key={item.value}
                                className={classNames('items', { active: item.value === agentRouting })}
                                onClick={() => _handleChangeAgentRouting(item.value)}
                            >
                                {item.name}
                            </li>
                        ))}
                    </ul>
                </DropdownPopper>
            </div>
            {VOIP_AGENT_ROUTING.ENABLE_DRAGGABLE.includes(agentRouting) ? (
                <VoipDragAndDrop options={finalUsers} onDragEnd={_onDragEnd} />
            ) : null}
        </div>
    );
};

export default VoipAgentRouting;
