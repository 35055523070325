import IconCogWheel from 'assets/icon/IconCogWheel';
import classNames from 'classnames';
import React, { Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
    ADDONS_VOIP_ACTIVE_USERS,
    ADDONS_VOIP_HOLD,
    ADDONS_VOIP_MANAGE_GROUP,
    ADDONS_VOIP_MANAGE_PERSONAL,
    ADDONS_VOIP_VOICEMAIL_DROP
} from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import { ADDONS_FOOTER_OPTIONS, ADDONS_JOB_STATUS_PERMISSION } from 'app/const/addons';
import { getPlanUser } from 'common/utils/PermissionUtils';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';
import VOIPAddonsAfterHours from './afterHours';
import VOIPAddonsAutoAttendant from './autoAttendant';
import AddonsOptionsFooterManage from '../../components/AddonsOptionsFooterManage';

export const VOIPAddonsOptionsFooter = ({ keyword, shouldCheckManage = false, ...props }) => {
    const { t } = useTranslation();
    const userProfile = useSelector(({ auth }) => auth.user.profile);
    const worklogAddon = useSelector(({ auth }) => auth.user.settings.addons.work_log);
    const { isProPlan, isGrowthPlan, isTrial } = getPlanUser(userProfile);
    const havePermissionManage = (isProPlan || isGrowthPlan || isTrial) && !!worklogAddon;
    const refAutoAttendant = useRef(null);
    const refAfterHour = useRef(null);

    const { items, upgrade, pathManage, isDisableManage = false } = props;
    const isDisable = !!upgrade;

    const {
        VOIP_ACTIVE_USER,
        VOIP_GROUP,
        VOIP_PERSONAL,
        VOIP_AFTER_HOURS,
        VOIP_AUTO_ATTENDANT,
        VOIP_HOLD_MUSIC,
        VOIP_VOICEMAIL_DROP
    } = ADDONS_FOOTER_OPTIONS;

    const isFooterMulti = [VOIP_AFTER_HOURS].includes(keyword);

    const _renderActiveUsers = () => {
        const lengthAssign = items?.length;

        const _renderAvatars = () => {
            return [...(items || [])].splice(0, 14).map((assignee) => {
                const { id, full_name, avatar } = assignee;
                return (
                    <span key={id} className="avt-img tooltip">
                        <img src={avatar} alt={full_name} width={22} height={22} />
                        <span className="tooltiptext top">{full_name}</span>
                    </span>
                );
            });
        };

        return (
            <Fragment>
                <div className="wrap-member-group flexcenter flex-1">
                    {_renderAvatars()}
                    {lengthAssign > 14 && <span className="avt-img avt-more fs-10 fw-500">{lengthAssign - 14}</span>}
                </div>
                <Link
                    to={addBranchPath(pathManage || ADDONS_VOIP_ACTIVE_USERS)}
                    className={classNames('v2-btn-default svg-purple btn-bg-purple --icon-r --sm', {
                        'is-disable': (!havePermissionManage && shouldCheckManage) || isDisableManage
                    })}
                >
                    {t('addons:manage')} <IconCogWheel />
                </Link>
            </Fragment>
        );
    };

    const _renderGroup = () => {
        const _renderPhoneGroup = () => {
            if (!items || !Array.isArray(items)) return null;
            const [groupOne = {}, groupTwo = {}] = items;
            const { phone_number: phoneNumberGroupOne } = groupOne;
            const { phone_number: phoneNumberGroupTwo } = groupTwo;

            return (
                <div className="fw-600 flex-1">
                    {phoneNumberGroupOne ? <span>{formatPhoneNumberVoip(phoneNumberGroupOne)}</span> : null}
                    {phoneNumberGroupTwo ? (
                        <span className="dots">{formatPhoneNumberVoip(phoneNumberGroupTwo)}</span>
                    ) : null}
                </div>
            );
        };
        return (
            <Fragment>
                {_renderPhoneGroup()}
                <Link
                    to={addBranchPath(ADDONS_VOIP_MANAGE_GROUP)}
                    className={classNames('v2-btn-default svg-purple btn-bg-purple --icon-r --sm', {
                        'is-disable': isDisable
                    })}
                >
                    {t('addons:manage')} <IconCogWheel />
                </Link>
            </Fragment>
        );
    };

    const _renderPersonal = () => {
        return (
            <Link
                to={addBranchPath(pathManage || ADDONS_VOIP_MANAGE_PERSONAL)}
                className={'v2-btn-default svg-purple btn-bg-purple --icon-r --sm'}
            >
                {t('addons:manage')} <IconCogWheel />
            </Link>
        );
    };

    const _renderAutoAttendant = () => {
        const _handleClick = () => {
            refAutoAttendant.current.setVisible(true);
        };

        return (
            <Fragment>
                <div
                    className={classNames('v2-btn-default svg-purple btn-bg-purple --icon-r --sm', {
                        'is-disable': isDisable
                    })}
                    onClick={_handleClick}
                >
                    {t('addons:manage')} <IconCogWheel />
                </div>
                <VOIPAddonsAutoAttendant ref={refAutoAttendant} />
            </Fragment>
        );
    };

    const _renderAfterHour = () => {
        const _handleClick = () => {
            refAfterHour.current.setVisible(true);
        };

        return (
            <Fragment>
                <p className="grey-extrlight txt-ellipsis">{t('addons:setup_message_and_time_frame')}</p>
                <div
                    className={classNames('v2-btn-default svg-purple btn-bg-purple --icon-r --sm', {
                        'is-disable': !true
                    })}
                    onClick={_handleClick}
                >
                    {t('addons:manage')} <IconCogWheel />
                </div>
                <VOIPAddonsAfterHours ref={refAfterHour} />
            </Fragment>
        );
    };

    const _renderFooter = () => {
        switch (keyword) {
            case VOIP_ACTIVE_USER:
            case ADDONS_JOB_STATUS_PERMISSION:
                return _renderActiveUsers();
            case VOIP_GROUP:
                return _renderGroup();
            case VOIP_PERSONAL:
                return _renderPersonal();
            case VOIP_AFTER_HOURS:
                return _renderAfterHour();
            case VOIP_AUTO_ATTENDANT:
                return _renderAutoAttendant();
            case VOIP_HOLD_MUSIC:
                return <AddonsOptionsFooterManage path={ADDONS_VOIP_HOLD} />;
            case VOIP_VOICEMAIL_DROP:
                return <AddonsOptionsFooterManage path={ADDONS_VOIP_VOICEMAIL_DROP} />;
            default:
                return null;
        }
    };

    return <div className={classNames('boxs__footer', { '--multi': isFooterMulti })}>{_renderFooter()}</div>;
};
