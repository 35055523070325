import classNames from 'classnames';
import React, { Fragment, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import GDIframe from 'app/components/iframe';
import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';
import IconYoutube from 'assets/icon/IconYoutube';
import { getPlanUser } from 'common/utils/PermissionUtils';

const GDTutorial = ({ tutorial = {}, isLoading = true }) => {
    const userProfile = useSelector(({ auth }) => auth.user.profile);
    const { isTrial } = getPlanUser(userProfile);
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { data: tutorial, isVisible: isTrial });
    const { data, isVisible } = state;
    const { title, link, link_article, description } = data;

    useEffect(() => {
        if (!!tutorial) {
            dispatchState((prev) => ({ ...prev, data: tutorial }));
        }
    }, [tutorial]);

    const _handleOpen = () => {
        dispatchState((prev) => ({ ...prev, isVisible: true }));
    };

    const _handleClose = () => {
        dispatchState((prev) => ({ ...prev, isVisible: false }));
    };

    return (
        <div className={classNames('btn-tutorials', { 'wrap-loading': isLoading })}>
            {isLoading ? (
                <LoadingButton />
            ) : (
                <Fragment>
                    <div className="v2-btn-default has-icon btn-modal" onClick={_handleOpen}>
                        <IconYoutube />
                        {t('tutorials')}
                    </div>
                    {isVisible ? (
                        <div className="modal container-modal modal-tutorials open">
                            <div className="modal__container">
                                <div className="header-modal">
                                    <h3 className="header-modal__label">{t('tutorials')}</h3>
                                    <div className="v2-btn-default --icon-lg --transparent" onClick={_handleClose}>
                                        <IconClose />
                                    </div>
                                </div>
                                <div className="body-modal scrolls">
                                    <GDIframe link={link} className="field-iframe" />
                                    {!!title && !!link_article ? (
                                        <a
                                            href={link_article}
                                            className="fw-700 fs-15 purple-default text-decoration mt-2 mb-1 dp-block"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {title}
                                        </a>
                                    ) : null}
                                    {!!description ? <p className="fs-14 black-darker1">{description}</p> : null}
                                </div>
                            </div>
                        </div>
                    ) : null}
                </Fragment>
            )}
        </div>
    );
};

export default GDTutorial;

const LoadingButton = () => {
    return (
        <div className="v2-btn-default btn-sm --grey loading bg-white">
            <div className="loading --animation --line --full"></div>
        </div>
    );
};
