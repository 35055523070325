import { PERMISSIONS } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import IconUser from 'assets/icon/IconUser';
import { getCommissionItems } from 'common/redux/actions/commission';
import { checkPermission, getPermissionCommission } from 'common/utils/PermissionUtils';
import React, { forwardRef, useEffect, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CommissionDropdown from './components/CommissionDropdown';

const Commission = ({ parentSelected = [] }, ref) => {
    const { t } = useTranslation(['common']);
    const dispatch = useDispatch();
    const commissionReducer = useSelector(({ commissionReducer }) => commissionReducer);
    const { permissions, settings } = useSelector(({ auth }) => auth.user);
    const { canModify, canDisplayCommission } = getPermissionCommission(
        settings.addons.commission_tracking,
        checkPermission(permissions?.enabled || [], PERMISSIONS.salesCommission)
    );
    const [state, dispatchState] = useReducer(reducer, {
        selected: parentSelected || []
    });
    const { selected } = state;

    useImperativeHandle(ref, () => ({ getValue: () => selected }));

    useEffect(() => {
        dispatchState({
            selected: parentSelected || []
        });
    }, [parentSelected]);

    useEffect(() => {
        if (commissionReducer.isFirstTime && canModify && canDisplayCommission) dispatch(getCommissionItems());
    }, []);

    const _handleSelect = (userInfo) => {
        const userIsSelected = selected.some((item) => item.id === userInfo.id);
        const newSelected = userIsSelected
            ? selected.filter((item) => item.id !== userInfo.id)
            : selected.concat(userInfo);

        dispatchState((prevState) => ({
            ...prevState,
            selected: newSelected
        }));
    };

    const _handleUnselect = (userId) => {
        const newSelected = selected.filter((item) => item.id !== userId);

        dispatchState((prevState) => ({
            ...prevState,
            selected: newSelected
        }));
    };

    if (!canModify) return null;
    return (
        <div className="rows border-bottom-border-color-grey">
            <div className="txt">
                <IconUser isWorker /> <span className="txt-ellipsis">{t('common:sold_by')}</span>
            </div>
            <div className="details detail-schedule">
                <CommissionDropdown selected={selected} onSelect={_handleSelect} onUnselect={_handleUnselect} />
            </div>
        </div>
    );
};

export default forwardRef(Commission);
