import React, { useEffect, useRef } from 'react';
import { debounce } from 'common/utils/FunctionUtils';

function GDInputRange({
    classWrapper = 'zoom-bar',
    classSlider = 'slider',
    classContainer = 'slidecontainer',
    leftComponent: LeftComponent,
    rightComponent: RightComponent,
    enableClick = false,
    divideBarComponent: DivideBarComponent,
    displayOverlay = false,
    displayTooltip = false,
    tooltipText = {},
    step = 5,
    min = 15,
    max = 50,
    defaultValue = 30,
    debounceTime = 350,
    onChange = () => {}
}) {
    const refRange = useRef(null);
    const refOverlay = useRef(null);
    const refTooltipText = useRef(null);
    const refContainer = useRef(null);

    React.useLayoutEffect(() => {
        refRange.current && refRange.current.addEventListener('wheel', _handleMouseWheel);
    }, []);

    useEffect(() => {
        refRange.current.value = defaultValue;
    }, [defaultValue]);

    const _incrementRange = (value) => {
        let newValue = Number.isNaN(value) ? 0 : value;

        if (newValue) {
            const valueIncrement = refRange.current.valueAsNumber + step;

            if (valueIncrement <= max) {
                newValue = valueIncrement;
            } else {
                newValue = max;
            }
        }

        refRange.current.value = newValue;
        handleChangeOverlay(newValue);
        onChange(newValue);
    };

    const _decrementRange = (value) => {
        let newValue = Number.isNaN(value) ? 0 : value;

        if (newValue) {
            const valueDecrement = refRange.current.valueAsNumber - step;
            if (valueDecrement >= min) {
                newValue = valueDecrement;
            } else {
                newValue = min;
            }
        }

        refRange.current.value = newValue;
        handleChangeOverlay(newValue);
        onChange(newValue);
    };

    const handleChangeOverlay = (value) => {
        if (refOverlay.current && displayOverlay) refOverlay.current.style.width = `${(value / max) * 100}%`;
    };

    const _handleChange = debounce((event) => {
        const valueAsNumber = event.target.valueAsNumber;
        onChange(valueAsNumber);
        handleChangeOverlay(valueAsNumber);
        if (displayTooltip && refTooltipText.current) {
            refTooltipText.current.innerText = tooltipText[valueAsNumber]?.['label'] || valueAsNumber;
            refContainer.current.style.setProperty('--value-slider', `${(valueAsNumber / max) * 100}%`);
        }
    }, debounceTime);

    const _handleMouseWheel = (e) => {
        let result = refRange.current.valueAsNumber;

        if (e.deltaY < 0) {
            result += step;
        } else {
            result -= step;
        }

        onChange(result);
    };

    return (
        <div className={classWrapper}>
            {LeftComponent && <LeftComponent onClick={_decrementRange} />}
            <span
                ref={refContainer}
                className={classContainer}
                style={{ '--value-slider': `${(defaultValue / max) * 100}%` }}
            >
                {displayOverlay ? (
                    <div
                        ref={refOverlay}
                        className="range-overlay"
                        style={{ width: `${(+defaultValue / max) * 100}%` }}
                    />
                ) : null}
                <input
                    ref={refRange}
                    type="range"
                    min={min}
                    max={max}
                    step={step}
                    className={classSlider}
                    defaultValue={defaultValue}
                    onChange={_handleChange}
                    onClick={(e) => {
                        if (enableClick) _handleChange(e);
                    }}
                    spellCheck
                />
                {displayTooltip ? (
                    <div className="tooltip">
                        <div ref={refTooltipText} className="tooltiptext top">
                            {tooltipText[defaultValue]?.['label']}
                        </div>
                    </div>
                ) : null}
                {DivideBarComponent ? <DivideBarComponent /> : null}
            </span>
            {RightComponent && <RightComponent onClick={_incrementRange} />}
        </div>
    );
}

export default GDInputRange;
