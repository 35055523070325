import React from 'react';
import ListButtonAction from 'app/modules/settings/components/ListButtonAction.js';
import classNames from 'classnames';
import CheckBoxHeader from 'app/modules/report/components/CheckBoxHeader';

const ActionHeader = ({
    totalSelected = 0,
    isShowPermanentlyDelete = true,
    listTabAction = [],
    checkedItems = {},
    handleClickButtonAction = () => {}
}) => {
    return (
        <div className={classNames('header --filter', { 'is-disable': !totalSelected })}>
            <CheckBoxHeader checkedItems={checkedItems} />
            <ListButtonAction
                list={listTabAction}
                isShowPermanentlyDelete={isShowPermanentlyDelete}
                fileTranslation="setting"
                onSubmit={handleClickButtonAction}
            />
        </div>
    );
};

export default ActionHeader;
