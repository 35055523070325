import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import GridEmpty from 'app/components/grid/components/GridEmpty';
import LoadingSmsList from 'app/components/loading/sms/LoadingSmsList';
import GDModalWarning from 'app/components/modal/ModalWarning';
import { reducer } from 'app/const/Reducer';
import { REPORT_LIMIT } from 'app/const/Reports';
import ConversationContent from 'app/modules/sms/components/ConversationContent';
import LoadMore from 'app/modules/sms/conversation/LoadMore';
import IconClose from 'assets/icon/IconClose';
import { smsGetListByCustomer } from 'common/redux/actions/sms/smsAction';

const SummaryConversation = (props, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false,
        isLoading: true,
        conversation: [],
        data: {},
        show_more: false,
        triggerRefresh: 0
    });
    const {
        isLoading,
        conversation: finalConversation,
        data: finalData,
        isOpen,
        show_more: showMore,
        triggerRefresh
    } = state;
    const {
        customer: { full_name, id: customerId } = {},
        summary,
        start_date: start,
        end_date: end,
        phone: finalPhone
    } = finalData || {};

    const refConversationDetail = useRef(null);
    const refLoadMore = useRef(null);
    const refWrapper = useRef(null);
    const refOffset = useRef(0);
    const refPreScroll = useRef(null);
    const refConfirm = useRef(null);
    const refRequestFrame = useRef(null);

    useImperativeHandle(ref, () => ({
        open: _handleOpen,
        close: _handleClose
    }));

    useEffect(() => {
        if (!isOpen) return;

        _getListSms();
        return () => {
            cancelAnimationFrame(refRequestFrame.current);
        };
    }, [isOpen, triggerRefresh]);

    const _handleOpen = (data = {}) => {
        refPreScroll.current = {};
        refOffset.current = 0;
        dispatchState((prev) => ({
            ...prev,
            isOpen: true,
            isLoading: true,
            data,
            triggerRefresh: prev.triggerRefresh + 1
        }));
    };

    const _handleClose = () => {
        dispatchState({ isOpen: false, conversation: [], triggerRefresh: 0 });
    };

    const _getListSms = ({ filter = {}, isLoadMore = false } = {}) => {
        const _handleSuccess = ({ data, show_more }) => {
            dispatchState((prev) => ({
                ...prev,
                isLoading: false,
                show_more,
                conversation: isLoadMore ? [...data, ...prev.conversation] : data
            }));
            cancelAnimationFrame(refRequestFrame.current);
            refRequestFrame.current = requestAnimationFrame(() => {
                if (!refWrapper.current) return;
                let scrollTop = refWrapper.current.scrollHeight;
                if (isLoadMore) {
                    const { height = 0, top = 0 } = refPreScroll.current;
                    scrollTop = top + (scrollTop - height);
                }
                refWrapper.current.scrollTop = scrollTop;
                refLoadMore.current?.setLoading(false);
            });
        };

        const _handleFail = ({ message }) => {
            refConfirm.current._open({ description: message });
        };

        dispatch(
            smsGetListByCustomer(
                {
                    customer_id: customerId || 0,
                    offset: refOffset.current,
                    limit: REPORT_LIMIT,
                    start,
                    end,
                    ...filter,
                    phone: finalPhone
                },
                _handleSuccess,
                _handleFail
            )
        );
    };

    const _handleOnScroll = (e) => {
        e && e.stopPropagation();

        if (refWrapper.current.scrollTop < 50 && !isLoading && !refLoadMore.current?.getState() && showMore) {
            refLoadMore.current?.setLoading(true);
            refOffset.current += REPORT_LIMIT;
            refPreScroll.current = {
                height: refWrapper.current.scrollHeight,
                top: refWrapper.current.scrollTop
            };

            _getListSms({ isLoadMore: true });
        }
    };

    if (!isOpen) return null;

    return (
        <div className="box-report-sms boxs-notification">
            <div ref={refConversationDetail} className="conversation-sms notification-dropdown active">
                <div className="message-header">
                    <div className="message-header__title flexcenter gap-8">
                        <div className="flex-column flex-1 txt-ellipsis">
                            <div className="fs-14 fw-500 purple-default txt-ellipsis" title={full_name}>
                                {full_name}
                            </div>
                            <div className="black txt-ellipsis" title={summary}>
                                {summary}
                            </div>
                        </div>
                    </div>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_handleClose}>
                        <IconClose />
                    </div>
                </div>
                {isLoading ? (
                    <LoadingSmsList />
                ) : !!finalConversation.length ? (
                    <div ref={refWrapper} className="message-contents" onScroll={_handleOnScroll}>
                        <div className="box-conversation">
                            <div className="conts-conversation">
                                <LoadMore ref={refLoadMore} />
                                <ConversationContent listSms={finalConversation} isHiddenMark />
                            </div>
                        </div>
                    </div>
                ) : (
                    <GridEmpty isFlat />
                )}
                <GDModalWarning ref={refConfirm} isLargeContent={false} title={t('addons:warning')} buttonConfirm />
            </div>
        </div>
    );
};

export default forwardRef(SummaryConversation);
