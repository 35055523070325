import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';

import { ADDONS_AI } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import IconArrow from 'assets/icon/IconArrow';
import ButtonAIChatBot from '../components/ButtonAIChatBot';
import { ADDONS_CHAT_BOT_MENU_OPTIONS } from '../constants';

const SideMenu = ({ onOpen = () => {} }, ref) => {
    const { t } = useTranslation(['addons']);

    const refButtonAIChatBot = useRef(null);

    useImperativeHandle(ref, () => ({ setDisableButton: _handleSetDisableButton }));

    const _handleSetDisableButton = (value) => {
        refButtonAIChatBot.current.setDisable(value);
    };

    return (
        <div className="sidebar-chatbot flex-column gap-16">
            <Link to={addBranchPath(ADDONS_AI)} className="v2-btn-default has-icon --grey">
                <IconArrow isPrev />
                {t('back')}
            </Link>
            <ButtonAIChatBot ref={refButtonAIChatBot} onOpen={onOpen} />
            <div className="is-divider --horizontal w-100" />
            <ul className="sidebar-left sidebar-menu">
                {ADDONS_CHAT_BOT_MENU_OPTIONS.map((item) => {
                    const { id, value, link, isHide } = item || {};
                    if (isHide) return null;
                    return (
                        <li key={id}>
                            <NavLink className="sidebar-items flexcenter" to={{ pathname: addBranchPath(link) }}>
                                <p className="txt-ellipsis">{value}</p>
                            </NavLink>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default forwardRef(SideMenu);
