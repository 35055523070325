import React, { useContext } from 'react';
import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';
import moment from 'moment';
import { convertTimeToISO } from 'common/utils/DateUtils';
import IconCalendar from 'assets/icon/IconCalendar';
import DatePickerInput from 'app/components/date/DateInput';
import { TABS_REPEAT } from '../../const';

const SettingScheduling = ({ onUpdate = () => {} }) => {
    const { repeatSettings, onChangeDataContext } = useContext(DataRepeatContext);

    function _handleChangeDate(date) {
        const currentRecurr = { ...repeatSettings?.recurrence?.offset };

        const finalDateStart = convertTimeToISO(
            moment(
                `${moment(date).format('MM-DD-YYYY')} ${moment(repeatSettings.timeStart).utc().format('hh:mm A')}`,
                'MM-DD-YYYY hh:mm A'
            )
        );

        if (currentRecurr.frequency === TABS_REPEAT.WEEKLY) {
            const arrayWeekDay = [
                currentRecurr.MO,
                currentRecurr.TU,
                currentRecurr.WE,
                currentRecurr.TH,
                currentRecurr.FR,
                currentRecurr.SA,
                currentRecurr.SU
            ];

            if (arrayWeekDay.filter((item) => parseInt(item)).length === 1) {
                onChangeDataContext({
                    ...repeatSettings,
                    recurrence: {
                        ...repeatSettings.recurrence,
                        offset: {
                            ...currentRecurr,
                            MO: '0',
                            TU: '0',
                            WE: '0',
                            TH: '0',
                            FR: '0',
                            SA: '0',
                            SU: '0',
                            [moment(convertTimeToISO(moment(date)))
                                .utc()
                                .format('dd')
                                .toUpperCase()]: '1'
                        }
                    },
                    start: finalDateStart,
                    reloadDateJob: Date.now()
                });
                return false;
            }
        }

        onUpdate(finalDateStart);

        onChangeDataContext({
            ...repeatSettings,
            start: finalDateStart,
            reloadDateJob: Date.now()
        });
    }

    return (
        <div className="rows">
            <div className="txt">
                <IconCalendar />
                Date
            </div>
            <DatePickerInput onChange={_handleChangeDate} selectDefault={repeatSettings.start} />
        </div>
    );
};

export default SettingScheduling;
