import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import { INBOX } from 'app/config/routes';
import { INBOX_EMAIL } from 'app/const/addons';
import { addBranchPath, addPrefixPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { FILTER_STATUS_INBOX, LIST_FILTER_STATUS_INBOX } from 'app/modules/inbox/const';
import IconDone from 'assets/icon/IconDone';
import IconForward from 'assets/icon/IconForward';
import IconFuture from 'assets/icon/IconFuture';
import IconInbox from 'assets/icon/IconInbox';
import IconOutbox from 'assets/icon/IconOutbox';
import IconPen from 'assets/icon/IconPen';
import IconSent from 'assets/icon/IconSent';
import routesInbox from '../routesInbox';

function FilterStatus({ onSelect, filterType, filterStatus, features }, ref) {
    const { pathname } = useLocation();
    const history = useHistory();
    const { t } = useTranslation();

    const { settings: settingsUser } = useSelector(({ auth }) => auth.user);
    const activeInboxAddon = !!settingsUser?.addons?.inbox_email;
    const isActiveSms = !!settingsUser.addons.smsplivo;
    const [state, setState] = useReducer(reducer, {
        selected: LIST_FILTER_STATUS_INBOX.find((item) => item.type === filterStatus),
        filterType
    });

    const { selected: finalSelected, filterType: finalTypeSelected } = state;
    const { type: selectedType } = finalSelected || {};

    useImperativeHandle(ref, () => ({
        changeType: _handleChangeFilterType,
        changeStatus: _handleChangeStatus
    }));

    const _handleChangeFilterType = (type) => {
        setState({ filterType: type });
    };

    const _handleChangeStatus = (status) => {
        setState({ selected: LIST_FILTER_STATUS_INBOX.find((item) => item.type === status) });
    };

    function _getIconOption(type) {
        switch (type) {
            case FILTER_STATUS_INBOX.INBOX:
                return <IconInbox />;
            case FILTER_STATUS_INBOX.DONE:
                return <IconDone />;
            case FILTER_STATUS_INBOX.FUTURE:
                return <IconFuture />;
            case FILTER_STATUS_INBOX.SENT:
                return <IconSent />;
            case FILTER_STATUS_INBOX.DRAFT:
                return <IconPen isUnderline />;
            case FILTER_STATUS_INBOX.OUTBOX:
                return <IconOutbox />;
            default:
                break;
        }
    }

    function _handleSelectOption(e, optionSelected, isActive) {
        const { path } = optionSelected;
        !!e && e.stopPropagation();

        const isChangePath = pathname !== path;

        if (isChangePath) {
            history.push(addBranchPath(path || INBOX), {
                status: optionSelected.type,
                isLoadingSideBar: false,
                features
            });
        }

        if (isActive) {
            return false;
        }
        setState({
            selected: optionSelected
        });
        onSelect(optionSelected.type, isChangePath);
    }

    const checkDifferentPath = () => {
        return routesInbox.some(({ id, path }) => {
            if (id === INBOX_EMAIL) return false;
            return !!matchPath(pathname, { path: addPrefixPath(path), exact: true });
        });
    };

    function _renderListOptions() {
        return LIST_FILTER_STATUS_INBOX.map((item) => {
            const { type: finalType, parent: isParent, path } = item;
            const isActive = finalType === selectedType && !checkDifferentPath();
            const featureValue = features?.[item?.keyFeature];
            const isHideFeature = typeof featureValue === 'undefined' ? false : !featureValue;

            if (!item.allow.includes(finalTypeSelected) || isHideFeature) return null;
            if (!activeInboxAddon && [FILTER_STATUS_INBOX.SENT, FILTER_STATUS_INBOX.DRAFT].includes(finalType))
                return null;
            if (!isActiveSms && finalType === FILTER_STATUS_INBOX.OUTBOX) return null;
            const _renderContent = () => {
                return (
                    <a
                        className={classNames('sidebar-items flexcenter gap-4', {
                            active: isActive,
                            'fw-600': isActive
                        })}
                    >
                        {_getIconOption(finalType)}
                        <span className="txt-ellipsis text-capitalize">{t(`common:${item.name}`)}</span>
                    </a>
                );
            };

            return (
                <div
                    key={finalType}
                    onClick={(e) => _handleSelectOption(e, { ...item, path: path || INBOX }, isActive)}
                    className="flexcenter gap-8"
                >
                    {!isParent && <IconForward isBottom />}
                    {_renderContent()}
                </div>
            );
        });
    }

    return _renderListOptions();
}

export default forwardRef(FilterStatus);
