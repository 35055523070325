export const TAXES = 'taxes_setting_page';
export const LINE_ITEMS = 'line_items_setting_page';
export const SOURCES = 'sources_setting_page';
export const COMPANY = 'company_setting_page';
export const TAGS = 'tags_setting_page';
export const PAYMENT_METHODS = 'payment_methods_setting_page';
export const EMAIL_INBOX = 'email_inbox_setting_page';
export const BROADCAST = 'broadcast_setting_page';
export const USERS = 'users_setting_page';
export const SERVICE = 'service_setting_page';
export const SCHEDULES = 'service_schedules_page';
export const INVOICES = 'invoices_setting_page';
export const SERVICE_PLAN = 'service_plan_setting_page';
