import classNames from 'classnames';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconThreeDots from 'assets/icon/IconThreeDots';
import {
    TWO_AUTHENTICATION_EDIT_OPTIONS,
    TWO_AUTHENTICATION_EDIT_TYPES,
    TWO_AUTHENTICATION_OPTIONS,
    TWO_AUTHENTICATION_TYPES
} from '../constants';

const RowTwoAuth = ({ item = {}, onClickAction = () => {} }) => {
    const { t } = useTranslation('');

    const { id: finalId, method, created_date, is_default: isDefault = 0 } = item;
    const { DEFAULT, UPDATE } = TWO_AUTHENTICATION_EDIT_TYPES;
    const isTypeEmail = method === TWO_AUTHENTICATION_TYPES.EMAIL;

    const refDropdown = useRef(null);

    const _renderOptions = () => {
        return TWO_AUTHENTICATION_EDIT_OPTIONS.map(({ id, name: finalName }) => {
            if (id === UPDATE && isTypeEmail) return null;
            const isDisable = id === DEFAULT && isDefault;

            const _handleClick = () => {
                onClickAction({ type: method, id: finalId, typeSelect: id });
                refDropdown.current?._closeDropdown();
            };

            return (
                <li key={id} className={classNames('items', { 'is-disable': isDisable })} onClick={_handleClick}>
                    {finalName}
                </li>
            );
        });
    };

    return (
        <div className="rows">
            <div className="col col-lg flexcenter">
                <p className="col-label">{t(TWO_AUTHENTICATION_OPTIONS.find((item) => item.id === method).name)}</p>
                {!!isDefault && <span className="budget --default fw-600">Default</span>}
            </div>
            <div className="col col-md">
                <p className="col-label">{created_date}</p>
            </div>
            <div className="col --menu">
                <CalendarDropdown
                    ref={refDropdown}
                    wrapperClassName="v2-dropdown"
                    buttonClassName="dropbtn v2-btn-default --icon-sm --transparent"
                    customDropButton={() => <IconThreeDots />}
                    wrapperListClass="v2-dropdown__menu content-full --right scrolls"
                >
                    <ul>{_renderOptions()}</ul>
                </CalendarDropdown>
            </div>
        </div>
    );
};

export default RowTwoAuth;
