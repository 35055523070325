import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import GdVideo from 'app/components/attachments/GdVideo';
import { ACCEPTED_ATTACHMENTS } from 'app/const/App';
import { getIconTypeFile } from 'app/const/icon/IconsType';
import { SMS_TYPE } from 'app/const/Sms';
import { LINK_CDN_IMG } from 'app/const/URL';
import { isFileAudio, isFileImage, isFileText, isFileVideo } from 'common/utils/FileUtils';

const SmsAttachments = ({
    data = [],
    description,
    boxAttachClass = null,
    onClick = () => {},
    smsType = SMS_TYPE.RECEIVE,
    excludeWrapper = false
}) => {
    if (!data?.length) return null;

    const _renderAttachments = () => {
        return (
            <div className={classNames('box-attach', boxAttachClass)}>
                {data?.map((item, key) => (
                    <SmsItemAttach
                        key={item.object_key || item?.url || key.toString()}
                        onClick={onClick}
                        {...item}
                        smsType={smsType}
                    />
                ))}
            </div>
        );
    };

    if (excludeWrapper) return _renderAttachments();

    return (
        <div className="wrap-attachment">
            {description && <div className="description" dangerouslySetInnerHTML={{ __html: description }} />}
            <div className="attachment-file">{_renderAttachments()}</div>
        </div>
    );
};

const SmsItemAttach = ({ url, name = '', type, onClick, smsType }) => {
    const [urlAttachMent, setUrlAttachMent] = useState(url);
    const nameAttach = name.split('.');
    if (!ACCEPTED_ATTACHMENTS.includes(type) && smsType === SMS_TYPE.SEND) return null;

    const _handleErrorImg = (e) => {
        e.target.src = `${LINK_CDN_IMG}image-broken.png`;
        setUrlAttachMent((url = e.target.src));
    };

    if (isFileImage(type)) {
        return (
            <div onClick={() => onClick('openDetail', urlAttachMent, type)} className="img-attach">
                <img src={urlAttachMent} alt="" onError={(e) => _handleErrorImg(e)} />
            </div>
        );
    }

    if (isFileVideo(type)) {
        return (
            <GdVideo src={urlAttachMent} type={type} handleOpen={() => onClick('openDetail', urlAttachMent, type)} />
        );
    }

    if (isFileText(type)) {
        return (
            <a href={urlAttachMent} className="pdf-attach" target="_blank" rel="noreferrer">
                <div className="detail">{getIconTypeFile(type)}</div>
                <div className="name-file">
                    <span>{nameAttach[0]}</span>
                    <span>.{nameAttach[nameAttach.length - 1]}</span>
                </div>
            </a>
        );
    }

    if (isFileAudio(type)) {
        return (
            <div className="attachment-audio">
                <audio controls onError={(e) => _handleErrorImg(e)}>
                    <source src={urlAttachMent} type="audio/mpeg" />
                </audio>
            </div>
        );
    }

    return (
        <div className="pdf-attach" onClick={() => onClick('openDetail', urlAttachMent, type)}>
            <div className="detail">{getIconTypeFile(type)}</div>
            <div className="name-file">
                <span>{nameAttach[0]}</span>
                <span>.{nameAttach[nameAttach.length - 1]}</span>
            </div>
        </div>
    );
};

SmsAttachments.propTypes = {
    attachments: PropTypes.array,
    description: PropTypes.string,
    boxAttachClass: PropTypes.string,
    onClick: PropTypes.func
};

export default SmsAttachments;
