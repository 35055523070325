import classNames from 'classnames';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import ButtonSave from 'app/components/button/ButtonSave';
import { ACCOUNT_DETAIL_CHATBOT } from 'app/const/api/V2';
import { DEFAULT_CURRENCY_SYMBOL, LIST_STATUS } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import IconClose from 'assets/icon/IconClose';
import { clientQuery } from 'common/utils/ApiUtils';
import { LIST_RECHARGE_AMOUNT, LIST_RECHARGE_THRESHOLD } from '../../constants';
import { LoadingManagePlan } from '../LoadingManagePlan';
import { ChatbotAddonsModifyPlan } from './ChatbotAddonsModifyPlan';

const ModalManagePlan = ({
    currencySymbol = DEFAULT_CURRENCY_SYMBOL,
    onClose = () => {},
    onUpdateSuccess = () => {}
}) => {
    const { t } = useTranslation('addons');
    const [state, dispatchState] = useReducer(reducer, { isLoading: true, account: {} });
    const { isLoading: finalIsLoading, account: finalAccount } = state;

    const refAlert = useRef(null);
    const refButtonSave = useRef(null);

    useEffect(() => {
        _fetchPlan();
    }, []);

    const _handleChangePlan = (data) => {
        dispatchState((prev) => ({ ...prev, account: { ...prev.account, ...(data || {}) } }));
    };

    const _handleUpdate = () => {
        const _handleSuccess = ({ message }) => {
            onUpdateSuccess();
            _handleShowAlert({ message: !!message.length ? message : t('your_plan_has_been_successfully_updated') });
            _removeLoading();
        };
        const _handleFail = ({ message }) => {
            _handleShowAlert({ message, isSuccess: false });
            _removeLoading();
        };
        clientQuery(
            ACCOUNT_DETAIL_CHATBOT,
            {
                method: 'PUT',
                toFormData: false,
                data: {
                    recharge_amount: finalAccount.recharge_amount || LIST_RECHARGE_AMOUNT[0],
                    recharge_threshold: finalAccount.recharge_threshold || LIST_RECHARGE_THRESHOLD[0]
                }
            },
            _handleSuccess,
            _handleFail
        );
    };

    const _removeLoading = () => {
        refButtonSave.current.removeLoading();
    };

    const _fetchPlan = () => {
        const _handleSuccess = ({ data }) => {
            dispatchState((prev) => ({ ...prev, account: data?.account || {}, isLoading: false }));
        };
        const _handleFail = ({ message }) => {
            dispatchState((prev) => ({ ...prev, account: {}, isLoading: false }));
            _handleShowAlert({ message: message || t('common:something_went_wrong'), isSuccess: false });
        };
        clientQuery(ACCOUNT_DETAIL_CHATBOT, { method: 'GET' }, _handleSuccess, _handleFail);
    };

    const _handleShowAlert = ({ message = '', isSuccess = true }) => {
        refAlert.current.showStatusBar({
            id: 'modal_manage_plan',
            message,
            type: isSuccess ? LIST_STATUS.SUCCESS : LIST_STATUS.ERROR
        });
    };

    return (
        <ReactModal
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal wrapper-has-preview is-preview-template wrapper-billing open"
            onRequestClose={onClose}
        >
            <div className="modal__overlay bg-fixed" />
            <div className="modal__container --preview-r">
                <div className="wrap-container">
                    <div className="wrap-container__form flex-column wrap-document-edit">
                        <div className="header-modal">
                            <h3 className="header-modal__label">{t('manage_your_plan')}</h3>
                            <div className="v2-btn-default --transparent --icon-lg" onClick={onClose}>
                                <IconClose />
                            </div>
                        </div>
                        <AlertCustomer ref={refAlert} />
                        <div className="body-modal scrolls">
                            {finalIsLoading ? (
                                <LoadingManagePlan />
                            ) : (
                                <ChatbotAddonsModifyPlan
                                    allowUpdateValue={false}
                                    currencySymbol={currencySymbol}
                                    data={state}
                                    onChangePlan={_handleChangePlan}
                                />
                            )}
                        </div>
                        <div className="footer-modal">
                            <div className="flexcenter justify-end flex-1">
                                <div className="v2-btn-default --transparent" onClick={onClose}>
                                    {t('cancel')}
                                </div>
                                <ButtonSave
                                    ref={refButtonSave}
                                    wrapClass={classNames('v2-btn-main ml-2', { 'is-disable': finalIsLoading })}
                                    onSave={_handleUpdate}
                                >
                                    {t('update_plan')}
                                </ButtonSave>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ReactModal>
    );
};

export default ModalManagePlan;
