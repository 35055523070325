import React from 'react';

export const LoadingManagePlan = () => {
    return (
        <div className="wrap-loading">
            <div className="wrap-plans flex-column gap-12">
                <div className="flex-column gap-6">
                    <div className="loading --animation --line --half"></div>
                    <div className="loading --animation --line --threefourth mt-2"></div>
                    <div className="loading --animation --line --threefourth mt-2"></div>
                    <div className="loading --animation --line --full mt-2"></div>
                    <div className="loading --animation --line --threefourth mt-2"></div>
                    <div className="loading --animation --line --half mt-4"></div>
                    <div className="loading --animation --line --half mt-2"></div>
                </div>
                <div className="is-divider --horizontal my-1s"></div>
                <div className="wrap-plans__cost flexcenter gap-24">
                    <div className="flex-column gap-4 flex-1">
                        <div className="loading --animation --line --threefourth"></div>
                        <div className="box-plans pb-6 border-border-color-grey">
                            <div className="loading --animation --line --full"></div>
                            <div className="loading --animation --line --full mt-2"></div>
                        </div>
                    </div>
                    <div className="flex-column gap-4 flex-1">
                        <div className="loading --animation --line --threefourth"></div>
                        <div className="box-plans pb-6 border-border-color-grey">
                            <div className="loading --animation --line --full"></div>
                            <div className="loading --animation --line --full mt-2"></div>
                        </div>
                    </div>
                </div>
                <div className="box-plans --lg flex-column gap-10 border-border-color-grey">
                    <div className="flex-betweenitems">
                        <div className="loading --animation --line --half"></div>
                        <div className="loading --animation --line --onefifth mt-0"></div>
                    </div>
                    <div className="is-divider --horizontal"></div>
                    <div className="flex-betweenitems fw-600">
                        <div className="loading --animation --line --half"></div>
                        <div className="loading --animation --line --onefifth mt-0"></div>
                    </div>
                </div>
                <div className="box-plans --lg flex-column gap-10 border-border-color-grey">
                    <div className="flex-betweenitems">
                        <div className="loading --animation --line --half"></div>
                        <div className="loading --animation --line --onefifth mt-0"></div>
                    </div>
                    <div className="is-divider --horizontal"></div>
                    <div className="flex-betweenitems fw-600">
                        <div className="loading --animation --line --half"></div>
                        <div className="loading --animation --line --onefifth mt-0"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};
