import classNames from 'classnames';
import React, { Fragment, useEffect, useMemo, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import TabSwitcher from 'app/components/tabs/TabSwitcher';
import { TYPE_PHONE_VOIP } from 'app/const/addons';
import { reducer } from 'app/const/Reducer';
import { VOIP_AGENT_ROUTING } from 'app/const/Voip';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { reorder } from 'app/modules/smartview/components/utils';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconCheck from 'assets/icon/IconCheck';
import IconClose from 'assets/icon/IconClose';
import { getUserInfo } from 'common/utils/CompanyUserUtils';
import { formatPhoneNumberVoip, rawPhone, validatePhoneNumberVoip } from 'common/utils/PhoneUtils';
import ItemPhoneAutoAttendant from './ItemPhoneAutoAttendant';

export const ForwardAutoAttendant = ({
    item,
    onChangeForward = () => {},
    onSelectPhone = () => {},
    onChangeAgentRouting = () => {},
    onChangeNumbers = () => {}
}) => {
    const { t } = useTranslation('addons');
    const refInput = useRef(null);
    const refTimer = useRef(null);
    const refChanged = useRef(false);

    // eslint-disable-next-line no-undef
    const listNumber = global.listNumberVoip || [];
    const { id: finalId, numbers: finalPhone, agent_routing } = item;

    const [state, dispatchState] = useReducer(reducer, {
        isCustomPhone: false,
        numberSelected: finalPhone,
        phoneMap: listNumber.reduce((phones, phone) => {
            phones[phone.phone_number] = phone;
            return phones;
        }, {})
    });

    const { isCustomPhone, numberSelected, phoneMap } = state;
    const enableDragNumbers = VOIP_AGENT_ROUTING.ENABLE_DRAGGABLE.includes(agent_routing);

    const listCustomPhone = useMemo(() => {
        const array = [];
        numberSelected.forEach((item) => {
            if (!listNumber.some((phone) => phone.phone_number === item.phone_number)) {
                array.push({ ...item, isCustom: true });
            }
        });
        return array;
    }, [numberSelected]);
    const finalListPhone = [...listCustomPhone, ...listNumber];

    const _getPhoneList = () => {
        const selectedPhoneNumbers = new Set(numberSelected.map((num) => num.phone_number));
        const defaultPhoneList = [];
        const agentRoutingPhoneList = [...numberSelected];
        finalListPhone.forEach((phone) => {
            if (!selectedPhoneNumbers.has(phone.phone_number)) {
                defaultPhoneList.push(phone);
            } else {
                const index = agentRoutingPhoneList.findIndex((item) => item.phone_number === phone.phone_number);
                if (index !== -1) {
                    agentRoutingPhoneList[index] = { ...agentRoutingPhoneList[index], ...phone };
                }
            }
        });

        return { defaultPhoneList, agentRoutingPhoneList };
    };
    const { defaultPhoneList, agentRoutingPhoneList } = _getPhoneList();

    useEffect(() => {
        return () => {
            clearTimeout(refTimer.current);
        };
    }, []);

    const _customButton = () => {
        const _renderSelected = () => {
            let tooltip = '';
            let isShowComma = -1;
            return {
                content: numberSelected.map(({ phone_number: phoneItem }) => {
                    if (!phoneItem) return null;
                    const phone = phoneMap[phoneItem];
                    const assignees = phone?.assignees;
                    if (Array.isArray(assignees) && !assignees.length) return null;
                    const { full_name = '' } = getUserInfo(assignees?.[0]) || {};
                    const nameNumber = phone?.type === TYPE_PHONE_VOIP.GROUP ? t('group_number') : full_name;
                    const finalPhone = formatPhoneNumberVoip(phoneItem || '');
                    tooltip += `${nameNumber} ${finalPhone}`.trim() + '\n';
                    isShowComma++;

                    return (
                        <Fragment key={phoneItem}>
                            <span className={classNames({ 'mr-2': !!nameNumber })}>{`${
                                !!isShowComma ? ', ' : ''
                            }${nameNumber}`}</span>
                            <span className="black-darklight">{finalPhone}</span>
                        </Fragment>
                    );
                }),
                tooltip
            };
        };

        const { content, tooltip } = _renderSelected();
        return (
            <Fragment>
                <span className="phone-info txt-ellipsis fw-normal black-jungle-green" title={tooltip.trim()}>
                    {content}
                </span>
                <div className="arrow">
                    <IconArrowDown />
                </div>
            </Fragment>
        );
    };

    const _onDragEnd = ({ sourceIndex = 0, destinationIndex = 0 }) => {
        const finalNumbers = reorder(numberSelected, sourceIndex, destinationIndex);
        dispatchState((prev) => ({ ...prev, numberSelected: finalNumbers }));
        onChangeNumbers({ id: finalId, numbers: finalNumbers });
    };

    const _renderContent = () => {
        return (
            <Fragment>
                <div className="content-checked__all">
                    {_renderCustomField()}
                    {!!agentRoutingPhoneList.length || !!defaultPhoneList.length ? (
                        <TabSwitcher
                            wrapperClassName="tabs flex-1 ml-1"
                            keyGetLabel="name"
                            activeTab={agent_routing}
                            listTabs={VOIP_AGENT_ROUTING.LIST_DATA}
                            onChange={(value) => onChangeAgentRouting({ id: finalId, value })}
                        />
                    ) : null}
                </div>
                <ItemPhoneAutoAttendant
                    listPhone={agentRoutingPhoneList}
                    isAgentRoutingPhone
                    isShowDrag={enableDragNumbers}
                    numberSelected={numberSelected}
                    onSelectOption={_handleSelectOption}
                    onDragEnd={_onDragEnd}
                />
                <ItemPhoneAutoAttendant
                    listPhone={defaultPhoneList}
                    isAgentRoutingPhone={false}
                    numberSelected={numberSelected}
                    onSelectOption={_handleSelectOption}
                />
            </Fragment>
        );
    };

    const _handleAddPhone = () => {
        const value = refInput.current?.value;
        clearTimeout(refTimer.current);
        if (
            !validatePhoneNumberVoip(value) ||
            finalListPhone.some((phone) => rawPhone(phone.phone_number, true) === rawPhone(value, true))
        ) {
            refInput.current?.classList?.add('field-error');

            refTimer.current = setTimeout(() => {
                refInput.current?.classList?.remove('field-error');
            }, 2000);
            return;
        }
        refChanged.current = true;
        dispatchState((prev) => {
            const numberSelected = [...prev.numberSelected, { number_id: null, phone_number: value }];
            onSelectPhone({ id: finalId, selected: numberSelected });
            return {
                ...prev,
                isCustomPhone: false,
                numberSelected
            };
        });
    };

    const _renderCustomField = () => {
        if (!isCustomPhone)
            return (
                <div className="items fw-500 grey-very-dark" onClick={() => _handleClickCustomPhone()}>
                    {t('custom_phone_number')}
                </div>
            );

        return (
            <div className="field-custom flexcenter gap-4">
                <input ref={refInput} className="field-input flex-1" type="number" placeholder={'+1'} autoFocus />
                <div className="v2-btn-default --icon-lg" onClick={() => _handleClickCustomPhone(false)}>
                    <IconClose />
                </div>
                <div className="v2-btn-main --icon-lg svg-white-stroke" onClick={_handleAddPhone}>
                    <IconCheck />
                </div>
            </div>
        );
    };

    const _handleClickCustomPhone = (value = true) => {
        dispatchState((prev) => ({ ...prev, isCustomPhone: value }));
    };

    const _handleSelectOption = ({ value, isChecked = false }) => {
        refChanged.current = true;
        dispatchState((prev) => {
            let finalSelected = prev.numberSelected;
            if (isChecked) finalSelected.push(value);
            else finalSelected = finalSelected.filter((item) => item.phone_number !== value.phone_number);
            onSelectPhone({ id: finalId, selected: finalSelected });
            return { ...prev, numberSelected: finalSelected };
        });
    };

    const _handleOnClose = () => {
        if (!refChanged.current) return;
        _handleClickCustomPhone(false);
        onChangeForward();
        refChanged.current = false;
    };

    return (
        <CalendarDropdown
            isDropUp
            wrapperClassName="v2-dropdown elm-custom-parent has-img-user "
            wrapperListClass={classNames('v2-dropdown__menu content-checked --right', {
                'has-drag-items': enableDragNumbers && agentRoutingPhoneList.length > 1
            })}
            buttonClassName="dropbtn v2-btn-default --transparent w-100 pl-3"
            customDropButton={_customButton}
            onHide={_handleOnClose}
        >
            {_renderContent()}
        </CalendarDropdown>
    );
};
