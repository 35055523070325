import moment from 'moment';
import { CALENDAR_MODES, COLOR_SCHEDULES } from '../const';
import { actionJobStatus } from './JobAction';
import { generateIdMarker } from './Calendar';

export const convertEventToDataMarker = ({ eventJob, customer, event, location, extended = null }) => {
    const extendedProps = extended || event.extendedProps || {};
    const idMarker = generateIdMarker(
        extendedProps.eventId,
        null,
        false,
        extendedProps.schedule?.id || '',
        extendedProps.type
    );
    return {
        idMarker,
        location,
        customer,
        schedule: extendedProps.schedule,
        repeat: eventJob.repeat,
        status: extendedProps.status,
        start: eventJob.start,
        id: eventJob.id,
        serviceName: extendedProps.name,
        recurrence: extendedProps.recurrence,
        event: extendedProps,
        jobId: event.id
    };
};

export const getColorWorkPool = ({ jobStart, start, end, scheduleId }) => {
    const startCalendar = moment.utc(start);
    const endCalendar = moment.utc(end);

    let days = endCalendar.diff(startCalendar, 'days');
    days = days > COLOR_SCHEDULES.length ? COLOR_SCHEDULES.length : days;

    const dayNumber = (moment.utc(jobStart)._d - moment.utc(start)._d) / (24 * 3600 * 1000);
    let startDateWeekday = dayNumber % days;
    startDateWeekday = Math.floor(startDateWeekday < 0 ? startDateWeekday + days : startDateWeekday);
    const idResourceColor = `${Math.abs(scheduleId)}_${startDateWeekday}`;
    const divQuery = document.querySelector(`[data-resource-id-color="${idResourceColor}"]`);

    return divQuery ? divQuery.style.backgroundColor : COLOR_SCHEDULES[startDateWeekday];
};

export const statusJobMapData = (events, jobData) => {
    const { list: newEvents } = actionJobStatus(jobData, events || []);
    return newEvents;
};

export const deletedJobMapData = (events = [], infoEvent) => {
    let newEvents = events || [];
    const currentParentJob = infoEvent.parent_job_id;
    const jobComplete = parseInt(infoEvent.previously_completed);
    const currentJobNo = infoEvent.job_no;

    if (jobComplete === 1) {
        newEvents = newEvents.filter((item) => {
            if (item.id !== infoEvent.id) return item;
        });
    } else {
        newEvents = newEvents.filter((eventDetail) => {
            if (currentParentJob === eventDetail.parent_job_id && eventDetail.job_no >= currentJobNo) {
                //
            } else {
                return eventDetail;
            }
        });
    }

    return newEvents;
};

export const getResourceIdHorizontal = (event, droppableEl) => {
    if (!droppableEl) throw new Error('droppableEl must be element HTML');
    const rect = droppableEl.getBoundingClientRect();
    const positionDropped = event.clientY - rect.top;

    let resourceId = null;
    const columnContainer = document.querySelectorAll('.fc-scrollgrid-section-body');
    const containerRect = columnContainer[0].getBoundingClientRect();
    const elementsContainsResource = document.querySelectorAll('.fc-datagrid-cell.fc-resource');

    for (let index = 0; index < elementsContainsResource.length; index++) {
        const item = elementsContainsResource[index];
        const itemRect = item.getBoundingClientRect();
        const range = [itemRect.top - containerRect.top, itemRect.bottom - containerRect.top];
        if (positionDropped >= range[0] && positionDropped <= range[1]) {
            resourceId = item.dataset.resourceId;
            break;
        }
    }

    return resourceId;
};

export const getWidthAfterDiv = ({ view, widthofcolumn }) => {
    switch (view) {
        case CALENDAR_MODES['AGENDA_WEEK']:
            return widthofcolumn * 4 * 24;
        case CALENDAR_MODES['AGENDA_2_WEEK']:
        case CALENDAR_MODES['AGENDA_3_WEEK']:
        case CALENDAR_MODES['AGENDA_4_WEEK']:
            return widthofcolumn * 24;
        default:
            return widthofcolumn * 4 * 24;
    }
};

export const isMapAttached = () => {
    const mapElement = document.getElementById('map');
    if (mapElement === null) return false;
    return mapElement.classList.contains('mapboxgl-map');
};
