import { SERVICE_PLAN_ACTIONS } from '../actions/servicePlanAction';

const servicePlanReducer = (
    state = {
        // servicePlanDetail: {
        //     jobId: '111',
        //     messaging_preferences: {
        //         appointment_confirmations: 3,
        //         appointment_reminders: 3,
        //         appointment_followups: 3,
        //         broadcasts: 3,
        //         late_payment_reminders: 3,
        //         sms_to: [],
        //         email_to: []
        //     },
        //     contact: [
        //         {
        //             id: 589788,
        //             first_name: 'demo',
        //             last_name: 'contact',
        //             full_name: 'demo contact',
        //             avatar: 'DC',
        //             title: '',
        //             email: '',
        //             phones: [
        //                 {
        //                     number: '+1 (650) 504-2307',
        //                     type: 'Mobile',
        //                     is_valid: 1
        //                 }
        //             ]
        //         }
        //     ],
        //     customer: {
        //         id: '3956589',
        //         account_no: '7793312501',
        //         title: 'huỳnh thị kim hiên',
        //         first_name: '$Hiên Huỳnh',
        //         last_name: '',
        //         full_name: '$Hiên Huỳnh ',
        //         avatar: 'HH',
        //         email: 'hien.huynh@namlongsoft.net',
        //         phones: [
        //             {
        //                 id: 4425060,
        //                 phone: '(123) 456-7845',
        //                 is_valid: 0,
        //                 type_id: 1,
        //                 type: 'Mobile',
        //                 is_default_type: 1
        //             }
        //         ]
        //     },
        //     location: {
        //         id: '4286466',
        //         name: '100 Centre Street',
        //         note: '',
        //         street1: '100 Centre Street',
        //         street2: '',
        //         city: 'New York',
        //         state: 'NY',
        //         zip: '10013',
        //         lat: '40.7158872',
        //         lng: '-74.0012066',
        //         photo: null,
        //         tax1: null,
        //         tax2: null
        //     },
        //     sold_by: [
        //         {
        //             id: '83903613',
        //             first_name: '0 Technican',
        //             last_name: 'New 1 Edit',
        //             full_name: '0 Technican New 1 Edit',
        //             avatar: 'https://d2e627ktfmb6xb.cloudfront.net/small/avatars%2F1705636074_images1.png',
        //             type: 0
        //         },
        //         {
        //             id: '83947941',
        //             first_name: '0 Admin',
        //             last_name: 'New 1',
        //             full_name: '0 Admin New 1',
        //             avatar: 'https://d2e627ktfmb6xb.cloudfront.net/small/avatars%2F1705379904_th.jpg',
        //             type: 0
        //         },
        //         {
        //             id: '83947943',
        //             first_name: '0 Technican',
        //             last_name: 'New 1',
        //             full_name: '0 Technican New 1',
        //             avatar: 'https://d2e627ktfmb6xb.cloudfront.net/small/avatars%2F1705379945_th_%281%29.jpg',
        //             type: 0
        //         },
        //         {
        //             id: '83948100',
        //             first_name: '0 Technican',
        //             last_name: 'New 4',
        //             full_name: '0 Technican New 4',
        //             avatar: 'https://d2e627ktfmb6xb.cloudfront.net/avatars%2Fstatic%2Favatar_1.jpg',
        //             type: 0
        //         }
        //     ]
        // },
        servicePlanDetail: null
    },
    action
) => {
    switch (action.type) {
        case SERVICE_PLAN_ACTIONS.OPEN_SERVICE_PLAN:
            return { ...state, servicePlanDetail: action.payload };
        case SERVICE_PLAN_ACTIONS.CLOSE_SERVICE_PLAN:
            return { ...state, servicePlanDetail: null };
        default:
            return state;
    }
};

export default servicePlanReducer;
