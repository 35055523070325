import i18n from 'assets/i18n';
import { CALENDAR_MODES } from '.';
import { DEFAULT_ALL } from 'app/const/App';
import { JOB_CONFIRMED, JOB_UNCONFIRMED } from 'app/const/Job';

export const CALENDAR_COLORS = [
    { id: 1, name: 'Job Status' },
    { id: 2, name: 'Service Template' },
    { id: 3, name: 'Zip Code' },
    { id: 4, name: 'County' }
];

export const AGENDAR_MODE = [
    {
        id: CALENDAR_MODES.AGENDA_DAY,
        name: 'Day',
        text: 'Day'
    },
    {
        id: CALENDAR_MODES.AGENDA_WEEK,
        name: 'Week',
        text: 'Week'
    },
    {
        id: CALENDAR_MODES.AGENDA_2_WEEK,
        name: '2 Weeks',
        text: 'Two Weeks'
    },
    {
        id: CALENDAR_MODES.AGENDA_3_WEEK,
        name: '3 Weeks',
        text: 'Three Weeks'
    },
    {
        id: CALENDAR_MODES.AGENDA_4_WEEK,
        name: '4 Weeks',
        text: 'Four Weeks'
    },
    {
        id: CALENDAR_MODES.DAY_GRID_MONTH,
        name: 'Month',
        text: 'Month'
    },
    {
        id: CALENDAR_MODES.AGENDA_3_DAY,
        name: '3 Days Rolling',
        text: '3 Days Rolling'
    },
    {
        id: CALENDAR_MODES.AGENDA_4_DAY,
        name: '4 Days Rolling',
        text: '4 Days Rolling'
    },
    { id: CALENDAR_MODES.LIST_DAY, name: i18n.t('calendar:agenda') },
    { id: CALENDAR_MODES.LIST_WEEK, name: i18n.t('calendar:agenda_week') },
    { id: CALENDAR_MODES.LIST_MONTH, name: i18n.t('calendar:agenda_month') }
];

export const CALENDAR_BATCH_ACTIONS_TYPE = {
    BATCH_MOVE_JOBS: 1,
    BATCH_REASSIGN_JOBS: 2
};

export const CALENDAR_BATCH_ACTIONS = [
    { id: 1, name: 'batch_move_jobs' },
    { id: 2, name: 'batch_reassign_jobs' }
];

export const CALENDAR_JOBS_STATUS_OPTIONS = {
    options: [
        {
            id: 7,
            name: 'pending_booking',
            value: 7
        },
        {
            id: 0,
            name: 'unconfirmed',
            value: 0
        },
        {
            id: 1,
            name: 'confirmed',
            value: 1
        },
        {
            id: 3,
            name: 'reschedule',
            value: 3
        },
        {
            id: 4,
            name: 'pending_confirmation',
            value: 4
        }
    ],
    selected: [JOB_UNCONFIRMED, JOB_CONFIRMED]
};

export const CALENDAR_BATCH_ACTIONS_MOVE_OPTIONS = [
    {
        id: 0,
        name: 'move_this_job_only',
        value: 0
    },
    {
        id: 1,
        name: 'move_this_job_and_all_recurring',
        value: 1
    }
];

export const CALENDAR_JOBS_STATUS = {
    0: 'unconfirmed',
    1: 'confirmed',
    2: 'completed',
    3: 'reschedule',
    4: 'pending-confirm',
    5: 'canceled',
    6: 'recurrence',
    7: 'pending-booking'
};

export const CALENDAR_BATCH_ACTIONS_REASSIGN_JOBS = 7;

export const CALENDAR_BROADCAST_TYPE = {
    SEND_REMINDERS: 1,
    SEND_CONFIRMATIONS: 2,
    SEND_A_BROADCAST: 3
};

export const CALENDAR_BROADCAST_ACTIONS = [
    {
        id: 1,
        name: 'send_reminders'
    },
    {
        id: 2,
        name: 'send_confirmations'
    },
    {
        id: 3,
        name: 'send_a_broadcast'
    }
];

export const CALENDAR_TYPE_SEND_OPTIONS = [
    {
        id: 1,
        name: 'confirmations',
        value: 1
    },
    {
        id: 2,
        name: 'reminders',
        value: 2
    }
];

export const CALENDAR_MESSAGE_TYPE = {
    CONFIRMATIONS: 1,
    REMINDERS: 2
};

export const SEND_APPOINTMENT = {
    CONFIRMATIONS: 0,
    REMINDERS: 1
};

export const TEMPLATE_TYPE = {
    SYSTEM: 1,
    USER: 2,
    CUSTOM: 3
};

export const CALENDAR_PRINT_TYPE = {
    SCHEDULE_ONLY: 1,
    SCHEDULE_WITH_INVOICES: 2,
    INVOICES_ONLY: 3,
    INVOICE_FOR_MAILING: 4,
    BLANK_INVOICES: 5,
    WORK_ORDER_ONLY: 6
};

export const CALENDAR_PRINT_ACTIONS_VALUE = {
    PRINT_SCHEDULE_ONLY: 1,
    PDF_SCHEDULE_ONLY: 2,
    PRINT_SCHEDULE_WITH_INVOICES: 3,
    PDF_SCHEDULE_WITH_INVOICES: 4,
    PRINT_INVOICES_ONLY: 5,
    PDF_INVOICES_ONLY: 6,
    PRINT_INVOICE_FOR_MAILING: 7,
    PDF_INVOICE_FOR_MAILING: 8,
    PRINT_BLANK_INVOICES: 9,
    PDF_BLANK_INVOICES: 10,
    PRINT_WORK_ORDER_ONLY: 11,
    PDF_WORK_ORDER_ONLY: 12
};

export const PRINT_SCHEDULE_VALUES = {
    pdf: [
        CALENDAR_PRINT_ACTIONS_VALUE.PDF_BLANK_INVOICES,
        CALENDAR_PRINT_ACTIONS_VALUE.PDF_INVOICES_ONLY,
        CALENDAR_PRINT_ACTIONS_VALUE.PDF_INVOICE_FOR_MAILING,
        CALENDAR_PRINT_ACTIONS_VALUE.PDF_SCHEDULE_ONLY,
        CALENDAR_PRINT_ACTIONS_VALUE.PDF_SCHEDULE_WITH_INVOICES,
        CALENDAR_PRINT_ACTIONS_VALUE.PDF_WORK_ORDER_ONLY
    ],
    preview: [
        CALENDAR_PRINT_ACTIONS_VALUE.PRINT_SCHEDULE_ONLY,
        CALENDAR_PRINT_ACTIONS_VALUE.PRINT_BLANK_INVOICES,
        CALENDAR_PRINT_ACTIONS_VALUE.PRINT_INVOICE_FOR_MAILING,
        CALENDAR_PRINT_ACTIONS_VALUE.PRINT_INVOICES_ONLY,
        CALENDAR_PRINT_ACTIONS_VALUE.PRINT_SCHEDULE_WITH_INVOICES,
        CALENDAR_PRINT_ACTIONS_VALUE.PRINT_WORK_ORDER_ONLY
    ]
};
export const CALENDAR_PRINT_ACTIONS_OPTIONS = [
    {
        id: 1,
        name: 'print_schedule_only',
        value: 1
    },
    {
        id: 2,
        name: 'pdf_schedule_only',
        value: 2
    },
    {
        id: 3,
        name: 'print_schedule_with_invoices',
        value: 3
    },
    {
        id: 4,
        name: 'pdf_schedule_with_invoices',
        value: 4
    },
    {
        id: 5,
        name: 'print_invoices_only',
        value: 5
    },
    {
        id: 6,
        name: 'pdf_invoices_only',
        value: 6
    },
    {
        id: 7,
        name: 'print_invoices_for_mailing',
        value: 7
    },
    {
        id: 8,
        name: 'pdf_invoices_for_mailing',
        value: 8
    },
    {
        id: 9,
        name: 'print_blank_invoices',
        value: 9
    },
    {
        id: 10,
        name: 'pdf_blank_invoices',
        value: 10
    },
    { id: 11, name: 'print_work_orders_only', value: 11 },
    { id: 12, name: 'pdf_work_orders_only', value: 12 }
];

export const ROUTE_OPTIMIZER_JOBS_STATUS_OPTIONS = {
    OPTIONS: [
        {
            id: 0,
            name: 'unconfirmed',
            value: 0
        },
        {
            id: 1,
            name: 'confirmed',
            value: 1
        },
        {
            id: 3,
            name: 'reschedule',
            value: 3
        },
        {
            id: 4,
            name: 'pending_confirmation',
            value: 4
        },
        {
            id: 7,
            name: 'pending_booking',
            value: 7
        }
    ],
    SELECTED: [JOB_UNCONFIRMED, JOB_CONFIRMED]
};

export const ROUTE_OPTIMIZER_WORK_TIME_OPTIONS = [
    {
        id: 0,
        value: 0,
        name: '12:00 AM'
    },
    {
        id: 30,
        value: 30,
        name: '00:30 AM'
    },
    {
        id: 60,
        value: 60,
        name: '01:00 AM'
    },
    {
        id: 90,
        value: 90,
        name: '01:30 AM'
    },
    {
        id: 120,
        value: 120,
        name: '02:00 AM'
    },
    {
        id: 150,
        value: 150,
        name: '02:30 AM'
    },
    {
        id: 180,
        value: 180,
        name: '03:00 AM'
    },
    {
        id: 210,
        value: 210,
        name: '03:30 AM'
    },
    {
        id: 240,
        value: 240,
        name: '04:00 AM'
    },
    {
        id: 270,
        value: 270,
        name: '04:30 AM'
    },
    {
        id: 300,
        value: 300,
        name: '05:00 AM'
    },
    {
        id: 330,
        value: 330,
        name: '05:30 AM'
    },
    {
        id: 360,
        value: 360,
        name: '06:00 AM'
    },
    {
        id: 390,
        value: 390,
        name: '06:30 AM'
    },
    {
        id: 420,
        value: 420,
        name: '07:00 AM'
    },
    {
        id: 450,
        value: 450,
        name: '07:30 AM'
    },
    {
        id: 480,
        value: 480,
        name: '08:00 AM'
    },
    {
        id: 510,
        value: 510,
        name: '08:30 AM'
    },
    {
        id: 540,
        value: 540,
        name: '09:00 AM'
    },
    {
        id: 570,
        value: 570,
        name: '09:30 AM'
    },
    {
        id: 600,
        value: 600,
        name: '10:00 AM'
    },
    {
        id: 630,
        value: 630,
        name: '10:30 AM'
    },
    {
        id: 660,
        value: 660,
        name: '11:00 AM'
    },
    {
        id: 690,
        value: 690,
        name: '11:30 AM'
    },
    {
        id: 720,
        value: 720,
        name: '12:00 PM'
    },
    {
        id: 750,
        value: 750,
        name: '12:30 PM'
    },
    {
        id: 780,
        value: 780,
        name: '01:00 PM'
    },
    {
        id: 810,
        value: 810,
        name: '01:30 PM'
    },
    {
        id: 840,
        value: 840,
        name: '02:00 PM'
    },
    {
        id: 870,
        value: 870,
        name: '02:30 PM'
    },
    {
        id: 900,
        value: 900,
        name: '03:00 PM'
    },
    {
        id: 930,
        value: 930,
        name: '03:30 PM'
    },
    {
        id: 960,
        value: 960,
        name: '04:00 PM'
    },
    {
        id: 990,
        value: 990,
        name: '04:30 PM'
    },
    {
        id: 1020,
        value: 1020,
        name: '05:00 PM'
    },
    {
        id: 1050,
        value: 1050,
        name: '05:30 PM'
    },
    {
        id: 1080,
        value: 1080,
        name: '06:00 PM'
    },
    {
        id: 1110,
        value: 1110,
        name: '06:30 PM'
    },
    {
        id: 1140,
        value: 1140,
        name: '07:00 PM'
    },
    {
        id: 1170,
        value: 1170,
        name: '07:30 PM'
    },
    {
        id: 1200,
        value: 1200,
        name: '08:00 PM'
    },
    {
        id: 1230,
        value: 1230,
        name: '08:30 PM'
    },
    {
        id: 1260,
        value: 1260,
        name: '09:00 PM'
    },
    {
        id: 1290,
        value: 1290,
        name: '09:30 PM'
    },
    {
        id: 1320,
        value: 1320,
        name: '10:00 PM'
    },
    {
        id: 1350,
        value: 1350,
        name: '10:30 PM'
    },
    {
        id: 1380,
        value: 1380,
        name: '11:00 PM'
    },
    {
        id: 1410,
        value: 1410,
        name: '11:30 PM'
    }
];

export const _getTimeOptimizeWorkTime = (value) => {
    const workTime = ROUTE_OPTIMIZER_WORK_TIME_OPTIONS.find((item) => item.value === value);
    return workTime ? workTime.name : '';
};

export const ROUTE_OPTIMIZER_DRIVE_BUFFER_OPTIONS = [
    {
        id: 0,
        value: 0,
        name: 'none',
        showIcon: false
    },
    {
        id: 2,
        value: 5,
        name: '5_minutes',
        showIcon: false
    },
    {
        id: 3,
        value: 15,
        name: '15_minutes',
        showIcon: false
    },
    {
        id: 4,
        value: 30,
        name: '30_minutes',
        showIcon: false
    },
    {
        id: 5,
        value: 45,
        name: '45_minutes',
        showIcon: false
    },
    {
        id: 6,
        value: 60,
        name: '1_hour',
        showIcon: false
    }
];

export const ROUTE_OPTIMIZER_JOBS_PER_DAY_OPTIONS = [
    {
        id: 0,
        value: 0,
        name: 'auto_fill'
    },
    {
        id: 1,
        value: 1,
        name: 'custom'
    }
];

export const ROUTE_OPTIMIZER_START_FORM_OPTIONS = [
    {
        id: 0,
        value: 0,
        name: 'slosest_job'
    },
    {
        id: 1,
        value: DEFAULT_ALL,
        name: 'farthest_job'
    }
];

export const ROUTE_OPTIMIZER_EXCLUDE_OPTIONS = {
    OPTIONS: [
        {
            id: 1,
            value: 1,
            name: 'monday'
        },
        {
            id: 2,
            value: 2,
            name: 'tuesday'
        },
        {
            id: 3,
            value: 3,
            name: 'wednesday'
        },
        {
            id: 4,
            value: 4,
            name: 'thursday'
        },
        {
            id: 5,
            value: 5,
            name: 'friday'
        },
        {
            id: 6,
            value: 6,
            name: 'saturday'
        },
        {
            id: 0,
            value: 0,
            name: 'sunday'
        }
    ],
    SELECTED: [6, 0]
};

export const CALENDAR_EXCLUDE = {
    0: 'sunday',
    1: 'monday',
    2: 'tuesday',
    3: 'wednesday',
    4: 'thursday',
    5: 'friday',
    6: 'saturday'
};

export const ROUTE_OPTIMIZER_DISTANCE_TYPE_OPTIONS = [
    {
        id: 1,
        value: 'KM',
        name: 'kilometers'
    },
    {
        id: 2,
        value: 'MI',
        name: 'miles'
    }
];

export const ROUTE_OPTIMIZER_JOBS_PER_DAY_CUSTOM_OPTIONS = [
    { id: 1, value: 1, name: 1 },
    { id: 2, value: 2, name: 2 },
    { id: 3, value: 3, name: 3 },
    { id: 4, value: 4, name: 4 },
    { id: 5, value: 5, name: 5 },
    { id: 6, value: 6, name: 6 },
    { id: 7, value: 7, name: 7 },
    { id: 8, value: 8, name: 8 },
    { id: 9, value: 9, name: 9 },
    { id: 10, value: 10, name: 10 },
    { id: 11, value: 11, name: 11 },
    { id: 12, value: 12, name: 12 },
    { id: 13, value: 13, name: 13 },
    { id: 14, value: 14, name: 14 },
    { id: 15, value: 15, name: 15 },
    { id: 16, value: 16, name: 16 },
    { id: 17, value: 17, name: 17 },
    { id: 18, value: 18, name: 18 },
    { id: 19, value: 19, name: 19 },
    { id: 20, value: 20, name: 20 },
    { id: 21, value: 21, name: 21 },
    { id: 22, value: 22, name: 22 },
    { id: 23, value: 23, name: 23 },
    { id: 24, value: 24, name: 24 },
    { id: 25, value: 25, name: 25 },
    { id: 26, value: 26, name: 26 },
    { id: 27, value: 27, name: 27 },
    { id: 28, value: 28, name: 28 },
    { id: 29, value: 29, name: 29 },
    { id: 30, value: 30, name: 30 }
];

export const ROUTE_OPTIMIZER_LIST_JOBS_STATUS = [0, 1, 3, 4, 7];

export const TYPE_MODAL_ACCEPT_NEW_ROUTE = {
    ACCEPT_ROUTE: 0,
    SCHEDULE_JOB: 1
};

export const ROUTE_OPTIMIZER_TAB = {
    LIST: 0,
    CALENDAR: 1,
    MAP: 2
};

export const CALENDAR_BROADCAST_JOBS_STATUS = {
    [DEFAULT_ALL]: 'all',
    0: 'unconfirmed',
    1: 'confirmed',
    2: 'completed',
    3: 'reschedule',
    4: 'pending-confirm',
    5: 'canceled',
    6: 'recurrence',
    7: 'pending-booking'
};

export const CALENDAR_BROADCAST_JOBS_STATUS_OPTIONS = {
    OPTIONS: [
        {
            id: DEFAULT_ALL,
            name: 'all',
            value: DEFAULT_ALL
        },
        {
            id: 7,
            name: 'pending_booking',
            value: 7
        },
        {
            id: 0,
            name: 'unconfirmed',
            value: 0
        },
        {
            id: 1,
            name: 'confirmed',
            value: 1
        },
        {
            id: 3,
            name: 'reschedule',
            value: 3
        },
        {
            id: 2,
            name: 'completed',
            value: 2
        },
        {
            id: 4,
            name: 'pending_confirmation',
            value: 4
        },
        {
            id: 5,
            name: 'canceled',
            value: 5
        }
    ],
    SELECTED: DEFAULT_ALL
};

export const CALENDAR_BATCH_ACTION_FILTER = {
    SCHEDULE: 'schedule',
    STATUSES: 'statuses',
    REASSIGN_TO: 'reassign_to'
};
export const CALENDAR_BROADCAST_SEND_LIMIT = 150;
export const BROADCAST_HAS = 'broadcast has';
export const BROADCASTS_HAVE = 'broadcasts have';
