import { GirdHeader, GirdHeaderOrder } from 'app/components/grid/components/GirdHeader';
import {
    ColumnBalance,
    ColumnCreatedBy,
    ColumnCreditCard,
    ColumnCurrency,
    ColumnCustomer,
    ColumnCustomerEdit,
    ColumnCustomerInfo,
    ColumnCustomerPaymentFor,
    ColumnCustomerStatus,
    ColumnDefault,
    ColumnEllipsis,
    ColumnEmailCustomer,
    ColumnEmailStatus,
    ColumnEstimateStatus,
    ColumnInput,
    ColumnJobsStatus,
    ColumnLink,
    ColumnLinkPaymentMethod,
    ColumnList,
    ColumnListActiveJobs,
    ColumnListContacts,
    ColumnListEmail,
    ColumnListEmailArray,
    ColumnListLocations,
    ColumnListNextInvoice,
    ColumnListPhone,
    ColumnListTags,
    ColumnParseHTML,
    ColumnPaymentCard,
    ColumnPaymentFor,
    ColumnPaymentMethod,
    ColumnQuickBookId,
    ColumnRating,
    ColumnRatingCounting,
    ColumnRatingCountingText,
    ColumnSwitch,
    ColumnText,
    ColumnTwoRowText,
    ColumnJob,
    ColumnJobFrequency,
    ColumnListAssignee,
    ColumnEditSubscription,
    ColumnAssigneeSelect,
    ColumnLocationSelect,
    ColumnCreditStatus,
    ColumnCustomerCredit,
    ColumnSubscription,
    ColumnInvoice,
    ColumnServiceFrequency,
    ColumnServiceColor,
    ColumnServiceName,
    ColumnLocationAddress,
    ColumnLocationAddressContact,
    ColumnLocationTaxes,
    ColumnDisplayTags,
    ColumnLocationActiveService,
    ColumnLocationUnits,
    ColumnNickName,
    ColumnSchedulesAddress,
    ColumnSchedulesAssign,
    ColumnCustomerEstimate,
    ColumnEstimateJob,
    ColumnStatusSystem,
    ColumnPlanSystem,
    ColumnTypeCustom,
    ColumnNameClick,
    ColumnPaymentCardWithAction,
    ColumnAllPaymentFor,
    ColumnTypeCommissionTracking,
    ColumnInvoiceFrequency,
    ColumnServiceType,
    ColumnServiceAddress,
    ColumnCurrencySymbol,
    ColumnTotalCredit,
    ColumnMethodRevenue,
    ColumnAssignedTo,
    ColumnWeather,
    ColumnAddressQb,
    ColumnCustomersFullAddress,
    ColumnAssignBooking,
    ColumnJobDocument,
    ColumnAddressLead,
    ColumnViewEstimateDelete,
    ColumnEditRecurring,
    ColumnCost,
    ColumnRatePercent,
    ColumnDeleteQbInvoice,
    ColumnJobStatusTab,
    ColumnBillingEmail,
    ColumnCustomButton,
    ColumnTimeAgo,
    ColumnDevice,
    StatementService,
    ColUserActiveVOIP,
    ColGroupNumberVoip,
    ColCallOptionsVoip,
    ColEditActiveUserVoip,
    ColPersonalNumberVoip,
    ColumnDigits,
    ColumnEditAutoAttendant,
    ColumnForwardAutoAttendant,
    ColumnTimeHMS,
    ColumnStatus,
    ColumnCustomerName,
    ColumnDate,
    ColumnNextTask,
    ColumnNextTaskDate,
    ColumnAddress,
    ColumnSMS,
    ColumnOppValue,
    ColumnExpand,
    ColAvatarMultipleBranch,
    ColPhoneMultipleBranch,
    ColPlanMultipleBranch,
    ColAssignedUsersMultipleBranch,
    ColEditMultipleBranch,
    ColStageFunnel,
    ColUserHasAvt,
    ColRole,
    ColSwitchCheckboxOppPermission,
    ColInOutTime,
    ColRatePerHour,
    ColDuration,
    ColTagsDisplay,
    ColCallActivity,
    ColStatusItem,
    ColumnPlanLength
} from 'app/components/grid/GridColumn';
import { COMMON, DATE_FORMAT, LIST_TABLE_ITEM_TYPE } from 'app/const/App';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ColumnGroupSchedule from './components/ColumnGroupSchedule';
import ColumnQuickBookSync from './components/ColumnQuickBookSync';
import ColumnTags from './components/ColumnTags';
import DropdownBranchColor, { ColumnCount } from 'app/modules/addons/multipleBranch/manage/DropdownBranchColor';
import ColumReferralStatus from 'app/modules/referral/components/ColumReferralStatus';
import { DOCUMENT_LIBRARY } from 'app/modules/addons/documents/constants';

////// HEADER ///////
export const GdGridRowHeader = ({ row, columns, fileTranslation, handleClickHeader, isLoading, isEmptyContent }) => {
    const { t } = useTranslation([fileTranslation]);
    return columns.map((column) => {
        const { id, status } = column;
        const columnHeaderProps = {
            id: id,
            style: `${row[id].style} ${isLoading ? 'is-disable' : ''}`,
            title: t(`${fileTranslation}:${row[id].title}`)
        };

        if (status && row[id]?.actionType === COMMON.ORDER) {
            columnHeaderProps.handleOrder = ({ nextOrder = '' }) => {
                if (isEmptyContent || isLoading) return false;
                handleClickHeader({
                    actionType: row[id].actionType,
                    columnsTarget: id,
                    currentValue: nextOrder,
                    // This values for smart view
                    objectType: row[id].objectType || '',
                    keyGetDirection: row[id].keyGetDirection || ''
                });
            };

            columnHeaderProps.orderStatus = row[id].currentValue;
            return <GirdHeaderOrder key={id} {...columnHeaderProps} />;
        }

        if (status) return <GirdHeader key={id} {...columnHeaderProps} />;

        return null;
    });
};

////// CONTENT //////
export const GdGridRowContent = ({
    row,
    idRow,
    zIndexRow,
    totalItems,
    columns,
    locations,
    contentConfig,
    companyUsers,
    handleClick,
    onSearchQB,
    onOpenLocation,
    onCancelSubscription,
    onEditSubscription,
    onOpenJob,
    onOpenSubscription,
    onOpenInvoice,
    onEdit,
    typeQuickBook,
    onClickItem,
    onNameClick,
    onChangeInput,
    onSelectAddress,
    onSelectAssign,
    currency,
    assignBookingPopup,
    onHandleActionGroups,
    isChecked,
    isDuplicateInvoiceQB = false,
    fileTranslation,
    onHandleSaveTags,
    onChangeSwitchOppPermission,
    handleMouseEnter = () => {},
    handleMouseLeave = () => {}
}) => {
    const RenderColumn = ({ value, item, config, id, companyUsers, keyDisplayValue, onClick }) => {
        const {
            typeValue,
            isShowEmpty,
            format,
            timezone,
            isGetFirstPhone,
            isBillingAddress,
            style,
            type,
            firstKey,
            secondKey,
            keyGetValue,
            noneLocation,
            styleText,
            isDeleted,
            urlConfig,
            defaultValue,
            additionalStyle,
            isLink,
            isColumnTeammate,
            isMethod,
            isDelete,
            isAccountNumber,
            strategy,
            typeSubscription,
            title,
            typeOpen,
            isUsePopup,
            isEmpty = false,
            showTypeDoc,
            typeTag,
            isCheckCustomerDeleted
        } = config;

        const props = { style, type, value, key: id, handleClick: onClick, row, styleText, defaultValue, isDelete };

        switch (type) {
            case LIST_TABLE_ITEM_TYPE.LINK:
                return ColumnLink({
                    ...props,
                    isShowTagPdf: showTypeDoc && item.type === DOCUMENT_LIBRARY.PDF,
                    isCheckCustomerDeleted
                });
            case LIST_TABLE_ITEM_TYPE.LINK_PAYMENT_METHODS:
                return ColumnLinkPaymentMethod({ ...props });
            case LIST_TABLE_ITEM_TYPE.PAYMENT_FOR:
                return ColumnPaymentFor({ ...props });
            case LIST_TABLE_ITEM_TYPE.LIST:
                return ColumnList({ ...props });
            case LIST_TABLE_ITEM_TYPE.LIST_CONTACTS:
                return ColumnListContacts({ ...props });
            case LIST_TABLE_ITEM_TYPE.LIST_LOCATIONS:
                return ColumnListLocations({ ...props });
            case LIST_TABLE_ITEM_TYPE.LIST_ACTIVE_JOBS:
                return ColumnListActiveJobs({ ...props });
            case LIST_TABLE_ITEM_TYPE.PARSE_HTML:
                return ColumnParseHTML({ ...props });
            case LIST_TABLE_ITEM_TYPE.BALANCE:
                return ColumnBalance({
                    style,
                    value,
                    styleText,
                    isLink,
                    handleClick: onClick,
                    key: id,
                    row,
                    currency,
                    isMethod,
                    item
                });
            case LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO:
                return (
                    <ColumnCustomerInfo
                        isColumnTeammate={isColumnTeammate}
                        style={style}
                        item={item}
                        urlConfig={urlConfig}
                        onClick={onClick}
                        row={row}
                        id={id}
                        isLink={isLink}
                        isAccountNumber={isAccountNumber}
                    />
                );
            case LIST_TABLE_ITEM_TYPE.AVATAR:
                return <ColumnCustomer style={style} value={value} item={item} />;
            case LIST_TABLE_ITEM_TYPE.AVATAR_LINK:
                return <ColumnCustomer id={id} style={style} value={value} item={item} onClick={onClick} isLink />;
            case LIST_TABLE_ITEM_TYPE.RATING:
                return ColumnRating(style, value, item);
            case LIST_TABLE_ITEM_TYPE.RATING_COUNTING:
                return ColumnRatingCounting(style, value, item);
            case LIST_TABLE_ITEM_TYPE.RATING_COUNTING_TEXT:
                return ColumnRatingCountingText(style, value, item);
            case LIST_TABLE_ITEM_TYPE.TEAMMATE:
                return <ColumnCustomer style={style} value={value} item={item} />;
            case LIST_TABLE_ITEM_TYPE.ELLIPSIS:
                return ColumnEllipsis(
                    style,
                    typeof value === 'string' ? value : value.value,
                    id,
                    onClick,
                    value.isLink,
                    value.list
                );
            case LIST_TABLE_ITEM_TYPE.STATUS:
                return ColumnEmailStatus(style, value, item);
            case LIST_TABLE_ITEM_TYPE.SWITCH:
                return <ColumnSwitch item={item} style={style} handleClick={onClick} />;
            case LIST_TABLE_ITEM_TYPE.INPUT:
                return <ColumnInput item={item} style={style} onSubmit={onClick} />;
            case LIST_TABLE_ITEM_TYPE.PHONE:
                return (
                    <ColumnListPhone
                        style={style}
                        value={value}
                        isGetFirstPhone={isGetFirstPhone}
                        customer={item?.customer || item?.info}
                        typeOpen={typeOpen}
                        isUsePopup={isUsePopup}
                        customerId={config.customerId}
                    />
                );
            // use for Report Recurring Invoices
            case LIST_TABLE_ITEM_TYPE.CUSTOM_NEXT_INVOICE:
                return (
                    <ColumnListNextInvoice
                        style={style}
                        handleClick={onOpenInvoice}
                        invoiceInfo={item?.next_invoice}
                        invoiceNumber={item?.invoice_number}
                        invoiceId={item?.invoice_id}
                    />
                );
            case LIST_TABLE_ITEM_TYPE.PAYMENT_METHOD:
                return <ColumnPaymentMethod style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.TAGS:
                return <ColumnListTags style={style} item={item?.tag} />;
            case LIST_TABLE_ITEM_TYPE.EMAIL:
                return <ColumnListEmail style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.EMAIL_ARRAY:
                return <ColumnListEmailArray style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.CURRENCY:
                return (
                    <ColumnCurrency
                        handleClick={onOpenInvoice}
                        style={style}
                        value={value}
                        idRow={idRow}
                        idCol={id}
                        totalItems={totalItems}
                        zIndexRow={zIndexRow}
                        currency={currency}
                    />
                );
            case LIST_TABLE_ITEM_TYPE.PAYMENT_CARD:
                return <ColumnPaymentCard style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.PAYMENT_CARD_WITH_ACTION:
                return <ColumnPaymentCardWithAction style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.ESTIMATE_STATUS:
                return <ColumnEstimateStatus style={style} item={item} id={id} onOpenInvoice={onOpenInvoice} />;

            case LIST_TABLE_ITEM_TYPE.CREATED_BY:
                return (
                    <ColumnCreatedBy
                        style={style}
                        id={id}
                        item={item?.[keyGetValue] || item?.created_by || item?.added_by || item?.user_id}
                        isEmpty={isEmpty}
                        companyUsers={companyUsers}
                    />
                );
            case LIST_TABLE_ITEM_TYPE.JOBS_STATUS:
                return <ColumnJobsStatus style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.CUSTOMER_STATUS:
                return <ColumnCustomerStatus style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.STATUS_CREDIT:
                return <ColumnCreditStatus style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.CUSTOMER_CREDIT:
                return <ColumnCustomerCredit style={style} handleClick={handleClick} item={item} />;
            case LIST_TABLE_ITEM_TYPE.QB_ID:
                return <ColumnQuickBookId style={style} item={item} isEmpty={isEmpty} onClick={onSearchQB} />;
            case LIST_TABLE_ITEM_TYPE.QB_SYNC:
                return (
                    <ColumnQuickBookSync
                        style={style}
                        item={item}
                        isEmpty={isEmpty}
                        typeQuickBook={typeQuickBook}
                        onClick={onSearchQB}
                        isDuplicateInvoiceQB={isDuplicateInvoiceQB}
                        handleMouseEnter={handleMouseEnter}
                        handleMouseLeave={handleMouseLeave}
                    />
                );
            case LIST_TABLE_ITEM_TYPE.CREDIT_CARD:
                return <ColumnCreditCard style={style} cards={item?.cards || []} />;
            case LIST_TABLE_ITEM_TYPE.BANK_ACH:
                return <ColumnCreditCard style={style} cards={item?.bank_accounts || []} />;
            case LIST_TABLE_ITEM_TYPE.LIST_TAGS:
                return <ColumnDisplayTags style={style} tags={item?.tags || []} />;
            case LIST_TABLE_ITEM_TYPE.CUSTOMER_EMAIL_LIST:
                return <ColumnEmailCustomer style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.CUSTOMER_EDIT:
                return (
                    <ColumnCustomerEdit
                        style={style}
                        item={item}
                        isEmpty={isEmpty}
                        onEdit={onEdit}
                        title={title}
                        isLink={isLink}
                    />
                );
            case LIST_TABLE_ITEM_TYPE.CUSTOMER_PAYMENT_FOR:
                return <ColumnCustomerPaymentFor style={style} item={item} handleClick={handleClick} />;
            case LIST_TABLE_ITEM_TYPE.TWO_ROWS:
                return <ColumnTwoRowText style={style} item={item} firstKey={firstKey} secondKey={secondKey} />;
            case LIST_TABLE_ITEM_TYPE.JOB:
                return <ColumnJob style={style} item={item} onOpenJob={onOpenJob} noneLocation={noneLocation} />;
            case LIST_TABLE_ITEM_TYPE.JOB_FREQUENCY:
                return <ColumnJobFrequency style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.JOB_STATUS_TAB:
                return <ColumnJobStatusTab style={style} value={value} />;
            case LIST_TABLE_ITEM_TYPE.TEXT:
                return <ColumnText style={style} item={item} keyGetValue={keyGetValue} />;
            case LIST_TABLE_ITEM_TYPE.LIST_ASSIGNEE:
                return <ColumnListAssignee style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.SUBSCRIPTION_EDIT:
                return (
                    <ColumnEditSubscription
                        style={style}
                        item={item}
                        onCancelSubscription={onCancelSubscription}
                        onEditSubscription={onEditSubscription}
                        typeSubscription={typeSubscription}
                        idRow={idRow}
                        idCol={id}
                        totalItems={totalItems}
                        zIndexRow={zIndexRow}
                        strategy={strategy}
                    />
                );
            case LIST_TABLE_ITEM_TYPE.INVOICE_RECURRING_EDIT:
                return <ColumnEditRecurring style={style} item={item} handleClick={handleClick} />;
            case LIST_TABLE_ITEM_TYPE.ASSIGNEES_SELECT:
                return <ColumnAssigneeSelect style={style} item={item} typeSubscription={typeSubscription} />;
            case LIST_TABLE_ITEM_TYPE.LOCATIONS_SELECT:
                return (
                    <ColumnLocationSelect
                        style={style}
                        item={item}
                        locations={locations}
                        typeSubscription={typeSubscription}
                    />
                );
            case LIST_TABLE_ITEM_TYPE.SUBSCRIPTION:
                return <ColumnSubscription style={style} item={item} onOpenSubscription={onOpenSubscription} />;
            case LIST_TABLE_ITEM_TYPE.INVOICE:
                return <ColumnInvoice style={style} item={item} onOpenInvoice={onOpenInvoice} />;
            case LIST_TABLE_ITEM_TYPE.SERVICE_FREQUENCY:
                return <ColumnServiceFrequency style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.INVOICE_FREQUENCY:
                return <ColumnInvoiceFrequency item={item} style={style} />;
            case LIST_TABLE_ITEM_TYPE.SERVICE_COLOR:
                return (
                    <ColumnServiceColor
                        style={style}
                        item={item}
                        idRow={idRow}
                        idCol={id}
                        zIndexRow={zIndexRow}
                        totalItems={totalItems}
                        isDelete={isDelete}
                    />
                );
            case LIST_TABLE_ITEM_TYPE.SCHEDULES_NICKNAME:
                return <ColumnNickName style={style} item={item} onChangeInput={onChangeInput} />;
            case LIST_TABLE_ITEM_TYPE.SCHEDULES_ADDRESS:
                return <ColumnSchedulesAddress style={style} item={item} onSelect={onSelectAddress} />;
            case LIST_TABLE_ITEM_TYPE.GROUPS:
                return (
                    <ColumnGroupSchedule
                        style={style}
                        item={item}
                        onHandleActionGroups={onHandleActionGroups}
                        idRow={idRow}
                    />
                );
            case LIST_TABLE_ITEM_TYPE.SCHEDULES_ASSIGNEE:
                return (
                    <ColumnSchedulesAssign
                        style={style}
                        item={item}
                        companyUsers={companyUsers}
                        onSelectAssign={onSelectAssign}
                        idRow={idRow}
                    />
                );
            case LIST_TABLE_ITEM_TYPE.SERVICE_NAME:
                return <ColumnServiceName style={style} item={item} onClickItem={onClickItem} isDelete={isDelete} />;
            case LIST_TABLE_ITEM_TYPE.LOCATION_ADDRESS:
                return (
                    <ColumnLocationAddress
                        style={style}
                        item={item}
                        onOpenLocation={onOpenLocation}
                        isDelete={isDelete}
                    />
                );
            case LIST_TABLE_ITEM_TYPE.LOCATION_ADDRESS_CONTACT:
                return <ColumnLocationAddressContact style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.LOCATION_TAXES:
                return <ColumnLocationTaxes style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.LOCATION_TAGS:
            case LIST_TABLE_ITEM_TYPE.CONVERSATION_TAGS:
                return (
                    <ColumnTags
                        item={item}
                        idRow={idRow}
                        keyGetValue={keyGetValue}
                        typeValue={typeValue}
                        typeTag={typeTag}
                        style={style}
                        onHandleSaveTags={onHandleSaveTags}
                    />
                );
            case LIST_TABLE_ITEM_TYPE.RATE_PERCENT:
                return <ColumnRatePercent style={style} value={value} />;
            case LIST_TABLE_ITEM_TYPE.LOCATION_ACTIVE_SERVICE:
                return <ColumnLocationActiveService style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.LOCATION_UNITS:
                return <ColumnLocationUnits style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.CUSTOMER_ESTIMATE:
                return (
                    <ColumnCustomerEstimate
                        style={style}
                        handleClick={handleClick}
                        item={item}
                        styleText={styleText}
                        isDeleted={isDeleted}
                    />
                );
            case LIST_TABLE_ITEM_TYPE.VIEW_ESTIMATE:
                return <ColumnViewEstimateDelete style={style} handleClick={handleClick} item={item} />;
            case LIST_TABLE_ITEM_TYPE.CUSTOMER_ESTIMATE_JOB:
                return <ColumnEstimateJob style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.SETTING_STATUS_SYSTEM:
                return <ColumnStatusSystem style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.SETTING_PLAN_SYSTEM:
                return <ColumnPlanSystem style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.SETTING_TYPE_CUSTOM:
                return <ColumnTypeCustom style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.SERVICE_TYPE:
                return (
                    <ColumnServiceType
                        style={style}
                        item={item}
                        handleClick={handleClick}
                        id={id}
                        styleText={styleText}
                    />
                );
            case LIST_TABLE_ITEM_TYPE.NAME_CLICK:
                return <ColumnNameClick style={style} item={item} value={value} onClick={onNameClick} />;
            case LIST_TABLE_ITEM_TYPE.ALL_PAYMENT_FOR:
                return <ColumnAllPaymentFor style={style} item={item} value={value} handleClick={handleClick} />;
            case LIST_TABLE_ITEM_TYPE.SERVICE_ADDRESS:
                return <ColumnServiceAddress style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.TYPE_COMMISSION:
                return <ColumnTypeCommissionTracking style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.CURRENCY_SYMBOL:
                return (
                    <ColumnCurrencySymbol
                        style={style}
                        value={value}
                        currency={currency}
                        isLink={isLink}
                        handleClick={handleClick}
                        item={item}
                        styleText={styleText}
                        id={id}
                    />
                );
            case LIST_TABLE_ITEM_TYPE.TOTAL_CREDIT:
                return <ColumnTotalCredit style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.METHOD_REVENUE:
                return <ColumnMethodRevenue style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.ASSIGNED_TO:
                return <ColumnAssignedTo style={style} value={value} companyUsers={companyUsers} />;
            case LIST_TABLE_ITEM_TYPE.WEATHER:
                return <ColumnWeather style={style} value={value} />;
            case LIST_TABLE_ITEM_TYPE.QB_ADDRESS:
                return <ColumnAddressQb style={style} item={item?.address || {}} />;
            case LIST_TABLE_ITEM_TYPE.CUSTOMERS_ADDRESS:
                return <ColumnCustomersFullAddress style={style} item={value} />;
            case LIST_TABLE_ITEM_TYPE.ASSIGN_BOOKING:
                return <ColumnAssignBooking style={style} item={item} assignBookingPopup={assignBookingPopup} />;
            case LIST_TABLE_ITEM_TYPE.JOB_DOCUMENT:
                return <ColumnJobDocument style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.ADDRESS_LEAD:
                return <ColumnAddressLead style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.COST:
                return <ColumnCost style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.WORD_BREAK:
            case LIST_TABLE_ITEM_TYPE.DESCRIPTION:
                return <ColumnExpand style={style} value={value} fileTranslation={fileTranslation} />;
            case LIST_TABLE_ITEM_TYPE.DELETE_QUICK_BOOK_INVOICE:
                return <ColumnDeleteQbInvoice style={style} handleClick={handleClick} isChecked={isChecked} />;
            case LIST_TABLE_ITEM_TYPE.BILLING_EMAIL:
                return <ColumnBillingEmail style={style} value={value} />;
            case LIST_TABLE_ITEM_TYPE.CUSTOM_BUTTON:
                return (
                    <ColumnCustomButton
                        style={style}
                        handleClick={handleClick}
                        styleText={styleText}
                        value={value}
                        item={item}
                        fileTranslation={fileTranslation}
                    />
                );
            case LIST_TABLE_ITEM_TYPE.TIME_AGO:
                return (
                    <ColumnTimeAgo
                        value={value}
                        format={format}
                        timezone={timezone}
                        style={style}
                        styleText={styleText}
                    />
                );
            case LIST_TABLE_ITEM_TYPE.DEVICE:
                return <ColumnDevice item={value} style={style} />;
            case LIST_TABLE_ITEM_TYPE.STATEMENT_SERVICE:
                return <StatementService item={item} style={style} />;
            case LIST_TABLE_ITEM_TYPE.USER_VOIP:
                return <ColUserActiveVOIP item={item} style={style} />;
            case LIST_TABLE_ITEM_TYPE.PERSONAL_NUMBER_VOIP:
                return <ColPersonalNumberVoip item={item} style={style} />;
            case LIST_TABLE_ITEM_TYPE.GROUP_NUMBER_VOIP:
                return <ColGroupNumberVoip item={item} style={style} />;
            case LIST_TABLE_ITEM_TYPE.CALL_RECORDING_VOIP:
                return <ColCallOptionsVoip item={item} style={style} keyGetValue="recording" title="call_recording" />;
            case LIST_TABLE_ITEM_TYPE.CALL_TRANSCRIPTION:
                return (
                    <ColCallOptionsVoip
                        item={item}
                        style={style}
                        keyGetValue="transcription"
                        title="generate_transcript"
                    />
                );
            case LIST_TABLE_ITEM_TYPE.EDIT_ACTIVE_USER_VOIP:
                return <ColEditActiveUserVoip item={item} style={style} />;
            case LIST_TABLE_ITEM_TYPE.DIGITS:
                return <ColumnDigits item={item} style={style} />;
            case LIST_TABLE_ITEM_TYPE.FORWARD_TO:
                return <ColumnForwardAutoAttendant item={item} style={style} />;
            case LIST_TABLE_ITEM_TYPE.EDIT_AUTO_ATTENDANT:
                return <ColumnEditAutoAttendant item={item} style={style} />;
            case LIST_TABLE_ITEM_TYPE.TIME_HMS:
                return <ColumnTimeHMS value={value} style={style} />;
            case LIST_TABLE_ITEM_TYPE.CUSTOMER_STATUS_SMART_VIEW:
                return <ColumnStatus value={value} style={style} />;
            case LIST_TABLE_ITEM_TYPE.NAME_CUSTOMER:
                return <ColumnCustomerName value={value} style={style} />;
            case LIST_TABLE_ITEM_TYPE.DATE_CREATED:
                return (
                    <ColumnDate
                        value={value}
                        format={format || DATE_FORMAT}
                        timezone={timezone}
                        isShowEmpty={isShowEmpty}
                        style={style}
                    />
                );
            case LIST_TABLE_ITEM_TYPE.NEXT_TASK:
                return <ColumnNextTask value={value} style={style} />;
            case LIST_TABLE_ITEM_TYPE.NEXT_TASK_DATE:
                return (
                    <ColumnNextTaskDate
                        value={value}
                        style={style}
                        format={format}
                        isBillingAddress={isBillingAddress}
                    />
                );
            case LIST_TABLE_ITEM_TYPE.ADDRESS:
                return <ColumnAddress value={value} isBillingAddress={isBillingAddress} style={style} />;
            case LIST_TABLE_ITEM_TYPE.SMS_MESSAGE:
                return <ColumnSMS value={value} style={style} styleText={styleText} />;
            case LIST_TABLE_ITEM_TYPE.OPPORTUNITY_VALUE:
                return <ColumnOppValue typeValue={typeValue} value={value} style={style} />;
            case LIST_TABLE_ITEM_TYPE.AVATAR_MULTIPLE_BRANCH:
                return <ColAvatarMultipleBranch style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.PHONE_MULTILE_BRANCH:
                return <ColPhoneMultipleBranch style={style} value={value} />;
            case LIST_TABLE_ITEM_TYPE.PLAN_MULTILE_BRANCH:
                return <ColPlanMultipleBranch style={style} value={value} />;
            case LIST_TABLE_ITEM_TYPE.ASSINGED_USERS_MULTILE_BRANCH:
                return <ColAssignedUsersMultipleBranch style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.EDIT_MULTILE_BRANCH:
                return <ColEditMultipleBranch style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.STAGE_SALE_FUNNEL:
                return <ColStageFunnel style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.USER_HAS_AVT:
                return <ColUserHasAvt style={style} value={value} item={item} />;
            case LIST_TABLE_ITEM_TYPE.ROLE_USER:
                return <ColRole style={style} value={value} item={item} />;
            case LIST_TABLE_ITEM_TYPE.SWITCH_CHECKBOX_OPP_PERMISSION:
                return (
                    <ColSwitchCheckboxOppPermission
                        style={style}
                        value={value}
                        item={item}
                        config={config}
                        onChange={onChangeSwitchOppPermission}
                    />
                );
            case LIST_TABLE_ITEM_TYPE.RATE_PER_HOUR:
                return <ColRatePerHour currency={currency} style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.IN_OUT_TIME:
                return <ColInOutTime style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.DURATION:
                return <ColDuration style={style} item={item} />;
            case LIST_TABLE_ITEM_TYPE.TAGS_DISPLAY:
                return <ColTagsDisplay style={style} item={item} config={config} />;
            case LIST_TABLE_ITEM_TYPE.BRANCH_COLOR:
                return <DropdownBranchColor style={style} value={value} item={item} />;
            case LIST_TABLE_ITEM_TYPE.REFERRAL_STATUS:
                return <ColumReferralStatus value={value} style={style} isIcon={config?.isIcon} />;
            case LIST_TABLE_ITEM_TYPE.COUNT:
                return <ColumnCount style={style} value={value} item={item} />;
            case LIST_TABLE_ITEM_TYPE.CALL_ACTIVITY:
                return (
                    <ColCallActivity
                        style={style}
                        item={item}
                        value={value}
                        handleClick={onClick}
                        action={config?.action}
                    />
                );
            case LIST_TABLE_ITEM_TYPE.STATUS_ITEM:
                return <ColStatusItem style={style} value={value} listStatus={config?.listStatus} />;
            case LIST_TABLE_ITEM_TYPE.PLAN_LENGTH:
                return <ColumnPlanLength style={style} item={item} />;
            default:
                return ColumnDefault(style, value, defaultValue, additionalStyle, keyDisplayValue);
        }
    };
    const _renderListColumn = (row, columns) => {
        return columns.map((column) => {
            const { id, status, keyGetValue, keyDisplayValue } = column;
            if (status) {
                return (
                    <RenderColumn
                        key={id}
                        id={id}
                        value={keyGetValue ? row[keyGetValue] : row[id]}
                        item={row}
                        config={contentConfig[id]}
                        companyUsers={companyUsers}
                        keyDisplayValue={keyDisplayValue}
                        onClick={handleClick}
                    />
                );
            }
            return null;
        });
    };

    return _renderListColumn(row, columns);
};
