import classNames from 'classnames';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import StatusBar from 'app/components/status/statusbar';
import { ADDONS_AI } from 'app/config/routes';
import { ADDONS_AI_AGENTS, ADDONS_LEVEL_GROWTH, ADDONS_STATUS_ACTIVE } from 'app/const/addons';
import { ADDONS_CHATBOT_AI } from 'app/const/Api';
import { ACCOUNT_DETAIL_CHATBOT } from 'app/const/api/V2';
import { ACCOUNT_ROLE } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import AddonsBoxHeader from 'app/modules/addons/components/AddonsBoxHeader';
import PlanChooseCard from 'app/modules/addons/voip/components/plan/PlanChooseCard';
import ErrorPage from 'app/modules/error';
import IconArrow from 'assets/icon/IconArrow';
import { updateAddonStatus } from 'common/redux/actions/authAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { showStatusBar } from 'common/utils/FunctionUtils';
import { getPlanUser } from 'common/utils/PermissionUtils';
import { LIST_RECHARGE_AMOUNT, LIST_RECHARGE_THRESHOLD, NAME_CHATBOT } from '../../constants';
import { ChatbotAddonsModifyPlan } from './ChatbotAddonsModifyPlan';
import LoadingActivePlan from './LoadingActivePlan';

const ChatbotAddonsPlan = () => {
    const { t } = useTranslation('addons');
    const history = useHistory();
    const dispatch = useDispatch();
    const userRole = useSelector(({ auth }) => auth.user.profile.role);
    const userProfile = useSelector(({ auth }) => auth.user.profile);
    const { isBasicPlan } = getPlanUser(userProfile);
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        account: {},
        isActivated: false,
        keyword: '',
        name: NAME_CHATBOT,
        level: ADDONS_LEVEL_GROWTH,
        status: ADDONS_STATUS_ACTIVE
    });
    const {
        isLoading: finalIsLoading,
        account: finalAccount,
        isActivated: finalIsActivated,
        keyword: finalKeyword,
        name: finalName,
        level: finalLevel,
        status: finalStatus
    } = state;
    const isSupperAdmin = userRole === ACCOUNT_ROLE.SUPERADMIN;
    const havePermission = isSupperAdmin && !isBasicPlan;

    const refAlert = useRef(null);
    const refCard = useRef(null);

    useEffect(() => {
        if (!havePermission) return;
        _fetchPlan();
    }, []);

    const _fetchPlan = () => {
        const _handleSuccess = ({ data }) => {
            const account = data?.account;
            dispatchState((prev) => ({
                ...prev,
                ...(data || {}),
                account: account || {},
                isLoading: false,
                isActivated: !!account
            }));
        };
        const _handleFail = ({ message }) => {
            dispatchState((prev) => ({ ...prev, account: {}, isLoading: false }));
            showStatusBar({ message: message || t('common:something_went_wrong'), refAlert });
        };
        clientQuery(ADDONS_CHATBOT_AI, { method: 'GET' }, _handleSuccess, _handleFail);
    };

    const _handleUpdate = () => {
        refCard.current._open();
    };

    const _handleActivePlan = ({ isNewCard = false, cardDetail = {} }) => {
        const newData = {
            recharge_amount: finalAccount.recharge_amount || LIST_RECHARGE_AMOUNT[0],
            recharge_threshold: finalAccount.recharge_threshold || LIST_RECHARGE_THRESHOLD[0]
        };

        if (isNewCard) {
            newData.stripe_card_token = cardDetail.token.id;
        }

        const _handleSuccess = () => {
            refCard.current._close();
            dispatch(updateAddonStatus({ keyword: ADDONS_AI_AGENTS, data: 1 }));
            history.push(addBranchPath(ADDONS_AI), { isActiveSuccess: true });
        };
        const _handleFail = ({ message }) => {
            refCard.current._removeLoading(message);
        };
        clientQuery(
            ACCOUNT_DETAIL_CHATBOT,
            { method: 'POST', toFormData: false, data: newData },
            _handleSuccess,
            _handleFail
        );
    };

    const _handleChangePlan = (data) => {
        dispatchState((prev) => ({ ...prev, account: { ...prev.account, ...(data || {}) } }));
    };

    if (!havePermission) return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;

    return (
        <div className="addons-wrap__container addons-chatbot">
            <div className="header">
                <Link to={addBranchPath(ADDONS_AI)} className="v2-btn-default has-icon --grey">
                    <IconArrow isPrev />
                    {t('addons')}
                </Link>
            </div>
            <div className="scrolls">
                <div className="boxs-wrapper">
                    <StatusBar ref={refAlert} />
                    {finalIsLoading ? (
                        <LoadingActivePlan />
                    ) : (
                        <Fragment>
                            <div className="boxs boxs--no-border mb-2">
                                <AddonsBoxHeader
                                    keyword={finalKeyword}
                                    name={finalName}
                                    isHideCheckbox
                                    status={finalStatus}
                                    level={finalLevel}
                                />
                            </div>
                            {finalIsActivated ? (
                                <div className="tables-empty m-0 mt-3 bg-white">
                                    {t('your_chatbot_plan_has_been_activated')}
                                </div>
                            ) : (
                                <Fragment>
                                    <ChatbotAddonsModifyPlan
                                        allowUpdateValue
                                        data={state}
                                        onChangePlan={_handleChangePlan}
                                    />
                                    <div className="d-flex justify-end mt-3">
                                        <div
                                            className={classNames('v2-btn-default has-bg-blue white --transparent', {
                                                'is-disable': finalIsLoading
                                            })}
                                            onClick={_handleUpdate}
                                        >
                                            {t('pay_total_and_activate_plan')}
                                        </div>
                                    </div>
                                    <PlanChooseCard ref={refCard} onChooseCardSuccess={_handleActivePlan} />
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChatbotAddonsPlan;
