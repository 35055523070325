import React, { forwardRef, Fragment, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import GdSwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import DropdownTime from 'app/components/dropdown/DropdownTime';
import SingleSelect from 'app/components/select/singleSelect';
import { TIMEOFF_HOURS, TIMEOFF_MINUTES } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { getDefaultValue } from 'app/modules/calendar/components/magnet/utils';
import IconTooltip from 'assets/icon/IconTooltip';
import { AVAILABLE_CLASS_NAME_DROPDOWN_TIME, BEST_TIME_DRIVE_BUFFER_OPTIONS } from '../constants';

const AvailableTimeSlot = ({ defaultData = {}, onDisableBtnSave = () => {} }, ref) => {
    const { t } = useTranslation(['addons', 'common']);

    const defaultBufferTime = defaultData?.buffer_time || 0;
    const refData = useRef({
        available_calendar_slot: defaultData?.available_calendar_slot || false,
        hour: defaultData?.hour || 0,
        minute: defaultData?.minute || 0,
        buffer_time: defaultBufferTime
    });

    const [state, dispatchState] = useReducer(reducer, { buffer_time: null });
    const { buffer_time: finalBufferTime } = state;

    useImperativeHandle(ref, () => ({
        getValue: () => refData.current
    }));

    const handleToggle = (checked) => {
        onDisableBtnSave(false);
        refData.current = { ...(refData.current || {}), available_calendar_slot: checked };
    };

    const handleSelectTime = (unit, newSelect) => {
        onDisableBtnSave(false);
        refData.current = { ...(refData.current || {}), [unit === 'h' ? 'hour' : 'minute']: newSelect?.value };
    };

    const _handleSelectBufferTime = (_, value) => {
        onDisableBtnSave(false);
        refData.current = { ...(refData.current || {}), buffer_time: value };
        dispatchState((prev) => ({ ...prev, buffer_time: value }));
    };

    return (
        <Fragment>
            <div className="flex-column gap-2 flex-1">
                <div className="txt">{t('calendar:drive_buffer')}</div>
                <SingleSelect
                    options={BEST_TIME_DRIVE_BUFFER_OPTIONS}
                    fileTranslation="calendar"
                    name="driveBuffer"
                    classWrapper="v2-dropdown view-buffer"
                    buttonClassName="dropbtn v2-btn-default selection action-buffer"
                    selected={finalBufferTime ?? defaultBufferTime}
                    onSelect={_handleSelectBufferTime}
                />
            </div>
            <div className="flexcenter gap-8">
                <div className="flexcenter gap-4 flex-1">
                    <GdSwitchCheckbox
                        onChangeImmediately={handleToggle}
                        shouldLabelActive={false}
                        textPosition="right"
                        textChecked={t('available_calendar_slot')}
                        textUnChecked={t('available_calendar_slot')}
                        defaultChecked={defaultData?.['available_calendar_slot'] || false}
                    />
                    <div className="d-flex tooltip">
                        <IconTooltip />
                        <p className="tooltiptext top">{t('job_magnet_tooltip')}</p>
                    </div>
                </div>
                <div className="field-input wrapper-choosetime flex-1">
                    <DropdownTime
                        id="minutes-length-timeoff"
                        unit="h"
                        subText="hrs"
                        options={TIMEOFF_HOURS}
                        defaultValue={getDefaultValue(defaultData?.['hour'], TIMEOFF_HOURS)}
                        {...AVAILABLE_CLASS_NAME_DROPDOWN_TIME}
                        onSelect={handleSelectTime}
                    />
                    <DropdownTime
                        id="minutes-length-timeoff"
                        unit="m"
                        subText="mins"
                        options={TIMEOFF_MINUTES}
                        defaultValue={getDefaultValue(defaultData?.['minute'], TIMEOFF_MINUTES)}
                        {...AVAILABLE_CLASS_NAME_DROPDOWN_TIME}
                        onSelect={handleSelectTime}
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default forwardRef(AvailableTimeSlot);
