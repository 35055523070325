export const ACCESS_CUSTOMERS_TAB = 'accessCustomersTab';
export const ACCESS_REPORT_TAB = 'accessReportsTab';
export const ACCESS_SETTINGS_TAB = 'accessSettingsTab';
export const ACCESS_ACCOUNT = 'accessAccount';
export const ACCESS_ADDONS = 'accessAddonsTab';
export const EXPORT_CUSTOMER_LIST = 'exportCustomerList';
export const SMS_FILTER_BY_USER = 'smsFilterByUser';
export const EDIT_OR_DELETE_NOTES = 'editOrDeleteNotes';
export const DELETE_COMMENTS = 'deleteComments';
export const EDIT_OR_DELETE_TOP_NOTE = 'editOrDeleteTopNote';
export const EDIT_OR_DELETE_TASK_INBOX = 'editOrDeleteTaskInbox';
export const EDIT_CHECK_TIME = 'editCheckTime';
export const ONLINE_PAYMENT_NOTIFICATION = 'onlinePaymentNotification';
export const E_SIGNED_DOCUMENT_NOTIFICATION = 'eSignedDocumentNotification';
export const E_SIGNED_ESTIMATE_NOTIFICATION = 'eSignedEstimateNotification';
export const E_SIGNED_INVOICE_NOTIFICATION = 'eSignedInvoiceNotification';
export const INBOUND_LEADS_NOTIFICATION = 'inboundLeadsNotification';
export const JOB_CONFIRMATION_NOTIFICATION = 'jobConfirmationNotification';
export const JOB_RESCHEDULE_NOTIFICATION = 'jobRescheduleNotification';
export const REVIEWS_NOTIFICATION = 'reviewsNotification';
export const SUBSCRIPTION_NOTIFICATION = 'subscriptionNotification';
export const ONLINE_BOOKING_NOTIFICATION = 'onlineBookingNotification';
export const MISSED_CALL_NOTIFICATION = 'voipMissedCallNotification';
export const ACCESS_DESKTOP = 'accessDesktop';
export const ACCESS_MOBILE_APP = 'accessMobileApp';
export const APP_SEARCH_CUSTOMER = 'appSearchCustomer';
export const APP_ADD_CUSTOMER = 'appAddCustomer';
export const APP_EDIT_CUSTOMER = 'appEditCustomer';
export const APP_ADD_JOB = 'appAddJob';
export const APP_EDIT_JOB = 'appEditJob';
export const DELETE_JOBS = 'deleteJobs';
export const MOVE_ALL_RECURRING = 'moveAllRecurring';
export const APP_STRIPE_CARD = 'appStripeCard';
export const SHOW_WEATHER = 'showWeather';
export const APP_INVOICE_CARD = 'appInvoiceCard';
export const INBOX = 'viewEmailInbox';
export const EDIT_SCHEDULE_COLOR = 'editScheduleColor';
export const ACCESS_DASHBOARD = 'accessDashboard';
export const CONTACT_INFORMATION = 'contactInformation';
export const WO_OPTIONS = 'woOptions';
export const DOCUMENT_CARD = 'documentCard';
export const ACCESS_MANAGE_BRANCH = 'manageBranch';

export const ADMIN = 'admin';
export const ACCESS_REPORTS_TAB = 'accessReportsTab';
export const ADD_CUSTOMER_NOTES_ASSIGNED = 'addCustomerNotesAssigned';
export const ADD_NEW_CUSTOMERS = 'addNewCustomers';
export const ADD_NEW_INVOICES = 'addNewInvoices';
export const ADD_NEW_JOBS = 'addNewJobs';
export const ADD_PAYMENTS = 'addPayments';
export const DELETE_JOBS_ASSIGNED = 'deleteJobsAssigned';
export const EDIT_CUSTOMERS_ASSIGNED = 'editCustomersAssigned';
export const EDIT_INVOICES_ASSIGNED = 'editInvoicesAssigned';
export const EDIT_JOBS_ASSIGNED = 'editJobsAssigned';
export const EDIT_PAYMENTS_ASSIGNED = 'editPaymentsAssigned';
export const EDIT_SCHEDULES_ASSIGNED = 'editSchedulesAssigned';
export const MOVE_ALL_RECURRING_ASSIGNED = 'moveAllRecurringAssigned';
export const VIEW_CUSTOMERS_ASSIGNED = 'viewCustomersAssigned';
export const VIEW_INVOICES_ASSIGNED = 'viewInvoicesAssigned';
export const VIEW_JOBS_ASSIGNED = 'viewJobsAssigned';
export const VIEW_PAYMENT_HISTORY_ASSIGNED = 'viewPaymentHistoryAssigned';
export const VIEW_SCHEDULES_ASSIGNED = 'viewSchedulesAssigned';
export const SALES_COMMISSION = 'salesCommission';
export const MANAGE_TODO_TEMPLATES = 'manageTodoTemplate';
export const SHOW_WORK_POOL = 'showWorkpool';
export const EDIT_OR_DELETE_TIMEOFF = 'editOrDeleteTimeOff';
export const EDIT_OR_DELETE_EVENT = 'editOrDeleteEvent';
export const MANAGE_NOTE_TEMPLATES = 'manageNoteTemplate';
export const ADD_TIMEOFF = 'addTimeOff';
export const ADD_EVENT = 'addEvent';
export const ACCESS_CUSTOMER_OPP = 'accessAddEditOpportunity';
export const ACCESS_PIPELINE_REPORT = 'accessOppPipelineReport';
export const ACCESS_SALES_FUNNEL = 'accessOppSalesFunnel';
export const ACCESS_STATUS_CHANGES = 'accessOppStatusChanges';
export const ADD_CUSTOM_FIELDS = 'addCustomField';
export const EDIT_CUSTOM_FIELDS = 'editCustomField';
export const ADD_JOB_STATUS = 'addCustomJobStatus';
export const EDIT_JOB_STATUS = 'editCustomJobStatus';
export const JOB_MAGNET = 'jobMagnet';
export const AVAILABLE_TIME = 'bestAvailableTime';
export const DRIVE_TIME = 'driveTime';
export const ADD_SMART_VIEWS = 'addSmartView';

export const PERMISSIONS = {
    [ADMIN]: ADMIN,
    [ACCESS_ACCOUNT]: ACCESS_ACCOUNT,
    [ACCESS_CUSTOMERS_TAB]: ACCESS_CUSTOMERS_TAB,
    [ACCESS_DESKTOP]: ACCESS_DESKTOP,
    [ACCESS_MOBILE_APP]: ACCESS_MOBILE_APP,
    [ACCESS_REPORTS_TAB]: ACCESS_REPORTS_TAB,
    [ACCESS_SETTINGS_TAB]: ACCESS_SETTINGS_TAB,
    [DELETE_COMMENTS]: DELETE_COMMENTS,
    [EDIT_CHECK_TIME]: EDIT_CHECK_TIME,
    [SMS_FILTER_BY_USER]: SMS_FILTER_BY_USER,
    [EDIT_OR_DELETE_NOTES]: EDIT_OR_DELETE_NOTES,
    [EDIT_OR_DELETE_TASK_INBOX]: EDIT_OR_DELETE_TASK_INBOX,
    [EDIT_OR_DELETE_TOP_NOTE]: EDIT_OR_DELETE_TOP_NOTE,
    [E_SIGNED_DOCUMENT_NOTIFICATION]: E_SIGNED_DOCUMENT_NOTIFICATION,
    [E_SIGNED_ESTIMATE_NOTIFICATION]: E_SIGNED_ESTIMATE_NOTIFICATION,
    [E_SIGNED_INVOICE_NOTIFICATION]: E_SIGNED_INVOICE_NOTIFICATION,
    [EXPORT_CUSTOMER_LIST]: EXPORT_CUSTOMER_LIST,
    [INBOUND_LEADS_NOTIFICATION]: INBOUND_LEADS_NOTIFICATION,
    [JOB_CONFIRMATION_NOTIFICATION]: JOB_CONFIRMATION_NOTIFICATION,
    [JOB_RESCHEDULE_NOTIFICATION]: JOB_RESCHEDULE_NOTIFICATION,
    [ONLINE_BOOKING_NOTIFICATION]: ONLINE_BOOKING_NOTIFICATION,
    [ONLINE_PAYMENT_NOTIFICATION]: ONLINE_PAYMENT_NOTIFICATION,
    [REVIEWS_NOTIFICATION]: REVIEWS_NOTIFICATION,
    [SUBSCRIPTION_NOTIFICATION]: SUBSCRIPTION_NOTIFICATION,
    [ADD_CUSTOMER_NOTES_ASSIGNED]: ADD_CUSTOMER_NOTES_ASSIGNED,
    [ADD_NEW_CUSTOMERS]: ADD_NEW_CUSTOMERS,
    [ADD_NEW_INVOICES]: ADD_NEW_INVOICES,
    [ADD_NEW_JOBS]: ADD_NEW_JOBS,
    [ADD_PAYMENTS]: ADD_PAYMENTS,
    [APP_ADD_CUSTOMER]: APP_ADD_CUSTOMER,
    [APP_ADD_JOB]: APP_ADD_JOB,
    [APP_EDIT_CUSTOMER]: APP_EDIT_CUSTOMER,
    [APP_EDIT_JOB]: APP_EDIT_JOB,
    [APP_INVOICE_CARD]: APP_INVOICE_CARD,
    [APP_SEARCH_CUSTOMER]: APP_SEARCH_CUSTOMER,
    [APP_STRIPE_CARD]: APP_STRIPE_CARD,
    [DELETE_JOBS]: DELETE_JOBS,
    [DELETE_JOBS_ASSIGNED]: DELETE_JOBS_ASSIGNED,
    [EDIT_CUSTOMERS_ASSIGNED]: EDIT_CUSTOMERS_ASSIGNED,
    [EDIT_INVOICES_ASSIGNED]: EDIT_INVOICES_ASSIGNED,
    [EDIT_JOBS_ASSIGNED]: EDIT_JOBS_ASSIGNED,
    [EDIT_PAYMENTS_ASSIGNED]: EDIT_PAYMENTS_ASSIGNED,
    [EDIT_SCHEDULES_ASSIGNED]: EDIT_SCHEDULES_ASSIGNED,
    [MOVE_ALL_RECURRING]: MOVE_ALL_RECURRING,
    [MOVE_ALL_RECURRING_ASSIGNED]: MOVE_ALL_RECURRING_ASSIGNED,
    [SHOW_WEATHER]: SHOW_WEATHER,
    [VIEW_CUSTOMERS_ASSIGNED]: VIEW_CUSTOMERS_ASSIGNED,
    [VIEW_INVOICES_ASSIGNED]: VIEW_INVOICES_ASSIGNED,
    [VIEW_JOBS_ASSIGNED]: VIEW_JOBS_ASSIGNED,
    [VIEW_PAYMENT_HISTORY_ASSIGNED]: VIEW_PAYMENT_HISTORY_ASSIGNED,
    [VIEW_SCHEDULES_ASSIGNED]: VIEW_SCHEDULES_ASSIGNED,
    [SALES_COMMISSION]: SALES_COMMISSION,
    [MANAGE_TODO_TEMPLATES]: MANAGE_TODO_TEMPLATES,
    [SHOW_WORK_POOL]: SHOW_WORK_POOL,
    [EDIT_OR_DELETE_TIMEOFF]: EDIT_OR_DELETE_TIMEOFF,
    [EDIT_OR_DELETE_EVENT]: EDIT_OR_DELETE_EVENT,
    [INBOX]: INBOX,
    [EDIT_SCHEDULE_COLOR]: EDIT_SCHEDULE_COLOR,
    [MANAGE_NOTE_TEMPLATES]: MANAGE_NOTE_TEMPLATES,
    [ADD_TIMEOFF]: ADD_TIMEOFF,
    [ADD_EVENT]: ADD_EVENT,
    [ACCESS_DASHBOARD]: ACCESS_DASHBOARD,
    [CONTACT_INFORMATION]: CONTACT_INFORMATION,
    [WO_OPTIONS]: WO_OPTIONS,
    [DOCUMENT_CARD]: DOCUMENT_CARD,
    [ACCESS_MANAGE_BRANCH]: ACCESS_MANAGE_BRANCH,
    [ACCESS_ADDONS]: ACCESS_ADDONS,
    [ADD_CUSTOM_FIELDS]: ADD_CUSTOM_FIELDS,
    [EDIT_CUSTOM_FIELDS]: EDIT_CUSTOM_FIELDS,
    [ADD_JOB_STATUS]: ADD_JOB_STATUS,
    [EDIT_JOB_STATUS]: EDIT_JOB_STATUS,
    [JOB_MAGNET]: JOB_MAGNET
};
