import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ADDONS_ENGINE_DETAIL, UPDATE_ADDONS_REVIEW_OPTIONS } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import AddonHeaderDetail from '../components/AddonHeaderDetail';
import AddonsOptions from '../components/AddonsOptions';
import ReviewAddonsContent from './components/ReviewAddonsContent';
import ReviewAddonsLoading from './components/ReviewAddonsLoading';

const AddonsReviewEngine = () => {
    const [state, dispatchState] = useReducer(reducer, {
        engine: {},
        isLoading: true,
        isOptionManager: true
    });
    const { t } = useTranslation(['addons', 'common']);
    const refAlert = useRef(null);

    useEffect(() => {
        _getDetailReviewAddons();
    }, []);

    const _getDetailReviewAddons = () => {
        clientQuery(ADDONS_ENGINE_DETAIL, { data: {}, method: 'GET' }, _handleGetSuccess, checkAccessFail);
    };

    const { isLoading, engine } = state;

    const _handleGetSuccess = ({ data }) => {
        dispatchState({ engine: data, isLoading: false });
    };

    const _handleManagerSuccess = () => {
        refAlert.current.showStatusBar({
            id: LIST_STATUS.SUCCESS,
            message: t('addons:update_social_link_successfully'),
            type: LIST_STATUS.SUCCESS
        });
    };

    return (
        <div className="addons-wrap__container">
            <AddonHeaderDetail isLoading={isLoading} tutorial={engine.tutorial} />
            <AlertCustomer ref={refAlert} />
            <div className="scrolls">
                <div className="boxs-wrapper">
                    {isLoading ? (
                        <ReviewAddonsLoading />
                    ) : (
                        <>
                            <ReviewAddonsContent {...engine} />
                            <AddonsOptions
                                data={engine.options}
                                isOptionManager={state.isOptionManager}
                                urlUpdate={UPDATE_ADDONS_REVIEW_OPTIONS}
                                handleManagerSuccess={_handleManagerSuccess}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddonsReviewEngine;
