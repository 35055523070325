import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import GdGridView from 'app/components/grid/GdGridView';
import GDStatusBar from 'app/components/status/statusbar';
import { ADDONS_SMARTVIEW } from 'app/config/routes';
import { SMART_VIEWS_PERMISSIONS } from 'app/const/api/V2';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import ErrorPage from 'app/modules/error';
import IconArrow from 'assets/icon/IconArrow';
import { clientQuery } from 'common/utils/ApiUtils';
import { getPlanUser } from 'common/utils/PermissionUtils';
import { GRID_COLUMNS } from '../constants';

const SmartViewPermission = () => {
    const { t } = useTranslation('addons');
    const [state, dispatchState] = useReducer(reducer, { data: [], isLoading: true });
    const { data, isLoading } = state;
    const refAlert = useRef(null);
    const refTimer = useRef({});

    const userProfile = useSelector(({ auth }) => auth.user.profile);
    const activeFieldAddon = useSelector(({ auth }) => auth.user.settings.addons['smart_views']);
    const { isGrowthPlan, isTrial } = getPlanUser(userProfile);
    const havePermission = (isTrial || isGrowthPlan) && activeFieldAddon;

    useEffect(() => {
        getPermissionList();
    }, []);

    const getPermissionList = () => {
        const _handleSuccess = ({ data }) => {
            dispatchState({ data, isLoading: false });
        };
        const _handleFail = () => {
            dispatchState((prevState) => ({ ...prevState, data: [], isLoading: false }));
        };
        clientQuery(SMART_VIEWS_PERMISSIONS, { method: 'GET' }, _handleSuccess, _handleFail);
    };

    const handleSwitch = ({ id, value, key: keyPermission }) => {
        clearTimeout(refTimer.current[id]);
        let newPermission = [];

        dispatchState((prevState) => ({
            ...prevState,
            data: prevState.data.map((item) => {
                if (item.user.id === id) {
                    const permission = item.permission;
                    newPermission = value
                        ? [...permission, keyPermission]
                        : permission.filter((key) => key !== keyPermission);
                    return { ...item, permission: newPermission };
                }
                return item;
            })
        }));

        refTimer.current[id] = setTimeout(() => {
            clientQuery(SMART_VIEWS_PERMISSIONS + '/' + id, {
                method: 'PUT',
                toFormData: false,
                data: { permissions: newPermission }
            });
        }, 500);
    };

    if (!havePermission) return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;
    return (
        <div className="addons-wrap__container">
            <div className="header">
                <div className="flexcenter gap-8">
                    <Link to={addBranchPath(ADDONS_SMARTVIEW)} className="v2-btn-default has-icon --grey">
                        <IconArrow isPrev />
                        {t('smart_views')}
                    </Link>
                </div>
            </div>
            <div className="scrolls">
                <div className="boxs-wrapper --pipeline --smart-view container-print">
                    <GDStatusBar ref={refAlert} />
                    <div className="wrap-tables flex-column relative">
                        <GdGridView
                            isScroll
                            isEmptyFlat
                            isLoading={isLoading}
                            content={data}
                            classTable="tables table-multi-column"
                            fileTranslation="report"
                            keyGetId="user.id"
                            {...GRID_COLUMNS}
                            onChangeSwitchOppPermission={handleSwitch}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SmartViewPermission;
