import loadable from '@loadable/component';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import GDTableSticky from 'app/components/table/TableSticky';
import { CUSTOMERS_ACCOUNT, SETTINGS_SERVICE_EDIT } from 'app/config/routes';
import {
    COMMON,
    DEFAULT_ALL,
    KEY_REPORT_LOCAL_STORAGE,
    LIST_OPTION_MATERIAL_USE_DETAIL,
    LIST_PAGE_SIZE,
    MATERIAL_DETAIL_OPTION,
    MATERIAL_USE_SELECT_OPTIONS
} from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { URL_EXPORT_MATERIAL_USE, URL_EXPORT_MATERIAL_USE_DETAIL } from 'app/const/api/Export';
import { LIST_EXPORT } from 'app/const/report/Common';
import {
    COLUMNS,
    COLUMNS_NY,
    COUNTY,
    MATERIAL_NEW_YORK_COLUMNS_DEFAULT_SELECT,
    MATERIAL_USE_LIST_FILTER
} from 'app/const/report/ReportFilter';
import { getDefaultParams } from 'app/const/report/ReportParams';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { actionOpenJobDetail } from 'common/redux/actions/job/detail';
import { getListCompletedJobWoCounty, getListReportMaterialUse } from 'common/redux/actions/reports/materialUseAction';
import { getListReportMaterialUseDetail } from 'common/redux/actions/reports/materialUseDetailAction';
import { checkAddon } from 'common/utils/AddonUtils';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import HeaderBottom from '../components/HeaderBottom';
import { TableDetailed } from './components/TableList';
import { getDefaultDataMaterial, getGroupsDefault } from './utils';

const Export = loadable(() => import('app/modules/report/components/Export'));
const MainHeaderReport = loadable(() => import('app/modules/report/components/MainHeader'));
const ErrorPage = loadable(() => import('app/modules/error'));

function ReportCustomersWoActiveJobs() {
    const { t } = useTranslation(['report']);
    const dispatch = useDispatch();
    const addonsList = useSelector((state) => state.auth.user?.settings?.addons || {});
    const country = useSelector(({ auth }) => auth.user.company.country);

    const keyLocalStorage = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.MATERIAL_USE);
    let paramsReport = getLocalStorage(keyLocalStorage);
    if (!paramsReport) {
        paramsReport = getDefaultParams(REPORT_TYPE.MATERIAL_USE);
    }
    if (!Array.isArray(paramsReport?.columns_ny)) paramsReport.columns_ny = MATERIAL_NEW_YORK_COLUMNS_DEFAULT_SELECT;
    setLocalStorage(keyLocalStorage, paramsReport);

    const countyParams = paramsReport.county;
    const orderByParams = paramsReport.order;

    const [dataReport, dispatchActionReport] = useReducer(reducer, {
        refreshScreen: 0,
        rerender: false,
        data: { tableDetailed: null },
        groups: null,
        total: 0,
        isLoading: true,
        isLoadMore: false,
        cursor: null,
        showMore: false
    });

    const {
        isLoading: finalIsLoading,
        data: dataTable,
        isLoadMore: finalIsLoadMore,
        cursor: finalCursor,
        showMore: finalShowMore,
        total: finalTotal
    } = dataReport;
    const { tableDetailed } = dataTable;
    const refPrevId = useRef(countyParams || null);

    useEffect(() => {
        getListReport({});
    }, []);

    const getListReport = ({ params = {}, shouldLoadMore = false, shouldLoading = true }) => {
        const finalParams = { ...(getLocalStorage(keyLocalStorage) || {}), ...params };
        const idReport = finalParams.county;
        const tempDataReport = {
            ...dataReport,
            isLoading: shouldLoading,
            isLoadMore: shouldLoadMore,
            data: { tableDetailed: null }
        };
        delete finalParams.columns;
        delete finalParams.columns_ny;

        const newParams = { ..._getNewParams(idReport, finalParams), limit: LIST_PAGE_SIZE[3] };
        if (shouldLoadMore && finalCursor) {
            newParams['cursor'] = finalCursor;
        }
        delete newParams?.currentPage;
        if (idReport) {
            switch (idReport) {
                case MATERIAL_USE_SELECT_OPTIONS.ALL:
                    tempDataReport['groups'] = getDefaultDataMaterial({
                        orderByParams: newParams.order,
                        countyParams: newParams.county,
                        country,
                        handleActionHeader
                    });
                    refPrevId.current = MATERIAL_USE_SELECT_OPTIONS.ALL;
                    dispatchActionReport((prevState) => ({ ...prevState, ...tempDataReport }));
                    dispatch(getListReportMaterialUse(newParams, getListAllSuccess, getListFailed));
                    break;
                case MATERIAL_USE_SELECT_OPTIONS.ALL_DETAILED:
                case MATERIAL_USE_SELECT_OPTIONS.NY_PRL:
                    refPrevId.current = idReport;
                    tempDataReport.data.tableDetailed = shouldLoadMore ? tableDetailed : [];
                    tempDataReport.groups = null;
                    tempDataReport.refreshScreen = dataReport.refreshScreen + 1;
                    dispatchActionReport(tempDataReport);
                    dispatch(
                        getListReportMaterialUseDetail(
                            {
                                ...newParams,
                                county: DEFAULT_ALL,
                                option: MATERIAL_DETAIL_OPTION[idReport]
                            },
                            (response) => getListAllDetailedSuccess(response, finalParams, shouldLoadMore),
                            getListFailed
                        )
                    );
                    break;
                default:
                    tempDataReport['groups'] = getGroupsDefault({
                        material: { paramsGetGrid: [newParams.order, newParams.county, country], handleActionHeader },
                        completed_job: { isLoading: true }
                    });
                    refPrevId.current = idReport;
                    dispatchActionReport(tempDataReport);
                    dispatch(
                        getListCompletedJobWoCounty(
                            newParams,
                            (response) => getListByCountySuccess(response, idReport),
                            getListFailed
                        )
                    );
                    break;
            }
        }
    };

    const _getNewParams = (newId, params) => {
        if (newId !== refPrevId.current) {
            switch (newId) {
                case MATERIAL_USE_SELECT_OPTIONS.ALL:
                    if (refPrevId.current !== MATERIAL_USE_SELECT_OPTIONS.ALL)
                        params.order = COMMON.MATERIAL.concat(' ', COMMON.ASC);
                    break;
                case MATERIAL_USE_SELECT_OPTIONS.ALL_DETAILED:
                    if (refPrevId.current !== MATERIAL_USE_SELECT_OPTIONS.ALL_DETAILED)
                        params.order = COMMON.DATE.concat(' ', COMMON.DESC);
                    break;
                case MATERIAL_USE_SELECT_OPTIONS.NY_PRL:
                    if (refPrevId.current !== MATERIAL_USE_SELECT_OPTIONS.NY_PRL)
                        params.order = COMMON.DATE.concat(' ', COMMON.DESC);
                    break;
                default:
                    params.order = COMMON.COUNTY.concat(' ', COMMON.ASC);
                    break;
            }
        }

        _setLocalParams({ order: params.order, currentPage: params.currentPage, offset: params.offset });
        return params;
    };

    const _setLocalParams = (newParams = {}) => {
        const localParams = getLocalStorage(keyLocalStorage) || {};
        setLocalStorage(keyLocalStorage, { ...(localParams || {}), ...(newParams || {}) });
    };

    const getListAllSuccess = ({ data = [] }) => {
        const paramsReport = getLocalStorage(keyLocalStorage);
        refPrevId.current = -1;
        dispatchActionReport((prevState) => {
            return {
                ...prevState,
                total: data.length,
                refreshScreen: prevState.refreshScreen + 1,
                groups: getDefaultDataMaterial({
                    country,
                    orderByParams: paramsReport.order,
                    countyParams: paramsReport.county,
                    isLoading: false,
                    data: data.map((item) => ({ ...item, qty: `${item.qty} ${item.unit_name}` })) || [],
                    handleActionHeader
                }),
                isLoading: false,
                isLoadMore: false
            };
        });
    };

    const getListAllDetailedSuccess = (
        { data = [], total = 0, show_more = false, cursor = '' },
        params,
        isLoadMore = false
    ) => {
        refPrevId.current =
            params?.county === MATERIAL_USE_SELECT_OPTIONS.NY_PRL
                ? MATERIAL_USE_SELECT_OPTIONS.NY_PRL
                : MATERIAL_USE_SELECT_OPTIONS.ALL_DETAILED;
        dispatchActionReport((prev) => {
            const { refreshScreen: prevRefreshScreen, data: prevData = {}, total: prevTotal } = prev;
            const { tableDetailed: prevTableDetailed = [] } = prevData;
            return {
                ...prev,
                isLoading: false,
                data: { tableDetailed: isLoadMore ? [...prevTableDetailed, ...data] : data },
                total: total ?? prevTotal ?? 0,
                refreshScreen: prevRefreshScreen + 1,
                groups: null,
                showMore: show_more,
                isLoadMore: false,
                cursor
            };
        });
    };

    const getListByCountySuccess = (response, idReport) => {
        const localValue = getLocalStorage(keyLocalStorage);
        refPrevId.current = idReport;
        dispatchActionReport((prevState) => {
            const newGroups = getGroupsDefault({
                material: {
                    data: response?.material,
                    isLoading: false,
                    paramsGetGrid: [localValue.order, localValue.county, country],
                    handleActionHeader
                },
                completed_job: {
                    data: response?.completedJob,
                    isLoading: false,
                    handleClick: _handleClickCompletedJob
                }
            });

            return {
                ...prevState,
                refreshScreen: prevState.refreshScreen + 1,
                total: response?.material?.length || 0,
                groups: newGroups,
                isLoading: false,
                isLoadMore: false
            };
        });
    };

    function getListFailed() {
        dispatchActionReport({ isLoading: false });
    }

    const handleChangeFilter = (params, mode) => {
        if (mode === COUNTY) {
            getListReport({ params });
        } else if (mode && (mode === COLUMNS || mode === COLUMNS_NY)) {
            dispatchActionReport((prevState) => ({
                ...prevState,
                rerender: true,
                refreshScreen: prevState.refreshScreen + 1
            }));
        }
    };

    const _handleUpdate = (params = {}) => {
        getListReport({ params: { ...(params || {}) } });
    };

    function _renderHeaderLeft() {
        const params = getLocalStorage(keyLocalStorage);

        return (
            <HeaderBottom
                typeReport={REPORT_TYPE.MATERIAL_USE}
                filters={MATERIAL_USE_LIST_FILTER}
                isLoading={getFinalLoading()}
                params={params}
                handleUpdate={_handleUpdate}
                handleChangeFilter={handleChangeFilter}
                forceRerenderFilter={dataReport.refreshScreen && params?.county === MATERIAL_USE_SELECT_OPTIONS.NY_PRL}
            />
        );
    }

    function getFinalLoading() {
        let disableExport = false;

        if (!tableDetailed) {
            const groupData = dataReport.groups || {};
            for (const key in groupData) {
                if (Object.hasOwnProperty.call(groupData, key)) {
                    const element = groupData[key];
                    if (element.isLoading) {
                        disableExport = true;
                        break;
                    }
                }
            }
        } else {
            disableExport = finalIsLoading;
        }
        return disableExport;
    }

    function _renderHeaderRight() {
        const isPrintMaterialUse = !LIST_OPTION_MATERIAL_USE_DETAIL.includes(countyParams);
        const paramsReport = getLocalStorage(keyLocalStorage);

        return (
            <Export
                title={t('report:records', { count: finalTotal })}
                activePrint
                noSpacing
                url={isPrintMaterialUse ? URL_EXPORT_MATERIAL_USE : URL_EXPORT_MATERIAL_USE_DETAIL}
                params={paramsReport}
                refresh={dataReport.refreshScreen}
                isDisable={getFinalLoading()}
                pageExport={
                    isPrintMaterialUse
                        ? LIST_EXPORT.EXPORT_REPORT_MATERIAL_USE
                        : LIST_EXPORT.EXPORT_REPORT_MATERIAL_USE_DETAIL
                }
            />
        );
    }

    const _handleOrder = () => {
        _handleUpdate();
    };
    const _handleClickCompletedJob = ({ key, row }) => {
        switch (key) {
            case COMMON.SERVICE_NAME:
                window.open(SETTINGS_SERVICE_EDIT.replace(':id', row.service_id), '_blank');
                break;
            case COMMON.SERVICE_ADDRESS:
                window.open(CUSTOMERS_ACCOUNT.replace(':id', row.customer.id), '_blank');
                break;
            default:
                break;
        }
    };

    const _handleClickDetail = ({ key, row }) => {
        if (key === COMMON.SERVICE_NAME) dispatch(actionOpenJobDetail({ id: row.job_id }));
    };

    function handleActionHeader({ actionType, columnsTarget, currentValue }) {
        const paramsReport = getLocalStorage(keyLocalStorage);
        handleActionHeaderReport({
            actionType,
            reportType: REPORT_TYPE.MATERIAL_USE,
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: _handleOrder
        });
    }

    const _handleScrollToEnd = () => {
        if (finalShowMore && !finalIsLoadMore) {
            getListReport({ shouldLoadMore: true, shouldLoading: false });
        }
    };

    // Check permission with addons turn on
    if (!checkAddon(addonsList?.material)) return <ErrorPage />;
    return (
        <>
            <MainHeaderReport
                contentLeft={_renderHeaderLeft}
                contentRight={_renderHeaderRight}
                reportType={REPORT_TYPE.MATERIAL_USE}
                onSelectTab={_handleUpdate}
            />
            <div className="wrapper-columns">
                <div className="container-print contents-pages">
                    <div className="wrap-tables flex-column relative">
                        {dataReport.groups ? (
                            <GDTableSticky
                                classTable="--material scrolls-x"
                                groups={{ ...(dataReport.groups || {}) } || {}}
                            />
                        ) : null}
                        <TableDetailed
                            data={tableDetailed}
                            isShow={tableDetailed}
                            isLoading={finalIsLoading}
                            orderStatus={orderByParams}
                            columnStatus={
                                paramsReport?.county === MATERIAL_USE_SELECT_OPTIONS.NY_PRL
                                    ? paramsReport?.columns_ny
                                    : paramsReport?.columns
                            }
                            isLoadMore={finalIsLoadMore}
                            countyParam={paramsReport?.county || null}
                            onClickHeaderCallBack={handleActionHeader}
                            handleClick={_handleClickDetail}
                            onScrollToEnd={_handleScrollToEnd}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportCustomersWoActiveJobs;
