import moment from 'moment';

import {
    COMMON,
    CURRENT_DAY,
    DATE_FORMAT,
    DEFAULT_ALL,
    DEFAULT_SORT_BY_NAME,
    END_DATE_OF_MONTH,
    LIST_PAGE_SIZE,
    PAGE_SIZE_DEFAULT,
    START_DATE_OF_MONTH,
    START_OF_YEAR,
    YEAR_CURRENT
} from 'app/const/App';
import { PAYMENT_CARD_CUSTOMER } from 'app/const/Customers';
import {
    getColumnsParams,
    PAYMENTS_COLLECTED_PAYMENT_FOR_DEFAULT_SELECT,
    SOURCE_NEW_JOB,
    TABS_FILTER
} from 'app/const/report/ReportFilter';
import { REPORT_TYPE, REVIEW_LIKELY_TAB, REVIEW_SERVICE_TAB } from 'app/const/report/ReportTypeContent';
import { CUSTOM_FIELDS_VIEWS } from 'app/modules/customfields/constant';
import { BILLED_INVOICE } from '../Customers';
import { SALE_FUNNEL_TAB } from '../opportunity';
import { REPORT_LIMIT, TEMPLATES } from '../Reports';

const reportRevenueDefaultParams = {
    start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
    end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
    year: YEAR_CURRENT,
    sale: 'total_collected',
    payment_for: 'Invoice',
    payment_methods: DEFAULT_ALL,
    orderDetail: COMMON.AMOUNT.concat(' ', COMMON.ASC),
    total: 1,
    offset: 0,
    limit: REPORT_LIMIT
};

export const getDefaultParams = (type) => {
    const REPORT_DEFAULT_PARAMS = {
        [REPORT_TYPE.INVOICE]: {
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            columns: getColumnsParams([REPORT_TYPE.INVOICE]),
            status: DEFAULT_ALL,
            sign_status: DEFAULT_ALL,
            invoice_tags: DEFAULT_ALL,
            service: DEFAULT_ALL,
            order: COMMON.DATE.concat(' ', COMMON.DESC),
            keyword: '',
            offset: 0,
            limit: PAGE_SIZE_DEFAULT,
            deleted: 0,
            archived: 0,
            filterTab: '{"archived":0,"deleted":0}',
            currentPage: 1,
            outstanding: 1,
            total: 1
        },
        [REPORT_TYPE.ESTIMATE]: {
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            columns: getColumnsParams([REPORT_TYPE.ESTIMATE]),
            status: DEFAULT_ALL,
            sign_status: DEFAULT_ALL,
            order: COMMON.DATE.concat(' ', COMMON.DESC),
            keyword: '',
            offset: 0,
            limit: PAGE_SIZE_DEFAULT,
            deleted: 0,
            archived: 0,
            filterTab: '{"archived":0,"deleted":0}',
            currentPage: 1,
            total: 1
        },
        [REPORT_TYPE.ESTIMATE_CUSTOMER_LIST]: {
            state: 1,
            offset: 0,
            limit: PAGE_SIZE_DEFAULT,
            filterTab: 1,
            total: 1,
            currentPage: 1,
            deleted: 0,
            active: 1,
            archived: 0,
            order: COMMON.DATE.concat(' ', COMMON.DESC)
        },
        [REPORT_TYPE.DOCUMENT]: {
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            columns: getColumnsParams([REPORT_TYPE.DOCUMENT]),
            status: DEFAULT_ALL,
            order: COMMON.DATE_CREATED.concat(' ', COMMON.DESC),
            keyword: '',
            offset: 0,
            limit: PAGE_SIZE_DEFAULT,
            types: DEFAULT_ALL,
            deleted: 0,
            archived: 0,
            filterTab: '{"archived":0,"deleted":0}',
            total: 1,
            currentPage: 1
        },
        [REPORT_TYPE.DOCUMENT_CUSTOMER_LIST]: {
            start: '',
            end: '',
            keyword: '',
            state: 1,
            offset: 0,
            limit: PAGE_SIZE_DEFAULT,
            filterTab: 1,
            total: 1,
            deleted: 1,
            archived: 0,
            order: COMMON.DATE_CREATED.concat(' ', COMMON.DESC)
        },
        [REPORT_TYPE.CUSTOMER_INVOICES]: {
            limit: PAGE_SIZE_DEFAULT,
            deleted: 0,
            active: 1,
            archived: 0,
            filterTab: '{"archived":0,"deleted":0}',
            total: 1,
            currentPage: 1,
            status: [0, 1],
            order: COMMON.CREATED.concat(' ', COMMON.DESC)
        },
        [REPORT_TYPE.CUSTOMERS]: {
            keyword: '',
            columns: getColumnsParams([REPORT_TYPE.CUSTOMERS]),
            limit: PAGE_SIZE_DEFAULT,
            sort_by: DEFAULT_SORT_BY_NAME,
            order: COMMON.CUSTOMER.concat(' ', COMMON.ASC),
            character: '',
            deleted: 0,
            active: 0,
            filterTab: '{"archived":0,"deleted":0}',
            total: 1,
            currentPage: 1,
            sideMenuActive: 'total_customer',
            status: [0, 1],
            tags: DEFAULT_ALL
        },
        [REPORT_TYPE.CUSTOMER_PAYMENTS]: {
            limit: PAGE_SIZE_DEFAULT,
            total: 1,
            currentPage: 1,
            order: COMMON.DATE.concat(' ', COMMON.DESC)
        },
        [REPORT_TYPE.CUSTOMER_JOBS]: {
            limit: PAGE_SIZE_DEFAULT,
            offset: 0,
            total: 1,
            currentPage: 1,
            filterTab: '{"archived":0,"deleted":0}',
            active: 0,
            deleted: 0,
            status: DEFAULT_ALL,
            order: COMMON.NEXT_SERVICE.concat(' ', COMMON.DESC),
            orderByAll: COMMON.NEXT_SERVICE.concat(' ', COMMON.DESC),
            orderByActive: COMMON.NEXT_SERVICE.concat(' ', COMMON.DESC),
            orderByComplete: COMMON.DATE.concat(' ', COMMON.DESC),
            orderByCanceled: COMMON.DATE.concat(' ', COMMON.DESC),
            orderByDeleted: COMMON.INVOICE_TOTAL.concat(' ', COMMON.DESC)
        },
        [REPORT_TYPE.PAYMENT]: {
            columns: getColumnsParams([REPORT_TYPE.PAYMENT]),
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            order: COMMON.DATE.concat(' ', COMMON.DESC),
            keyword: '',
            offset: 0,
            limit: PAGE_SIZE_DEFAULT,
            payment_methods: DEFAULT_ALL,
            currentPage: 1,
            total: 1
        },
        [REPORT_TYPE.CREDIT]: {
            columns: getColumnsParams([REPORT_TYPE.CREDIT]),
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            order: COMMON.DATE.concat(' ', COMMON.DESC),
            keyword: '',
            offset: 0,
            limit: PAGE_SIZE_DEFAULT,
            total: 1
        },
        [REPORT_TYPE.RECURRING_INVOICE]: {
            columns: getColumnsParams([REPORT_TYPE.RECURRING_INVOICE]),
            keyword: '',
            offset: 0,
            status: DEFAULT_ALL,
            sort_by: DEFAULT_SORT_BY_NAME,
            limit: PAGE_SIZE_DEFAULT,
            total: 1,
            filterTab: '{"status":-1}'
        },
        [REPORT_TYPE.SUBSCRIPTION[PAYMENT_CARD_CUSTOMER.STRIPE]]: {
            columns: getColumnsParams([REPORT_TYPE.SUBSCRIPTION[PAYMENT_CARD_CUSTOMER.STRIPE]]),
            keyword: '',
            offset: 0,
            plans: DEFAULT_ALL,
            status: DEFAULT_ALL,
            sort_by: DEFAULT_SORT_BY_NAME,
            limit: PAGE_SIZE_DEFAULT,
            filterTab: '{"status":-1}',
            total: 1,
            currentPage: 1,
            techs: DEFAULT_ALL,
            staffs: DEFAULT_ALL
        },
        [REPORT_TYPE.SUBSCRIPTION[PAYMENT_CARD_CUSTOMER.SQUARE]]: {
            columns: getColumnsParams([REPORT_TYPE.SUBSCRIPTION[PAYMENT_CARD_CUSTOMER.SQUARE]]),
            keyword: '',
            offset: 0,
            square_plans: DEFAULT_ALL,
            status: DEFAULT_ALL,
            sort_by: DEFAULT_SORT_BY_NAME,
            limit: PAGE_SIZE_DEFAULT,
            filterTab: '{"status":-1}',
            total: 1,
            techs: DEFAULT_ALL,
            staffs: DEFAULT_ALL
        },
        [REPORT_TYPE.LOCATIONS_WO_ACTIVE_JOBS]: {
            columns: getColumnsParams([REPORT_TYPE.LOCATIONS_WO_ACTIVE_JOBS]),
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            keyword: '',
            status: DEFAULT_ALL,
            sort_by: DEFAULT_SORT_BY_NAME,
            offset: 0,
            limit: PAGE_SIZE_DEFAULT,
            total: 0,
            currentPage: 1
        },
        [REPORT_TYPE.CUSTOMERS_WO_ACTIVE_JOBS]: {
            columns: getColumnsParams([REPORT_TYPE.CUSTOMERS_WO_ACTIVE_JOBS]),
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            keyword: '',
            offset: 0,
            status: DEFAULT_ALL,
            sort_by: DEFAULT_SORT_BY_NAME,
            service_type_single: DEFAULT_ALL,
            total: 0
        },
        [REPORT_TYPE.NEW_CUSTOMERS]: {
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            order: COMMON.DATE_ADDED.concat(' ', COMMON.ASC),
            offset: 0,
            report_source: DEFAULT_ALL,
            sort_by: DEFAULT_SORT_BY_NAME,
            limit: PAGE_SIZE_DEFAULT,
            total: 1
        },
        [REPORT_TYPE.PAYMENTS_COLLECTED]: {
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            order: COMMON.DATE.concat(' ', COMMON.ASC),
            payment_for: PAYMENTS_COLLECTED_PAYMENT_FOR_DEFAULT_SELECT,
            sort_by: DEFAULT_SORT_BY_NAME,
            staff: DEFAULT_ALL,
            clients: DEFAULT_ALL,
            customer_id: DEFAULT_ALL,
            payment_methods: DEFAULT_ALL,
            total: 1,
            offset: 0,
            limit: PAGE_SIZE_DEFAULT
        },
        [REPORT_TYPE.TOTAL_SALES]: {
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            order: COMMON.DATE.concat(' ', COMMON.DESC),
            client: DEFAULT_ALL,
            sort_by: DEFAULT_SORT_BY_NAME,
            customer_id: DEFAULT_ALL,
            total: 1
        },
        [REPORT_TYPE.SALES_FORECAST]: {
            keyword: '',
            year: YEAR_CURRENT,
            sort_by: DEFAULT_SORT_BY_NAME,
            order: COMMON.CUSTOMER.concat(' ', COMMON.ASC)
        },

        [REPORT_TYPE.SALES_TAX_SUMMARY]: {
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            keyword: '',
            revenue: COMMON.CASH,
            sort_by: DEFAULT_SORT_BY_NAME,
            payment_methods: DEFAULT_ALL,
            order: COMMON.DATE.concat(' ', COMMON.DESC),
            orderTaxable: COMMON.DATE.concat(' ', COMMON.DESC),
            orderSaleable: COMMON.TAXABLE_SALES.concat(' ', COMMON.DESC),
            total: 1
        },

        [REPORT_TYPE.REVENUE_BY_CLIENT]: {
            ...reportRevenueDefaultParams,
            clients: DEFAULT_ALL,
            sort_by: DEFAULT_SORT_BY_NAME,
            order: COMMON.CUSTOMER.concat(' ', COMMON.ASC),
            orderMonth: COMMON.CUSTOMER.concat(' ', COMMON.ASC)
        },
        [REPORT_TYPE.REVENUE_BY_SERVICE]: {
            ...reportRevenueDefaultParams,
            service: DEFAULT_ALL,
            service_type_single: DEFAULT_ALL,
            order: COMMON.SERVICE_NAME.concat(' ', COMMON.ASC),
            orderMonth: COMMON.SERVICE_NAME.concat(' ', COMMON.ASC)
        },
        [REPORT_TYPE.REVENUE_BY_ITEM]: {
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            year: YEAR_CURRENT,
            item: DEFAULT_ALL,
            sale: 'total_collected',
            payment_methods: DEFAULT_ALL,
            payment_for: 'Invoice',
            order: COMMON.ITEM_NAME.concat(' ', COMMON.ASC),
            orderMonth: COMMON.ITEM_NAME.concat(' ', COMMON.ASC),
            orderDetail: COMMON.AMOUNT.concat(' ', COMMON.ASC),
            total: 1
        },
        [REPORT_TYPE.REVENUE_BY_STAFF]: {
            ...reportRevenueDefaultParams,
            staff: DEFAULT_ALL,
            order: COMMON.USER_NAME.concat(' ', COMMON.ASC),
            orderMonth: COMMON.USER_NAME.concat(' ', COMMON.ASC)
        },
        [REPORT_TYPE.REVENUE_BY_SOURCE]: {
            ...reportRevenueDefaultParams,
            source: DEFAULT_ALL,
            order: COMMON.SOURCE_NAME.concat(' ', COMMON.ASC),
            orderMonth: COMMON.SOURCE_NAME.concat(' ', COMMON.ASC)
        },
        [REPORT_TYPE.REVENUE_BY_TAGS]: {
            ...reportRevenueDefaultParams,
            tag_id: DEFAULT_ALL,
            tag_type: 4,
            order: COMMON.TAG_NAME.concat(' ', COMMON.ASC),
            orderMonth: COMMON.TAG_NAME.concat(' ', COMMON.ASC)
        },
        [REPORT_TYPE.MATERIAL_USE]: {
            columns: getColumnsParams([REPORT_TYPE.MATERIAL_USE]),
            columns_ny: getColumnsParams([REPORT_TYPE.MATERIAL_NEW_YORK]),
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            order: COMMON.MATERIAL.concat(' ', COMMON.ASC),
            offset: 0,
            county: DEFAULT_ALL,
            limit: PAGE_SIZE_DEFAULT,
            total: 1,
            clients: DEFAULT_ALL,
            materials: DEFAULT_ALL
        },
        [REPORT_TYPE.MATERIAL_USE_DETAIL]: {
            columns: getColumnsParams([REPORT_TYPE.MATERIAL_USE_DETAIL]),
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            order: '',
            offset: 0,
            county: '',
            limit: PAGE_SIZE_DEFAULT,
            clients: DEFAULT_ALL,
            materials: DEFAULT_ALL
        },
        [REPORT_TYPE.EMAIL_HISTORY]: {
            columns: getColumnsParams([REPORT_TYPE.EMAIL_HISTORY]),
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            status: DEFAULT_ALL,
            customer_id: DEFAULT_ALL,
            sort_by: DEFAULT_SORT_BY_NAME,
            order: '',
            offset: 0,
            limit: PAGE_SIZE_DEFAULT,
            currentPage: 1,
            total: 1
        },
        [REPORT_TYPE.SERVICE_LOOKUP]: {
            columns: getColumnsParams([REPORT_TYPE.SERVICE_LOOKUP]),
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            order: COMMON.SCHEDULE_DATE.concat(' ', COMMON.ASC),
            keyword: '',
            sort_by: DEFAULT_SORT_BY_NAME,
            service: DEFAULT_ALL,
            job_status: DEFAULT_ALL,
            assigned_to: DEFAULT_ALL,
            city: DEFAULT_ALL,
            zipcode: DEFAULT_ALL,
            tags: DEFAULT_ALL,
            total: 0,
            filterTab: TABS_FILTER.ACTIVE_SERVICE_LOOKUP.value
        },

        [REPORT_TYPE.ACCOUNTS_AGING]: {
            columns: getColumnsParams([REPORT_TYPE.ACCOUNTS_AGING]),
            period_ending: moment(CURRENT_DAY).format(DATE_FORMAT),
            keyword: '',
            filters: DEFAULT_ALL,
            sort_by: DEFAULT_SORT_BY_NAME,
            email: DEFAULT_ALL,
            order: COMMON.CUSTOMER.concat(' ', COMMON.ASC),
            total: 1,
            service: DEFAULT_ALL
        },
        [REPORT_TYPE.LEADS.inbound_leads]: {
            columns: getColumnsParams([REPORT_TYPE.LEADS.inbound_leads]),
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            order: COMMON.DATE_ADDED.concat(' ', COMMON.ASC),
            source_inbound: DEFAULT_ALL,
            sort_by: DEFAULT_SORT_BY_NAME
        },
        [REPORT_TYPE.LEADS.online_bookings]: {
            columns: getColumnsParams([REPORT_TYPE.LEADS.online_bookings]),
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            order: COMMON.DATE.concat(' ', COMMON.ASC),
            offset: 0,
            source_online_booking: DEFAULT_ALL,
            sort_by: DEFAULT_SORT_BY_NAME,
            limit: PAGE_SIZE_DEFAULT,
            total: 1
        },
        [REPORT_TYPE.COMMISSIONS]: {
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            staffs: DEFAULT_ALL,
            commission: 1,
            display_by: 0,
            invoice_status: DEFAULT_ALL,
            payment_methods: DEFAULT_ALL,
            order: COMMON.INVOICE_DATE.concat(' ', COMMON.DESC),
            report_type: 0,
            job_status: DEFAULT_ALL
        },
        [REPORT_TYPE.STATEMENT_CUSTOMER_LIST]: {
            start: moment(START_OF_YEAR).format(DATE_FORMAT),
            end: moment(CURRENT_DAY).format(DATE_FORMAT),
            type: BILLED_INVOICE,
            columns: getColumnsParams([REPORT_TYPE.STATEMENT_CUSTOMER_LIST]),
            statement_type: 2
        },
        [REPORT_TYPE.CREDIT_CUSTOMER_LIST]: {
            offset: 0,
            total: 1,
            limit: PAGE_SIZE_DEFAULT,
            order: COMMON.DATE.concat(' ', COMMON.DESC)
        },
        [REPORT_TYPE.LOCATION_CUSTOMER_LIST]: {
            offset: 0,
            limit: PAGE_SIZE_DEFAULT,
            total: 1,
            columns: getColumnsParams([REPORT_TYPE.LOCATION_CUSTOMER_LIST]),
            order: COMMON.LOCATION_NAME.concat(' ', COMMON.DESC),
            filterTab: 1,
            deleted: 1,
            archived: 0,
            status: 1
        },
        [REPORT_TYPE.REVIEW_PAGE_PARAMS_LIKELY]: {
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            items: DEFAULT_ALL,
            total: 1,
            items_likely: DEFAULT_ALL,
            offset: 0,
            limit: PAGE_SIZE_DEFAULT,
            order: COMMON.DATE.concat(' ', COMMON.ASC)
        },
        [REPORT_TYPE.REVIEW_PAGE_PARAMS_SERVICE]: {
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            items_likely: DEFAULT_ALL,
            items: DEFAULT_ALL,
            total: 1,
            users: DEFAULT_ALL,
            offset: 0,
            limit: PAGE_SIZE_DEFAULT,
            order: COMMON.DATE.concat(' ', COMMON.ASC)
        },
        [REPORT_TYPE.USER_SESSION_LIST]: {
            start: moment().subtract(3, 'months').format(DATE_FORMAT),
            end: moment().format(DATE_FORMAT),
            offset: 0,
            limit: PAGE_SIZE_DEFAULT,
            total: 1
        },
        [REPORT_TYPE.ACTIVITY_COMPARISON]: {
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            staffs: DEFAULT_ALL,
            columns: getColumnsParams([REPORT_TYPE.ACTIVITY_COMPARISON]),
            order: COMMON.USER.concat(' ', COMMON.ASC)
        },
        [REPORT_TYPE.OPPORTUNITIES_PIPELINE]: {
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            staffs: DEFAULT_ALL
        },
        [REPORT_TYPE.OPPORTUNITIES_PERMISSIONS]: {
            offset: 0,
            limit: LIST_PAGE_SIZE[3],
            total: 1
        },
        [REPORT_TYPE.CUSTOM_FIELDS_PERMISSIONS]: {
            offset: 0,
            limit: LIST_PAGE_SIZE[3],
            total: 1
        },
        [REPORT_TYPE.CUSTOM_FIELDS_MANAGE]: {
            offset: 0,
            limit: LIST_PAGE_SIZE[3],
            total: 1,
            activeTab: CUSTOM_FIELDS_VIEWS.ACTIVE
        },
        [REPORT_TYPE.MANAGE_MULTIPLE_BRANCH]: {
            filterTab: 1
        },
        [REPORT_TYPE.SALES_FUNNEL]: {
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            staffs: DEFAULT_ALL,
            selectedTab: SALE_FUNNEL_TAB.COUNT
        },
        [REPORT_TYPE.BLOCK_NUMBERS]: {
            offset: 0,
            limit: PAGE_SIZE_DEFAULT,
            total: 1,
            keyword: ''
        },
        [REPORT_TYPE.STATUS_CHANGES]: {
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            staffs: DEFAULT_ALL
        },
        [REPORT_TYPE.WORK_LOG]: {
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            user_ids: DEFAULT_ALL
        },
        [REPORT_TYPE.CUSTOM_JOB_STATUS_PERMISSIONS]: {
            offset: 0,
            limit: LIST_PAGE_SIZE[3],
            total: 1
        },
        [REPORT_TYPE.CUSTOM_JOB_STATUS_MANAGE_DELETE]: {
            offset: 0,
            limit: LIST_PAGE_SIZE[3],
            total: 1
        },
        [REPORT_TYPE.NEW_JOB]: {
            columns: getColumnsParams([REPORT_TYPE.NEW_JOB]),
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            order: COMMON.JOB_CREATED_DATE.concat(' ', COMMON.ASC),
            [SOURCE_NEW_JOB]: DEFAULT_ALL,
            service: DEFAULT_ALL,
            job_status: DEFAULT_ALL,
            assigned_to: DEFAULT_ALL
        },
        [REPORT_TYPE.DEVICE_TRACKING]: {
            columns: getColumnsParams([REPORT_TYPE.DEVICE_TRACKING]),
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
            order: COMMON.DATE.concat(' ', COMMON.ASC),
            limit: REPORT_LIMIT,
            total: 1
        },
        [REPORT_TYPE.CHATBOT_REPORT]: {
            limit: LIST_PAGE_SIZE[3],
            total: 1,
            keyword: '',
            tags: DEFAULT_ALL,
            order: COMMON.DATE.concat(' ', COMMON.DESC),
            status: DEFAULT_ALL,
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT)
        },
        [REPORT_TYPE.CHATBOT_TRAIN_AI]: {
            limit: LIST_PAGE_SIZE[3],
            total: 1,
            keyword: '',
            status: DEFAULT_ALL,
            order: COMMON.QUESTION.concat(' ', COMMON.DESC),
            start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT)
        },
        [REPORT_TYPE.CHATBOT_CUSTOM_ANSWER]: {
            limit: LIST_PAGE_SIZE[3],
            total: 1,
            status: DEFAULT_ALL,
            keyword: '',
            order: COMMON.QUESTIONS.concat(' ', COMMON.DESC)
        },
        [REPORT_TYPE.CHATBOT_CONTENT]: {
            limit: LIST_PAGE_SIZE[3],
            total: 1,
            status: DEFAULT_ALL,
            keyword: '',
            order: COMMON.LABEL.concat(' ', COMMON.DESC),
            [TEMPLATES]: 1
        }
    };

    return { ...REPORT_DEFAULT_PARAMS[type] };
};

export const PARAMS_LOCAL_REVIEW = {
    [REVIEW_LIKELY_TAB]: REPORT_TYPE.REVIEW_PAGE_PARAMS_LIKELY,
    [REVIEW_SERVICE_TAB]: REPORT_TYPE.REVIEW_PAGE_PARAMS_SERVICE
};
