import { PERMISSIONS } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';
import AddSchedule from 'app/modules/jobdetail/tabs/settingschedule/assign/Add';
import IconCircleClose from 'assets/icon/IconCircleClose';
import IconUser from 'assets/icon/IconUser';
import classNames from 'classnames';
import { checkPermission, getPermissionCommission } from 'common/utils/PermissionUtils';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CrewAssign } from './CrewGroup';

const Assign = forwardRef(
    ({ jobAssign = [], crewIds = [], isWorkPool = false, onUpdate = () => {}, triggerReload }, ref) => {
        const { t } = useTranslation(['jobDetail']);
        const [state, dispatchState] = useReducer(reducer, {
            scheduleSelected: jobAssign || [],
            crewSelected: crewIds
        });

        const { onChangeDataRepeatContext } = useContext(DataRepeatContext);
        const commissionReducer = useSelector(({ commissionReducer }) => commissionReducer);
        const { permissions, settings } = useSelector(({ auth }) => auth.user);
        const { canModify: canModifyCommission } = getPermissionCommission(
            settings.addons.commission_tracking,
            checkPermission(permissions?.enabled || [], PERMISSIONS.salesCommission)
        );

        const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);

        const { scheduleSelected: finalScheduleSelected, crewSelected: finalCrewSelected } = state;

        useImperativeHandle(ref, () => ({
            getValue: () => {
                return { schedules: finalScheduleSelected, crew_ids: finalCrewSelected };
            },
            selectBestTime: _handleSelectBestTime
        }));

        useEffect(() => {
            if (triggerReload) {
                dispatchState({
                    scheduleSelected: jobAssign,
                    crewSelected: crewIds
                });
            }
        }, [triggerReload]);

        function _handleSelect(assign) {
            let tempScheduleSelected = [...finalScheduleSelected];
            let checkExist = null;
            let checkExistPrimary = false;

            tempScheduleSelected = tempScheduleSelected.filter((item) => {
                if (item.is_primary === 1 && assign.id !== item.id) {
                    checkExistPrimary = true;
                }
                if (item.id === assign.id) {
                    checkExist = assign;
                } else {
                    return item;
                }
            });

            if (!checkExist) {
                tempScheduleSelected = finalScheduleSelected.concat(assign);
            }

            if (tempScheduleSelected.length === 0) {
                return false;
            }

            if (!checkExistPrimary) {
                const newObject = { ...tempScheduleSelected[0], is_primary: 1 };
                tempScheduleSelected[0] = newObject;
            }

            dispatchState({ scheduleSelected: tempScheduleSelected });
            _handleChangeContextSchedule(tempScheduleSelected);
        }

        function _changePrimary(newPrimary, isWP = false) {
            let tempScheduleSelected = !isWP ? [...finalScheduleSelected] : [];

            tempScheduleSelected = tempScheduleSelected
                .filter((itemDelete) => itemDelete.id !== newPrimary.id)
                .map((item) => {
                    return {
                        ...item,
                        is_primary: 0
                    };
                });
            const newSchedule = tempScheduleSelected.concat({ ...newPrimary, is_primary: 1 });
            dispatchState({ scheduleSelected: newSchedule });
            _handleChangeContextSchedule(newSchedule);
        }

        const _handleChangeContextSchedule = (newSchedule) => {
            onUpdate(newSchedule);
            if (canModifyCommission) {
                onChangeDataRepeatContext({
                    changeSchedule: Date.now(),
                    schedule: commissionReducer.items.filter((item) =>
                        newSchedule.some((el) => +item.user_id === +el.user_id)
                    )
                });
            }
        };

        function _renderAvatar(scheduleDetail) {
            const userDetail = companyUsers.find((user) => user.id === scheduleDetail.user_id);

            if (userDetail?.avatar) {
                return <img src={userDetail.avatar} alt="" width="24" height="24" />;
            } else {
                return <div className="avt fs-11">{scheduleDetail?.name?.substring(0, 2)}</div>;
            }
        }

        function _renderButtonClose(item, length, index) {
            if (length === 1 && index === 0) {
                return false;
            }
            return (
                <div className="close" onClick={() => _handleSelect(item)}>
                    <IconCircleClose />
                </div>
            );
        }

        function _renderScheduleSelected() {
            const selectedLength = finalScheduleSelected.length;

            return finalScheduleSelected
                .sort(function (a, b) {
                    return b.is_primary - a.is_primary;
                })
                .map((item, index) => {
                    return (
                        <div key={item.id} className="tech-name">
                            <div className="avt-img">{_renderAvatar(item)}</div>
                            <span className="txt-ellipsis">{item.name}</span>
                            {!!item.is_primary && <span className="txt-primary">Primary</span>}
                            {_renderButtonClose(item, selectedLength, index)}
                        </div>
                    );
                });
        }

        const _handleSelectCrew = (data) => {
            dispatchState((prev) => ({ ...prev, crewSelected: data }));
        };

        const _handleSelectBestTime = ({ next = {} }) => {
            dispatchState((prev) => ({ ...prev, scheduleSelected: [{ ...next, is_primary: 1 }] }));
        };

        return (
            <div className={classNames('rows', { 'border-bottom-border-color-grey': !canModifyCommission })}>
                <div className="txt">
                    <IconUser />
                    Assign To
                </div>

                <div
                    className={classNames('details detail-schedule --assign-to', {
                        'field-error': !finalScheduleSelected.length,
                        'mb-0': canModifyCommission,
                        'flex-wrap': isWorkPool
                    })}
                >
                    <div className="schedule-user">
                        {_renderScheduleSelected()}
                        <CrewAssign listCrew={finalCrewSelected} onCloseCrewSelect={_handleSelectCrew} />
                    </div>

                    <AddSchedule
                        isWorkPool={isWorkPool}
                        selected={finalScheduleSelected}
                        crewSelected={finalCrewSelected}
                        onSelect={_handleSelect}
                        onSelectCrew={_handleSelectCrew}
                        changePrimary={_changePrimary}
                    />

                    {isWorkPool && (
                        <span className="workpool-notify fw-bold flex-auto">{t('jobDetail:work_pool')}</span>
                    )}
                </div>
            </div>
        );
    }
);

export default Assign;
