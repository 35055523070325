import classNames from 'classnames';
import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import Input from 'app/components/input/Input';
import { SUCCESS_CODE } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { LIST_CODE_BY_COUNTRY, LIST_COUNTRY } from 'app/const/Settings';
import { LINK_IP_INFO } from 'app/const/URL';
import { getCountry } from 'app/modules/auth/utils';
import SearchOption from 'app/modules/report/filter/SearchOption';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconCheck from 'assets/icon/IconCheck';
import { formatPhoneTrial } from 'common/utils/PhoneUtils';
import { CODE, COUNTRY, COUNTRY_CODE } from './const';

const TrialStepTwo = ({
    fileTranslation = 'auth',
    infoTrial = {},
    error = {},
    isFetchedCountry = false,
    handleFocus = () => {},
    onChangeInput = () => {},
    onKeyPress = () => {},
    handleSetValue = () => {},
    handleFetchedCountry = () => {}
}) => {
    const { t } = useTranslation(fileTranslation);

    const [state, dispatchState] = useReducer(reducer, {
        code: infoTrial?.country_code || '',
        phone: formatPhoneTrial(infoTrial?.company_phone || ''),
        country: getCountry(infoTrial?.country)
    });
    const { code: finalCode, phone: finalPhone, country: finalCountry } = state;

    const refDropdownCodeByCountry = useRef(null);

    useEffect(() => {
        if (!isFetchedCountry) _handleFetchCountry();
    }, [isFetchedCountry]);

    const _handleFetchCountry = () => {
        const _handleSuccess = (data) => {
            if (isFetchedCountry) return;
            const finalCountry = getCountry(data.country?.toLowerCase());
            dispatchState((prev) => ({ ...prev, country: finalCountry }));
            handleSetValue({ key: COUNTRY, value: finalCountry.value });
            handleFetchedCountry();
        };
        // eslint-disable-next-line no-undef
        const tokens = process.env.REACT_APP_IP_INFO_TOKEN?.split(',') || [];
        const randomToken = tokens[Math.floor(Math.random() * tokens.length)];

        fetch(`${LINK_IP_INFO}?token=${randomToken}`)
            .then((response) => {
                if (!response.ok || response.status !== SUCCESS_CODE) return false;
                return response.json();
            })
            .then(_handleSuccess);
    };

    const _onChangePhone = (event) => {
        const finalValue = event.target.value.replace(/\D+/g, '');
        event.target.value = finalValue;
        dispatchState({ phone: formatPhoneTrial(finalValue) });
        onChangeInput(event);
    };

    const _handleSelectCountry = (country) => {
        handleSetValue({ key: COUNTRY, value: country.value });
        dispatchState((prevState) => ({ ...prevState, [COUNTRY]: country }));
        handleFocus();
        if (!isFetchedCountry) handleFetchedCountry();
    };

    const _renderListCodeByCountry = () => {
        return (
            <ul>
                {LIST_CODE_BY_COUNTRY.map(({ dial_code, code }) => {
                    const onClickItem = () => {
                        handleSetValue({ key: COUNTRY_CODE, value: code });
                        dispatchState((prev) => ({ ...prev, [CODE]: code }));
                        refDropdownCodeByCountry.current?._close();
                    };

                    return (
                        <li
                            key={code}
                            className={classNames('items', { active: code === finalCode })}
                            onClick={onClickItem}
                        >
                            {dial_code} ({code.toUpperCase()})
                        </li>
                    );
                })}
            </ul>
        );
    };

    const _renderButtonDropdownCodeByCountry = () => {
        const countrySelected = LIST_CODE_BY_COUNTRY.find((country) => country.code === finalCode) || {};
        return (
            <Fragment>
                <div className="txt-ellipsis">
                    {countrySelected.dial_code} ({countrySelected.code ? countrySelected.code : ''})
                </div>
                <div className="arrow">
                    <IconArrowDown />
                </div>
            </Fragment>
        );
    };

    return (
        <Fragment>
            <div className="box-input">
                <Input
                    autoFocus
                    placeholder={t('auth:first_name')}
                    type="text"
                    name="first_name"
                    classWrapper="user-box rows"
                    initValue={infoTrial.first_name}
                    error={error?.first_name}
                    isShowIconError={false}
                    onFocus={handleFocus}
                    onChange={onChangeInput}
                    handleKeypress={onKeyPress}
                />
            </div>
            <div className="box-input">
                <Input
                    placeholder={t('auth:last_name')}
                    type="text"
                    name="last_name"
                    classWrapper="user-box rows"
                    initValue={infoTrial.last_name}
                    error={error?.last_name}
                    isShowIconError={false}
                    onFocus={handleFocus}
                    onChange={onChangeInput}
                    handleKeypress={onKeyPress}
                />
            </div>
            <div className="rows d-flex">
                <div className="first-field">
                    <DropdownPopper
                        ref={refDropdownCodeByCountry}
                        wrapperClassName="v2-dropdown --area-code"
                        wrapperListClass="v2-dropdown__menu scrolls"
                        buttonClassName="dropbtn items"
                        isUseToggle
                        isCalculateWidth
                        customStyle={{ width: 'auto', maxWidth: 'auto' }}
                        customButton={_renderButtonDropdownCodeByCountry()}
                    >
                        {_renderListCodeByCountry()}
                    </DropdownPopper>
                </div>
                <div className="second-field flex-1">
                    <Input
                        placeholder="000-000-0000"
                        type="text"
                        name="company_phone"
                        classWrapper="user-box rows"
                        value={finalPhone}
                        error={error?.company_phone}
                        maxLength={10}
                        isShowIconError={false}
                        onFocus={handleFocus}
                        onChange={_onChangePhone}
                        handleKeypress={onKeyPress}
                    />
                </div>
            </div>
            <div className="rows">
                <DropdownCountry
                    isFetchedCountry={isFetchedCountry}
                    country={finalCountry}
                    onSelect={_handleSelectCountry}
                />
            </div>
        </Fragment>
    );
};

const DropdownCountry = ({ country = LIST_COUNTRY[0], error = {}, onSelect = () => {} }) => {
    const { t } = useTranslation('auth');
    const refTrigger = useRef(null);
    const refDropdownCountry = useRef(null);

    const handleSelect = (country) => {
        onSelect(country);
        refDropdownCountry.current?._close();
    };

    return (
        <DropdownPopper
            ref={refDropdownCountry}
            wrapperClassName="v2-dropdown"
            wrapperListClass="v2-dropdown__menu content-search flex-column"
            buttonClassName="dropbtn items"
            isUseToggle
            isCalculateWidth
            customButton={
                <Fragment>
                    <p className="txt-ellipsis">{country.name || t('choose_your_country')}</p>
                    <div className="arrow">
                        <IconArrowDown />
                    </div>
                </Fragment>
            }
            error={error?.country}
            isShowIconError={false}
            onOpen={() => refTrigger.current?.focusTrigger()}
        >
            <ListCountries ref={refTrigger} activeCountry={country} onSelect={handleSelect} />
        </DropdownPopper>
    );
};

const sortedCountries = () => {
    const priorityCountries = ['us', 'ca', 'au', 'gb'];
    const sortedCountries = LIST_COUNTRY.sort((a, b) => {
        const aIndex = priorityCountries.indexOf(a.value);
        const bIndex = priorityCountries.indexOf(b.value);
        if (aIndex === -1 && bIndex === -1) return a.name.localeCompare(b.name);
        if (aIndex === -1) return 1;
        if (bIndex === -1) return -1;
        return aIndex - bIndex;
    });
    return sortedCountries;
};

const ListCountries = forwardRef(({ activeCountry = LIST_COUNTRY[0], onSelect = () => {} }, ref) => {
    const { t } = useTranslation('auth');
    const COUNTRIES_SORTED = sortedCountries();
    const [countries, setCountries] = useState(COUNTRIES_SORTED);
    const [focusTrigger, setFocusTrigger] = useState(0);

    useImperativeHandle(ref, () => ({ focusTrigger: () => setFocusTrigger(new Date().getTime()) }));

    const handleSearch = (searchText) => {
        const newCountries = COUNTRIES_SORTED.filter(({ name }) =>
            name.toLowerCase().includes(searchText.toLowerCase())
        );
        setCountries(newCountries);
    };

    return (
        <>
            <SearchOption
                parentStyle="flex-auto header-search"
                isSearchIcon
                autoFocus
                triggerFocus={focusTrigger}
                timeDdebound={0}
                placeholder={t('search_industry')}
                onSearch={handleSearch}
                onCloseClick={() => setFocusTrigger(new Date().getTime())}
            />
            <div className="drop-container">
                <div className="drop-items">
                    {countries.length ? (
                        <ul className="drop-items__content">
                            {countries.map((country) => {
                                return (
                                    <li
                                        key={country.id}
                                        className={classNames('items --ticked', {
                                            active: activeCountry.id === country.id
                                        })}
                                        onClick={() => onSelect(country)}
                                    >
                                        <IconCheck />
                                        <span className="txt-ellipsis">{country.name}</span>
                                    </li>
                                );
                            })}
                        </ul>
                    ) : null}
                </div>
            </div>
        </>
    );
});

export default TrialStepTwo;
