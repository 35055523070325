import React, { useEffect, useReducer } from 'react';

import { ADDONS_GPS_TRACKING_DETAIL, UPDATE_ADDONS_GPS_TRACKING_OPTIONS } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import AddonHeaderDetail from '../components/AddonHeaderDetail';
import AddonsOptions from '../components/AddonsOptions';
import GpsTrackingAddonsContent from './components/GpsTrackingAddonsContent';
import GpsTrackingAddonsLoading from './components/GpsTrackingAddonsLoading';

const AddonsGpsTracking = () => {
    const [state, dispatchState] = useReducer(reducer, {
        gpsTracking: {},
        isLoading: true
    });
    const { isLoading, gpsTracking } = state;

    useEffect(() => {
        _getDetailGpsTracking();
    }, []);

    const _getDetailGpsTracking = () => {
        clientQuery(ADDONS_GPS_TRACKING_DETAIL, { method: 'GET' }, _handleGetSuccess, checkAccessFail);
    };

    const _handleGetSuccess = ({ data }) => {
        dispatchState({ gpsTracking: data, isLoading: false });
    };

    return (
        <div className="addons-wrap__container">
            <AddonHeaderDetail isLoading={isLoading} tutorial={gpsTracking.tutorial} />
            <div className="scrolls">
                <div className="boxs-wrapper">
                    {isLoading ? (
                        <GpsTrackingAddonsLoading />
                    ) : (
                        <>
                            <GpsTrackingAddonsContent {...gpsTracking} />
                            <AddonsOptions data={gpsTracking?.options} urlUpdate={UPDATE_ADDONS_GPS_TRACKING_OPTIONS} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddonsGpsTracking;
