import classNames from 'classnames';
import {
    AtomicBlockUtils,
    ContentState,
    convertFromHTML as convertFromHTMLDraft,
    Editor,
    EditorState,
    Modifier,
    RichUtils,
    SelectionState
} from 'draft-js';
import React, { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Link } from 'react-router-dom';

import { ADDONS_SMS } from 'app/config/routes';
import { ACCESS_TOKEN } from 'app/const/App';
import { ENTER_KEY_CODE } from 'app/const/Keyboard';
import { detectKeyPress } from 'common/utils/FunctionUtils';
import ActionToolbar from './components/ActionToolbar';
import BlockAttachment from './components/BlockAttachment';
import RichTextDropzone from './components/RichTextDropzone';
import { TYPE_FUNCTION_RICHTEXT } from './const';
import {
    checkLink,
    checkProperties,
    compositeDecorator,
    customBlockRender,
    handleConvertFromHTML,
    handleConvertToHTML,
    isCtrlKeyCommand
} from './utils';
import { addBranchPath } from 'app/const/Branch';
import GDAttachments from '../attachments';

const GdRichtext = (
    {
        placeholder = '',
        wrapperClass = '',
        valueRestore = '',
        defaultContent = '',
        typePresign = 'item',
        itemIdPresign = '',
        isDisable = false,
        isDisableSMS = false,
        shouldGetFocus = false,
        haveAttachmentBlock = false,
        attachmentProps = {},
        toolbarConfig = {},
        onChange = () => {},
        onFocus = () => {},
        onBlur = () => {},
        onGetFocus = () => {},
        onInsertImgSuccess = () => {},
        onInsertVariable = () => {},
        onTriggerUploadImage = () => {},
        onUploadAttachmentSuccess = () => {},
        onRemoveAttachment = () => {},
        onRestore = () => {},
        isShowAction = true,
        isLink = true,
        isBreakLine = false,
        spellCheck = true,
        textDrag,
        textDrop,
        isBlank = false,
        maxLength = -1,
        isShowListAttachment = false,
        defaultAttachments = []
    },
    ref
) => {
    let dragTimer;
    const decorators = isLink ? compositeDecorator(toolbarConfig) : null;
    // https://github.com/facebookarchive/draft-js/issues/1198#issuecomment-1016201721
    const [isReadonly, setIsReadonly] = useState(true);
    const [editorState, setEditorState] = useState(() => {
        if (isBreakLine) {
            return EditorState.createWithContent(
                handleConvertFromHTML(defaultContent.replaceAll('\n', '<br />')),
                decorators
            );
        }
        return defaultContent
            ? EditorState.createWithContent(handleConvertFromHTML(defaultContent), decorators)
            : EditorState.moveFocusToEnd(EditorState.createEmpty(decorators));
    });

    const refEditor = useRef(null);
    const refState = useRef(null);
    const refTextArea = useRef(null);
    const refStoreState = useRef(editorState);
    const refPlaintext = useRef(false);
    const refActionToolbar = useRef(false);
    const refWrapBoxEditor = useRef(null);
    const refCurrentCodeView = useRef(false);
    const refBlockAttachment = useRef(null);
    const refDropWarning = useRef(null);
    const refDiv = useRef(null);
    const isDraggingFile = useRef(false);
    const refStoreAttachments = useRef([]);
    const refImagesUploading = useRef([]);
    const refAttachments = useRef(null);

    useImperativeHandle(ref, () => ({
        getValue: _handleGetHTML,
        getValueWithAttach: () => {
            const value = { content: _handleGetHTML() };
            if (haveAttachmentBlock) value['attachments'] = refBlockAttachment.current.getValue();
            value['images'] = refStoreAttachments.current;
            return value;
        },
        getNotify: () => {
            if (haveAttachmentBlock) return refBlockAttachment.current.getNotify();
            return [];
        },
        clearAttachments: () => {
            if (haveAttachmentBlock) return refBlockAttachment.current.clearAttachments();
        },
        clearNotify: () => {
            if (haveAttachmentBlock) return refBlockAttachment.current.clearNotify();
        },
        shouldUpdateAttach: () => refBlockAttachment.current.shouldUpdate(),
        setValue: (value, isPlainText = false) => {
            if (isPlainText !== isReadonly) setIsReadonly(isPlainText);
            _handleChange(_handleConvertContentToEditorState(value));
        },
        isEmpty: () => !editorState.getCurrentContent().hasText(),
        focus: () => refEditor.current.focus(),
        scrollTo: (data) => refWrapBoxEditor.current.scrollIntoView(data),
        getWordCount: () => {
            const plainText = editorState.getCurrentContent().getPlainText('');
            const regex = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed
            const cleanString = plainText.replace(regex, ' '); // replace above characters w/ space
            return cleanString ? cleanString.length : 0;
        },
        plainText: _handlePlaintext,
        getUploadingImages: () => refImagesUploading.current,
        setAttachments: _handleSetAttachment,
        getAttachments: () => refAttachments.current?.getAttachments(),
        setUploadLoading: (isLoading) => refActionToolbar.current.setIsUploading(isLoading)
    }));

    useEffect(() => {
        if (!haveAttachmentBlock && !checkProperties(toolbarConfig, ['image'])) return;
        document.addEventListener('dragstart', _handleSetRef);
        document.addEventListener('dragover', _handleDragOver);
        document.addEventListener('dragleave', _handleDragEnd);
        document.addEventListener('drop', _handleDropDropzone);
        document.addEventListener('dragend', _handleDragEnd);

        return () => {
            document.removeEventListener('dragover', _handleDragOver);
            document.removeEventListener('dragleave', _handleDragEnd);
            document.removeEventListener('drop', _handleDropDropzone);
            document.removeEventListener('dragstart', _handleSetRef);
            document.removeEventListener('dragend', _handleDragEnd);
        };
    }, []);

    useLayoutEffect(() => {
        if (((placeholder && !editorState?.getCurrentContent()?.hasText()) || false) && refWrapBoxEditor.current) {
            const div = refWrapBoxEditor.current.querySelector('.public-DraftEditor-content');
            if (!div) return;
            const placeholderDiv = refWrapBoxEditor.current.querySelector('.public-DraftEditorPlaceholder-root');
            if (!placeholderDiv) return;
            const style = window.getComputedStyle(div);
            const paddingLeft = style.getPropertyValue('padding-left');
            const paddingTop = style.getPropertyValue('padding-top');
            if (placeholderDiv) {
                placeholderDiv.style.top = paddingTop;
                placeholderDiv.style.left = paddingLeft;
            }
        }
    }, [editorState]);

    useEffect(() => {
        setIsReadonly(false);
    }, []);

    const _handleSetRef = (e) => {
        isDraggingFile.current = e.dataTransfer.types.includes('Files');
    };

    const _handleDropDropzone = (e) => {
        if (refDiv.current && refDiv.current.contains(e.target)) {
            refDropWarning.current && refDropWarning.current.handleDrop(e);
        }
        refDropWarning.current && refDropWarning.current.hide();
        isDraggingFile.current = false;
    };

    // Handle drag image over window
    const _handleDragOver = (e) => {
        e.preventDefault();
        if (!isDraggingFile.current) {
            const types = e.dataTransfer.types;
            isDraggingFile.current = types && Array.from(types).some((type) => type.startsWith('Files'));
        }
        if (refDropWarning.current) refDropWarning.current.handleDisplay();
        refDropWarning.current.handleSetDragEnter(refDiv.current && refDiv.current.contains(e.target));
        window.clearTimeout(dragTimer);
    };

    const _handleDragEnd = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dragTimer = window.setTimeout(() => {
            if (refDropWarning.current) refDropWarning.current.handleHidden();
        }, 25);
    };

    // Handle drop image over editor
    const _handleDrop = (attachments) => {
        if (typeof attachments === 'string') {
            _handleInsertImage(attachments);
        } else {
            if (refBlockAttachment.current) {
                refBlockAttachment.current.uploadAttachments(attachments);
            } else {
                _handlePastedFiles(attachments);
            }
        }
    };

    /**
     * Returns the HTML of the editor
     * @returns The function handleConvertToHTML is being returned.
     */
    const _handleGetHTML = (value = null) => {
        const valueConvert =
            value ||
            (refPlaintext.current ? refStoreState.current.getCurrentContent() : editorState.getCurrentContent());

        if (refCurrentCodeView.current) {
            const editorStateFromTextarea = _handleConvertContentToEditorState(refTextArea.current.value);
            return handleConvertToHTML(editorStateFromTextarea.getCurrentContent());
        }
        const REG_HEX = /&#x([a-fA-F0-9]+);/g;
        return handleConvertToHTML(valueConvert).replace(REG_HEX, (match, group1) => {
            const num = parseInt(group1, 16);
            return String.fromCharCode(num);
        });
    };

    const _handleConvertContentToEditorState = (content) => {
        if (!content) return EditorState.createEmpty(decorators);
        return EditorState.push(editorState, handleConvertFromHTML(content.replaceAll('\n', '<br />')));
    };

    /**
     * It takes a URL, creates a link entity, and then toggles the link on the selected text
     * @returns the value of the function _handleChange.
     */
    const _handleInsertLink = ({ url, text, isBlank }) => {
        const selection = editorState.getSelection();

        if (selection.isCollapsed()) {
            const selection = editorState.getSelection();
            const contentState = editorState.getCurrentContent();
            const textWithSpace = text.concat(' ');
            const newContent = Modifier.insertText(contentState, selection, textWithSpace);
            const newContentWithEntity = newContent.createEntity('LINK', 'MUTABLE', { url, text, isBlank }, false);
            const entityKey = newContentWithEntity.getLastCreatedEntityKey();
            const anchorOffset = selection.getAnchorOffset();
            const newSelection = new SelectionState({
                anchorKey: selection.getAnchorKey(),
                anchorOffset,
                focusKey: selection.getAnchorKey(),
                focusOffset: anchorOffset + text.length
            });
            const newContentWithLink = Modifier.applyEntity(newContentWithEntity, newSelection, entityKey);
            const withLinkText = EditorState.push(editorState, newContentWithLink, 'insert-characters');
            const withProperCursor = EditorState.forceSelection(withLinkText, newContent.getSelectionAfter());
            _handleChange(withProperCursor);
        } else {
            const contentState = editorState.getCurrentContent();
            const selectionState = editorState.getSelection();
            const entity = contentState.createEntity('LINK', 'MUTABLE', { url, text, isBlank }, false);
            const entityKey = entity.getLastCreatedEntityKey();

            const newContentState = Modifier.replaceText(
                contentState,
                selectionState,
                text,
                editorState.getCurrentInlineStyle(),
                entityKey
            );
            const updatedEditorState = EditorState.push(editorState, newContentState);
            _handleChange(updatedEditorState);
            onBlur(handleConvertToHTML(updatedEditorState.getCurrentContent()));
        }
    };

    /**
     * We create a new entity with the type 'IMAGE' and the mutability 'IMMUTABLE' and pass in the url
     * as the data. We then create a new editor state with the new entity and insert the entity into
     * the editor state
     * @param url - The url of the image to be inserted
     * @param fileInfo - The file info of the image to be inserted
     */
    const _handleInsertImage = (url, fileInfo) => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity('image', 'MUTABLE', {
            src: url,
            fileInfo,
            isShouldUpload: !!fileInfo,
            isLoadingUpload: !!fileInfo
        });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
        _handleChange(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '));
        refImagesUploading.current = [...refImagesUploading.current, entityKey];
        onTriggerUploadImage(refImagesUploading.current);
        if (fileInfo && refActionToolbar.current) refActionToolbar.current.setImageLoading(entityKey, 'ADD');
    };

    const _handleToggleCodeView = (active) => {
        refCurrentCodeView.current = active;
        refWrapBoxEditor.current.classList.toggle('has-codeview', active);

        if (active) {
            refTextArea.current.value = handleConvertToHTML(editorState.getCurrentContent());
            refTextArea.current.style.display = 'block';
        } else {
            refTextArea.current.style.display = 'none';
            _handleChange(_handleConvertContentToEditorState(refTextArea.current.value));
        }
    };

    /**
     * If the command is handled, update the editor state and return 'handled'. Otherwise, return
     * 'not-handled'
     * @param command - The command to execute.
     * @param editorState - The current editor state.
     * @returns The return value is a string that indicates whether the key command was handled or not.
     */
    const _handleKeyCommand = (command, editorState) => {
        if (command === 'insert-horizontal-rule') {
            return 'handled';
        } else if (command === 'split-block' && isCtrlKeyCommand()) {
            const contentState = editorState.getCurrentContent();
            const contentStateWithEntity = contentState.createEntity('HORIZONTAL_RULE', 'MUTABLE', {
                type: 'HORIZONTAL_RULE'
            });
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
            _handleChange(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '));
            return 'handled';
        } else {
            const newState = RichUtils.handleKeyCommand(editorState, command);
            if (newState) {
                _handleChange(newState);
                return 'handled';
            }
            return 'not-handled';
        }
    };

    /**
     * It takes a string, gets the current content and selection of the editor, replaces the current
     * selection with the string, and then pushes the new content to the editor
     * @param string - The string to insert.
     */
    const _handleInsertText = (string) => {
        const currentContent = editorState.getCurrentContent(),
            currentSelection = editorState.getSelection();
        const newContent = Modifier.replaceText(currentContent, currentSelection, string);
        const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
        _handleChange(EditorState.forceSelection(newEditorState, newContent.getSelectionAfter()));
    };

    /**
     * It inserts a string into the editor at the current cursor position
     * @param string - The string to insert into the editor.
     * @returns A function that takes a string as an argument.
     */
    const _handleInsertVariable = (string) => {
        if (shouldGetFocus && onGetFocus()) {
            onInsertVariable(string);
        } else {
            const currentContent = editorState.getCurrentContent(),
                currentSelection = editorState.getSelection();
            const newContent = Modifier.replaceText(currentContent, currentSelection, string);
            const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
            _handleChange(EditorState.forceSelection(newEditorState, newContent.getSelectionAfter()));
        }
    };

    /**
     * Takes a string of HTML as an argument, converts it to a
     * DraftJS ContentState object, and then passes that ContentState object to _handleChange() which
     * updates the EditorState
     * @param contentRestore - The content to restore.
     */
    const _handleRestore = (contentRestore) => {
        const blocksFromHTML = convertFromHTMLDraft(contentRestore ?? valueRestore ?? defaultContent);
        const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
        _handleChange(EditorState.createWithContent(state, decorators));
        isShowListAttachment && _handleSetAttachment(defaultAttachments);
        onRestore();
    };

    const _handleRegexText = (html) => {
        const hasLinkConfig = checkProperties(toolbarConfig, 'link');
        const regex = isLink
            ? /<\/?(?!b|br|li|ul|ol|i|p|strong|div|a|h[1-6])\w*\b[^>]*>/gi
            : /<\/?(?!b|br|li|ul|ol|i|p|strong|div|h[1-6])\w*\b[^>]*>/gi;
        html = html.replaceAll(regex, '');
        html = html.replaceAll(/<br class="Apple-interchange-newline">/gi, '');
        html = html.replaceAll(/style="[^"]*"/gi, '');
        html = html.replaceAll(/class="[^"]*"/gi, '');
        html = html.replaceAll(/<img.*?>/gi, '');
        html = html.replaceAll(/<button.*?>/gi, '');
        hasLinkConfig
            ? (html = html.replaceAll(/<a.*?(href=\x22.*?\x22).*?>/g, '<a $1>'))
            : (html = html.replaceAll(/<(\w+)\s+[^>]*>/g, (match, tagName) => `<${tagName}>`));
        html = html.replaceAll(/<h[1-6].*?>/gi, '<p>');
        html = html.replaceAll(/<\/h[1-6]>/gi, '</p>');
        return html;
    };

    /**
     * It takes the pasted text and html, removes all the html tags and attributes, and then inserts
     * the text into the editor.
     * @param text - The text that was pasted.
     * @param html - The HTML string that was pasted.
     * @returns a boolean value.
     */
    const _handlePastedText = (text, html) => {
        if (html) {
            const valueToHtml = handleConvertFromHTML(_handleRegexText(html)).getBlockMap();

            const newState = Modifier.replaceWithFragment(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                valueToHtml
            );

            _handleChange(EditorState.push(editorState, newState, 'insert-fragment'));
            return true;
        }

        if (checkLink(text)) {
            _handleInsertLink({ text, url: text, isBlank });
            return true;
        }
        return false;
    };

    const _handlePastedFiles = (files) => {
        const file = files[0];

        if (file.type.indexOf('image/') === 0) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                _handleInsertImage(reader.result, file);
            };
            return true;
        }
        return false;
    };

    const _handlePlaintext = (isPlaintext = false) => {
        refPlaintext.current = !isPlaintext;
        setIsReadonly(!isPlaintext);

        if (!isPlaintext && refCurrentCodeView.current) {
            _handleToggleCodeView(false);
            refActionToolbar.current?.resetActions();
        }

        if (isPlaintext) {
            _handleChange(refStoreState.current);
            return;
        }

        refStoreState.current = editorState;
        const inputText = handleConvertToHTML(editorState.getCurrentContent());
        let returnText = '' + inputText;

        //-- remove BR tags and replace them with line break
        returnText = returnText.replace(/<br>/gi, '\n');
        returnText = returnText.replace(/<br\s\/>/gi, '\n');
        returnText = returnText.replace(/<br\/>/gi, '\n');

        //-- remove P and A tags but preserve what's inside of them
        returnText = returnText.replace(/<p>/gi, '\n');
        returnText = returnText.replace(/<a.*href="(.*?)".*>(.*?)<\/a>/gi, '[$2]($1)');

        //-- remove all inside SCRIPT and STYLE tags
        returnText = returnText.replace(/<script.*>[\w\W]{1,}(.*?)[\w\W]{1,}<\/script>/gi, '');
        returnText = returnText.replace(/<style.*>[\w\W]{1,}(.*?)[\w\W]{1,}<\/style>/gi, '');

        //-- remove all else
        returnText = returnText.replace(/<(?:.|\s)*?>/g, '');

        //-- get rid of more than 2 multiple line breaks:
        returnText = returnText.replace(/(?:(?:\r\n|\r|\n)\s*){2,}/gim, '\n\n');
        returnText = returnText.replace(/\n/g, '<br />');

        //-- get rid of more than 2 spaces:
        returnText = returnText.replace(/ +(?= )/g, '');

        const blocksFromHTML = convertFromHTMLDraft(returnText);
        const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
        _handleChange(EditorState.createWithContent(state));
    };

    const _handleToggleInlineStyle = (event, style) => {
        event.preventDefault();
        _handleChange(RichUtils.toggleInlineStyle(editorState, style));
    };

    const _handleToggleList = (event, type) => {
        event.preventDefault();
        _handleChange(RichUtils.toggleBlockType(editorState, type));
    };

    /**
     * It returns a function based on the type of function passed to it.
     * @param type - The type of the function, which is used to determine the function to be executed.
     * @returns A function
     */
    const _handleGetAction = (type) => {
        switch (type) {
            case TYPE_FUNCTION_RICHTEXT.EMOJI:
                return _handleInsertText;
            case TYPE_FUNCTION_RICHTEXT.VARIABLES:
                return _handleInsertVariable;
            case TYPE_FUNCTION_RICHTEXT.BOLD:
            case TYPE_FUNCTION_RICHTEXT.ITALIC:
            case TYPE_FUNCTION_RICHTEXT.UNDERLINE:
                return _handleToggleInlineStyle;
            case TYPE_FUNCTION_RICHTEXT.OL:
            case TYPE_FUNCTION_RICHTEXT.UL:
                return _handleToggleList;
            case TYPE_FUNCTION_RICHTEXT.LINK:
                return _handleInsertLink;
            case TYPE_FUNCTION_RICHTEXT.IMAGE:
                return _handleInsertImage;
            case TYPE_FUNCTION_RICHTEXT.RESTORE:
                return _handleRestore;
            case TYPE_FUNCTION_RICHTEXT.CODE:
                return _handleToggleCodeView;
            case TYPE_FUNCTION_RICHTEXT.ATTACHMENT:
                return _handleAddAttachment;
            default:
                break;
        }
    };

    const _handleChange = (newState) => {
        if (isDisable || isDisableSMS) return;
        refState.current = newState;
        const currentContent = newState.getCurrentContent();
        const lengthInput = currentContent.getPlainText('').length;

        if (maxLength > -1 && lengthInput > maxLength) return;
        setEditorState(newState);

        onChange(_handleGetHTML(currentContent));
    };

    const _handleResizeImage = ({ entity, data }) => {
        const newEditorState = refState.current;
        const selectionState = newEditorState.getSelection();
        newEditorState.getCurrentContent().replaceEntityData(entity, data);
        _handleChange(EditorState.forceSelection(newEditorState, selectionState));
    };

    /**
     * Take in a dataImage parameter and pushes it to the
     * refStoreAttachments array
     * @param dataEntity - Entity to replace
     * @param dataImage - object_key, object_tag
     */
    const _handleInsertImageSuccess = (dataEntity = null, dataImage) => {
        if (dataEntity) {
            const newEditorState = refState.current;
            const selectionState = newEditorState.getSelection();
            newEditorState.getCurrentContent().replaceEntityData(dataEntity.entity, dataEntity.data);
            _handleChange(EditorState.forceSelection(newEditorState, selectionState));

            refImagesUploading.current = refImagesUploading.current.filter((item) => item !== dataEntity.entity);
            onTriggerUploadImage(refImagesUploading.current);
            onInsertImgSuccess(dataImage);
            refActionToolbar.current && refActionToolbar.current.setImageLoading(dataEntity.entity, 'REMOVE');
        }
        refStoreAttachments.current.push(dataImage);
    };

    const _handleBlur = () => {
        onBlur(handleConvertToHTML(editorState.getCurrentContent()));
    };

    const _customBlockRender = (contentBlock) => {
        return customBlockRender({
            contentBlock,
            typePresign,
            itemIdPresign,
            onUploadSuccess: _handleInsertImageSuccess,
            onResize: _handleResizeImage
        });
    };

    const _handleSetUploading = (isUploading = false) => {
        refActionToolbar.current && refActionToolbar.current.setIsUploading(isUploading);
    };

    const _handleFocusEditor = (e) => {
        const btnSave = document.getElementById('rich-text-btn-save');
        if (btnSave) btnSave.tabIndex = '0';
        onFocus(e);
    };

    const _handleBeforeInput = (chars, editorState) => {
        const currentContentState = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();
        const inlineStyle = editorState.getCurrentInlineStyle();
        _handleChange(
            EditorState.push(editorState, Modifier.replaceText(currentContentState, selectionState, chars, inlineStyle))
        );
        return 'handled';
    };

    const _handleError = (dataError, infoError) => {
        if (dataError.name === 'ChunkLoadError') return;
        // eslint-disable-next-line no-undef
        if (process.env.REACT_APP_ENV === 'beta') {
            if (dataError.name === 'ChunkLoadError') return;
            // eslint-disable-next-line no-undef
            fetch(process.env.REACT_APP_URL_WEB_HOOK_SLACK, {
                method: 'POST',
                body: JSON.stringify({
                    type: 'crash',
                    text: JSON.stringify({
                        token: window.localStorage.getItem(ACCESS_TOKEN),
                        message: dataError.message,
                        path_name: window?.location?.pathname,
                        content_default: defaultContent,
                        stack_component: infoError?.componentStack
                    })
                }),
                headers: { Accept: 'application/json' }
            });
        }
    };

    function _handleReturn(e, editorState) {
        if (detectKeyPress(e, ENTER_KEY_CODE) && !isCtrlKeyCommand()) {
            const contentState = editorState.getCurrentContent();
            const selectionState = editorState.getSelection();
            const currentBlock = contentState.getBlockForKey(selectionState.getStartKey());
            const currentBlockText = currentBlock.getText();
            const blockLength = currentBlock.getLength();
            const blockType = currentBlock.getType();
            const blockDepth = currentBlock.getDepth();
            currentBlock.merge({
                text: currentBlockText.slice(selectionState.getStartOffset(), blockLength),
                type: blockType,
                depth: blockDepth
            });
            const newContentState = Modifier.splitBlock(contentState, selectionState);
            const newEditorState = EditorState.push(editorState, newContentState, 'split-block');
            _handleChange(newEditorState);
            return 'handled';
        }
        return 'not-handled';
    }

    const _handleAddAttachment = (attachments) => {
        refAttachments.current?.addAttachment(attachments);
    };

    const _handleSetAttachment = (attachments = []) => {
        refAttachments.current?.setAttachments(attachments);
    };

    return (
        <ErrorBoundary
            fallbackRender={() => (
                <div className="wrap-content">
                    <div className="wrap-content__notify">Something went wrong!. Please reload the page.</div>
                </div>
            )}
            onError={_handleError}
        >
            <div ref={refDiv} className={classNames(wrapperClass, { 'wrap-content': !wrapperClass })}>
                <div
                    ref={refWrapBoxEditor}
                    className={classNames('wrapbox-editor', { 'is-enable-sms': isDisableSMS || isReadonly })}
                >
                    <div className="wrapbox-editor__form">
                        {isShowListAttachment ? (
                            <div className="wrapbox-editor__form-attachment">
                                <div className="file-attachment scrolls-x">
                                    <div className="file-attachment-conts">
                                        <GDAttachments
                                            ref={refAttachments}
                                            itemId={Date.now()}
                                            type="sms"
                                            onUploadSuccess={onUploadAttachmentSuccess}
                                            onRemove={onRemoveAttachment}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <textarea
                            ref={refTextArea}
                            className="content-editable field-textarea --no-resize --no-border"
                            style={{ display: 'none' }}
                            spellCheck={spellCheck}
                            onChange={(e) => _handleChange(_handleConvertContentToEditorState(e.target.value))}
                        />
                        <Editor
                            ref={refEditor}
                            stripPastedStyles
                            readOnly={isDisableSMS || isDisable || isReadonly}
                            editorState={editorState}
                            blockRendererFn={_customBlockRender}
                            handleBeforeInput={_handleBeforeInput}
                            handleKeyCommand={_handleKeyCommand}
                            handlePastedText={_handlePastedText}
                            handlePastedFiles={_handlePastedFiles}
                            handleReturn={_handleReturn}
                            onChange={_handleChange}
                            onBlur={_handleBlur}
                            onFocus={_handleFocusEditor}
                            spellCheck={spellCheck}
                            placeholder={placeholder || ''}
                        />
                    </div>
                    {haveAttachmentBlock && (
                        <BlockAttachment
                            ref={refBlockAttachment}
                            onSetIsUploading={_handleSetUploading}
                            {...(attachmentProps || {})}
                        />
                    )}
                    {isShowAction && (
                        <ActionToolbar
                            ref={refActionToolbar}
                            editorState={editorState}
                            toolbarConfig={toolbarConfig}
                            typePresign={typePresign}
                            itemIdPresign={itemIdPresign}
                            onInsertSuccess={_handleInsertImageSuccess}
                            onGetAction={_handleGetAction}
                        />
                    )}
                </div>

                {isDisableSMS && (
                    <div className="wrap-content__notify">
                        SMS is currently off.
                        <Link to={addBranchPath(ADDONS_SMS)} className="is-link ml-1">
                            Activate a package to enable SMS.
                        </Link>
                    </div>
                )}

                <RichTextDropzone ref={refDropWarning} text={textDrag} textDrop={textDrop} onDrop={_handleDrop} />
            </div>
        </ErrorBoundary>
    );
};

export default forwardRef(GdRichtext);
