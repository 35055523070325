import classNames from 'classnames';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CUSTOMER_SEARCH_LIMIT, DEFAULT_ALL, KEY_CODE_ESCAPE, KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { MATERIALS } from 'app/const/report/ReportFilter';
import SearchOption from 'app/modules/report/filter/SearchOption';
import IconCircleClose from 'assets/icon/IconCircleClose';
import IconClose from 'assets/icon/IconClose';
import IconDropDown from 'assets/icon/IconDropDown';
import IconLoading from 'assets/icon/IconLoading';
import { getListCustomerReport } from 'common/redux/actions/customers/customerAction';
import { getListMaterial } from 'common/redux/actions/reports/materialUseAction';
import { isScrollToEndBottom } from 'common/utils/FunctionUtils';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';

const MultiSelectClients = ({ name, selected, title, onSelect, reportType, classWrapper = '' }) => {
    const { t } = useTranslation(['report', 'header', 'common']);
    const { list_customer, total_customer } = useSelector((store) => store.CustomerReport);
    const dispatch = useDispatch();
    const listRef = useRef(null);
    const refDropDown = useRef(null);
    const KEY_FILTER = KEY_REPORT_LOCAL_STORAGE.concat('_', reportType, '_', name);

    const setStorage = (selectedOptions = []) => {
        setLocalStorage(KEY_FILTER, selectedOptions);
    };

    const initState = {
        isOpen: false,
        isLoading: true,
        options: [],
        keyword: '',
        totalSearch: 0,
        selected: getLocalStorage(KEY_FILTER) || selected
    };

    const [state, dispatchState] = useReducer(reducer, { ...initState });
    const { options: listOptions, selected: listOptionsSelected = [], isLoading, isOpen, keyword, totalSearch } = state;
    const isSelectAll = listOptionsSelected === DEFAULT_ALL;
    const listOptionIds = isSelectAll ? [] : listOptionsSelected.map((item) => item.id);
    const isFirstTime = useRef(true);
    const SELECTED_LIMIT_OPTION = 3;
    const refOptions = useRef([]);
    const typeMaterial = name === MATERIALS;

    const dropdownId = `multi_select_${name}`;
    const paramsSearch = {
        limit: CUSTOMER_SEARCH_LIMIT,
        offset: 0,
        total: 1,
        localSaveStore: true,
        localResetData: true
    };

    useEffect(() => {
        if (isOpen) {
            isFirstTime.current && _getListOption({ ...paramsSearch });
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [isOpen]);

    const handleHideDropdown = (event) => {
        const elPrevent = document.getElementById(dropdownId);
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            dispatchState((prev) => ({ ...prev, isOpen: false }));
        }
    };

    const handleClickOutside = (event) => {
        const elPrevent = document.getElementById(dropdownId);
        if (
            refDropDown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropDown.current.contains(event.target)
        ) {
            dispatchState((prev) => ({ ...prev, isOpen: false }));
        }
    };

    function _getListOption(params) {
        const _handleFail = () => {
            refOptions.current = [];
            dispatchState((prev) => ({ ...prev, options: [], isLoading: false }));
        };
        const functionAPI = typeMaterial ? getListMaterial : getListCustomerReport;
        dispatch(functionAPI(typeMaterial ? {} : params, (response) => _handleSuccess(response, params), _handleFail));
    }

    function _handleSuccess(response, params) {
        isFirstTime.current = false;
        const { data = [], total = 0 } = response || {};
        refOptions.current = data;
        dispatchState((prev) => ({
            ...prev,
            isLoading: false,
            options: !params.localResetData && !typeMaterial ? [...listOptions, ...data] : data,
            totalSearch: keyword && params.total === 1 ? total : totalSearch
        }));
    }

    const toggleOpen = () => {
        dispatchState((prev) => ({ ...prev, isOpen: !isOpen }));
    };

    const createListOptions = ({ selectedOption = {}, currentList = [], totalLength = 0 }) => {
        let listOptions = [];
        if (Array.isArray(currentList)) {
            listOptions = currentList.filter((item) => item.id !== selectedOption.id);
        }
        if (currentList.length === listOptions.length || currentList === DEFAULT_ALL) listOptions.push(selectedOption);
        if (listOptions.length === totalLength) return DEFAULT_ALL;
        return listOptions;
    };

    const handleSelect = (e, value) => {
        e && e.stopPropagation();
        const selectedList = createListOptions({
            selectedOption: value,
            currentList: listOptionsSelected,
            totalLength: typeMaterial ? refOptions.current.length : total_customer
        });
        setStorage(selectedList);
        dispatchState((prev) => ({ ...prev, selected: selectedList }));
        onSelect(name, Array.isArray(selectedList) ? selectedList.map((item) => item.id).toString() : DEFAULT_ALL);
    };

    const handleSelectAll = (e) => {
        e && e.stopPropagation();
        const value = listOptionsSelected === DEFAULT_ALL ? [] : DEFAULT_ALL;
        setStorage(value);
        dispatchState((prev) => ({ ...prev, selected: value }));
        onSelect(name, value.toString());
    };

    const handleClearAll = (e) => {
        e && e.stopPropagation();
        setStorage('');
        dispatchState((prev) => ({ ...prev, selected: [] }));
        onSelect(name, '');
    };

    function _handleChangeSearch(keyword) {
        if (keyword === '') {
            dispatchState((prev) => ({
                ...prev,
                isLoading: false,
                options: typeMaterial ? refOptions.current : list_customer,
                keyword
            }));
            listRef.current.scrollTo(0, 0);
        } else {
            dispatchState((prev) => ({
                ...prev,
                isLoading: !typeMaterial,
                options: typeMaterial
                    ? refOptions.current.filter((item) => item.name?.toLowerCase().includes(keyword.toLowerCase()))
                    : [],
                keyword
            }));
            if (typeMaterial) return;
            const params = { ...paramsSearch, localSaveStore: false, keyword };
            _getListOption(params);
        }
    }

    function handleOnScrollContent(e) {
        const listOptionsLength = listOptions.length;
        const isSearching = keyword !== '';
        const finalTotalCompare = isSearching ? totalSearch : total_customer;

        if (!typeMaterial && !isLoading && isScrollToEndBottom(e.target) && listOptionsLength < finalTotalCompare) {
            dispatchState((prev) => ({ ...prev, isLoading: true }));
            const params = {
                ...paramsSearch,
                offset: listOptionsLength,
                localSaveStore: !isSearching,
                localResetData: false,
                keyword: keyword
            };
            _getListOption(params);
        }
    }

    const createName = (client) => {
        return `${client.first_name} ${client.last_name}`;
    };

    const renderList = (list) => {
        return list.map((item) => {
            const { id, name = '', avatar = '' } = item || {};
            const isSelected = isSelectAll || listOptionIds.includes(id);
            return (
                <li
                    key={id}
                    className={classNames('items', { active: isSelected })}
                    onClick={(e) => handleSelect(e, item)}
                >
                    {typeMaterial ? (
                        <span className="txt-ellipsis">{name}</span>
                    ) : (
                        <div className="user-name">
                            <div className="avt fs-11 fw-500">{avatar}</div>
                            <span className="txt-ellipsis">{createName(item)}</span>
                        </div>
                    )}
                </li>
            );
        });
    };

    const renderTitle = () => {
        if (isSelectAll || !listOptionsSelected?.length)
            return (
                <p className="budget --grey ml-1">
                    <span className="txt-ellipsis m-0">{title}</span>
                    <span onClick={(e) => isSelectAll && handleClearAll(e)}>
                        <IconClose isSmall />
                    </span>
                </p>
            );

        return listOptionsSelected.map((item, index) => {
            const { id, name = '' } = item;
            if (index < SELECTED_LIMIT_OPTION) {
                return (
                    <p key={id} className="budget --grey ml-1">
                        <span className="txt-ellipsis m-0">{typeMaterial ? name : createName(item)}</span>
                        <span onClick={(e) => handleSelect(e, item)}>
                            <IconClose isSmall />
                        </span>
                    </p>
                );
            }
            return false;
        });
    };

    return (
        <div ref={refDropDown} id={dropdownId} className={classNames('v2-dropdown', classWrapper, { active: isOpen })}>
            <div className="dropbtn v2-btn-default selection" onClick={toggleOpen}>
                <span className="txt">{t(`report:${name}`)}</span>
                {renderTitle()}
                {listOptionsSelected.length > SELECTED_LIMIT_OPTION && (
                    <span className="budget --grey ml-1">
                        <span>+{listOptionsSelected.length - SELECTED_LIMIT_OPTION}</span>
                    </span>
                )}
                {listOptionIds.length || isSelectAll ? (
                    <div onClick={(e) => handleClearAll(e)}>
                        <IconCircleClose />
                    </div>
                ) : null}
                <span className="svg-selectbox">
                    <IconDropDown />
                </span>
            </div>

            <div className="v2-dropdown__menu content-search content-user">
                <div className="container-column">
                    <SearchOption
                        placeholder={t('header:search')}
                        onSearch={_handleChangeSearch}
                        defaultValue={''}
                        style="field-search"
                        isSearchIcon
                    />
                    {keyword === '' && (
                        <div className="content-checked__all">
                            <li
                                className={classNames('items', { active: isSelectAll })}
                                onClick={(e) => handleSelectAll(e)}
                            >
                                <div className="txt-ellipsis">{title || t('report:select_all')}</div>
                            </li>
                        </div>
                    )}
                    <ul ref={listRef} className="box-auto scrolls" onScroll={handleOnScrollContent}>
                        {renderList(listOptions)}
                        {isLoading && (
                            <div className="items justify-center">
                                <div className="loading -ajaxbar">
                                    <IconLoading />
                                </div>
                            </div>
                        )}
                    </ul>
                    {listOptions.length === 0 && !isLoading && (
                        <div className="items justify-center pointer-events-none">
                            <div className="loading -ajaxbar">
                                {keyword
                                    ? t('header:search_not_match')
                                    : t('common:no_data_to_display', { title: t(`report:${name}`) })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MultiSelectClients;
