var userAgent = window.navigator.userAgent;
export const isIOS = userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/Mac/i);

export const DEVICE_MOBILE = {
    WINDOW_PHONE: 'Windows Phone',
    ANDROID: 'android',
    IOS: 'ios'
};

export const LINK_DOWNLOAD_APP = {
    [DEVICE_MOBILE.ANDROID]: 'https://play.google.com/store/apps/details?id=com.gorilladesk.app&pli=1',
    [DEVICE_MOBILE.IOS]: 'https://apps.apple.com/app/gorilladesk/id978627591'
};
