import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import commonUs from './us/common';
import authUs from './us/auth';
import headerUs from './us/header';
import addonUs from './us/addons';
import reportUs from './us/report';
import reviewUs from './us/reviews';
import notificationsUs from './us/notifications';
import commissionsUs from './us/commissions';
import settingUs from './us/settings';
import dripUs from './us/drip';
import customersUs from './us/customers';
import messageUs from './us/message';
import calendarUs from './us/calendar';
import jobdetailUs from './us/jobdetail';
import pageTitleUs from './us/pageTitle';
import smartViewUS from './us/smartview';
import referralUs from './us/referral';
import onboardingUs from './us/onboarding';

i18n.use(LanguageDetector).init({
    resources: {
        'en-US': {
            common: commonUs,
            auth: authUs,
            header: headerUs,
            addons: addonUs,
            report: reportUs,
            notifications: notificationsUs,
            review: reviewUs,
            commission: commissionsUs,
            setting: settingUs,
            drip: dripUs,
            customers: customersUs,
            messages: messageUs,
            calendar: calendarUs,
            pageTitle: pageTitleUs,
            jobDetail: jobdetailUs,
            smartView: smartViewUS,
            referral: referralUs,
            onboarding: onboardingUs
        }
    },
    fallbackLng: 'en-US',
    debug: false,
    ns: [
        'common',
        'auth',
        'header',
        'addons',
        'notifications',
        'report',
        'review',
        'commission',
        'setting',
        'drip',
        'customers',
        'messages',
        'calendar',
        'pageTitle',
        'jobDetail',
        'smartView',
        'referral',
        'onboarding'
    ],
    defaultNS: 'common',

    keySeparator: false,
    interpolation: {
        escapeValue: false,
        formatSeparator: ','
    },

    react: {
        useSuspense: true
    }
});

export default i18n;
