import React, { useEffect, useReducer, useRef } from 'react';

import { ADDONS_MULTI_UNIT_DETAIL, UPDATE_ADDONS_MULTI_UNIT_OPTIONS } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import AddonHeaderDetail from '../components/AddonHeaderDetail';
import AddonsOptions from '../components/AddonsOptions';
import MultiUnitAddonsContent from './components/MultiUnitAddonsContent';
import MultiUnitAddonsLoading from './components/MultiUnitAddonsLoading';

const AddonsMultiUnit = () => {
    const [state, dispatchState] = useReducer(reducer, {
        mdu: {},
        status: false,
        isLoading: true
    });
    const refAlert = useRef(null);
    const { isLoading, mdu } = state;

    useEffect(() => {
        _getDetailMduAddons();
    }, []);

    const _getDetailMduAddons = () => {
        clientQuery(ADDONS_MULTI_UNIT_DETAIL, { method: 'GET' }, _handleGetMduSuccess, checkAccessFail);
    };

    const _handleGetMduSuccess = ({ data }) => {
        dispatchState({ mdu: data, isLoading: false, status: data.status });
    };

    const _handleUpdateSuccess = (isEnable) => {
        dispatchState({ status: isEnable });
    };

    return (
        <div className="addons-wrap__container">
            <AddonHeaderDetail isLoading={isLoading} tutorial={mdu.tutorial} />
            <AlertCustomer ref={refAlert} />
            <div className="scrolls">
                <div className="boxs-wrapper">
                    {isLoading ? (
                        <MultiUnitAddonsLoading />
                    ) : (
                        <>
                            <MultiUnitAddonsContent {...mdu} onHandleUpdateSuccess={_handleUpdateSuccess} />
                            <AddonsOptions
                                data={mdu.options}
                                status={state.status}
                                urlUpdate={UPDATE_ADDONS_MULTI_UNIT_OPTIONS}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddonsMultiUnit;
