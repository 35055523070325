import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import GDStatusBar from 'app/components/status/statusbar';
import { ONBOARDING } from 'app/config/routes';
import { STATUS_ACCOUNT } from 'app/const/App';
import { REDIRECT_MAP, SETTINGS_PLANS_REDIRECT_CODE } from 'app/const/Auth';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { AUTH_LOGIN, CALENDAR } from 'app/const/Route';
import { restoreOptionJobCalendar } from 'app/modules/calendar/ultil/Calendar';
import { mixpanelLogin } from 'app/modules/mixpanel/MixpanelLogin';
import { userLoginSocialRequest } from 'common/redux/actions/authAction';
import { showStatusBar } from 'common/utils/FunctionUtils';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { getPlanUser } from 'common/utils/PermissionUtils';
import TwoStepAuthentication from '../../twoStepAuthentication';

function LoginSocial() {
    const { t } = useTranslation(['auth']);
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { dataUser = {} } = location?.state || {};
    const { id: dataUserId, token: dataUserToken, type: dataUserType } = dataUser || {};

    const [state, dispatchState] = useReducer(reducer, { isShow2FA: false, list2FA: [] });
    const { isShow2FA: finalShow2FA, list2FA: finalList2FA } = state;

    const refStatus = useRef(null);

    useEffect(() => {
        if (!Object.keys(dataUser).length) {
            _handleBackLogin();
        }
    }, [dataUser]);

    const _handleBackLogin = () => {
        history.push(AUTH_LOGIN);
    };

    const _handleLoginWithSocial = () => {
        dispatch(
            userLoginSocialRequest(
                { token: dataUserToken, type: dataUserType, access_token: dataUserId },
                _handleSuccess,
                _handleFail
            )
        );
    };

    const _handleSuccess = ({ profile, company }) => {
        handleTrackingEvent(mixpanelLogin({ profile, company }));

        const redirectCode =
            profile?.plan?.status === STATUS_ACCOUNT.EXPIRED ? SETTINGS_PLANS_REDIRECT_CODE : profile?.redirect;
        restoreOptionJobCalendar();
        history.push(addBranchPath(getPlanUser(profile).isTrial ? ONBOARDING : REDIRECT_MAP[redirectCode] || CALENDAR));
    };

    const _handleFail = ({ list_2fa = [], redirect_2fa = false, message }) => {
        if (redirect_2fa) {
            dispatchState({ isShow2FA: true, list2FA: list_2fa });
        } else showStatusBar({ id: 'login_social_fail', message, refAlert: refStatus });
    };

    return (
        <Fragment>
            {finalShow2FA ? (
                <TwoStepAuthentication data={finalList2FA} handleSuccess={_handleLoginWithSocial} />
            ) : (
                <div className="gd-form-v2 social-form">
                    <GDStatusBar ref={refStatus} />
                    <p className="social-desc">{t('auth:email_already_login')}</p>
                    <p className="social-desc mt-2">{t('auth:continue_with_login_social')}</p>
                    <div onClick={_handleLoginWithSocial} className="v2-btn-main">
                        {t('auth:login_with_google')}
                    </div>
                    <div onClick={_handleBackLogin} className="v2-btn-main">
                        {t('auth:return_to_main_login')}
                    </div>
                </div>
            )}
        </Fragment>
    );
}

export default LoginSocial;
