import moment from 'moment-timezone';

import { COMMON, DATE_FORMAT } from 'app/const/App';
import i18n from 'assets/i18n';

export const TIME_LENGTH_UNIT = { H: 'h', M: 'm' };

export const formatTime = (value) => {
    const length = value;
    const hours = Math.floor(length / 60);
    const minutes = length % 60;
    const convertNumberToTime = {
        [TIME_LENGTH_UNIT.H]: hours,
        [TIME_LENGTH_UNIT.M]: minutes
    };

    const h = `${convertNumberToTime.h} ${COMMON.HOURS}`;
    const m = convertNumberToTime.m ? ` ${convertNumberToTime.m} ${COMMON.MINUTES}` : '';

    const jobLength = !hours ? m : `${h}${m}`;

    return jobLength;
};

// eslint-disable-next-line no-unused-vars
export const convertTimeNumber = (length) => {
    const hours = Math.floor(length / 60);
    const minutes = length % 60;
    const midDay = hours > 11 ? 'PM' : 'AM';

    return {
        hour: hours - (hours > 12 ? 12 : 0),
        minute: minutes > 9 ? minutes : `0${minutes}`,
        midDay
    };
};

export const convertTimeToNumber = (hour, minute, midDay = '') => {
    const pareHour = parseInt(hour);
    return (pareHour + (pareHour !== 12 && midDay === 'PM' ? 12 : 0)) * 60 + parseInt(minute);
};

/**
 * If the start time of the time you're checking is less than the end time of the time you're checking
 * against, and the end time of the time you're checking is greater than the start time of the time
 * you're checking against, then the times are overlapping
 * @param timesCheck - an array of objects with start and end times
 * @param time - { start: '09:00', end: '10:00' }
 * @returns a boolean value.
 */
export const isTimeOverlapping = (timesCheck, time) => {
    const a = +time.start;
    const b = +time.end;

    for (const timeCheck of timesCheck) {
        const c = +timeCheck.start;
        const d = +timeCheck.end;

        if (a < d && b > c && time.id !== timeCheck.id) {
            return true;
        }
    }
    return false;
};

export const caculateTimeAgo = (date, format = DATE_FORMAT, timezone) => {
    const dateCompare = timezone ? moment.tz(date, timezone) : moment(date);
    const time = moment(new Date()).diff(dateCompare, 'seconds');

    const minute = 60;
    const hours = 60 * minute;
    const day = 24 * hours;
    const week = 7 * day;

    switch (time >= 0) {
        case time < minute:
            return `${time} ${i18n.t(time > 1 ? 'common:seconds_ago' : 'common:second_ago')}`;
        case time < hours:
            const minutesAgo = Math.floor(time / minute);
            return `${minutesAgo} ${i18n.t(minutesAgo > 1 ? 'common:minutes_ago' : 'common:minute_ago')}`;
        case time < day:
            const hoursAgo = Math.floor(time / hours);
            return `${hoursAgo} ${i18n.t(hoursAgo > 1 ? 'common:hours_ago' : 'common:hour_ago')}`;
        case time < week:
            const daysAgo = Math.floor(time / day);
            return `${daysAgo} ${i18n.t(daysAgo > 1 ? 'common:days_ago' : 'common:day_ago')} `;
        default:
            break;
    }

    if (timezone) return moment.tz(date, timezone).format(format);
    return moment(date).format(format);
};

export const formatTimeToHMS = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    if (Number.isNaN(seconds)) return '';

    const formattedHours = `${hours}h`;
    const formattedMinutes = `${minutes < 10 ? '0' : ''}${minutes}m`;
    const formattedSeconds = `${seconds < 10 ? '0' : ''}${seconds}s`;

    return `${formattedHours} ${formattedMinutes} ${formattedSeconds}`;
};

export const calculateSecondVoip = (timeStamp) => {
    const value = Math.floor((Date.now() - timeStamp) / 1000);
    return value < 0 ? 0 : value;
};

export const formatSecondToDay = (totalSeconds) => {
    if (!totalSeconds) return;

    const timeUnits = [
        { unit: 'day', seconds: 24 * 60 * 60 },
        { unit: 'hour', seconds: 60 * 60 },
        { unit: 'minute', seconds: 60 }
    ];

    for (const { unit, seconds } of timeUnits) {
        if (totalSeconds >= seconds) {
            const unitValue = Math.floor(totalSeconds / seconds);
            return `${unitValue} ${unitValue === 1 ? unit : unit + 's'}`;
        }
    }

    return `${totalSeconds} second${totalSeconds > 1 ? 's' : ''}`;
};
export const formatUnix = (timestamp, tz) => {
    if (tz) return moment.unix(timestamp).tz(tz).format('hh:mm A');
    return moment.unix(timestamp).format('hh:mm A');
};

export const formatTimeUnix = (timestamp) => {
    const hours = Math.floor(timestamp / 3600);
    const minutes = Math.floor((timestamp % 3600) / 60);
    const seconds = Math.floor(timestamp % 60);

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export const timeObjectFromString = (timeString, seconds = 0) => {
    const date = moment(timeString, 'hh:mm A');
    return { hours: date.get('hours'), minutes: date.get('minutes'), seconds };
};
export const checkDisableTimeString = (timeString, timeDisable) => {
    const time = timeObjectFromString(timeString);
    const timeDisableObject = timeObjectFromString(timeDisable);

    return (
        time.hours > timeDisableObject.hours ||
        (timeDisableObject.hours === 0 && timeDisableObject.minutes === 0) ||
        (time.hours === timeDisableObject.hours && time.minutes > timeDisableObject.minutes)
    );
};

export const getTimeObject = (timeString) => {
    const timeParts = timeString.split(':');

    if (timeParts.length !== 3) {
        throw new Error('Invalid time format. Expected format: HH:mm:ss');
    }

    const hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);
    const seconds = parseInt(timeParts[2]);

    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
        throw new Error('Invalid time format. Expected format: HH:mm:ss');
    }

    return { hours, minutes, seconds };
};

export const formatUnixToTime = (seconds, tz) => {
    if (!tz) throw new Error('Timezone is required.');
    let hours = moment.unix(seconds).tz(tz).get('hours');
    let minutes = moment.unix(seconds).tz(tz).get('minute');
    const modifier = hours >= 12 ? 'PM' : 'AM';

    // Convert 24-hour time to 12-hour time
    if (hours > 12) {
        hours -= 12;
    } else if (hours === 0) {
        hours = 12;
    }

    if (minutes < 10) minutes = `0${minutes}`;
    if (hours < 10) hours = `0${hours}`;
    return `${hours}:${minutes} ${modifier}`;
};

export const convertStringToTime = (timeString, dateNow = null) => {
    const now = dateNow || moment();
    const [time, modifier] = timeString.split(' ');
    // eslint-disable-next-line prefer-const
    let [hours, minutes] = time.split(':');

    // Convert 12-hour time to 24-hour time
    if (hours === '12') hours = '00';
    if (modifier === 'PM') hours = parseInt(hours, 10) + 12;

    // Set the hours and minutes to the current date
    now.set('hour', hours);
    now.set('minute', minutes);
    now.set('second', 0);
    return now;
};

export const convertTimeToEpoch = (timeString, dateNow = null) => {
    const now = convertStringToTime(timeString, dateNow);
    return now.unix();
};

export const calculateDaysPassed = (start, end, timezone) => {
    const startDate = moment.unix(start).tz(timezone).startOf('day');
    const endDate = moment.unix(end).tz(timezone).startOf('day');
    return endDate.diff(startDate, 'day');
};

export function roundToNearestFive(date) {
    const minutes = date.get('minutes');
    const roundingFactor = 5;
    const newMinutes = Math.round(minutes / roundingFactor) * roundingFactor;
    date.set('minutes', newMinutes);
    date.set('seconds', 0);
    return date;
}
export const validateTimeDuration = (input) => {
    const timeFormat = /^\d{2,}:(?:[0-5]\d):(?:[0-5]\d)$/;
    if (!timeFormat.test(input)) return false;
    return true;
};
// Format is HH:mm:ss
export const correctNearFormats = (input) => {
    const parts = input.split(':');

    // Handle cases where parts of the time might be missing
    while (parts.length < 3) {
        parts.push('00'); // Add '00' for missing minutes and seconds
    }

    // Convert parts to numbers
    let [hours, minutes, seconds] = parts.map((part) => parseInt(part, 10));

    // Correct minutes and seconds if they exceed 59
    if (seconds > 59) {
        minutes += Math.floor(seconds / 60);
        seconds %= 60;
    }

    if (minutes > 59) {
        hours += Math.floor(minutes / 60);
        minutes %= 60;
    }

    // Limit hours to a maximum of 99
    if (hours > 24) hours = 24;

    // Format each part to two digits
    const format = (num) => num.toString().padStart(2, '0');

    return `${format(hours)}:${format(minutes)}:${format(seconds)}`;
};

export const mergeTimeOverlap = (intervals) => {
    // Sort the intervals based on start time
    intervals.sort((a, b) => a.start - b.start);

    const mergedIntervals = [];
    for (let i = 0; i < intervals.length; i++) {
        // If the list of merged intervals is empty or if the current
        // interval does not overlap with the previous, add it to the merged list
        if (mergedIntervals.length === 0 || mergedIntervals[mergedIntervals.length - 1].end < intervals[i].start) {
            mergedIntervals.push(intervals[i]);
        } else {
            // Else merge the current and previous intervals
            mergedIntervals[mergedIntervals.length - 1].end = Math.max(
                mergedIntervals[mergedIntervals.length - 1].end,
                intervals[i].end
            );
        }
    }
    return mergedIntervals;
};

export const convertSecondsToMMSS = (duration) => {
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

export const ETA_TIME_FORMAT_TYPES = { SHORT: 'short', LONG: 'long' };
const TIME_UNITS_SHORT = { DAY: 'd', HOUR: 'h', MINUTE: 'm' };
const TIME_UNITS_LONG = { DAY: 'day', HOUR: 'hr', MINUTE: 'min' };
const stringETA = ({ unit, value, suffix, typeFormat = ETA_TIME_FORMAT_TYPES.LONG }) => {
    let suffixString = value > 1 ? 's' : suffix;
    if (typeFormat === ETA_TIME_FORMAT_TYPES.SHORT && value > 1) suffixString = suffix;
    return `${value}${unit}${suffixString}`;
};

export const convertSecondsToETA = (duration, { typeFormat = ETA_TIME_FORMAT_TYPES.LONG, haveSpace = false } = {}) => {
    const suffix = haveSpace ? ' ' : '';
    const isShortFormat = typeFormat === ETA_TIME_FORMAT_TYPES.SHORT;
    const timeUnits = isShortFormat ? TIME_UNITS_SHORT : TIME_UNITS_LONG;

    const days = Math.floor(duration / (24 * 3600));
    const hours = Math.floor((duration % (24 * 3600)) / 3600);
    const minutes = Math.floor((duration % 3600) / 60);

    let eta = '';
    if (days > 0) eta += stringETA({ value: days, unit: timeUnits.DAY, suffix, typeFormat });
    if (hours > 0) eta += stringETA({ value: hours, unit: timeUnits.HOUR, suffix, typeFormat });
    if (minutes > 0) eta += stringETA({ value: minutes, unit: timeUnits.MINUTE, suffix, typeFormat });

    // If the duration is less than a minute, return '0 min'
    if (duration < 60) return isShortFormat ? '0m' : '0mins';
    return eta.trim();
};
export const convertLengthToTimeStr = (length = 0) => {
    const minutes = length;
    return moment().startOf('day').add(minutes, 'minutes').format('HH:mm:ss');
};
