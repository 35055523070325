import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { v4 as uuidV4 } from 'uuid';

import GdSwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import GDModalWarning from 'app/components/modal/ModalWarning';
import GDStatusBar from 'app/components/status/statusbar';
import { CHATBOT_CUSTOM_ANSWER } from 'app/const/api/V2';
import { LIST_STATUS } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import IconPlus from 'assets/icon/IconPlus';
import IconTrash from 'assets/icon/IconTrash';
import { clientQuery } from 'common/utils/ApiUtils';
import { showStatusBar } from 'common/utils/FunctionUtils';
import { TYPE_DATA_CHATBOT } from '../constants';
import LoadingModalAddCustomAnswer from './LoadingModalAddCustomAnswer';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';

const ModalAddCustomAnswer = ({ onUpdateData = () => {} }, ref) => {
    const { t } = useTranslation(['addons']);

    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false,
        item: {},
        isEdit: false,
        id: '',
        isLoading: false
    });
    const { isOpen, item, isEdit, isLoading } = state;
    const { status = false, questions = [], answer = '', id = '' } = item || {};
    const lengthQuestions = questions?.length || 0;

    const refStatus = useRef(null);
    const refAnswer = useRef(null);
    const refWarning = useRef(null);
    const refModalFooter = useRef(null);
    const refWrap = useRef(null);
    const refAlert = useRef(null);

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = ({ id = null, commonQuestion = '' }) => {
        if (!id) {
            dispatchState((prev) => ({
                ...prev,
                isOpen: true,
                item: { status: true, questions: [{ id: 0, content: commonQuestion || '' }], answer: '' }
            }));
        } else {
            _handleGetDetail({ id, commonQuestion });
        }
    };

    const _handleGetDetail = ({ id, commonQuestion }) => {
        dispatchState((prev) => ({ ...prev, isOpen: true, isLoading: true }));

        const _handleSuccess = ({ data = {} }) => {
            const { answer = '', status, questions = [], id = '' } = data || {};
            const finalQuestions = [...questions];
            if (!!commonQuestion) {
                finalQuestions.push(commonQuestion);
            }
            dispatchState((prev) => ({
                ...prev,
                isLoading: false,
                isEdit: true,
                item: {
                    ...prev.item,
                    status,
                    answer,
                    id,
                    questions: finalQuestions.map((question, index) => ({ id: index, content: question }))
                }
            }));
        };
        const _handleFail = ({ message }) => {
            refWarning.current?._open({ title: t('addons:confirm'), description: message });
        };

        clientQuery(
            `${CHATBOT_CUSTOM_ANSWER}/${id}`,
            { data: {}, method: 'GET', toFormData: false },
            _handleSuccess,
            _handleFail
        );
    };

    const _close = () => {
        dispatchState({ isOpen: false, item: {}, isEdit: false, id: '', isLoading: false });
    };

    const _handleDeleteQuestion = (id) => {
        dispatchState((prev) => ({
            ...prev,
            item: { ...prev.item, questions: prev.item.questions.filter((question) => question.id !== id) }
        }));
    };

    const _renderQuestion = () => {
        return questions.map((question, index) => (
            <div key={question.id} className="flex-column gap-4">
                <div className="flex-betweenitems">
                    <div className="fw-500 black-darker3 fs-14">
                        {t('addons:number_question', { number: index + 1 > 1 ? index + 1 : '' })}
                    </div>
                    {lengthQuestions < 2 ? null : (
                        <div
                            className="v2-btn-default --icon-sm has-bg-red"
                            onClick={() => _handleDeleteQuestion(question.id)}
                        >
                            <IconTrash />
                        </div>
                    )}
                </div>
                <textarea
                    className="field-textarea --h-40"
                    placeholder={t('addons:question')}
                    defaultValue={question.content}
                    autoFocus
                />
            </div>
        ));
    };

    const _handleChangeStatus = (checked) => {
        dispatchState((prev) => ({ ...prev, item: { ...prev.item, status: checked ? 1 : 0 } }));
    };

    const _handleAddCustomAnswer = () => {
        dispatchState((prev) => ({
            ...prev,
            item: { ...prev.item, questions: [...prev.item.questions, { id: uuidV4(), content: '' }] }
        }));
    };

    const _handleShowStatusBar = ({ message, success }) => {
        showStatusBar({ id: 'content_answer', message, success, refAlert });
        _handleResetLoading();
    };

    const _handleSave = () => {
        const finalQuestions = Array.from(refWrap.current.querySelectorAll('textarea'))
            .slice(0, -1)
            .reduce((array, el) => {
                const value = el.value.trim();
                if (!!value) array.push(value);
                return array;
            }, []);
        const finalAnswer = (refAnswer.current?.value || '').trim();
        const errors = [];

        if (!finalQuestions.length) errors.push(t('common:can_not_be_blank', { name: t('addons:question') }));
        if (!finalAnswer) errors.push(t('common:can_not_be_blank', { name: t('addons:answer') }));
        if (!!errors.length) {
            _handleShowStatusBar({ message: errors });
            return;
        }

        const _handleSuccess = ({ data }) => {
            onUpdateData({ type: TYPE_DATA_CHATBOT.UPDATE, data });
            _close();
        };

        const _handleFail = (err) => {
            _handleShowStatusBar(err);
        };

        const _handleFinally = () => {
            _handleResetLoading();
        };

        let endpoint = CHATBOT_CUSTOM_ANSWER;
        if (isEdit) endpoint += `/${id}`;
        clientQuery(
            endpoint,
            {
                data: {
                    status: !!status ? 1 : 0,
                    questions: finalQuestions,
                    answer: finalAnswer
                },
                method: isEdit ? 'PUT' : 'POST',
                toFormData: false
            },
            _handleSuccess,
            _handleFail,
            _handleFinally
        );
    };

    const _handleConfirmWarning = (_, idDelete) => {
        refWarning.current?._clearStatusAlert();
        if (!idDelete) {
            refWarning.current?._close();
            _close();
            return;
        }

        const _handleSuccess = () => {
            onUpdateData({ type: TYPE_DATA_CHATBOT.DELETE, data: idDelete });
            _close();
        };
        const _handleFail = ({ message }) => {
            _onShowStatusAlert({ message });
        };
        const _handleFinally = () => {
            _handleResetLoading();
        };

        clientQuery(
            `${CHATBOT_CUSTOM_ANSWER}/${idDelete}`,
            { data: {}, method: 'DELETE' },
            _handleSuccess,
            _handleFail,
            _handleFinally
        );
    };

    const _handleDelete = (id) => {
        refWarning.current?._open({
            dataConfirm: id,
            title: t('addons:title_delete_custom_answer'),
            description: t('addons:desc_delete_custom_answer')
        });
    };

    const _handleResetLoading = () => {
        refWarning.current?._setLoadingBtnSave(false);
        refModalFooter.current?._setLoadingBtnSave(false);
    };

    const _onShowStatusAlert = ({ message = '', status = LIST_STATUS.ERROR }) => {
        refWarning.current?._setStatusAlert(message, status);
    };

    const _handleCloseWarning = () => {
        if (!isLoading) return;
        _close();
    };

    if (!isOpen) return null;

    return (
        <ReactModal
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal modal-chatbot open"
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            {isLoading ? (
                <LoadingModalAddCustomAnswer />
            ) : (
                <div className="modal__container --preview-r">
                    <ModalHeader title={t('addons:custom_answer')} onClose={_close} />
                    <div className="body-modal flex-column p-0 scrolls">
                        <GDStatusBar ref={refAlert} />
                        <div className="rows flexcenter">
                            <div className="rows__title fw-500 black-darker3 fs-14 mr-2">{t('common:status')}</div>
                            <GdSwitchCheckbox
                                ref={refStatus}
                                wrapClassName="switch large"
                                textUnChecked={t('common:active')}
                                textChecked={t('common:active')}
                                textPosition="right"
                                defaultChecked={status}
                                onChangeImmediately={_handleChangeStatus}
                            />
                        </div>
                        <div ref={refWrap} className="rows flex-column gap-16 flex-1 scrolls">
                            {_renderQuestion()}
                            <div className={'v2-btn-default has-icon btn-question'} onClick={_handleAddCustomAnswer}>
                                <IconPlus />
                                {t('add_similar_question')}
                            </div>
                            <div className="flex-column gap-4">
                                <div className="fw-500 black-darker3 fs-14">{t('addons:answer')}</div>
                                <textarea
                                    ref={refAnswer}
                                    className="field-textarea --h-250"
                                    placeholder={t('addons:answer')}
                                    defaultValue={answer}
                                />
                            </div>
                        </div>
                    </div>
                    <ModalFooter
                        ref={refModalFooter}
                        isEdit={isEdit}
                        isShowDelete={isEdit}
                        onClose={_close}
                        onSave={_handleSave}
                        onDelete={() => _handleDelete(id)}
                    />
                </div>
            )}
            <GDModalWarning
                ref={refWarning}
                isLargeContent={false}
                buttonSave
                titleButtonConfirm={t('addons:confirm')}
                onConfirm={_handleConfirmWarning}
                onClose={_handleCloseWarning}
            />
        </ReactModal>
    );
};

export default forwardRef(ModalAddCustomAnswer);
