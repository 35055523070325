import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { ADDONS_SMARTVIEW_PERMISSION } from 'app/config/routes';
import { ADDONS_SMARTVIEW_DETAIL, UPDATE_ADDONS_SMARTVIEW_OPTIONS } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import AddonHeaderDetail from '../components/AddonHeaderDetail';
import AddonsBoxHeader from '../components/AddonsBoxHeader';
import { AddonsOptionsItem } from '../components/AddonsOptions';
import SmartViewLoading from './components/SmartViewLoading';

const AddonSmartView = () => {
    const { t } = useTranslation('addons');
    const [state, dispatchState] = useReducer(reducer, { smartview: {}, isLoading: true });
    const { isLoading, smartview } = state;

    useEffect(() => {
        _getDetailSmartViewAddons();
    }, []);

    const _getDetailSmartViewAddons = () => {
        const _handleSuccess = ({ data }) => dispatchState({ smartview: data, isLoading: false });

        clientQuery(ADDONS_SMARTVIEW_DETAIL, { method: 'GET' }, _handleSuccess, checkAccessFail);
    };

    const _handleUpdateSuccess = (checked) => {
        dispatchState((prev) => ({ ...prev, data: { ...prev.data, status: checked } }));
    };

    return (
        <div className="addons-wrap__container">
            <AddonHeaderDetail isLoading={isLoading} tutorial={smartview.tutorial} />
            <div className="scrolls">
                <div className="boxs-wrapper">
                    {isLoading ? (
                        <SmartViewLoading />
                    ) : (
                        <>
                            <div className="boxs boxs--no-border --mr-line">
                                <AddonsBoxHeader
                                    upgrade={smartview.upgrade}
                                    keyword={smartview.keyword}
                                    name={smartview.name}
                                    status={smartview.status}
                                    level={smartview.level}
                                    urlUpdateStatus={UPDATE_ADDONS_SMARTVIEW_OPTIONS}
                                    onUpdateStatusSuccess={_handleUpdateSuccess}
                                />
                                <div className="boxs__contents mt-3">
                                    <p className="description white-space-pre">{t('desc_addons_smart_view')}</p>
                                    <div className="is-divider --horizontal my-3" />
                                </div>
                            </div>
                            <AddonsSmartViewOptions data={smartview.options} keyAddon={smartview.keyword} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

const AddonsSmartViewOptions = ({ data = [], keyAddon = '' }) => {
    if (!data || !data.length) return null;
    return data.map((item) => {
        const keyword = item.keyword;
        return (
            <AddonsOptionsItem
                key={keyword}
                isPowerUp
                shouldCheckManage
                pathManage={ADDONS_SMARTVIEW_PERMISSION}
                keyAddon={keyAddon}
                {...item}
            />
        );
    });
};

export default AddonSmartView;
