import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import PaymentHeader from 'app/modules/jobdetail/tabs/payment/components/PaymentHeader';
import PaymentSummary from 'app/modules/jobdetail/tabs/payment/components/PaymentSummary';
import PaymentInvoice from 'app/modules/jobdetail/tabs/payment/components/PaymentInvoice';
import PaymentFooter from 'app/modules/jobdetail/tabs/payment/components/PaymentFooter';
import AddPaymentContextProvider from 'app/modules/jobdetail/contexts/AddPaymentContext';
import { INVOICE_PAYMENT_STATUS, INVOICE_STATUS } from 'app/modules/jobdetail/const/Invoice';
import Balance from 'app/modules/jobdetail/tabs/payment/components/Balance';
import Amount from 'app/modules/jobdetail/tabs/payment/components/Amount';
import SelectDate from 'app/modules/jobdetail/tabs/payment/components/Date';
import PaymentMethod from 'app/modules/jobdetail/tabs/payment/components/payment';
import InvoiceStatus from 'app/modules/jobdetail/tabs/payment/components/InvoiceStatus';
import CustomerInfo from 'app/modules/jobdetail/tabs/payment/components/CustomerInfo';
import StatusBar from 'app/components/status/statusbar';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { mixpanelAddPayment } from 'app/modules/mixpanel/MixpanelAddPayment';
import { useSelector } from 'react-redux';

const Payment = forwardRef(
    (
        {
            balanceDue = {},
            customerCredit = {},
            customer,
            contacts = [],
            invoiceNumber,
            invoiceStatus,
            addPaymentSuccess
        },
        ref
    ) => {
        const [state, dispatchState] = useReducer(reducer, {
            isVisible: false
        });
        const refFormAddPayment = useRef(null);
        const refStatusBar = useRef(null);
        const profileId = useSelector(({ auth }) => auth.user.profile.id);
        const invoiceDetail = {};

        useImperativeHandle(ref, () => ({
            showModal,
            hideModal
        }));

        const showModal = () => {
            const elm = document.getElementById('tab_inoive_detail_setting');
            elm && elm.classList.add('show-addpayment');
            dispatchState({ isVisible: true });
        };

        const hideModal = () => {
            const elm = document.getElementById('tab_inoive_detail_setting');
            elm && elm.classList.remove('show-addpayment');
            dispatchState({ isVisible: false });
        };

        function _addPaymentSuccess(message, type) {
            if (type === LIST_STATUS.SUCCESS) handleTrackingEvent(mixpanelAddPayment({ id: profileId }));
            refStatusBar.current.showStatusBar('show_message', message, type);
            refFormAddPayment.current.scrollIntoView();
        }

        if (!state.isVisible) {
            return false;
        }

        return (
            <div ref={refFormAddPayment} className="container-column form-add-payment">
                <AddPaymentContextProvider
                    paymentData={{
                        customerId: customer.id,
                        filterInvoice:
                            invoiceStatus &&
                            (invoiceStatus === INVOICE_STATUS.SENT || invoiceStatus === INVOICE_STATUS.DRAFT)
                                ? invoiceStatus
                                : INVOICE_PAYMENT_STATUS[0].value,
                        balanceDue: balanceDue,
                        customerCredit: customerCredit,
                        customerInfo: customer || null,
                        isDefaultCustomer: true
                    }}
                >
                    <PaymentHeader invoiceNumber={invoiceNumber} invoiceData={invoiceDetail} onClose={hideModal} />
                    <StatusBar ref={refStatusBar} />
                    <div className="rows --header-info">
                        <div className="customer-info">
                            <div className="customer-info-number">
                                <CustomerInfo customer={customer} />
                                <Balance />
                                <Amount />
                                <SelectDate />
                                <PaymentMethod />
                                <InvoiceStatus />
                            </div>
                        </div>
                    </div>
                    <PaymentInvoice invoiceData={invoiceDetail} invoiceNumber={invoiceNumber} />
                    <PaymentSummary />
                    <PaymentFooter
                        isInvoice
                        contacts={contacts}
                        onCallback={_addPaymentSuccess}
                        onCallbackUpdate={addPaymentSuccess}
                    />
                </AddPaymentContextProvider>
            </div>
        );
    }
);

export default Payment;
