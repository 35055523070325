import 'app/modules/onBoarding/css/onboarding.scss';
import React, { useLayoutEffect } from 'react';

import Sidebar from './components/Sidebar';
import OnBoardingFAQ from './components/faq';
import OnBoardingVideoLibrary from './components/videoLibrary';
import OnBoardingVideoPopular from './components/videoPopular';

const OnBoarding = () => {
    useLayoutEffect(() => {
        const mainPageDiv = document.getElementById('main-page');
        mainPageDiv.classList.add('onboarding-page');
        return () => {
            mainPageDiv.classList.remove('onboarding-page');
        };
    }, []);

    return (
        <div className="container-wrap flex-column gap-16 relative">
            <div className="flextop gap-16 flex-1 scrolls relative">
                <Sidebar />
                <div className="onboarding-container scrolls">
                    <OnBoardingVideoPopular />
                    <OnBoardingFAQ />
                    <OnBoardingVideoLibrary />
                </div>
            </div>
        </div>
    );
};

export default OnBoarding;
