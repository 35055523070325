import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonSave from 'app/components/button/ButtonSave';
import GdConfirm from 'app/components/confirm';
import GDModalWarning from 'app/components/modal/ModalWarning';
import { UPDATE_ADDONS_RESET_KEY_ZAPIER, ZAPIER_ADDONS_DETAIL } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import IconCopy from 'assets/icon/IconCopy';
import IconEye from 'assets/icon/IconEye';
import IconHide from 'assets/icon/IconHide';
import IconSync from 'assets/icon/IconSync';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import AddonHeaderDetail from '../components/AddonHeaderDetail';
import ZapierAddonsContent from './components/ZapierAddonsContent';
import ZapierAddonsLoading from './components/ZapierAddonsLoading';

const AddonsZapier = () => {
    const [state, dispatchState] = useReducer(reducer, {
        zapier: {},
        isLoading: true,
        isHideKey: true
    });
    const { t } = useTranslation(['addons', 'common']);
    const refAlert = useRef(null);
    const refInput = useRef(null);
    const refConfirm = useRef(null);
    const refButtonSave = useRef(null);
    const refConfirmCopyKey = useRef(null);
    const { zapier, isLoading, isHideKey } = state;

    const compileKey = (key) => key.replace(/[a-zA-Z0-9-&\/\\#,+()$~%.'":*?<>{}=@!^]/g, '*');

    useEffect(() => {
        _getDetailZapierAddons();
    }, []);

    const _getDetailZapierAddons = () => {
        const _handleSuccess = ({ data }) => {
            dispatchState((prev) => ({ ...prev, zapier: data, isLoading: false }));
        };
        clientQuery(ZAPIER_ADDONS_DETAIL, { method: 'GET' }, _handleSuccess, checkAccessFail);
    };

    const _handleResetKey = () => refConfirm.current._open();

    const _handleConfirmResetKey = () => {
        const _handleSuccess = ({ data, message }) => {
            _showStatusBar({ id: 'reset_success', message });
            dispatchState((prev) => ({ ...prev, zapier: { ...prev.zapier, key: data.key }, isHideKey: true }));
            refInput.current.value = compileKey(zapier.key);
            _handleCloseConfirmReset();
        };
        const _handleFail = ({ message }) => {
            _showStatusBar({ id: 'reset_fail', message, type: LIST_STATUS.ERROR });
            dispatchState((prev) => ({ ...prev, isHideKey: true }));
        };

        clientQuery(UPDATE_ADDONS_RESET_KEY_ZAPIER, { method: 'PUT' }, _handleSuccess, _handleFail);
    };

    const _handleCopyKey = async () => {
        await navigator.clipboard.writeText(zapier.key);
        refConfirmCopyKey.current.open(null, t('addons:confirm_text'));
    };

    const _handleHideKey = () => {
        dispatchState((prev) => ({ ...prev, isHideKey: !prev.isHideKey }));
        refInput.current.value = isHideKey ? zapier.key : compileKey(refInput.current.value);
    };

    const _handleCloseConfirmReset = () => refConfirm.current._close();

    const _showStatusBar = ({ id = '', message = '', type = LIST_STATUS.SUCCESS }) => {
        refAlert.current.showStatusBar({ id, message, type });
        refButtonSave.current.removeLoading();
    };

    const FooterWarning = () => {
        return (
            <div className="footer-modal">
                <div className="v2-btn-default --transparent" onClick={_handleCloseConfirmReset}>
                    {t('addons:cancel')}
                </div>
                <ButtonSave title={t('addons:confirm')} ref={refButtonSave} onSave={_handleConfirmResetKey} />
            </div>
        );
    };

    return (
        <div className="addons-wrap__container">
            <AddonHeaderDetail isLoading={isLoading} tutorial={zapier.tutorial} />
            <AlertCustomer ref={refAlert} />
            <div className="scrolls">
                <div className="boxs-wrapper">
                    {isLoading ? (
                        <ZapierAddonsLoading />
                    ) : (
                        <>
                            <ZapierAddonsContent {...zapier} />
                            {!zapier.upgrade && (
                                <>
                                    <div className="has-form form-zaipier">
                                        <span className="txt mb-1">{t('addons:secret_key')}</span>
                                        <div className="flex-betweenitems">
                                            <div className="password-input">
                                                <input
                                                    ref={refInput}
                                                    defaultValue={compileKey(zapier.key)}
                                                    className="field-input"
                                                    type="text"
                                                />
                                                <span
                                                    className="svg-search-absolute cursor-pointer"
                                                    onClick={_handleHideKey}
                                                >
                                                    {isHideKey ? <IconEye /> : <IconHide />}
                                                </span>
                                            </div>
                                            <div className="v2-btn-default has-icon" onClick={_handleResetKey}>
                                                <IconSync /> {t('addons:reset')}
                                            </div>
                                            <div
                                                className="v2-btn-main has-icon svg-white-stroke"
                                                onClick={_handleCopyKey}
                                            >
                                                <IconCopy /> {t('addons:copy')}
                                            </div>
                                        </div>
                                    </div>
                                    <GDModalWarning
                                        ref={refConfirm}
                                        title={t('addons:title_confirm_reset_key_zapier')}
                                        description={t('addons:description_confirm_reset_key_zapier')}
                                        footer={<FooterWarning />}
                                    />
                                    <GdConfirm
                                        ref={refConfirmCopyKey}
                                        title={t('addons:confirm')}
                                        listButton={{ confirm: true, cancel: false }}
                                        titleConfirm={t('common:confirm')}
                                    />
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddonsZapier;
