import loadable from '@loadable/component';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { DEFAULT_ALL, KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { URL_EXPORT_DEVICE_TRACKING } from 'app/const/api/Export';
import { REPORT_DEVICE_TRACKING } from 'app/const/api/V2';
import { LIST_EXPORT } from 'app/const/report/Common';
import { CLIENTS, COLUMNS, DEVICE_TRACKING_LIST_FILTER } from 'app/const/report/ReportFilter';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { handleAbortController } from 'app/modules/customer/utils';
import ErrorPage from 'app/modules/error';
import { clientQuery } from 'common/utils/ApiUtils';
import { showStatusBar } from 'common/utils/FunctionUtils';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { getPlanUser } from 'common/utils/PermissionUtils';
import { getLocalParamsReport } from 'common/utils/ReportUtils';
import HeaderBottom from '../components/HeaderBottom';
import { getGridTableDeviceTracking } from './constant';

const Export = loadable(() => import('app/modules/report/components/Export'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const MainHeaderReport = loadable(() => import('app/modules/report/components/MainHeader'));
const GDStatusBar = loadable(() => import('app/components/status/statusbar'));

const ReportDeviceTracking = () => {
    const { t } = useTranslation('report');
    const profileData = useSelector(({ auth }) => auth.user.profile);
    const { isProPlan, isGrowthPlan, isTrial } = getPlanUser(profileData);
    const deviceTrackingAddon = useSelector(({ auth }) => auth.user.settings.addons.device_tracking);
    const havePermission = (isProPlan || isGrowthPlan || isTrial) && deviceTrackingAddon;

    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        isLoading: false,
        total: 0,
        showMore: false,
        cursor: '',
        isLoadMore: false,
        refreshScreen: 0
    });
    const {
        data: finalData,
        isLoading: finalIsLoading,
        total: finalTotal,
        showMore: finalShowMore,
        cursor: finalCursor,
        isLoadMore: finalIsLoadMore,
        refreshScreen: finalRefreshScreen
    } = state;
    const finalTypeReport = REPORT_TYPE.DEVICE_TRACKING;
    const keyLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', finalTypeReport);
    const paramsReport = getLocalParamsReport(keyLocal, finalTypeReport);

    const abortController = useRef(null);
    const refAlert = useRef(null);

    useEffect(() => {
        if (!havePermission) return;
        getListReport();

        return () => {
            handleAbortController(abortController);
        };
    }, []);

    const getListReport = ({ params = {}, shouldLoadMore = false, shouldLoading = true } = {}) => {
        handleAbortController(abortController);
        abortController.current = new AbortController();
        dispatchState((prev) => ({
            ...prev,
            isLoadMore: shouldLoadMore,
            data: !shouldLoadMore ? [] : prev.data,
            isLoading: shouldLoading
        }));

        const _getListSuccess = ({ data = [], total = 0, show_more = false, cursor = '' }) => {
            dispatchState((prev) => ({
                ...prev,
                data: prev.isLoadMore ? [...(prev.data || []), ...data] : data,
                isLoading: false,
                isLoadMore: false,
                total: total ?? prev.total ?? 0,
                showMore: show_more,
                cursor,
                refreshScreen: prev.refreshScreen + 1
            }));
        };
        const _getListFailed = ({ isAborted = false, message }) => {
            if (isAborted) return;
            showStatusBar({ id: 'get_list_fail', message, refAlert });
            dispatchState((prev) => ({ ...prev, isLoading: false, isLoadMore: false }));
        };

        const finalParams = { ...paramsReport, ...params, cursor: shouldLoadMore ? finalCursor : null };
        delete finalParams?.columns;
        delete finalParams?.sign_status;
        delete finalParams?.currentPage;
        // eslint-disable-next-line no-prototype-builtins
        if (!Object(finalParams).hasOwnProperty(CLIENTS)) {
            finalParams[CLIENTS] = DEFAULT_ALL;
        }
        clientQuery(
            REPORT_DEVICE_TRACKING,
            { data: finalParams, method: 'GET', abortController: abortController.current },
            _getListSuccess,
            _getListFailed
        );
    };

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType: actionType,
            reportType: finalTypeReport,
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: _handleUpdate
        });
    };

    const _handleChangeFilter = (params, mode) => {
        if (mode && mode === COLUMNS) {
            dispatchState((prev) => ({ ...prev, refreshScreen: prev.refreshScreen + 1 }));
        }
    };

    const _handleLoadMore = () => {
        if (finalShowMore && !finalIsLoading && !finalIsLoadMore) {
            getListReport({ shouldLoadMore: true, shouldLoading: false });
        }
    };

    const _handleUpdate = () => {
        getListReport({ params: getLocalStorage(keyLocal) });
    };

    if (!havePermission) return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;

    return (
        <>
            <MainHeaderReport
                reportType={finalTypeReport}
                contentRight={() => (
                    <Export
                        title={t('report:records', { count: finalTotal })}
                        activePrint
                        isDisable={finalIsLoading}
                        url={URL_EXPORT_DEVICE_TRACKING}
                        params={paramsReport}
                        pageExport={LIST_EXPORT.EXPORT_DEVICE_TRACKING}
                        refresh={finalRefreshScreen}
                    />
                )}
            />
            <div className="wrapper-columns">
                <div className="container-print contents-pages">
                    <GDStatusBar ref={refAlert} />
                    <div className="wrap-tables flex-column relative">
                        <HeaderBottom
                            classNameHeader="header --filter"
                            typeReport={finalTypeReport}
                            filters={DEVICE_TRACKING_LIST_FILTER}
                            isShowAvatar
                            isLoading={finalIsLoading}
                            handleChangeFilter={_handleChangeFilter}
                            handleUpdate={_handleUpdate}
                        />
                        <GdGridView
                            isEmptyFlat
                            content={finalData}
                            isLoading={finalIsLoading}
                            fileTranslation="report"
                            classTable="tables has-text-ellipsis table-multi-column scrolls-x"
                            {...getGridTableDeviceTracking(paramsReport.columns, paramsReport.order)}
                            isScroll
                            isLoadmore={finalIsLoadMore}
                            handleClickHeader={_handleActionHeader}
                            onScrollToEnd={_handleLoadMore}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReportDeviceTracking;
