import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Checkbox from 'app/components/checkbox';
import { GDGlobalImg } from 'app/components/img';
import { listNoteImages } from 'app/const/api/Photos';
import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';
import { actionToggleInsertJobNotes } from 'common/redux/actions/notesAction';
import { actionToggleInsertJobImages } from 'common/redux/actions/photosAction';
import { clientQuery } from 'common/utils/ApiUtils';
import LoadingInsert from './LoadingInsert';

export default function InsertImage({ jobId }) {
    const { t } = useTranslation(['header', 'common']);
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        data: [],
        showMore: false,
        keyword: '',
        isChanged: 0
    });

    const { openTime, callback: functionCallBack } = useSelector(
        ({ photosReducer }) => photosReducer.toggleInsert || {}
    );

    const { isLoading: finalIsLoading, data: dataImages, keyword: finalKeyword } = state;

    const dispatch = useDispatch();

    const itemChecked = dataImages.filter((image) => image.checked);
    const countChecked = itemChecked.length;

    useEffect(() => {
        if (openTime) {
            dispatch(actionToggleInsertJobNotes(null));
            _handleGetListNoteImages();
        } else {
            dispatchState((prev) => {
                return {
                    ...prev,
                    isLoading: true,
                    data: [],
                    showMore: false,
                    keyword: ''
                };
            });
        }
    }, [openTime]);

    const _handleGetListNoteImages = () => {
        clientQuery(listNoteImages(jobId), { method: 'GET' }, _getDataSuccess);
    };

    function _getDataSuccess(response) {
        dispatchState((prev) => {
            return {
                ...prev,
                isLoading: false,
                data: response.data
            };
        });
    }

    function _handleClose() {
        dispatch(actionToggleInsertJobImages(null));
    }

    function _handleSelectValue(e, value) {
        e.preventDefault();
        e.stopPropagation();

        dispatchState((prev) => {
            return {
                ...prev,
                isLoading: false,
                data: [...prev.data].map((item) => {
                    if (item.id === value) {
                        return {
                            ...item,
                            checked: !item.checked
                        };
                    }
                    return item;
                })
            };
        });
    }

    function _handleInsert() {
        if (typeof functionCallBack === 'function') {
            dispatchState((prev) => {
                return {
                    ...prev,
                    isLoading: false,
                    data: [...prev.data].map((item) => {
                        return {
                            ...item,
                            checked: false
                        };
                    })
                };
            });

            functionCallBack(itemChecked);
        }
    }

    const _renderEmpty = () => {
        return (
            <p className="p-3 flex-1">
                {finalKeyword
                    ? t('header:search_not_match')
                    : t('common:no_data_to_display', { title: t('common:job_images') })}
            </p>
        );
    };

    function _renderListImages() {
        return dataImages.map((item) => {
            const { id: itemId, checked: isChecked, thumbnail_url, url } = item;

            return (
                <div
                    onClick={(e) => _handleSelectValue(e, itemId)}
                    draggable="false"
                    key={itemId}
                    className={`wrap-images ${isChecked ? 'is-selected' : ''}`}
                >
                    <div draggable="false" className="wrap-images__action relative">
                        <GDGlobalImg draggable="false" thumbnailUrl={thumbnail_url} src={url} />
                        <Checkbox
                            label={''}
                            checked={isChecked}
                            id={itemId}
                            onChangeValue={_handleSelectValue}
                            value={itemId}
                        />
                    </div>
                    <div className="wrap-images__desc">{item.description}</div>
                </div>
            );
        });
    }

    if (!openTime) {
        return false;
    }

    return (
        <div className="wrapper-pane-sub wrap-existing-images">
            <div className="is-overlay" />
            {finalIsLoading ? (
                <div className="wrapper-pane-sub__content flex-column">
                    <LoadingInsert />
                </div>
            ) : (
                <div className="wrapper-pane-sub__content flex-column">
                    <div className="header-modal px-3 border-bt-grey">
                        <h3 className="header-modal__label">Existing Job Images</h3>
                        <div
                            onClick={_handleClose}
                            className="v2-btn-default --icon-lg --transparent js-show-existing-images"
                        >
                            <IconClose />
                        </div>
                    </div>
                    {!dataImages?.length ? _renderEmpty() : <div className="wrap-content">{_renderListImages()}</div>}
                    <div className="wrap-footer">
                        <div
                            onClick={_handleInsert}
                            className={`v2-btn-main justify-center w-100 ${!countChecked ? 'is-disable' : ''}`}
                        >{`Insert ${!!countChecked ? ' '.concat(countChecked) : ''} ${
                            countChecked > 1 ? 'Images' : 'Image'
                        }`}</div>
                    </div>
                </div>
            )}
        </div>
    );
}
