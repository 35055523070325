import { combineReducers } from 'redux';
import authReducer from './authReducer';
import appReducer from './appReducer';
import smsReducer from './sms';
import customerReducer from './customerReducer';
import companyUsersReducer from './companyUsersReducer';
import customerReportReducer from './CustomerReport';
import calendarReducer from './calendar';
import serviceCalendar from './calendar/service';
import jobReducer from './job';
import jobDetailReducer from './job/detail';
import createJobReducer from './job/create';
import bookMarkReducer from './calendar/bookMark';
import jobListReducer from './calendar/jobList';
import workPoolReducer from './calendar/workPool';
import listTaskReducer from './calendar/listTasks';
import inlineCalendarReducer from './calendar/inlineCalendar';
import mapReducer from './map';
import schedulesReducer from './calendar/schedule';
import serviceReducer from './serviceReducer';
import templatesReducer from './templates';
import driveMatrixReducer from './routeoptimizer/DriveMatrixReducer';
import documentsReducer from './job/documents';
import itemsReducer from './itemsReducer';
import taxesReducer from './taxesReducer';
import devicesReducer from './job/devices';
import tagsReducer from './settings/tags';
import emailVariableReducer from './settings/email';
import broadcastReducer from './calendar/broadcast';
import phonesReducer from './phones';
import customer from './customer';
import statusbar from './common/statusbarReducer';
import emailTemplateReducer from './email/template';
import invoiceReducer from './invoiceReducer';
import estimateReducer from './estimateReducer';
import documentReducer from './documentReducer';
import inboxReducer from './inbox';
import commissionReducer from './commission';
import optimizeRouteReducer from './routeoptimizer/OptimizedRouteReducer';
import timeWindowReducer from './timeWindowReducer';
import locationReducer from './location';
import eventReducer from './event';
import notesReducer from './notesReducer';
import photosReducer from './photosReducer';
import voipReducer from './voipReducer';
import createCustomerReducer from './customer/createCustomer';
import assignLeadReducer from './assignLead';
import pipelinesReducer from './settings/pipelines';
import schedulesList from './schedulesList';
import worklogReducer from './worklogs';
import SVReducer from './smartviews';
import customFieldsReducer from './customFields';
import servicePlanReducer from './servicePlanReducer';

export default combineReducers({
    serviceCalendar,
    map: mapReducer,
    jobReducer,
    jobDetailReducer,
    calendar: calendarReducer,
    serviceReducer,
    bookMarkReducer,
    jobListReducer,
    workPoolReducer,
    listTaskReducer,
    inlineCalendarReducer,
    auth: authReducer,
    app: appReducer,
    sms: smsReducer,
    customer: customerReducer,
    companyUsers: companyUsersReducer,
    CustomerReport: customerReportReducer,
    createCustomer: createCustomerReducer,
    schedules: schedulesReducer,
    templates: templatesReducer,
    driveMatrix: driveMatrixReducer,
    documents: documentsReducer,
    itemsReducer,
    taxesReducer,
    devices: devicesReducer,
    tags: tagsReducer,
    emailVariable: emailVariableReducer,
    broadcastReducer: broadcastReducer,
    phoneTypes: phonesReducer,
    customerReducer: customer,
    createJobReducer,
    statusbar: statusbar,
    emailTemplateReducer,
    invoiceReducer,
    inbox: inboxReducer,
    estimateReducer,
    documentReducer,
    optimizeRouteReducer,
    timeWindowReducer,
    commissionReducer,
    locationReducer,
    eventReducer,
    notesReducer,
    photosReducer,
    voipReducer,
    assignLeadReducer,
    pipelines: pipelinesReducer,
    schedulesList,
    worklogs: worklogReducer,
    smartViews: SVReducer,
    customFields: customFieldsReducer,
    servicePlanReducer
});
