import { getTextColorFromBackground } from 'app/modules/settings/utils';
import { EVENT_TYPES, TASK_BORDER_DEFAULT, TASK_CLASS_NAME, TASK_TEXT_DEFAULT } from '../const';
import moment from 'moment';

const DEFAULT_DATA_HOLIDAY_EVENT = {
    borderColor: TASK_BORDER_DEFAULT,
    textColor: TASK_TEXT_DEFAULT,
    type: EVENT_TYPES.HOLIDAY,
    resourceEditable: false,
    editable: false
};

const convertEvent = ({ event = {}, country = {}, schedule = {}, isFirstSchedule = false, isVerticalMode = true }) => {
    const resourceId = schedule.id || '1';
    const countryColor = country.color;
    return {
        ...event,
        ...DEFAULT_DATA_HOLIDAY_EVENT,
        allDay: isVerticalMode,
        classNames: isVerticalMode ? TASK_CLASS_NAME + ' is-holiday' : 'is-holiday',
        id: country.name + '_' + event.start + '_' + resourceId + '_' + event.summary,
        title: event.summary,
        schedule,
        resourceId,
        isFirstSchedule,
        color: countryColor,
        textColor: countryColor && getTextColorFromBackground(countryColor),
        countryColor: countryColor
    };
};

const convertEvents = (data = [], schedules = [], country, isVerticalMode = true) => {
    const result = [];
    schedules.forEach((schedule, index) => {
        const events = data.map((item) =>
            convertEvent({ event: item, country, schedule, isFirstSchedule: index === 0, isVerticalMode })
        );
        if (events.length) result.push(...events);
    });
    return result;
};

const convertEventBackground = (event = {}, country = {}, schedules = [], isMonth = false, isVerticalMode = true) => {
    const result = { start: event.start, end: event.end, display: 'background' };
    if (country.color) result['color'] = country.color;
    schedules.length > 1
        ? (result['resourceIds'] = schedules.map((item) => item.id))
        : (result['resourceId'] = schedules[0]?.id || '1');
    // Display all day in month view
    result['allDay'] = isMonth && isVerticalMode;
    result['resource'] = schedules[0];
    result['schedule'] = schedules[0];
    result['summary'] = event.summary;
    return result;
};

export const handleConvertHoliday = ({
    data = [],
    schedules = [],
    isMonthView = false,
    isListView = false,
    isVerticalMode = true
}) => {
    const sortedSchedules = schedules.sort((a, b) => parseInt(a.id) - parseInt(b.id));
    const normalEvents = [];
    const backgroundEvents = [];
    const tempObj = {};

    // Countries loop
    data.forEach(({ country = {}, events = [] }) => {
        if (country.value && events.length) {
            // Normal events display on top of calendar with allDay property
            if (sortedSchedules.length > 1 && !isMonthView && !isListView) {
                normalEvents.push(...convertEvents(events, sortedSchedules, country, isVerticalMode));
            }
            // Events in country loop
            events.forEach((event) => {
                if (sortedSchedules.length === 1 || isMonthView || isListView) {
                    const eventConverted = convertEvent({
                        event,
                        country,
                        schedule: sortedSchedules[0],
                        isFirstSchedule: true,
                        isVerticalMode
                    });
                    normalEvents.push(eventConverted);
                }
                // Background events display whole day with background color
                if (!tempObj[event.start]) {
                    tempObj[event.start] = true;
                    // Background events display whole day with background color from country
                    const data = convertEventBackground(event, country, sortedSchedules, isMonthView, isVerticalMode);
                    backgroundEvents.push(data);
                }
            });
        }
    });
    return [...backgroundEvents, ...normalEvents];
};
export const getHolidayData = ({ holidayEvents = [], startStr = '' }) => {
    return holidayEvents?.find((item) => {
        return item.start && item.display === 'background' && moment(startStr).utc().isSame(item.start, 'day');
    });
};
