import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ADDONS } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import IconCogWheel from 'assets/icon/IconCogWheel';

const AddonsOptionsFooterManage = ({
    path = ADDONS,
    className = 'v2-btn-default svg-purple btn-bg-purple --icon-r --sm',
    isDisable = false
}) => {
    const { t } = useTranslation('addons');
    return (
        <Link
            to={addBranchPath(path)}
            className={classNames(className, {
                'is-disable': isDisable
            })}
        >
            {t('addons:manage')} <IconCogWheel />
        </Link>
    );
};

export default AddonsOptionsFooterManage;
