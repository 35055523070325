import classNames from 'classnames';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { COMMISSIONS } from 'app/config/routes';
import { ADDONS_COMMISSION_TRACKING } from 'app/const/Api';
import { UPDATE_ADDONS_COMMISSION_OPTIONS } from 'app/const/api/V2';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import IconCogWheel from 'assets/icon/IconCogWheel';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import AddonHeaderDetail from '../components/AddonHeaderDetail';
import AddonsOptions from '../components/AddonsOptions';
import CommissionAddonsContent from './components/CommissionAddonsContent';
import CommissionAddonsLoading from './components/CommissionAddonsLoading';

const AddonsCommissionTracking = () => {
    const [state, dispatchState] = useReducer(reducer, {
        commission: {},
        isLoading: true,
        isEnabled: true
    });
    const { t } = useTranslation(['addons']);
    const refAlert = useRef(null);

    useEffect(() => {
        _getDetailCommissionAddons();
    }, []);

    const _getDetailCommissionAddons = () => {
        clientQuery(ADDONS_COMMISSION_TRACKING, { method: 'GET' }, _handleGetSuccess, checkAccessFail);
    };

    const { isLoading, commission, isEnabled } = state;

    const _handleGetSuccess = ({ data }) => {
        dispatchState({ commission: data, isLoading: false, isEnabled: !!data.status });
    };

    const _handleSuccess = (value) => {
        dispatchState({ isEnabled: !!value });
    };

    return (
        <div className="addons-wrap__container">
            <AddonHeaderDetail isLoading={isLoading} tutorial={commission.tutorial} />
            <AlertCustomer ref={refAlert} />
            <div className="scrolls">
                <div className="boxs-wrapper">
                    {isLoading ? (
                        <CommissionAddonsLoading />
                    ) : (
                        <>
                            <CommissionAddonsContent {...commission} handleSuccess={_handleSuccess} />
                            <div className="boxs boxs-has-footer active">
                                <div className="boxs__header">
                                    <div className="title">{t('addons:commission_manager')}</div>
                                </div>
                                <div className="boxs__contents ">
                                    <p className="description">{t('addons:commission_manager_description')}</p>
                                </div>
                                <div className="boxs__footer">
                                    <Link
                                        to={{
                                            pathname: addBranchPath(COMMISSIONS),
                                            state: { redirectManage: true }
                                        }}
                                        className={classNames('v2-btn-default svg-purple --purple --icon-r', {
                                            'is-disable': !isEnabled
                                        })}
                                    >
                                        {t('addons:manager')} <IconCogWheel />
                                    </Link>
                                </div>
                            </div>
                            <AddonsOptions data={commission.options} urlUpdate={UPDATE_ADDONS_COMMISSION_OPTIONS} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddonsCommissionTracking;
