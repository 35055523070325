import React, { useEffect, useReducer, useContext, Fragment, useRef } from 'react';
import { clientQuery } from 'common/utils/ApiUtils';
import { listEmailsSimplify, listCardsSimplify } from 'app/const/Api';
import SettingRepeat from 'app/modules/jobdetail/tabs/addinvoice/form/settings/repeat';
import InvoiceRepeatContextProvider from 'app/modules/jobdetail/contexts/InvoiceRepeatContext';
import { AddInvoiceContext } from 'app/modules/jobdetail/contexts/AddInvoiceContext';
import IconCogWheel from 'assets/icon/IconCogWheel';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';
import GdConfirm from 'app/components/confirm';
import { TYPE_INVOICE_REPEAT } from 'app/modules/jobdetail/const/Invoice';

export default function InvoiceRepeat() {
    const { t } = useTranslation('common');
    const [state, dispatchState] = useReducer(reducer, {
        isVisibleSetting: false,
        listEmail: {},
        listCard: []
    });

    const { invoiceData } = useContext(AddInvoiceContext);
    const { isVisibleSetting: finalIsVisibleSetting, listEmail, listCard } = state;
    const { recurrence: invoiceRecurrence = {} } = invoiceData;
    const refConfirm = useRef(null);
    const { repeat: finalInvoiceRepeat, summary: finalSummary = '' } = invoiceRecurrence || {};

    useEffect(() => {
        if (finalIsVisibleSetting && invoiceData.location_id) {
            _getListEmailsSimplify();
            _getListCardsSimplify();
        }
    }, [finalIsVisibleSetting]);

    const _getListEmailsSimplify = () => {
        const _getListEmailsSimplifySuccess = (response) => {
            dispatchState({
                listEmail: response?.data || []
            });
        };

        clientQuery(
            listEmailsSimplify(invoiceData.customer_id),
            { method: 'GET', data: { location_id: invoiceData.location_id } },
            _getListEmailsSimplifySuccess
        );
    };

    const _getListCardsSimplify = () => {
        const _getListCardsSimplifySuccess = (response) => {
            dispatchState({
                listCard: response?.data || []
            });
        };

        clientQuery(listCardsSimplify(invoiceData.customer_id), { method: 'GET' }, _getListCardsSimplifySuccess);
    };

    function _toggleInvoiceRepeat() {
        if (!invoiceData.location_id && !invoiceData.settingService) {
            const elm = document.getElementById('location_id');
            elm && elm.classList.add('field-error');
            refConfirm.current?.open(null, 'Please select location', 'Warning');

            setTimeout(() => {
                elm && elm.classList.remove('field-error');
            }, 3000);

            return false;
        }
        dispatchState({ isVisibleSetting: true });
        const elm = document.getElementById('tab_inoive_detail_setting');
        if (elm) {
            elm.classList.add('show-repeat-inv');
            elm.setAttribute('data-form-repeat', true);
        }
    }

    function _closeInvoiceRepeat() {
        dispatchState({ isVisibleSetting: false });
        const elm = document.getElementById('tab_inoive_detail_setting');

        if (elm) {
            elm.classList.remove('show-repeat-inv');
            elm.setAttribute('data-form-repeat', false);
        }
    }

    function _renderFormRepeat() {
        if (!finalIsVisibleSetting) {
            return false;
        }

        return (
            <InvoiceRepeatContextProvider
                isJobInvoice={invoiceData.isJobInvoice}
                invoiceRepeatData={{ ...invoiceRecurrence }}
            >
                <SettingRepeat onClose={_closeInvoiceRepeat} listEmail={listEmail} listCard={listCard} />
            </InvoiceRepeatContextProvider>
        );
    }

    return (
        <Fragment>
            <div className="content-elm-edit repeats-invoice">
                <div
                    disabled={true}
                    className={`repeats-invoice__link ${
                        finalInvoiceRepeat === TYPE_INVOICE_REPEAT.LOCK ? 'is-disable' : ''
                    } `}
                    onClick={_toggleInvoiceRepeat}
                >
                    <div className="v2-btn-default --icon-lg flex-auto tooltip" tabIndex="0">
                        <IconCogWheel />
                        <p className="tooltiptext top">{t('common:invoice_frequency')}</p>
                    </div>
                    {finalSummary?.length > 0
                        ? finalSummary
                        : invoiceData.isJobInvoice
                          ? 'This invoice repeats with the job.'
                          : 'One'}
                </div>
            </div>
            {_renderFormRepeat()}
            <GdConfirm
                ref={refConfirm}
                title="Confirm"
                listButton={{ confirm: true, cancel: false }}
                titleConfirm="Ok"
            />
        </Fragment>
    );
}
