import classNames from 'classnames';
import React, { useRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import RenderAvatar from 'app/components/avatar/RenderAvatar';
import { TYPE_PHONE_VOIP } from 'app/const/addons';
import { getItemStyle, getListStyle } from 'app/modules/smartview/components/utils';
import IconThreeDots from 'assets/icon/IconThreeDots';
import { getUserInfo } from 'common/utils/CompanyUserUtils';
import { ItemCheckbox } from './autoAttendant/ItemPhoneAutoAttendant';

const VoipDragAndDrop = ({
    options = [],
    isShowCheckbox = false,
    isShowDrag = true,
    onSelectOption = null,
    onDragEnd = () => {}
}) => {
    const refPlaceHolder = useRef(null);

    const _onDragEnd = (result = {}) => {
        if (refPlaceHolder.current) {
            refPlaceHolder.current.style.display = 'none';
            refPlaceHolder.current.style.top = 0;
            refPlaceHolder.current.style.left = 0;
        }
        if (!result.destination) return;
        const {
            source: { index: sourceIndex },
            destination: { index: destinationIndex }
        } = result;
        if (sourceIndex === destinationIndex) return;
        onDragEnd({ sourceIndex, destinationIndex });
    };

    const _onDragUpdate = (update = {}) => {
        if (!update.destination) return;
        const draggableId = update.draggableId;
        const initialIndex = update.source.index;
        const destinationIndex = update.destination.index;
        const queryAttr = 'data-rbd-drag-handle-draggable-id';
        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        if (!draggedDOM) return;
        const parentDom = draggedDOM.closest('.box-draggable');

        const arr = [...parentDom.children];
        if (initialIndex < destinationIndex) arr.splice(initialIndex, 1);

        const clientY = arr.slice(0, destinationIndex).reduce((total, curr) => {
            const style = curr.currentStyle || window.getComputedStyle(curr);
            const marginBottom = parseFloat(style.marginBottom);
            return total + curr.clientHeight + marginBottom;
        }, 0);

        if (refPlaceHolder.current) {
            refPlaceHolder.current.style.display = 'flex';
            refPlaceHolder.current.style.top = `${clientY}px`;
            refPlaceHolder.current.style.left = `${parseFloat(window.getComputedStyle(parentDom).paddingLeft)}px`;
        }
    };

    return (
        <DragDropContext onDragEnd={_onDragEnd} onDragUpdate={_onDragUpdate}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <ul
                        ref={provided.innerRef}
                        className={classNames('box-draggable scrolls', {
                            'flex-column mt-2': !isShowCheckbox
                        })}
                        {...provided.droppableProps}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        {options.map((item, index) => {
                            const { id, full_name, avatar, assignees, phone_number, type } = item;
                            const key = isShowCheckbox ? phone_number : id;
                            const isHideDrag = options.length < 2;

                            const _handleChangeValue = () => {
                                onSelectOption && onSelectOption({ value: { id, phone_number }, isChecked: false });
                            };

                            return (
                                <Draggable
                                    key={key}
                                    draggableId={key}
                                    index={index}
                                    isDragDisabled={isHideDrag || !isShowDrag}
                                >
                                    {(provided) => (
                                        <li
                                            key={key}
                                            className={classNames({
                                                'items active': isShowCheckbox,
                                                'user-items flexcenter gap-6': !isShowCheckbox
                                            })}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                        >
                                            {isHideDrag || !isShowDrag ? null : (
                                                <div
                                                    className={classNames({
                                                        'col-drag': !isShowCheckbox,
                                                        'icon-drag': isShowCheckbox
                                                    })}
                                                >
                                                    <IconThreeDots isDouble fillColor="#4D5054" />
                                                </div>
                                            )}
                                            {isShowCheckbox ? (
                                                <ItemCheckbox
                                                    idElement={key}
                                                    checked
                                                    isGroup={type === TYPE_PHONE_VOIP.GROUP}
                                                    {...item}
                                                    {...getUserInfo(assignees?.[0])}
                                                    onChangeValue={_handleChangeValue}
                                                />
                                            ) : !!avatar || !full_name ? (
                                                <RenderAvatar userData={{ avatar, full_name }} />
                                            ) : null}
                                            {!!full_name ? (
                                                <div className="txt-ellipsis flex-1" title={full_name}>
                                                    {full_name}
                                                </div>
                                            ) : null}
                                        </li>
                                    )}
                                </Draggable>
                            );
                        })}
                        {provided.placeholder}
                        <div
                            ref={refPlaceHolder}
                            className={classNames('has-drag absolute w-100', {
                                'user-items': !isShowCheckbox,
                                items: isShowCheckbox
                            })}
                            style={{ display: 'none' }}
                        />
                    </ul>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default VoipDragAndDrop;
