import IconError from 'assets/icon/IconError';
import classNames from 'classnames';
import React, { Fragment, forwardRef, useImperativeHandle, useRef } from 'react';

function AuthInputField(
    {
        label,
        error,
        inputDisable,
        onKeyPress = () => {},
        placeholder,
        name,
        type,
        onBlur = () => {},
        onChange = () => {},
        onFocus = () => {},
        autoFocus,
        isShowLabel = true,
        isShowIconError = true,
        labelField = ''
    },
    ref
) {
    const inputRef = useRef();

    useImperativeHandle(ref, () => ({
        setValue: _onSetValue
    }));

    const _onSetValue = (value = '') => {
        inputRef.current.value = value;
    };

    const _handleKeyPress = (e) => {
        onKeyPress(e);
    };

    const _handleBlur = (e) => {
        onBlur(e);
    };

    const _handleFocus = (e) => {
        onFocus(e);
    };

    const _handleChange = (e) => {
        onChange(e);
    };

    return (
        <React.Fragment>
            {isShowLabel && <label className="fs-15">{label}</label>}
            <input
                ref={inputRef}
                className={classNames('field-input', { error })}
                placeholder={placeholder}
                disabled={inputDisable}
                onKeyDown={_handleKeyPress}
                name={name}
                type={type}
                onBlur={_handleBlur}
                onChange={_handleChange}
                onFocus={_handleFocus}
                autoFocus={autoFocus}
                autoComplete={name === 'password' ? 'current-password' : undefined}
                data-label-field={labelField || name}
            />
            {error && (
                <span className="txt-incorrect">
                    {isShowIconError && (
                        <Fragment>
                            <IconError />
                            &nbsp;
                        </Fragment>
                    )}
                    {error}
                </span>
            )}
        </React.Fragment>
    );
}

export default forwardRef(AuthInputField);
