import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';

const ServiceJobPlan = ({ onCreateJobPlan, onCloseCreateJobPlan }, ref) => {
    const { newJobPlanData, actionBackPlanDetail } = useSelector(({ createJobReducer }) => createJobReducer);

    useImperativeHandle(ref, () => ({ onFallBack: _handleFallBackService }));

    useEffect(() => {
        if (newJobPlanData) {
            onCreateJobPlan(newJobPlanData);
        } else {
            onCloseCreateJobPlan();
        }
    }, [newJobPlanData]);

    const _handleFallBackService = (newData) => {
        actionBackPlanDetail(newData);
    };

    return false;
};

export default forwardRef(ServiceJobPlan);
