import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useVirtual } from 'react-virtual';

import CrewGroup from './CrewGroup';
import { reducer } from 'app/const/Reducer';

const CrewTab = ({ crewSelected = [], onSelectCrew = () => {} }) => {
    const { t } = useTranslation('setting');

    const { group, withOutGroup, users } = useSelector(({ companyUsers }) => companyUsers.crew);
    const isHaveWithOutGroup = !!withOutGroup.length;
    const finalGroup = Object.values(group);

    const parentRef = React.useRef();
    const rowVirtualizer = useVirtual({
        size: finalGroup.length + (isHaveWithOutGroup ? 1 : 0),
        parentRef
    });

    const [state, dispatchState] = useReducer(reducer, {
        crewSelected: crewSelected.reduce((a, b) => {
            a[b] = b;
            return a;
        }, {})
    });
    const { crewSelected: finalCrewSelected } = state;

    const _handleSelectCrew = ({ id, isChecked, groupName, isGroup }) => {
        dispatchState((prev) => {
            const newSelected = { ...prev.crewSelected };

            const _handleUpdateSelected = (id = '') => {
                isChecked ? (newSelected[id] = id) : delete newSelected[id];
            };

            if (isGroup) {
                (group[groupName]?.crew_ids || []).forEach((item) => {
                    _handleUpdateSelected(item);
                });
            } else {
                _handleUpdateSelected(id);
            }

            return { ...prev, crewSelected: newSelected };
        });
    };

    const _handleApply = () => {
        onSelectCrew(Object.values(finalCrewSelected));
    };

    if (!Object.keys(users).length && !isHaveWithOutGroup)
        return <div className="p-2 content-empty black-jungle-green">{t('crew_is_empty')}</div>;

    return (
        <div className="wrap-crew">
            <div ref={parentRef} className="wrap-crew__group">
                <div
                    style={{
                        height: `${rowVirtualizer.totalSize}px`,
                        width: '100%',
                        position: 'relative'
                    }}
                >
                    {rowVirtualizer.virtualItems.map((virtualRow) => {
                        const index = virtualRow.index;
                        if (index === 0 && isHaveWithOutGroup)
                            return (
                                <CrewGroup
                                    key={'withOutGroup'}
                                    crewSelected={finalCrewSelected}
                                    group={{ crew_ids: withOutGroup }}
                                    isWithOutGroup
                                    onSelectCrew={_handleSelectCrew}
                                    virtualRow={virtualRow}
                                />
                            );
                        const item = finalGroup[isHaveWithOutGroup ? index - 1 : index];

                        return (
                            <CrewGroup
                                key={item.id}
                                crewSelected={finalCrewSelected}
                                group={item}
                                onSelectCrew={_handleSelectCrew}
                                virtualRow={virtualRow}
                            />
                        );
                    })}
                </div>
            </div>
            <div className="v2-btn-main justify-center m-2" onClick={_handleApply}>
                {t('common:apply')}
            </div>
        </div>
    );
};

export default CrewTab;
