import loadable from '@loadable/component';
import React, { forwardRef, Fragment, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import ButtonSave from 'app/components/button/ButtonSave';
import GDStatusBar from 'app/components/status/statusbar';
import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';
import IconDownload from 'assets/icon/IconDownload';
import IconPrint from 'assets/icon/IconPrint';
import IconSingleStep from 'assets/icon/IconSingleStep';
import { showStatusBar } from 'common/utils/FunctionUtils';
import {
    DEFAULT_STEP_VERIFY,
    ID_INPUT_AUTH_VERIFY,
    ID_PRINT_BACKUP_CODES,
    METHOD_2FA,
    TITLE_MODAL_AUTH,
    TWO_AUTHENTICATION_TYPES,
    TYPE_VERIFY_MODAL_AUTH
} from '../constants';

const AuthBackupContent = loadable(() => import('./AuthBackupContent'));
const AuthEmailContent = loadable(() => import('./AuthEmailContent'));
const AuthGoogleContent = loadable(() => import('./AuthGoogleContent'));

const ModalAuthVerify = ({ onVerify = () => {} }, ref) => {
    const { t } = useTranslation('setting');
    const { GOOGLE, BACKUP, EMAIL } = TWO_AUTHENTICATION_TYPES;

    const [state, dispatchState] = useReducer(reducer, {
        visible: false,
        step: DEFAULT_STEP_VERIFY,
        typeModal: GOOGLE,
        typeVerify: TYPE_VERIFY_MODAL_AUTH.ADD,
        methodId: null,
        disableButton: true,
        codes: []
    });
    const {
        visible: finalVisible,
        step: finalStep,
        typeModal: finalTypeModal,
        typeVerify: finalTypeVerify,
        methodId: finalMethodID,
        disableButton: finalDisableButton,
        codes: finalCodes
    } = state;
    const titleModalVerify = TITLE_MODAL_AUTH[finalTypeVerify][finalTypeModal];

    const refAlert = useRef(null);
    const refBtnSave = useRef(null);

    useImperativeHandle(ref, () => ({
        open: _handleOpen,
        close: _handleClose,
        alert: _handleShowAlert,
        setDisableButton: _handleDisableButton
    }));

    const _handleOpen = (data = {}) => {
        const { typeModal = '', id: methodId = '' } = data;
        dispatchState((prev) => ({ ...prev, visible: true, ...data, methodId: typeModal === EMAIL ? methodId : null }));
    };

    const _handleChangeStep = (step) => {
        dispatchState({ step });
    };

    const _handleClose = () => {
        dispatchState({ visible: false, step: DEFAULT_STEP_VERIFY, disableButton: true, codes: [] });
    };

    const _handleDisableButton = () => {
        dispatchState((prev) => ({ ...prev, disableButton: true }));
    };

    const _handleVerify = () => {
        const value = document.querySelector(`#${ID_INPUT_AUTH_VERIFY}`)?.value || '';
        onVerify({ value, methodId: finalMethodID });
    };

    const _handleChangeMethodId = (value) => {
        dispatchState((prev) => ({ ...prev, methodId: Number(value) }));
    };

    const _handleChangeDisableButton = (value) => {
        dispatchState((prev) => ({ ...prev, disableButton: value }));
    };

    const _renderContent = () => {
        const { lengthCode } = METHOD_2FA[finalTypeModal] || {};
        switch (finalTypeModal) {
            case GOOGLE:
                return (
                    <AuthGoogleContent
                        step={finalStep}
                        lengthCode={lengthCode}
                        onChangeStep={_handleChangeStep}
                        onShowAlert={_handleShowAlert}
                        onChangeMethodId={_handleChangeMethodId}
                        onChangeDisableButton={_handleChangeDisableButton}
                    />
                );
            case EMAIL:
                return (
                    <AuthEmailContent
                        lengthCode={lengthCode}
                        onShowAlert={_handleShowAlert}
                        onChangeMethodId={_handleChangeMethodId}
                        onChangeDisableButton={_handleChangeDisableButton}
                    />
                );
            case BACKUP:
                return <AuthBackupContent codes={finalCodes} />;
            default:
                return null;
        }
    };

    const _handleShowAlert = ({ message, success = false }) => {
        showStatusBar({ id: 'modal_2fa', message, success, refAlert });
        refBtnSave.current?.removeLoading();
    };

    const _handlePrint = () => {
        const printWindow = window.open('', '', 'height=600,width=800');
        printWindow.document.write(`<html><head><title>${titleModalVerify}</title>`);
        printWindow.document.write('<style>@page { size: A5; margin: 10mm; }</style>');
        printWindow.document.write('</head><body >');
        printWindow.document.write(document.getElementById(ID_PRINT_BACKUP_CODES).innerHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
    };

    const _handleDownload = () => {
        const textContent = document.getElementById(ID_PRINT_BACKUP_CODES).innerText;
        const blob = new Blob([textContent], { type: 'text/plain;charset=utf-8' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'BackupCodes.txt';
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
    };

    if (!finalVisible) return null;

    return (
        <ReactModal isOpen className="modal container-modal modal-authenticator open">
            <div className="modal__overlay bg-fixed" onClick={_handleClose} />
            <div className="modal__container large">
                <div className="header-modal">
                    {finalStep !== DEFAULT_STEP_VERIFY ? (
                        <div
                            className="v2-btn-default --icon-lg mr-2"
                            onClick={() => _handleChangeStep(DEFAULT_STEP_VERIFY)}
                        >
                            <IconSingleStep isPrev />
                        </div>
                    ) : null}
                    <h3 className="header-modal__label">{titleModalVerify}</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_handleClose}>
                        <IconClose />
                    </div>
                </div>
                <div className="body-modal scrolls">
                    <GDStatusBar ref={refAlert} />
                    {_renderContent()}
                </div>
                <div className="footer-modal justify-end">
                    {finalTypeModal === BACKUP ? (
                        <Fragment>
                            <div className="v2-btn-default has-icon" onClick={_handleDownload}>
                                <IconDownload />
                                {t('common:download')}
                            </div>
                            <div className="flexcenter justify-end flex-1">
                                <div className="v2-btn-default --transparent" onClick={_handleClose}>
                                    {t('cancel')}
                                </div>
                                <div className="v2-btn-main has-icon svg-white-stroke ml-2" onClick={_handlePrint}>
                                    <IconPrint />
                                    {t('common:print')}
                                </div>
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className="v2-btn-default --transparent" onClick={_handleClose}>
                                {t('cancel')}
                            </div>
                            <ButtonSave
                                ref={refBtnSave}
                                title={t('verify')}
                                disabled={finalDisableButton}
                                onSave={_handleVerify}
                            />
                        </Fragment>
                    )}
                </div>
            </div>
        </ReactModal>
    );
};

export default forwardRef(ModalAuthVerify);
