import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import GdTags from 'app/components/tags';
import { getCustomerContact } from 'app/const/Api';
import { LIMIT_TAGS, TYPE_TAGS } from 'app/const/Customers';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import AddressInfo from 'app/modules/customer/add/components/address/AddressInfo';
import { clientQuery } from 'common/utils/ApiUtils';
import DropdownCustomerContact from './DropdownCustomerContact';
import LocationBillingEmail from './LocationBillingEmail';

const LocationBilling = (
    {
        customerId = '',
        options = [],
        selected = {},
        isSame = true,
        dataUpdate = {},
        isUpdate = false,
        isLoading = true,
        billingAddressOptions = [],
        isLoadingAddress,
        isLoadMore = false,
        onHandleCheckSame = () => {},
        onSelect = () => {},
        onVisible = () => {},
        onSetSameAddress = () => {},
        onHandleGetBillingAddress = () => {},
        onScrollBillingTo = () => {}
    },
    ref
) => {
    const { t } = useTranslation('customers');
    const [emails, setEmails] = useState([]);
    const refFormLocation = useRef(null);
    const refTags = useRef(null);
    const refInvoiceTags = useRef(null);
    const refBillingEmail = useRef(null);
    const refBillingEmailCC = useRef(null);
    const refAddressInfo = useRef(true);
    const isFirstTime = useRef(true);

    useImperativeHandle(ref, () => ({
        _getValue,
        _setValueForm,
        _updateTagGlobal: () => refTags.current.updateGlobal()
    }));

    const _getValue = () => {
        return {
            billing_address: { bill_to: selected.full_name, ..._handleGetDataAddress() },
            billing_email: refBillingEmail.current.getValue(),
            billing_email_cc: refBillingEmailCC.current._getValue(),
            tags: refTags.current.getValue(),
            invoice_tags: refInvoiceTags.current.getValue()
        };
    };

    const _setValueForm = (name, newValue) => {
        if (typeof newValue !== 'string') {
            for (const key in newValue) {
                refFormLocation.current[`billing_${key}`].value = newValue[key];
            }
        } else {
            refFormLocation.current[`billing_${name}`].value = newValue;
        }
    };

    const _handleGetDataAddress = () => {
        const result = refAddressInfo.current.getValue();

        delete result['lat'];
        delete result['lng'];
        delete result['formattedAddress'];
        delete result['country'];
        delete result['county'];

        return result;
    };

    const _handleGetContact = () => {
        if (isFirstTime.current) {
            clientQuery(
                getCustomerContact(customerId),
                { method: 'GET', data: { inc: 'phones' } },
                _getCustomerInfoSuccess
            );
        }
    };

    const _getCustomerInfoSuccess = ({ data }) => {
        isFirstTime.current = false;
        setEmails([{ id: '', email: 'None', type: 'empty' }, ...data.emails]);
    };

    const _handleSelectBilling = (id) => {
        const result = billingAddressOptions.find((item) => item.id === id) || {};

        refAddressInfo.current.setValue({
            ...result,
            name: result.billing_address_1 || '',
            street2: result.billing_address_2 || '',
            city: result.billing_city || '',
            state: result.billing_state || '',
            zip: result.billing_zip || ''
        });
        onHandleCheckSame(false);
    };

    return (
        <div className="column-location">
            <span className="title">
                {t('billing_address')}
                <div className="switch large">
                    <input
                        id="toggle-billing"
                        className="toggle toggle-round"
                        type="checkbox"
                        checked={isSame}
                        onChange={onSetSameAddress}
                    />
                    <label htmlFor="toggle-billing">
                        <span className="same" />
                    </label>
                </div>
            </span>

            <div className="rows">
                <div className="txt txt-ellipsis">
                    <span className="red-delete">*&nbsp;</span>
                    {t('bill_to')}
                </div>
                <DropdownCustomerContact
                    id="billing_to_location"
                    selected={selected}
                    options={options}
                    isLoading={isLoading}
                    isLoadMore={isLoadMore}
                    onSelect={onSelect}
                    onVisible={onVisible}
                    onScroll={onScrollBillingTo}
                />
            </div>

            <form ref={refFormLocation} className="rows">
                <div className="txt">{t('billing_address')}</div>
                <div className="has-many-address">
                    <AddressInfo
                        ref={refAddressInfo}
                        type="billing"
                        defaultValue={isUpdate ? { ...dataUpdate?.address?.billing, id: dataUpdate.id } : {}}
                        billingAddressOptions={billingAddressOptions}
                        onHandleGetBillingAddress={onHandleGetBillingAddress}
                        onHandleSelectBilling={_handleSelectBilling}
                        isLoading={isLoadingAddress}
                        shouldCheckExisted={false}
                        onHandleCheckSame={onHandleCheckSame}
                    />
                </div>
            </form>

            <LocationBillingEmail
                ref={refBillingEmail}
                options={emails}
                defaultSelect={dataUpdate.billing_email}
                isFirstTimeFetch={isFirstTime.current}
                openOptions={_handleGetContact}
            />

            <EmailBillingCC
                ref={refBillingEmailCC}
                options={emails}
                onVisible={_handleGetContact}
                defaultSelect={dataUpdate?.billing_email_cc?.[0]}
                isFirstTimeFetch={isFirstTime.current}
            />

            <div className="rows">
                <div className="txt">{t('location_tags')}</div>
                <GdTags
                    ref={refTags}
                    id="add-location-location-tags"
                    type={TYPE_TAGS.TAG_CUSTOMER}
                    isDropUp
                    deleteWithId
                    defaultTags={dataUpdate?.tags || []}
                />
            </div>
            <div className="rows">
                <div className="txt">{t('invoice_tags')}</div>
                <GdTags
                    ref={refInvoiceTags}
                    id="add-location-invoice-tags"
                    isDropUp
                    deleteWithId
                    limit={LIMIT_TAGS.TAG_INVOICE}
                    type={TYPE_TAGS.TAG_INVOICE}
                    defaultTags={dataUpdate?.invoice_tags || []}
                />
            </div>
        </div>
    );
};

const EmailBillingCC = forwardRef(({ options = [], isFirstTimeFetch = false, defaultSelect = null, ...props }, ref) => {
    const { t } = useTranslation('customers');
    const refDropdown = useRef(null);
    const [selected, setSelected] = useState(defaultSelect || { id: null, email: 'None' });

    useImperativeHandle(ref, () => ({ _getValue: () => (selected.id ? selected : {}) }));

    const _handleSelect = (id) => {
        setSelected(options.find((item) => item.id === id) || { id: null, email: 'None' });
    };

    return (
        <div className="rows">
            <div className="txt txt-ellipsis">{t('billing_cc')}</div>
            <CalendarDropdown
                ref={refDropdown}
                id="billing_email_cc_location"
                keyGetValue="id"
                keyGetName="email"
                options={options}
                selected={selected?.email}
                selectedOption={selected}
                onSelect={_handleSelect}
                isLoading={isFirstTimeFetch}
                {...props}
            />
        </div>
    );
});

export default forwardRef(LocationBilling);
