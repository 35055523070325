import classNames from 'classnames';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import { AUTH_LOGIN } from 'app/const/Route';
import AuthInputField from 'app/modules/auth/components/AuthInputField';
import ButtonLoading from '../../components/ButtonLoading';

const FormIdentify = ({ onSubmitForm, errorsForm, formDisable, onKeyPress, onFocusField, t }) => {
    let timer;
    const userNameRef = useRef(null);
    const onSubmit = () => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            onSubmitForm && onSubmitForm();
        }, 300);
    };

    const _handleKeyPress = (e) => {
        onKeyPress && onKeyPress(e);
    };
    const _handleFocus = (e) => {
        onFocusField && onFocusField(e);
    };

    const _handleChange = (e) => {
        if (e.target.name === 'username') userNameRef.current?.setValue(e.target.value.toLowerCase());
    };

    return (
        <React.Fragment>
            <div className="user-box">
                <AuthInputField
                    ref={userNameRef}
                    label={t('common:username_or_email')}
                    placeholder={t('common:email')}
                    type="text"
                    name="username"
                    error={errorsForm?.username}
                    onKeyPress={_handleKeyPress}
                    onFocus={_handleFocus}
                    onBlur={_handleChange}
                    onChange={_handleChange}
                    isShowIconError={false}
                    isShowLabel={false}
                    labelField="email"
                />
            </div>
            <div>
                <AuthInputField
                    label={t('auth:password')}
                    placeholder={t('auth:password')}
                    type="password"
                    name="password"
                    error={errorsForm?.password}
                    onKeyPress={_handleKeyPress}
                    onFocus={_handleFocus}
                    isShowIconError={false}
                    isShowLabel={false}
                />
            </div>
            <ButtonLoading
                classWrapper={classNames(`gd-getstarted v2-btn-main gd-btn-getstarted fw-500`, {
                    disable: formDisable
                })}
                text={t('auth:start_your_free_trial')}
                isLoading={formDisable}
                onSubmit={onSubmit}
            />
            <div className="line mt-2 mb-4" />
            <div className="gd-login-confirm black-3 fw-600 py-5">
                {t('common:you_have_account')}
                <Link className="gd-login-label" to={AUTH_LOGIN}>
                    {t('auth:login')}
                </Link>
            </div>
        </React.Fragment>
    );
};

export default FormIdentify;
