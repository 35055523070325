import queryStrings from 'query-string';
import React, { useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { SQUARE } from 'app/const/addons';
import { ADDONS_SQUARE_DETAIL, SQUARE_ADDONS_CONNECT, SQUARE_ADDONS_OPTIONS } from 'app/const/Api';
import { KEY_LOCAL_STORAGE_SQUARE } from 'app/const/Payments';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import { mixpanelManageAddons } from 'app/modules/mixpanel/MixpanelManageAddons';
import { updateAddonStatus } from 'common/redux/actions/authAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { getLocalStorageValue } from 'common/utils/LocalStorageUtils';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import AddonHeaderDetail from '../components/AddonHeaderDetail';
import AddonsAlert from '../components/AddonsAlert';
import AddonsOptions from '../components/AddonsOptions';
import SquareAddonsLoading from './components/SquareAddonsLoading';
import SquareContent from './components/SquareContent';

const AddonsSquare = () => {
    const dispatch = useDispatch();
    const profileId = useSelector(({ auth }) => auth.user.profile.id);
    const { search } = useLocation();
    const history = useHistory();
    const { code: authorization_code, state: squareState } = queryStrings.parse(search);
    const refAlert = useRef(null);
    const [state, dispatchState] = useReducer(reducer, { square: {}, isLoading: true });
    const { isLoading, square } = state;

    /* This is a React Hook that is being used to get the data from the API. */
    useEffect(() => {
        authorization_code ? _handleConnectWithSquare() : _getDetailSquareAddons();
    }, []);

    const _getDetailSquareAddons = () => {
        clientQuery(ADDONS_SQUARE_DETAIL, { data: {}, method: 'GET' }, _handleGetSuccess, checkAccessFail);
    };

    /**
     * When the server returns a successful response, update the state with the data.
     */
    const _handleGetSuccess = ({ data }) => {
        dispatchState({ square: data, isLoading: false });
    };

    const _handleConnectWithSquare = () => {
        const squareStateLocal = getLocalStorageValue(KEY_LOCAL_STORAGE_SQUARE);

        if (squareStateLocal !== squareState) {
            history.replace({ search: '' });
            _getDetailSquareAddons();
        } else {
            const _handleConnectSuccess = ({ data }) => {
                /* Replacing the search string in the URL with an empty string. */
                history.replace({ search: '' });
                handleTrackingEvent(mixpanelManageAddons({ id: profileId }));
                dispatch(updateAddonStatus({ keyword: SQUARE, data: data?.addons || {} }));
                _getDetailSquareAddons();
            };

            clientQuery(SQUARE_ADDONS_CONNECT, { data: { authorization_code }, method: 'POST' }, _handleConnectSuccess);
        }
    };

    const _handleDisplayAlert = (data) => {
        refAlert.current.showStatusBar(data);
    };

    const _handleDisableUpdate = () => {
        refAlert.current.showStatusBar({
            id: 'disable_update',
            message: 'You have to connect to Square to activate Square addons.',
            type: LIST_STATUS.ERROR
        });
    };

    const _handleDisconnect = () => {
        dispatchState({ square: { ...square, status: 0 } });
    };

    return (
        <div className="addons-wrap__container">
            <AddonHeaderDetail isLoading={isLoading} tutorial={square.tutorial} />
            <div className="scrolls">
                <div className="boxs-wrapper --stripe">
                    <AddonsAlert ref={refAlert} />
                    {isLoading ? (
                        <SquareAddonsLoading />
                    ) : (
                        <>
                            <SquareContent
                                {...square}
                                onDisableUpdate={_handleDisableUpdate}
                                onDisconnect={_handleDisconnect}
                            />
                            <AddonsOptions
                                data={square.options}
                                urlUpdate={SQUARE_ADDONS_OPTIONS}
                                onDisplayAlert={_handleDisplayAlert}
                                status={square.status}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddonsSquare;
