import RenderAvatar from 'app/components/avatar/RenderAvatar';
import IconAssign from 'assets/icon/IconAssign';
import { getUserInfo } from 'common/utils/CompanyUserUtils';
import React from 'react';

export default function AssignPreview({ onToggle, schedules }) {
    const _renderListSchedules = () => {
        const number = schedules.length;

        if (!number) {
            return (
                <div onClick={onToggle} className="flexcenter gap-4">
                    <IconAssign isCircle />
                    <span className="black-darklight nowrap">No Tech</span>
                </div>
            );
        }

        const remaining = number - 5;
        const renderShedules = schedules.slice(0, 5);

        return (
            <div className="wrap-member-group flexcenter flex-1">
                {renderShedules.map((item) => {
                    const { id, is_primary } = item;
                    return (
                        <RenderAvatar
                            key={id}
                            userData={getUserInfo(item.user_id)}
                            extendClass={!!is_primary ? '--primary' : ''}
                        />
                    );
                })}
                {remaining > 0 && <span className="avt-img avt-more fs-10 fw-500">+{remaining}</span>}
            </div>
        );
    };

    return <div className="wrap-member-group flexcenter flex-1">{_renderListSchedules()}</div>;
}
