import React from 'react';

const ProgressBarExport = ({ message = '', percent = 0 }) => {
    return (
        <div className="wrap-process --has-icon">
            <p className="wrap-process__label">{message}</p>
            <div className={`progress-bar --blue per-${percent >= 100 ? 100 : Math.round(percent)}`}>
                <span />
            </div>
        </div>
    );
};

export default ProgressBarExport;
