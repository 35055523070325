import {
    ACTIVITY_COMPARISON,
    INBOX_HOME,
    OPPORTUNITIES_INBOX,
    SALES_FUNNEL,
    SMART_DIALER,
    SMART_VIEW,
    STATUS_CHANGES
} from 'app/config/routes';
import ActivityComparison from './comparison';
import SmartView from '../smartview';
import { KEY_SUB_INBOX, TYPE_FEATURE_SETTING, TYPE_OPPORTUNITY_INBOX } from './const';
import { INBOX_EMAIL } from 'app/const/addons';
import InboxPage from './Inbox';
import DialerCallDone from '../layouts/GDVoip/components/smartDialer/DialerCallDone';
import OpportunitiesPipeline from './opportunity/pipeline';
import SaleFunnel from './opportunity/saleFunnel';
import StatusChanges from './opportunity/statusChanges';

const { OUTBOX, DONE, SENT, FUTURE, DRAFT } = KEY_SUB_INBOX;

export default [
    {
        id: TYPE_FEATURE_SETTING.COMPARISON,
        path: ACTIVITY_COMPARISON,
        component: ActivityComparison,
        title: 'activity_comparison',
        isUseFeature: true
    },
    {
        id: TYPE_FEATURE_SETTING.SMART_VIEWS,
        path: SMART_VIEW,
        component: SmartView,
        title: 'smart_view'
    },
    {
        id: TYPE_FEATURE_SETTING.SMART_DIALER,
        path: SMART_DIALER,
        component: DialerCallDone,
        title: 'smart_dialer',
        isUseFeature: true
    },
    {
        id: TYPE_OPPORTUNITY_INBOX.PIPELINE,
        path: OPPORTUNITIES_INBOX,
        component: OpportunitiesPipeline,
        title: 'opportunities_pipeline'
    },
    {
        id: TYPE_OPPORTUNITY_INBOX.SALES_FUNNEL,
        path: SALES_FUNNEL,
        component: SaleFunnel,
        title: 'sales_funnel'
    },
    {
        id: TYPE_OPPORTUNITY_INBOX.STATUS_CHANGES,
        path: STATUS_CHANGES,
        component: StatusChanges,
        title: 'status_changes'
    },
    {
        id: INBOX_EMAIL,
        path: INBOX_HOME,
        component: InboxPage,
        childPath: [OUTBOX, DONE, SENT, FUTURE, DRAFT],
        title: 'inbox'
    }
];
