import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { RouteRender } from 'app/components/routes/RouteWithSubRoutes';
import { ADDONS_AI_AGENTS } from 'app/const/addons';
import ErrorPage from 'app/modules/error';
import { getPlanUser } from 'common/utils/PermissionUtils';
import AIConversationDetail from '../testSmsChatbot/AIConversationDetail';
import SideMenu from './SideMenu';

const ChatbotLayout = ({ routes = [], ...props }) => {
    const { t } = useTranslation();
    const activeChatbot = useSelector(({ auth }) => auth.user.settings.addons[ADDONS_AI_AGENTS]);
    const userProfile = useSelector(({ auth }) => auth.user.profile);
    const { isBasicPlan } = getPlanUser(userProfile);
    const havePermission = !isBasicPlan && !!activeChatbot;

    const refSideMenu = useRef(null);
    const refAIConversationDetail = useRef(null);

    const _handleOpenConversation = () => {
        refAIConversationDetail.current._open();
    };

    const _handleCloseConversation = () => {
        refSideMenu.current.setDisableButton(false);
    };

    if (!havePermission) return <ErrorPage errorMessage={t('page_is_unavailable')} />;
    return (
        <div className="container-chatbot addons-chatbot boxs-notification">
            <SideMenu ref={refSideMenu} onOpen={_handleOpenConversation} />
            <Switch>
                {routes.map((route, i) => {
                    const result = { ...route };
                    if (route.path.split(',').length > 1) {
                        result.path = route.path.split(',');
                    }

                    return <Route key={i} path={result.path} render={() => <RouteRender {...props} route={route} />} />;
                })}
            </Switch>
            <AIConversationDetail ref={refAIConversationDetail} onClose={_handleCloseConversation} />
        </div>
    );
};

export default ChatbotLayout;
