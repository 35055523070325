import React, { useReducer, useEffect, useRef } from 'react';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import IconDropDown from 'assets/icon/IconDropDown';
import IconLoading from 'assets/icon/IconLoading';
import { reducer } from 'app/const/Reducer';

const LocationInfo = ({
    name,
    title = '',
    classWrapper,
    options,
    selected,
    onSelect,
    onScrollContent = () => {},
    isLoading,
    selectedParent = {},
    onClose = () => {}
}) => {
    const [state, dispatchState] = useReducer(reducer, {
        dropDownVisible: false
    });
    const refSearch = useRef({});
    const refDropDown = useRef(null);
    const dropDownVisible = state.dropDownVisible;

    useEffect(() => {
        if (dropDownVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);

            onClose();
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [dropDownVisible]);

    const handleHideDropdown = (event) => {
        const elPrevent = document.getElementById('show_list_dropdown');

        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeDropdown();
            return false;
        }

        const finalKey = event.key;
        const listItem = options.filter((item) => item.name[0].toLowerCase().includes(finalKey));

        const oldValue = refSearch.current[finalKey] || 0;
        let findItem = listItem[oldValue];
        refSearch.current = { [finalKey]: oldValue + 1 };

        if (!findItem) {
            findItem = listItem[0];
            refSearch.current = { [finalKey]: findItem ? 1 : 0 };
        }

        if (findItem) {
            onSelect(name, { ...findItem, autoSelect: true });
            setTimeout(() => {
                const element = document.getElementById(`location_item_${findItem.id}`);
                element && element.scrollIntoView();
            }, 0);
        }
    };

    const handleClickOutside = (event) => {
        const elPrevent = document.getElementById('show_list_dropdown');
        if (
            refDropDown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropDown.current.contains(event.target)
        ) {
            _closeDropdown();
        }
    };

    const _handleOpenDropdown = (e) => {
        e.stopPropagation();

        dispatchState({ dropDownVisible: !dropDownVisible });
    };

    const _closeDropdown = () => {
        dropDownVisible && dispatchState({ dropDownVisible: false });
    };

    const _handleOnSelect = (name, value) => {
        onSelect(name, value);
        _closeDropdown();
    };

    const _renderTitle = (title, list) => {
        if ((!Array.isArray(list) || list.length === 0) && !selectedParent.id) {
            return <span className="txt-ellipsis">{title}</span>;
        } else {
            const data = selectedParent.id ? selectedParent : list.find((item) => item.id === selected) || {};
            return <span className="txt-ellipsis">{data.name || title}</span>;
        }
    };

    const _renderListOptions = (list) => {
        if (isLoading) {
            return (
                <div className="items justify-center">
                    <div className="loading -ajaxbar">
                        <IconLoading />
                    </div>
                </div>
            );
        }

        if (!Array.isArray(list) || list.length === 0) return null;

        return list.map((item) => {
            const isCheck = selected === item?.id;

            return (
                <div
                    key={item.id}
                    id={`location_item_${item.id}`}
                    className={`items ${isCheck && 'active'}`}
                    onClick={() => {
                        _handleOnSelect(name, item);
                    }}
                    tabIndex="0"
                >
                    <span className="word-break">{item.name || ''}</span>
                </div>
            );
        });
    };

    return (
        <div ref={refDropDown} id={name} className={`${classWrapper} ${dropDownVisible ? 'active' : ''}`}>
            <div
                onClick={(e) => _handleOpenDropdown(e)}
                className="dropbtn items"
                tabIndex="0"
                id={`${name}_trigger_click`}
            >
                {_renderTitle(title, options)}
                <span className="arrow">
                    <IconDropDown />
                </span>
            </div>
            {Array.isArray(options) && dropDownVisible ? (
                <div
                    id="show_list_dropdown"
                    className="v2-dropdown__menu content-full scrolls"
                    onScroll={onScrollContent}
                >
                    <ul>{_renderListOptions(options)}</ul>
                </div>
            ) : null}
        </div>
    );
};

export default LocationInfo;
