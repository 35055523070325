import React, { createContext, useEffect, useReducer } from 'react';
import { reducer } from 'app/const/Reducer';
import { SOURCE_PHONE_NUMBER_VOIP, TYPE_MANAGE_ADDONS_VOIP, TYPE_PHONE_VOIP } from 'app/const/addons';
import { ACTION_PHONE_NUMBER } from 'app/const/api/Voip';
import { clientQuery } from 'common/utils/ApiUtils';

export const ActiveUsersContext = createContext();

export const ActiveUsersProvider = ({ children }) => {
    const [state, dispatchState] = useReducer(reducer, { data: [], groupNumber: [] });

    useEffect(() => {
        _fetchListPersonalNumber();
    }, []);

    const _fetchListPersonalNumber = () => {
        const _handleSuccess = ({ data }) => {
            const dataGroup = [];
            const dataPersonal = [];
            data.forEach((item) => {
                if (item.type === TYPE_PHONE_VOIP.PERSONAL) dataPersonal.push(item);
                else if (item.source !== SOURCE_PHONE_NUMBER_VOIP.EXTERNAL) dataGroup.push(item);
            });
            dispatchState((prev) => ({ ...prev, data: dataPersonal, groupNumber: dataGroup }));
        };

        clientQuery(ACTION_PHONE_NUMBER, { method: 'GET' }, _handleSuccess);
    };

    const _handleAssignPersonal = ({ numberId, userId }) =>
        dispatchState((prev) => ({
            ...prev,
            data: prev.data.map((item) => {
                if (item.id === numberId) item.assignees = [+userId];
                return item;
            })
        }));

    const _handleUnassignPersonal = ({ numberId }) =>
        dispatchState((prev) => ({
            ...prev,
            data: prev.data.map((item) => {
                if (item.id === numberId) item.assignees = [];
                return item;
            })
        }));

    const _handleSwapNumber = ({ oldUserId, newUserId }) => {
        dispatchState((prev) => ({
            ...prev,
            data: prev.data.map((item) => {
                const userAssignee = item.assignees[0];
                if (userAssignee === +oldUserId) item.assignees = [+newUserId];
                if (userAssignee === +newUserId) item.assignees = [+oldUserId];

                return item;
            })
        }));
    };

    const _handleDeleteUser = ({ userId }) => {
        dispatchState((prev) => ({
            ...prev,
            data: prev.data.map((item) => {
                if (item.assignees[0] === parseInt(userId) && item.type === TYPE_MANAGE_ADDONS_VOIP.personal)
                    item.assignees = [];
                return item;
            })
        }));
    };

    return (
        <ActiveUsersContext.Provider
            value={{
                ...state,
                onAssignPersonal: _handleAssignPersonal,
                onUnassignPersonal: _handleUnassignPersonal,
                onSwapNumber: _handleSwapNumber,
                onDeleteNumber: _handleDeleteUser
            }}
        >
            {children}
        </ActiveUsersContext.Provider>
    );
};
