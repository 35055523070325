import React, { useEffect, useReducer } from 'react';

import { ADDONS_WORK_LOG_MANAGE } from 'app/config/routes';
import { ADDONS_WORK_LOG_DETAIL, UPDATE_ADDONS_WORK_LOG_OPTIONS } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import AddonHeaderDetail from '../components/AddonHeaderDetail';
import AddonsOptions from '../components/AddonsOptions';
import WorkLogContent from './components/WorkLogContent';
import WorkLogLoading from './components/WorkLogLoading';

const AddonsTracker = () => {
    const [state, dispatchState] = useReducer(reducer, { worklog: {}, isLoading: true });
    const { isLoading, worklog } = state;

    useEffect(() => {
        _getDetailReviewAddons();
    }, []);

    const _getDetailReviewAddons = () => {
        const _handleGetSuccess = ({ data }) => {
            dispatchState({ worklog: data, isLoading: false });
        };
        clientQuery(ADDONS_WORK_LOG_DETAIL, { data: {}, method: 'GET' }, _handleGetSuccess, checkAccessFail);
    };

    return (
        <div className="addons-wrap__container">
            <AddonHeaderDetail isLoading={isLoading} tutorial={worklog.tutorial} />
            <div className="scrolls">
                <div className="boxs-wrapper">
                    {isLoading ? (
                        <WorkLogLoading />
                    ) : (
                        <>
                            <WorkLogContent {...worklog} />
                            <AddonsOptions
                                isPowerUp
                                shouldCheckManage
                                data={worklog.options}
                                pathManage={ADDONS_WORK_LOG_MANAGE}
                                urlUpdate={UPDATE_ADDONS_WORK_LOG_OPTIONS}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddonsTracker;
