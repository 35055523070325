import { MIXPANEL_METHOD, MIXPANEL_PROPERTIES, MIXPANEL_SIGNUP_EVENT, START_SIGN_UP_FORM } from 'app/const/Mixpanel';
import { capitalizeEachWord } from 'common/utils/StringUtils';

export const mixpanelSignup = ({ profile = {}, company = {}, event = START_SIGN_UP_FORM }) => {
    const peopleSet = {
        [MIXPANEL_PROPERTIES.$NAME]: profile.name ? capitalizeEachWord(profile.name) : undefined,
        [MIXPANEL_PROPERTIES.$EMAIL]: profile.email,
        [MIXPANEL_PROPERTIES.$PHONE]: company.phone,
        [MIXPANEL_PROPERTIES.COMPANY]: company.name ? capitalizeEachWord(company.name) : undefined,
        [MIXPANEL_PROPERTIES.ROLE]: profile.role || MIXPANEL_PROPERTIES.SUPERADMIN,
        [MIXPANEL_PROPERTIES.PLAN_TYPE]: MIXPANEL_PROPERTIES.FREE,
        [MIXPANEL_PROPERTIES.TERM_LENGTH]: MIXPANEL_PROPERTIES.TRIAL,
        [MIXPANEL_PROPERTIES.PLAN_PRICE]: 0,
        [MIXPANEL_PROPERTIES.METHOD]: profile.registration_method,
        [MIXPANEL_PROPERTIES.INDUSTRY]: company.industry,
        [MIXPANEL_PROPERTIES.TEAM_SIZE]: company.team_size,
        [MIXPANEL_PROPERTIES.REVENUE]: company.estimate,
        [MIXPANEL_PROPERTIES.ATTRIBUTION]: company.source,
        [MIXPANEL_PROPERTIES.COUNTRY]: company.country
    };

    return {
        type: MIXPANEL_SIGNUP_EVENT[event],
        data: {
            [MIXPANEL_METHOD.IDENTIFY]: profile.id,
            [MIXPANEL_METHOD.REGISTER]: peopleSet,
            [MIXPANEL_METHOD.SET]: peopleSet
        }
    };
};
