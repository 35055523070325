import firebase from 'firebase/app';
import 'firebase/auth';

import React, { useEffect } from 'react';

import { FIREBASE_CONFIG, LOGIN_TYPE } from 'app/const/Auth';
import IconFaceBook from 'assets/icon/IconFaceBook';
import IconGoogle from 'assets/icon/IconGoogle';

function FormLoginSocial({ onLoginSocial, onLoginFail = () => {}, t }) {
    useEffect(() => {
        if (!firebase.apps.length) firebase.initializeApp(FIREBASE_CONFIG);
    }, []);

    const loginWithGoogle = () => {
        const googleProvider = new firebase.auth.GoogleAuthProvider();
        googleProvider.setCustomParameters({ prompt: 'select_account' });
        googleProvider.addScope('email');
        firebase
            .auth()
            .signInWithPopup(googleProvider)
            .then((result) => {
                const profile = result.additionalUserInfo.profile;
                const {
                    id: profileId,
                    email: profileEmail,
                    given_name: profileGivenName,
                    family_name: profileFamilyName
                } = profile || {};
                const postData = {
                    access_token: profileId,
                    type: LOGIN_TYPE.google,
                    email: profileEmail,
                    first_name: profileGivenName,
                    last_name: profileFamilyName
                };
                result.user
                    .getIdToken()
                    .then((idToken) => {
                        _handleLoginSocial({ ...postData, id_token: idToken });
                    })
                    .catch(() => _handleLoginSocial(postData));
            })
            .catch(() => {});
    };
    const loginWithFaceBook = () => {
        const facebookProvider = new firebase.auth.FacebookAuthProvider();
        firebase
            .auth()
            .signInWithPopup(facebookProvider)
            .then((result) => {
                const profile = result.additionalUserInfo.profile;
                const {
                    id: profileId,
                    email: profileEmail,
                    first_name: profileFirstName,
                    last_name: profileLastName
                } = profile || {};
                const postData = {
                    access_token: profileId,
                    type: LOGIN_TYPE.facebook,
                    email: profileEmail,
                    first_name: profileFirstName,
                    last_name: profileLastName
                };

                result.user
                    .getIdToken()
                    .then((idToken) => {
                        _handleLoginSocial({ ...postData, id_token: idToken });
                    })
                    .catch(() => _handleLoginSocial(postData));
            })
            .catch(onLoginFail);
    };

    const _handleLoginSocial = (dataSocial) => {
        onLoginSocial(dataSocial);
    };

    return (
        <div className="login-action">
            <div className="login-action-btn --gg" onClick={loginWithGoogle}>
                <div className="login-action-btn__icon">
                    <IconGoogle />
                </div>
                <div className="login-action-btn__label">{t('common:login_with_google')}</div>
            </div>
            <div className="login-action-btn --fb" onClick={loginWithFaceBook}>
                <div className="login-action-btn__icon">
                    <IconFaceBook isCenter />
                </div>
                <div className="login-action-btn__label">{t('common:login_with_facebook')}</div>
            </div>
        </div>
    );
}

export default FormLoginSocial;
