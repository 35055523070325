import React from 'react';
import { useTranslation } from 'react-i18next';

import GdButton from 'app/components/button';
import { BUTTON_ACTION_PERMANENTLY_DELETE } from 'app/const/setting/SettingServiceTable';

function ListButtonAction({
    list = [],
    fileTranslation = 'report',
    isShowPermanentlyDelete = true,
    withSpacing,
    onSubmit = () => {},
    ...anotherProps
}) {
    const { t } = useTranslation(['setting']);

    if (!Array.isArray(list)) return null;

    const handleSubmit = (value) => {
        onSubmit(value);
    };

    const renderListButton = (list) => {
        // withSpacing: use margin & padding with Bootstrap class. Ex: withSpacing="ml-15"
        if (withSpacing) {
            return list.map((item) => {
                return (
                    <GdButton
                        key={item.id}
                        title={t(`${fileTranslation}:${item.label}`)}
                        onClick={() => {
                            handleSubmit(item.value);
                        }}
                        className={`${item.className} ${withSpacing}`}
                        iconClassName={item.iconClassName}
                        iconSvg={item.iconSvg}
                        {...anotherProps}
                    />
                );
            });
        }

        return list.map((item) => {
            if (!isShowPermanentlyDelete && item.label === BUTTON_ACTION_PERMANENTLY_DELETE.label) return null;
            return (
                <GdButton
                    key={item.id}
                    title={t(`${fileTranslation}:${item.label}`)}
                    onClick={() => {
                        handleSubmit(item.value);
                    }}
                    className={item.className}
                    iconClassName={item.iconClassName}
                    iconSvg={item.iconSvg}
                    {...anotherProps}
                />
            );
        });
    };
    return renderListButton(list);
}

export default ListButtonAction;
