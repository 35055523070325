import classNames from 'classnames';
import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from 'app/components/checkbox';
import { reducer } from 'app/const/Reducer';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconTooltip from 'assets/icon/IconTooltip';
import { getJobStatus } from 'common/utils/JobStatusUtils';
import CalendarDropdown from '../calendar/components/CalendarDropdown';
import ServiceNotifyCustomer from './ServiceNotifyCustomer';

const NotifyCustomer = ({ isDisable = false, status = null, parentNotify, parentSelected }, ref) => {
    const { t } = useTranslation(['jobDetail']);
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: false,
        listTemplate: [],
        selected: parentSelected || {},
        isNotifyCustomer: parentNotify || false
    });
    const {
        isNotifyCustomer: finalIsNotifyCustomer,
        selected: finalSelected,
        listTemplate: finalListTemplate,
        isLoading: finalIsLoading
    } = state;

    const finalJobStatus = getJobStatus(status)?.type;

    const refJobStatus = useRef(null);
    const refDropdown = useRef(null);
    const refServiceNotifyCustomer = useRef(null);

    useEffect(() => {
        if (finalIsNotifyCustomer && refJobStatus.current !== finalJobStatus) {
            _handleVisible();
        }
    }, [finalIsNotifyCustomer, finalJobStatus]);

    useImperativeHandle(ref, () => ({
        getValueNotifyCustomer: _getValueNotifyCustomer
    }));

    const _getValueNotifyCustomer = () => {
        return {
            isNotifyCustomer: finalIsNotifyCustomer,
            template: finalSelected
        };
    };

    const _handleChangeNotifyCustomer = (e) => {
        dispatchState((prev) => ({ ...prev, isNotifyCustomer: !!e.target.checked }));
    };

    const _renderOptions = () => {
        if (!finalListTemplate.length) return null;

        const _handleSelecteTemplate = (item) => {
            dispatchState((prev) => ({ ...prev, selected: item }));
            refDropdown.current?._closeDropdown();
        };

        return finalListTemplate.map((item) => {
            const { id, name } = item;

            return (
                <li
                    key={id}
                    className={classNames('items', { active: id === finalSelected?.id })}
                    onClick={() => _handleSelecteTemplate(item)}
                >
                    <span className="txt-ellipsis">{name}</span>
                </li>
            );
        });
    };

    const _renderButton = () => {
        return (
            <Fragment>
                <span className="txt-ellipsis">{finalSelected?.name || t('select_template')}</span>
                <div className="arrow">
                    <IconArrowDown />
                </div>
            </Fragment>
        );
    };

    const _handleVisible = () => {
        refJobStatus.current = finalJobStatus;
        if (!finalIsLoading) {
            dispatchState((prev) => ({ ...prev, isLoading: true }));
        }
        const _success = ({ data }) => {
            dispatchState((prev) => ({
                ...prev,
                listTemplate: data,
                selected: prev.selected.id ? prev.selected : data?.[0] || {},
                isLoading: false
            }));
        };
        const _fail = () => {
            dispatchState((prev) => ({ ...prev, listTemplate: [], selected: {}, isLoading: false }));
        };
        refServiceNotifyCustomer.current?.getListTemplate(status, _success, _fail);
    };

    return (
        <Fragment>
            <div className={classNames('wrap-notify__items', { 'is-disable': isDisable })}>
                <div className="check-items">
                    <Checkbox
                        checked={finalIsNotifyCustomer}
                        wrapClass="item-checkbox"
                        onChangeValue={_handleChangeNotifyCustomer}
                    >
                        <div className="flexcenter">
                            <p className="fw-600">{t('notify_customer_title')}</p>
                            <div className="tooltip ml-1 d-flex">
                                <IconTooltip />
                                <span className="tooltiptext top">{t('notify_customer_tooltip')}</span>
                            </div>
                        </div>
                    </Checkbox>
                </div>
                <CalendarDropdown
                    selectedOption={finalSelected}
                    ref={refDropdown}
                    id="dropdown-notify-customer"
                    buttonClassName="dropbtn items bg-white"
                    wrapperClassName={classNames('mt-2', { 'is-disable': !finalIsNotifyCustomer })}
                    isLoading={finalIsLoading}
                    isDropUp
                    customDropButton={_renderButton}
                >
                    <ul>{_renderOptions()}</ul>
                </CalendarDropdown>
            </div>
            <ServiceNotifyCustomer ref={refServiceNotifyCustomer} />
        </Fragment>
    );
};

export default forwardRef(NotifyCustomer);
