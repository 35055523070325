import ButtonSave from 'app/components/button/ButtonSave';
import { ADDONS_CALENDAR } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import classNames from 'classnames';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CalendarSelectJobSync from './CalendarSelectJobSync';
import SelectCalendarList from './SelectCalendarList';
import SelectScheduleList from './SelectScheduleList';
import { DEFAULT_ALL } from 'app/const/App';

const CalendarSetup = (
    {
        email = '',
        dataSync = {},
        dataAuth,
        isConnected = false,
        isFirstConnect = true,
        access_token = '',
        onSaveSuccess = () => {}
    },
    ref
) => {
    const { t } = useTranslation();
    const { job_statuses: finalJobStatuses = [] } = dataSync || {};
    const [state, dispatchState] = useReducer(reducer, {
        isChangeInfo: false,
        selectedCalendar: null,
        selectedSchedule: null,
        calendarList: []
    });
    const { isChangeInfo, isDisable: finalIsDisable } = state;

    const refSchedule = useRef();
    const refCalendar = useRef();
    const refButtonSave = useRef(null);

    useImperativeHandle(ref, () => ({ setIsChangeInfo: (value) => dispatchState({ isChangeInfo: value }) }));

    const _handleChange = () => dispatchState({ isChangeInfo: !isChangeInfo });

    const _handleSave = () => {
        const calendarValue = refCalendar.current._getValue() || {};
        const data = {
            schedule_id: refSchedule.current._getValue().id,
            calendar_id: calendarValue.id,
            calendar_email: email,
            calendar_summary: calendarValue.summary
        };

        if (isFirstConnect) data['token'] = dataAuth;

        const _handleSaveSuccess = ({ message }) => {
            onSaveSuccess(message);
            dispatchState({ isChangeInfo: !isChangeInfo });
        };

        const _handleSaveFinally = () => refButtonSave.current.removeLoading();

        clientQuery(
            ADDONS_CALENDAR,
            { data, toFormData: false, method: 'PUT' },
            _handleSaveSuccess,
            null,
            _handleSaveFinally
        );
    };

    if (!isConnected) return null;
    return (
        <>
            <div className={classNames('boxs elm-change-sync', { 'dp-hide': isChangeInfo })}>
                <h3 className="title --sm">GorillaDesk</h3>
                <CalendarSelectJobSync selectedStatuses={finalJobStatuses || DEFAULT_ALL} />
                <div className={classNames('v2-btn-default', { 'is-disable': finalIsDisable })} onClick={_handleChange}>
                    {t('change')}
                </div>
            </div>
            <div className={classNames('boxs boxs-change-info', { 'dp-hide': !isChangeInfo })}>
                <div className="flex-1">
                    <SelectScheduleList ref={refSchedule} defaultSelect={dataSync?.schedule_id || ''} />
                    <SelectCalendarList
                        ref={refCalendar}
                        defaultSelect={dataSync?.calendar_id || ''}
                        accessToken={access_token || ''}
                    />
                </div>
                <ButtonSave ref={refButtonSave} title={t('save')} wrapClass="v2-btn-main" onSave={_handleSave} />
            </div>
        </>
    );
};

export default forwardRef(CalendarSetup);
