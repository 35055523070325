import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import GDStatusBar from 'app/components/status/statusbar';
import { LIST_STATUS } from 'app/const/App';
import IconClose from 'assets/icon/IconClose';
import IconLink from 'assets/icon/IconLink';
import { INSERT_LINK_VALIDATION_FIELDS } from '../const';
import { checkLink, handleGetSelectedText } from '../utils';

const CustomLinkOption = ({ editorState, onInsert = () => {} }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const refTitleLink = useRef(null);
    const refLinkTarget = useRef(null);
    const refTargetBlank = useRef(null);
    const refStatusBar = useRef(null);

    const _handleAfterOpen = () => {
        const selectedText = handleGetSelectedText(editorState);
        if (selectedText.trim().length && refTitleLink.current) refTitleLink.current.value = selectedText;
    };

    const _toggleModal = () => setIsOpen(!isOpen);

    const _getErrorMessage = ({ isCheckLink = false, name = '' }) => {
        return !isCheckLink ? t('common:can_not_be_blank', { name: t(`${name}`) }) : t('auth:link_is_invalid');
    };

    const _handleInsertLink = () => {
        if (refStatusBar.current) refStatusBar.current.clearAllStatusBar();
        const msgError = [];
        const linkTitle = refTitleLink.current.value;
        const linkTarget = refLinkTarget.current.value;
        if (!linkTitle) msgError.push(_getErrorMessage({ name: INSERT_LINK_VALIDATION_FIELDS.INSERT_TITLE }));
        if (!linkTarget) {
            msgError.push(_getErrorMessage({ name: INSERT_LINK_VALIDATION_FIELDS.INSERT_TARGET }));
        } else if (!checkLink(linkTarget)) {
            msgError.push(_getErrorMessage({ name: INSERT_LINK_VALIDATION_FIELDS.INSERT_TARGET, isCheckLink: true }));
        }
        if (!!msgError.length && refStatusBar.current) {
            refStatusBar.current.showStatusBar('validate_insert', msgError, LIST_STATUS.ERROR);
            return;
        }
        onInsert({ url: linkTarget, text: linkTitle, isBlank: refTargetBlank.current.checked });
        _toggleModal();
    };

    return (
        <>
            <div className="editor-controls__btn tooltip btn-modal" onClick={_toggleModal}>
                <IconLink />
                <span className="tooltiptext top">{t('insert_link')}</span>
            </div>

            <ReactModal
                isOpen={isOpen}
                id="insert_link"
                style={{ overlay: { background: 'transparent' } }}
                className="modal container-modal open"
                onAfterOpen={_handleAfterOpen}
                onRequestClose={_toggleModal}
                portalClassName="ReactModalPortal_customer"
            >
                <div className="modal__overlay bg-fixed" />
                <div className="modal__container">
                    <div className="header-modal btn-close">
                        <h3 className="header-modal__label">{t('insert_link')}</h3>
                        <span className="v2-btn-default --icon-lg --transparent" onClick={_toggleModal}>
                            <IconClose />
                        </span>
                    </div>
                    <div className="body-modal modal-insert has-form scrolls">
                        <GDStatusBar ref={refStatusBar} />
                        <div className="rows">
                            <div className="txt">{t('insert_title')}</div>
                            <input ref={refTitleLink} className="field-input" type="text" autoFocus spellCheck />
                        </div>
                        <div className="rows mt-1">
                            <div className="txt">{t('insert_target')}</div>
                            <input ref={refLinkTarget} className="field-input" type="text" spellCheck />
                        </div>
                        <div className="rows mt-2">
                            <div className="check-items">
                                <input id="new_window" ref={refTargetBlank} type="checkbox" defaultChecked />
                                <div className="item-checkbox">
                                    <label htmlFor="new_window" className="txt-ellipsis">
                                        <span>{t('open_link_in_new_window')}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-modal footer-hasbtn btn-close">
                        <span className="v2-btn-default --noborder --label" onClick={_toggleModal}>
                            {t('cancel')}
                        </span>
                        <div className="v2-btn-main" onClick={_handleInsertLink}>
                            {t('insert')}
                        </div>
                    </div>
                </div>
            </ReactModal>
        </>
    );
};

export default React.memo(CustomLinkOption);
