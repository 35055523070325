import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ButtonSave from 'app/components/button/ButtonSave';
import { addBranchPath } from 'app/const/Branch';
import { SETTINGS_PLANS } from 'app/const/Route';
import IconUpgrade from 'assets/icon/IconUpgrade';
import { getPlanUser } from 'common/utils/PermissionUtils';

const CalendarSyncConnect = (
    { isConnected = false, isFirstConnect = true, onAuth = () => {}, onClose = () => {} },
    ref
) => {
    const { t } = useTranslation();
    const userProfile = useSelector(({ auth }) => auth.user.profile);
    const { isTrial } = getPlanUser(userProfile);
    const refButtonSave = useRef(null);

    useImperativeHandle(ref, () => ({ _setRemoveLoading: () => refButtonSave.current.removeLoading() }));

    if (isTrial) {
        return (
            <div className="boxs">
                <h3 className="title --sm">{t('google_calendar_setup')}</h3>
                <Link
                    to={addBranchPath(SETTINGS_PLANS)}
                    className="v2-btn-main has-icon --bg-orange justify-center fs-14"
                    onClick={onClose}
                >
                    <IconUpgrade isOnBoarding />
                    {t('upgrade_to_connect')}
                </Link>
            </div>
        );
    }

    return (
        <div className="boxs">
            <h3 className="title --sm">{t('google_calendar_setup')}</h3>
            {!isFirstConnect && (
                <ButtonSave
                    ref={refButtonSave}
                    title={isConnected ? t('disconnect') : t('connect')}
                    wrapClass="v2-btn-main"
                    onSave={onAuth}
                />
            )}
            {isFirstConnect && !isConnected && (
                <ButtonSave ref={refButtonSave} title={t('connect')} wrapClass="v2-btn-main" onSave={onAuth} />
            )}
        </div>
    );
};

export default forwardRef(CalendarSyncConnect);
