import classNames from 'classnames';
import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import GDPreviewAttach from 'app/components/attachments/GDPreviewAttach';
import GdConfirm from 'app/components/confirm';
import { GDGlobalImg } from 'app/components/img';
import GdPhoneItem from 'app/components/phone/ItemPhone';
import { updateLocationCustomerMessagingPreferences } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { TYPE_OPEN_SMS } from 'app/const/Sms';
import { eventLocationDropdown, getQueryLocationMap } from 'app/modules/customer/detail/locations/utils';
import { TabDetailContext } from 'app/modules/jobdetail/contexts/TabDetailContext';
import MessagePreferences from 'app/modules/jobdetail/tabs/settingmessaging';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconSpeaker from 'assets/icon/IconSpeaker';
import { clientQuery } from 'common/utils/ApiUtils';
import LocationNote from './LocationNote';
import Options from './Options';

const Location = ({ isLoading, onUpdateSuccess, jobData }) => {
    const { t } = useTranslation(['customers']);
    const { updateJobDataContext } = useContext(TabDetailContext);
    const profileUser = useSelector(({ auth }) => auth?.user?.profile || {});
    const refPreviewAttach = useRef(null);
    const refConfirm = useRef(null);

    const messageProps = jobData.messaging_preferences;

    const { customer: customerInfo, contact: customerContact, location: parentLocation = {} } = jobData;
    const customerId = customerInfo?.id;

    const [state, dispatchState] = useReducer(reducer, {
        isVisibleSetting: false,
        message: messageProps,
        isShowMore: false,
        locationDetail: jobData.location
    });

    const { isVisibleSetting: finalIsVisibleSetting, isShowMore, locationDetail: jobLocation = {} } = state;
    const { photo: finalLocationPhoto } = jobLocation;

    useEffect(() => {
        if (messageProps || parentLocation?.id) {
            dispatchState({ message: messageProps, locationDetail: parentLocation });
        }
    }, [messageProps, parentLocation]);

    const _rendertextLocation = () => {
        return (
            <div className="location-info">
                <div className="dp-inline-block cursor-pointer" onClick={_handleOpenLocation}>
                    <div className="name-label" title="Home">
                        {jobLocation.name}
                    </div>
                    <p className="info-label">{jobLocation?.street1 || ''}</p>
                    <p className="info-label">{jobLocation?.street2 || ''}</p>
                    <p className="info-label">
                        {jobLocation?.city || ''}, {jobLocation?.state?.toUpperCase() || ''} {jobLocation?.zip || ''}
                    </p>
                </div>
            </div>
        );
    };

    const _handleUpdateImage = (locationPhoto) => {
        dispatchState((prev) => {
            return {
                ...prev,
                locationDetail: {
                    ...prev.locationDetail,
                    photo: locationPhoto
                        ? { ...locationPhoto, user: { ...profileUser, full_name: profileUser.name } }
                        : ''
                }
            };
        });
    };

    const _handleDeleteAttachment = () => {
        _handleUpdateImage('');
    };

    const _toggleMessageSetting = (e) => {
        e && e.stopPropagation();
        dispatchState({ isVisibleSetting: true });
        document.getElementById('tab_job_detail').classList.add('show-message-prefer');
    };

    const _handleCloseMessageSetting = () => {
        dispatchState({ isVisibleSetting: false });
        document.getElementById('tab_job_detail').classList.remove('show-message-prefer');
    };

    function _handleSave(confirmations, reminders, followups, broadcasts, payment, sms, email, callback) {
        const params = {
            appointment_confirmations: confirmations,
            appointment_reminders: reminders,
            appointment_followups: followups,
            broadcasts: broadcasts,
            late_payment_reminders: payment,
            sms,
            email,
            location_ids: [jobLocation.id]
        };

        const newParam = {
            ...params,
            sms_to: sms,
            email_to: email
        };

        clientQuery(
            updateLocationCustomerMessagingPreferences(customerId),
            {
                method: 'PUT',
                data: params
            },
            (response) => _updateSuccess(response, newParam, callback),
            _updateFailed
        );
    }

    function _updateSuccess(response, params, callback) {
        callback();

        document.getElementById('tab_job_detail').classList.toggle('show-message-prefer');

        updateJobDataContext({
            messaging_preferences: {
                ...state.message,
                ...params,
                sms_to: params.sms || [],
                email_to: params.email || []
            }
        });
        onUpdateSuccess(response?.message?.toString() || 'Update Messaging preferences Successfully');
    }

    function _updateFailed(response) {
        refConfirm.current.open(null, response?.message?.toString() || 'Please try again');
    }

    function _handleOpenLocation(target) {
        eventLocationDropdown({
            target,
            address: getQueryLocationMap(jobLocation),
            dataLatLng: { ...(jobLocation || {}) }
        });
    }

    function _handleToggleShow(e) {
        e && e.stopPropagation();
        dispatchState({
            isShowMore: !isShowMore
        });
    }

    const _openPreView = () => {
        refPreviewAttach.current.openPreviewWithAttach([finalLocationPhoto], finalLocationPhoto.url);
    };

    function _renderLoading() {
        return (
            <div className="wrap-loading details-job mt-3 mb-5">
                <div className="rows justify-start">
                    <div className="txt flex-auto">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="details">
                        <div className="d-flex align-center mb-2">
                            <div className="loading --animation --line --onefifth" />
                        </div>
                        <div className="loading --animation --line --threefourth" />
                        <div className="mt-5">
                            <div className="loading --animation --line --twothird" />
                            <div className="loading --animation --line --half" />
                            <div className="loading --animation --line --twothird" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function _renderListCustomerPhones({ listPhone = [], contactId }) {
        return listPhone.map((item, index) => {
            const finalPhone = item?.phone || item?.number || '';
            return (
                <GdPhoneItem
                    key={item?.id?.toString() || index.toString()}
                    isValid={item.is_valid}
                    phoneType={item.type}
                    phone={finalPhone}
                    isUsePopup
                    typeOpen={TYPE_OPEN_SMS.DETAIL}
                    contactId={contactId}
                    customerId={customerId}
                />
            );
        });
    }

    function _renderCustomerContact() {
        return customerContact.map((item, index) => {
            const finalEmail = item?.email;
            if (index > 0 && !isShowMore) return null;

            const contactId = item.id.toString();
            return (
                <div key={contactId} className="additional-contact__items">
                    <div className="valid-name">
                        <div className="valid-name__title" title={item.full_name}>
                            {item.full_name}
                        </div>
                        {item?.title ? <div className="label-content">{item.title}</div> : null}
                    </div>
                    {_renderListCustomerPhones({ listPhone: item.phones, contactId })}
                    {finalEmail && (
                        <a className="info-label cursor-pointer" title={finalEmail} href={`mailto:${finalEmail}`}>
                            {finalEmail}
                        </a>
                    )}
                </div>
            );
        });
    }

    if (isLoading) return _renderLoading();

    return (
        <>
            <div className="details-job__elm rows">
                <div className="txt">Location</div>
                <div className="details detail-location">
                    <div className="detail-location__box">
                        {_rendertextLocation()}
                        {!!finalLocationPhoto ? (
                            <LocationImage photo={finalLocationPhoto} onOpenPreview={_openPreView} />
                        ) : null}
                        <Options itemId={jobLocation.id} onUpdateImage={_handleUpdateImage} />
                        <LocationNote locationId={jobLocation.id} defaultValue={jobLocation.note} />
                    </div>
                    <div className="detail-location__box relative">
                        <div className="right-icon">
                            <div className="v2-btn-default just-icon tooltip" onClick={(e) => _toggleMessageSetting(e)}>
                                <IconSpeaker />
                                <p className="tooltiptext top --right">Edit Messaging Preferences</p>
                            </div>
                        </div>
                        <div className={`additional-contact ${isShowMore ? 'has-all-contact' : ''}`}>
                            {_renderCustomerContact()}
                            {customerContact.length > 1 && (
                                <div
                                    onClick={_handleToggleShow}
                                    className="btn-more v2-btn-default --icon-r --transparent js-show-contact"
                                >
                                    <p id="btn_more_label">Show {isShowMore ? 'Less' : 'More'}</p>
                                    <IconArrowDown />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {finalIsVisibleSetting && (
                <MessagePreferences
                    onSave={_handleSave}
                    settings={state.message}
                    onClose={_handleCloseMessageSetting}
                    customerId={customerId}
                />
            )}
            <GDPreviewAttach
                ref={refPreviewAttach}
                preventSetSource
                hasDetail
                idLocation={jobLocation.id}
                onDeleteAttachment={_handleDeleteAttachment}
            />
            <GdConfirm
                ref={refConfirm}
                title={t('customers:confirm')}
                listButton={{ confirm: true, cancel: false }}
                titleConfirm={t('customers:confirm')}
            />
        </>
    );
};

const LocationImage = ({ photo = {}, onOpenPreview = () => {} }) => {
    return (
        <div className="location-image">
            <div
                className={classNames('location-image__view', { 'is-disable': photo.isDisable })}
                onClick={onOpenPreview}
            >
                <GDGlobalImg
                    thumbnailUrl={photo.thumbnail_url}
                    src={photo.url}
                    loading="lazy"
                    draggable={false}
                    data-close-preview="not-close"
                    width={90}
                    height={70}
                    alt=""
                />
            </div>
        </div>
    );
};

export default Location;
