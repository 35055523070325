export const TYPE_SETTING_SERVICE = {
    GET_LIST: 'SETTING_GET_LIST_SERVICE',
    GET_LIST_SUCCESS: 'SETTING_GET_LIST_SERVICE_SUCCESS',
    RESET_SERVICE_LIST: 'RESET_SERVICE_LIST',
    SERVICE_LIST_CLEAN: 'SERVICE_LIST_CLEAN',

    GET_LIST_PLAN: 'SETTING_GET_LIST_SERVICE_PLAN'
};

export const getListSettingService = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_SERVICE.GET_LIST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const resetServiceList = () => {
    return {
        type: TYPE_SETTING_SERVICE.RESET_SERVICE_LIST
    };
};

export const getListSettingServicePlan = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_SERVICE.GET_LIST_PLAN,
        params,
        actionSuccess,
        actionFailed
    };
};
