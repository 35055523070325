import classNames from 'classnames';
import React from 'react';

const EventContentHoliday = ({ title = '', scheduleCount = 1, isFirstSchedule = false, customStyle = {} }) => {
    return (
        <div
            className={classNames('txt-holiday', { 'first-holiday': isFirstSchedule })}
            style={{ '--schedule-number': scheduleCount, ...customStyle }}
            title={title}
        >
            {title}
        </div>
    );
};

export default EventContentHoliday;
