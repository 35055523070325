export const CREATE_JOB_ACTIONS = {
    CREATE_JOB: 'ACTION_CREATE_JOB',
    CREATE_JOB_PLAN: 'ACTION_CREATE_JOB_PLAN',
    CREATE_JOB_SUCCESS: 'CREATE_JOB_SUCCESS'
};

export const actionCreateJob = (payload, actionCreateLocation = () => {}, actionUpdateUnit = () => {}) => {
    return {
        type: CREATE_JOB_ACTIONS.CREATE_JOB,
        payload,
        createLocation: actionCreateLocation,
        updateUnit: actionUpdateUnit
    };
};

export const actionCreateJobPlan = (payload, fallback) => {
    return {
        type: CREATE_JOB_ACTIONS.CREATE_JOB_PLAN,
        payload,
        actionBack: fallback
    };
};

export const actionCreateJobSuccess = (payload) => {
    return {
        type: CREATE_JOB_ACTIONS.CREATE_JOB_SUCCESS,
        payload
    };
};
