import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ADDONS_ROUTING } from 'app/config/routes';
import { ADVANCE_ROUTING_OPTIONS_KEYWORDS } from 'app/const/addons';
import { addBranchPath } from 'app/const/Branch';
import ErrorPage from 'app/modules/error';
import IconArrow from 'assets/icon/IconArrow';
import { getPlanUser, isActiveFeature } from 'common/utils/PermissionUtils';
import BoxDefaultSetup from '../components/BoxDefaultSetup';
import ManageUsersTable from '../components/ManageUsersTable';

const JobMagnetManage = () => {
    const { t } = useTranslation(['common', 'addons']);
    const userProfile = useSelector(({ auth }) => auth.user.profile);
    const jobMagnetSetup = useSelector(({ auth }) => auth.user.settings.addons.routing?.job_magnet);
    // Permissions
    const { isGrowthPlan, isTrial } = getPlanUser(userProfile);
    const havePermission =
        isActiveFeature(ADVANCE_ROUTING_OPTIONS_KEYWORDS.JOB_MAGNET) && (isTrial || isGrowthPlan) && jobMagnetSetup;

    if (!havePermission) return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;
    return (
        <div className="addons-wrap__container">
            <div className="header">
                <Link to={addBranchPath(ADDONS_ROUTING)} className="v2-btn-default has-icon --grey">
                    <IconArrow isPrev />
                    {t('addons:route_optimization')}
                </Link>
            </div>
            <div className="scrolls">
                <div className="boxs-wrapper --pipeline --custom-field container-print">
                    <BoxDefaultSetup
                        title="Job Magnet Default Values"
                        description="Enter your preferred default mile radius and date range for the Job Magnet to pull nearby jobs."
                        defaultData={jobMagnetSetup || {}}
                    />
                    <ManageUsersTable
                        title="Manage Users"
                        description="Select which users are authorized to use the Job Magnet feature."
                    />
                </div>
            </div>
        </div>
    );
};

export default JobMagnetManage;
