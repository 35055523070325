import moment from 'moment';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { LIST_HOLIDAYS } from 'app/const/api/V2';
import { clientQuery } from 'common/utils/ApiUtils';
import { handleConvertHoliday } from './ultil/HolidayUtil';

const ServicesHoliday = ({
    isMonthView = false,
    isListView = false,
    isVerticalMode = false,
    onUpdateHoliday = () => {}
}) => {
    const { start, end } = useSelector(({ inlineCalendarReducer }) => inlineCalendarReducer);
    const schedules = useSelector(({ calendar }) => calendar.schedules);
    const refRangeDate = useRef({ start: '', end: '' });
    const refEvents = useRef([]);

    useEffect(() => {
        const isSameMonthStart = moment(start).isSame(refRangeDate.current.start, 'month');
        const isSameMonthEnd = moment(end).subtract(1, 'day').isSame(refRangeDate.current.end, 'month');
        const checkSameMonth = !isSameMonthStart || !isSameMonthEnd;
        if (start && end && (checkSameMonth || isMonthView)) getHolidays({ start, end });
        refRangeDate.current = { start, end };
    }, [start]);

    useEffect(() => {
        const dataHolidayEvents = handleConvertHoliday({
            data: refEvents.current,
            schedules,
            isMonthView,
            isListView,
            isVerticalMode
        });
        onUpdateHoliday(dataHolidayEvents);
    }, [schedules, isMonthView, isListView, isVerticalMode]);

    const getHolidays = ({ start = '', end = '' } = {}) => {
        const handleGetSuccess = ({ data }) => {
            refEvents.current = data || [];
            const dataHolidayEvents = handleConvertHoliday({
                data,
                schedules,
                isMonthView,
                isListView,
                isVerticalMode
            });
            onUpdateHoliday(dataHolidayEvents);
        };
        const handleGetFail = () => {
            onUpdateHoliday([]);
        };
        clientQuery(LIST_HOLIDAYS, { data: { start, end }, method: 'GET' }, handleGetSuccess, handleGetFail);
    };
    return null;
};

export default ServicesHoliday;
