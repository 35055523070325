import React, { useEffect, useReducer, useRef } from 'react';

import GdGridView from 'app/components/grid/GdGridView';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import { getGridColumns } from '../constants/table';
import { CONFIG_ADDON_ROUTING, TYPE_ADVANCE_ROUTING } from '../constants';

const ManageUsersTable = ({ title = '', description = '', type = TYPE_ADVANCE_ROUTING.MAGNET }) => {
    const [state, dispatchState] = useReducer(reducer, { data: [], isLoading: true });
    const { data, isLoading } = state;
    const refTimer = useRef({});

    const { endPointPermission, permissionId } = CONFIG_ADDON_ROUTING[type];

    useEffect(() => {
        getListUsersPermission();
    }, []);

    const getListUsersPermission = () => {
        const handleSuccess = ({ data = [] }) => {
            dispatchState({ data: data, isLoading: false });
        };
        const handleFail = ({ message = [] }) => {
            dispatchState({ data: [], errors: message, isLoading: false });
        };

        clientQuery(endPointPermission, { data: {}, method: 'GET', toFormData: false }, handleSuccess, handleFail);
    };

    const handleSwitch = ({ id, value, key: keyPermission }) => {
        clearTimeout(refTimer.current[id]);
        let newPermission = [];

        dispatchState((prevState) => ({
            ...prevState,
            data: prevState.data.map((item) => {
                if (item.user.id === id) {
                    const permission = item.permission;
                    newPermission = value
                        ? [...permission, keyPermission]
                        : permission.filter((key) => key !== keyPermission);

                    return { ...item, permission: newPermission };
                }
                return item;
            })
        }));

        refTimer.current[id] = setTimeout(() => {
            clientQuery(endPointPermission + '/' + id, {
                method: 'PUT',
                toFormData: false,
                data: { permissions: newPermission }
            });
        }, 500);
    };

    return (
        <div className="wrap-tables flex-column relative">
            <div className="table-title flex-column gap-4">
                <p className="fs-15 fw-700 black">{title}</p>
                <p className="black-darker3">{description}</p>
            </div>
            <GdGridView
                isScroll
                isEmptyFlat
                content={data || []}
                isLoading={isLoading}
                classTable="tables table-multi-column"
                fileTranslation="report"
                keyGetId="user.id"
                {...getGridColumns({ permissionId })}
                onChangeSwitchOppPermission={handleSwitch}
            />
        </div>
    );
};

export default ManageUsersTable;
