export default function IconServicePlan({ inactive = false, hasColor = false }) {
    if (inactive) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path
                    xmlns="http://www.w3.org/2000/svg"
                    d="M3 6C3 3.79086 4.79086 2 7 2H31C33.2091 2 35 3.79086 35 6V30C35 32.2091 33.2091 34 31 34H7C4.79086 34 3 32.2091 3 30V6Z"
                    fill="black"
                    fillOpacity="0.04"
                    shapeRendering="crispEdges"
                ></path>
                <path
                    opacity="0.3"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.28571 12.5385H23.7143C24.4244 12.5385 25 13.1239 25 13.8461V22.3846C25 23.829 23.8487 24.9999 22.4286 24.9999H9.57143C8.15127 24.9999 7 23.829 7 22.3846V13.8461C7 13.1239 7.57563 12.5385 8.28571 12.5385Z"
                    fill="black"
                    fillOpacity="0.4"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.8571 6C20.5672 6 21.1429 6.4391 21.1429 6.98076V8.61537H23.7143C24.4244 8.61537 25 9.20084 25 9.92306C25 10.6453 24.4244 11.2307 23.7143 11.2307H8.28571C7.57563 11.2307 7 10.6453 7 9.92306C7 9.20084 7.57563 8.61537 8.28571 8.61537H10.8571V6.98076C10.8571 6.48077 11.3476 6.06816 11.9816 6.00764L12.1429 6C12.8529 6 13.4286 6.4391 13.4286 6.98076V8.61537H18.5714V6.98076C18.5714 6.48077 19.0619 6.06816 19.6959 6.00764L19.8571 6ZM11.8538 16.1467C11.9475 15.9509 12 15.7316 12 15.5C12 14.6716 11.3284 14 10.5 14C9.67157 14 9 14.6716 9 15.5C9 16.3284 9.67157 17 10.5 17C10.7316 17 10.9509 16.9475 11.1467 16.8538L12.1462 17.8533C12.0525 18.0491 12 18.2684 12 18.5C12 19.3284 12.6716 20 13.5 20C14.1531 20 14.7087 19.5826 14.9146 19H17.0854C17.2913 19.5826 17.8469 20 18.5 20C18.7316 20 18.9509 19.9475 19.1467 19.8538L20.1462 20.8533C20.0525 21.0491 20 21.2684 20 21.5C20 22.3284 20.6716 23 21.5 23C22.3284 23 23 22.3284 23 21.5C23 20.6716 22.3284 20 21.5 20C21.2684 20 21.0491 20.0525 20.8533 20.1462L19.8538 19.1467C19.9475 18.9509 20 18.7316 20 18.5C20 17.6716 19.3284 17 18.5 17C17.8469 17 17.2913 17.4174 17.0854 18H14.9146C14.7087 17.4174 14.1531 17 13.5 17C13.2684 17 13.0491 17.0525 12.8533 17.1462L11.8538 16.1467Z"
                    fill="black"
                    fillOpacity="0.4"
                ></path>
            </svg>
        );
    }

    if (hasColor) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.28571 8.53845H19.7143C20.4244 8.53845 21 9.12392 21 9.84614V18.3846C21 19.829 19.8487 20.9999 18.4286 20.9999H5.57143C4.15127 20.9999 3 19.829 3 18.3846V9.84614C3 9.12392 3.57563 8.53845 4.28571 8.53845Z"
                    fill="#C1C9D4"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.8571 2C16.5672 2 17.1429 2.4391 17.1429 2.98076V4.61537H19.7143C20.4244 4.61537 21 5.20084 21 5.92306C21 6.64527 20.4244 7.23075 19.7143 7.23075H4.28571C3.57563 7.23075 3 6.64527 3 5.92306C3 5.20084 3.57563 4.61537 4.28571 4.61537H6.85714V2.98076C6.85714 2.48077 7.34762 2.06816 7.98158 2.00764L8.14286 2C8.85294 2 9.42857 2.4391 9.42857 2.98076V4.61537H14.5714V2.98076C14.5714 2.48077 15.0619 2.06816 15.6959 2.00764L15.8571 2ZM7.85381 12.1467C7.94752 11.9509 8 11.7316 8 11.5C8 10.6716 7.32843 10 6.5 10C5.67157 10 5 10.6716 5 11.5C5 12.3284 5.67157 13 6.5 13C6.73157 13 6.95089 12.9475 7.14671 12.8538L8.14619 13.8533C8.05248 14.0491 8 14.2684 8 14.5C8 15.3284 8.67157 16 9.5 16C10.1531 16 10.7087 15.5826 10.9146 15H13.0854C13.2913 15.5826 13.8469 16 14.5 16C14.7316 16 14.9509 15.9475 15.1467 15.8538L16.1462 16.8533C16.0525 17.0491 16 17.2684 16 17.5C16 18.3284 16.6716 19 17.5 19C18.3284 19 19 18.3284 19 17.5C19 16.6716 18.3284 16 17.5 16C17.2684 16 17.0491 16.0525 16.8533 16.1462L15.8538 15.1467C15.9475 14.9509 16 14.7316 16 14.5C16 13.6716 15.3284 13 14.5 13C13.8469 13 13.2913 13.4174 13.0854 14H10.9146C10.7087 13.4174 10.1531 13 9.5 13C9.26843 13 9.04911 13.0525 8.85329 13.1462L7.85381 12.1467Z"
                    fill="#7A83A6"
                ></path>
            </svg>
        );
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
                d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                fill="#FFF"
            ></path>
            <path
                opacity="0.3"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.28571 12.5385H23.7143C24.4244 12.5385 25 13.1239 25 13.8461V22.3846C25 23.829 23.8487 24.9999 22.4286 24.9999H9.57143C8.15127 24.9999 7 23.829 7 22.3846V13.8461C7 13.1239 7.57563 12.5385 8.28571 12.5385Z"
                fill="#1EAA5C"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.8571 6C20.5672 6 21.1429 6.4391 21.1429 6.98076V8.61537H23.7143C24.4244 8.61537 25 9.20084 25 9.92306C25 10.6453 24.4244 11.2307 23.7143 11.2307H8.28571C7.57563 11.2307 7 10.6453 7 9.92306C7 9.20084 7.57563 8.61537 8.28571 8.61537H10.8571V6.98076C10.8571 6.48077 11.3476 6.06816 11.9816 6.00764L12.1429 6C12.8529 6 13.4286 6.4391 13.4286 6.98076V8.61537H18.5714V6.98076C18.5714 6.48077 19.0619 6.06816 19.6959 6.00764L19.8571 6ZM11.8538 16.1467C11.9475 15.9509 12 15.7316 12 15.5C12 14.6716 11.3284 14 10.5 14C9.67157 14 9 14.6716 9 15.5C9 16.3284 9.67157 17 10.5 17C10.7316 17 10.9509 16.9475 11.1467 16.8538L12.1462 17.8533C12.0525 18.0491 12 18.2684 12 18.5C12 19.3284 12.6716 20 13.5 20C14.1531 20 14.7087 19.5826 14.9146 19H17.0854C17.2913 19.5826 17.8469 20 18.5 20C18.7316 20 18.9509 19.9475 19.1467 19.8538L20.1462 20.8533C20.0525 21.0491 20 21.2684 20 21.5C20 22.3284 20.6716 23 21.5 23C22.3284 23 23 22.3284 23 21.5C23 20.6716 22.3284 20 21.5 20C21.2684 20 21.0491 20.0525 20.8533 20.1462L19.8538 19.1467C19.9475 18.9509 20 18.7316 20 18.5C20 17.6716 19.3284 17 18.5 17C17.8469 17 17.2913 17.4174 17.0854 18H14.9146C14.7087 17.4174 14.1531 17 13.5 17C13.2684 17 13.0491 17.0525 12.8533 17.1462L11.8538 16.1467Z"
                fill="#1EAA5C"
            ></path>
        </svg>
    );
}
