import { takeEvery, takeLatest } from 'redux-saga/effects';
import { actionGetListSchedule } from './schedule';
import {
    actionGetListJob,
    actionResizeTimeJob,
    actionMoveJob,
    actionMoveJobToWorkPool,
    calendarGetListJobEvents,
    calendarBatchActionMoveJobs
} from './job';
import { calendarSendAction, getTotalBroadcast, calendarSendBroadcast } from './broadcast';
import { TYPE_SCHEDULE_ACTION } from 'common/redux/actions/calendar/scheduleAction';
import { CALENDAR_ACTIONS } from 'common/redux/actions/calendar';
import { CALENDAR_JOB_ACTIONS } from 'common/redux/actions/calendar/jobAction';
import { CALENDAR_BROADCAST_ACTIONS } from 'common/redux/actions/calendar/broadcastAction';

export function* getCalendarListScheduleWatcher() {
    yield takeLatest(TYPE_SCHEDULE_ACTION.ACTION_LIST_SCHEDULE_REQUEST, actionGetListSchedule);
}

export function* getCalendarListJobWatcher() {
    yield takeLatest(CALENDAR_ACTIONS.ACTION_GET_JOBS, actionGetListJob);
}

export function* updateResizeTimeJobWatcher() {
    yield takeEvery(CALENDAR_JOB_ACTIONS.CALENDAR_RESIZE_TIME_JOB, actionResizeTimeJob);
}

export function* updateMoveJobWatcher() {
    yield takeEvery(CALENDAR_JOB_ACTIONS.CALENDAR_MOVE_JOB, actionMoveJob);
}

export function* updateMoveJobToWorkPoolWatcher() {
    yield takeEvery(CALENDAR_JOB_ACTIONS.CALENDAR_MOVE_JOB_TO_WORK_POOL, actionMoveJobToWorkPool);
}

export function* calendarGetListJobEventsWatcher() {
    yield takeLatest(CALENDAR_JOB_ACTIONS.CALENDAR_GET_LIST_JOBS_EVENTS, calendarGetListJobEvents);
}

export function* calendarBatchActionMoveJobsWatcher() {
    yield takeEvery(CALENDAR_JOB_ACTIONS.CALENDAR_BATCH_ACTION_MOVE_JOB, calendarBatchActionMoveJobs);
}

export function* calendarSendActionWatcher() {
    yield takeLatest(CALENDAR_BROADCAST_ACTIONS.CALENDAR_SEND_ACTION, calendarSendAction);
}

export function* getTotalBroadcastWatcher() {
    yield takeLatest(CALENDAR_BROADCAST_ACTIONS.CALENDAR_GET_TOTAL_BROADCAST_REQUEST, getTotalBroadcast);
}

export function* calendarSendBroadcastWatcher() {
    yield takeEvery(CALENDAR_BROADCAST_ACTIONS.CALENDAR_SEND_BROADCAST, calendarSendBroadcast);
}
