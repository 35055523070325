import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { addBranchPath } from 'app/const/Branch';
import { SETTINGS_PLANS } from 'app/const/Route';
import IconUpgrade from 'assets/icon/IconUpgrade';
import { BOOK_A_COACHING_CALL } from '../constant';

const Sidebar = () => {
    const { t } = useTranslation('onboarding');
    const history = useHistory();

    const _handleUpgradePlan = () => {
        history.push(addBranchPath(SETTINGS_PLANS));
    };

    const _handleContactSupport = () => {
        window.Intercom('boot');
        window.Intercom('show');
    };

    const _handlePickATime = () => {
        window.open(BOOK_A_COACHING_CALL);
    };

    return (
        <div className="onboarding-sidebar">
            <div className="onboarding-items">
                <div
                    className="v2-btn-main has-icon --bg-orange --large w-100 justify-center fs-14"
                    onClick={_handleUpgradePlan}
                >
                    <IconUpgrade isOnBoarding />
                    {t('upgrade_your_plan')}
                </div>
            </div>
            <div className="onboarding-items">
                <h5 className="black fw-500 fs-15 mb-3">{t('need_some_help')}</h5>
                <div className="v2-btn-default w-100" onClick={_handleContactSupport}>
                    {t('header:chat_with_support')}
                </div>
            </div>
            <div className="onboarding-items">
                <h5 className="black fw-500 fs-15 mb-3">{t('header:book-s-sales-call')}</h5>
                <div className="v2-btn-default w-100" onClick={_handlePickATime}>
                    {t('pick_a_time')}
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
