import { LIST_TABLE_ITEM_TYPE } from 'app/const/App';
import { ADD_SMART_VIEWS } from 'app/const/Permissions';

const HEADER_GRID = {
    user: { title: 'user', style: 'col col-lg' },
    add: { title: 'add', style: 'col col-md-plus' }
};
const COLUMNS_GRID = [
    { id: 'user', title: 'user', status: true },
    { id: 'add', title: 'add', status: true, keyGetValue: 'permission' }
];
const CONTENT_GRID = {
    user: { id: 'user', style: 'col col-lg', type: LIST_TABLE_ITEM_TYPE.USER_HAS_AVT },
    add: {
        id: ADD_SMART_VIEWS,
        style: 'col col-md-plus',
        type: LIST_TABLE_ITEM_TYPE.SWITCH_CHECKBOX_OPP_PERMISSION
    }
};
export const GRID_COLUMNS = { header: HEADER_GRID, columns: COLUMNS_GRID, contentConfig: CONTENT_GRID };
