import React, { useRef } from 'react';
import GdLazyImage from 'app/components/lazyimage';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userLoginRequest } from 'common/redux/actions/authAction';
import { useEffect } from 'react';
import { AUTH_LOGIN } from 'app/const/Route';
import { useTranslation } from 'react-i18next';
import { LINK_CDN_IMG } from 'app/const/URL';
import ButtonSave from 'app/components/button/ButtonSave';
import { addBranchPath } from 'app/const/Branch';
import { restoreOptionJobCalendar } from 'app/modules/calendar/ultil/Calendar';
import { ONBOARDING } from 'app/config/routes';

const SignupSuccess = () => {
    const { t } = useTranslation(['auth', 'common']);
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const isActivation = !!location.state?.isActivation;
    const industry = location.state?.industry || '';
    const isFirstTime = useRef(true);

    useEffect(() => {
        setTimeout(() => {
            isActivation ? history.replace(addBranchPath(ONBOARDING)) : _handleLogin();
        }, 0);
    }, []);

    const _handleLogin = () => {
        if (isFirstTime.current) {
            dispatch(userLoginRequest(location.state?.info, loginSuccess, loginFailed));
            isFirstTime.current = false;
        }
    };

    const loginSuccess = () => {
        restoreOptionJobCalendar();
        history.push({ pathname: addBranchPath(ONBOARDING), state: { isSignupSuccess: true } });
    };

    const loginFailed = () => {
        history.push(AUTH_LOGIN);
    };

    const _renderBoxContent = () => {
        return (
            <div className="box-success">
                <div className="gd-form-v2 p-6 ">
                    <p className="box-success__desc">
                        {isActivation
                            ? t('auth:your_gorilladesk_account_upgraded_successfully')
                            : t('auth:customizing_your_gorilladesk_experience_for', { industry })}
                    </p>
                    <p className="box-success__desc">
                        {isActivation && t('auth:we_will_redirect_you_to_your_active_account')}
                    </p>
                    <img
                        className="box-success__gif"
                        src={`${LINK_CDN_IMG}preload-animated.gif`}
                        width={160}
                        height={51}
                    />
                    {!isActivation && (
                        <ButtonSave className="box-success__btn v2-btn-main mt-4" onSave={_handleLogin}>
                            {t('auth:continue_to_your_account')}
                        </ButtonSave>
                    )}
                </div>
            </div>
        );
    };

    if (!isActivation) {
        return _renderBoxContent();
    }

    return (
        <div className="gorilladesk auth-page">
            <div className="wrapper">
                <h1 className="logo-text-gorilla">
                    <GdLazyImage
                        src={`${LINK_CDN_IMG}logo_text_gorilla.png`}
                        alt={t('common:gorilladesk')}
                        width={335}
                        height={80}
                    />
                </h1>
                <div className="main-login">{_renderBoxContent()}</div>
            </div>
        </div>
    );
};

export default SignupSuccess;
