export const BellIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            fill="#DAC2F2"
            fillRule="evenodd"
            d="M11.5 5h1A4.5 4.5 0 0117 9.5V13a1 1 0 01-1 1H8a1 1 0 01-1-1V9.5A4.5 4.5 0 0111.5 5z"
            clipRule="evenodd"
        ></path>
        <path fill="#8D4AFC" d="M12 6a1 1 0 100-2 1 1 0 000 2z"></path>
        <path
            fill="#8D4AFC"
            fillRule="evenodd"
            d="M12 20a2 2 0 002-2h-4a2 2 0 002 2z"
            clipRule="evenodd"
        ></path>
        <path fill="#8D4AFC" d="M17 15H7a1 1 0 100 2h10a1 1 0 100-2z"></path>
    </svg>
);

export const PaymentIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            fill="#DAC2F2"
            fillRule="evenodd"
            d="M5.5 6h13A1.5 1.5 0 0120 7.5V8a1 1 0 01-1 1H5a1 1 0 01-1-1v-.5A1.5 1.5 0 015.5 6zM5 13h14a1 1 0 011 1v3a2 2 0 01-2 2H6a2 2 0 01-2-2v-3a1 1 0 011-1z"
            clipRule="evenodd"
        ></path>
        <path fill="#8D4AFC" d="M5 10h14a1 1 0 110 2H5a1 1 0 110-2z"></path>
    </svg>
);

export const CheckIconActive = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            fill="#DAC2F2"
            fillRule="evenodd"
            d="M11 4c2.253 0 4.288.931 5.742 2.43L11 12.17 8.414 9.586l-.127-.117a2 2 0 00-2.701 2.945L11 17.828l7.764-7.763A8 8 0 1111 4z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#8D4AFC"
            fillRule="evenodd"
            d="M18.293 6.293a1 1 0 011.497 1.32l-.083.094L11 16.414l-4.707-4.707a1 1 0 011.32-1.497l.094.083L11 13.585l7.293-7.292z"
            clipRule="evenodd"
        ></path>
    </svg>
);

export const CheckIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            fill="#C1C9D4"
            fillRule="evenodd"
            d="M11 4c2.253 0 4.288.931 5.742 2.43L11 12.17 8.414 9.586l-.127-.117a2 2 0 00-2.701 2.945L11 17.828l7.764-7.763A8 8 0 1111 4z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#7A83A6"
            fillRule="evenodd"
            d="M18.293 6.293a1 1 0 011.497 1.32l-.083.094L11 16.414l-4.707-4.707a1 1 0 011.32-1.497l.094.083L11 13.585l7.293-7.292z"
            clipRule="evenodd"
        ></path>
    </svg>
);

export const CalendarIcon = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15 5C15.5523 5 16 5.33579 16 5.75V7H18C18.5523 7 19 7.44772 19 8C19 8.55228 18.5523 9 18 9H6C5.44772 9 5 8.55228 5 8C5 7.44772 5.44772 7 6 7H8V5.75C8 5.36765 8.38149 5.05212 8.87456 5.00584L9 5C9.55228 5 10 5.33579 10 5.75V7H14V5.75C14 5.36765 14.3815 5.05212 14.8746 5.00584L15 5Z"
            fill="#8D4AFC"
        ></path>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 10H18C18.5523 10 19 10.4477 19 11V16C19 17.1046 18.1046 18 17 18H7C5.89543 18 5 17.1046 5 16V11C5 10.4477 5.44772 10 6 10Z"
            fill="#DAC2F2"
        ></path>
    </svg>
);

export const DocumentsIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            fill="#DAC2F2"
            fillRule="evenodd"
            d="M5 3h3a1 1 0 011 1v4a1 1 0 001 1h4a1 1 0 011 1v5a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#8D4AFC"
            fillRule="evenodd"
            d="M11.707 3.707l2.586 2.586A1 1 0 0113.586 8H11a1 1 0 01-1-1V4.414a1 1 0 011.707-.707z"
            clipRule="evenodd"
        ></path>
        <path
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="5"
            d="M8.434 17.549c0-1.594 1.867-2.549 3.797-.549 1.929 2 4.072-.64 4.594-4.372"
        ></path>
        <path
            stroke="#8D4AFC"
            strokeLinecap="round"
            strokeWidth="2"
            d="M8.434 17.549c0-1.594 1.867-2.549 3.797-.549 1.929 2 4.072-.64 4.594-4.372"
        ></path>
    </svg>
);

export const EstimateIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            fill="#DAC2F2"
            fillRule="evenodd"
            d="M3 12h2v-1H3V9h2V8H3V6h3V5H3V4a2 2 0 012-2h2a2 2 0 012 2v12a2 2 0 01-2 2H5a2 2 0 01-2-2v-1h3v-1H3v-2z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#8D4AFC"
            fillRule="evenodd"
            d="M11 6h4v8.807c0 .128-.024.254-.072.372l-1 2.5a1 1 0 01-1.857 0l-1-2.5a1.001 1.001 0 01-.071-.372V6zM13 2a2 2 0 012 2v1h-4V4a2 2 0 012-2z"
            clipRule="evenodd"
        ></path>
        <path
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="5"
            d="M8.434 17.549c0-1.594 1.867-2.549 3.797-.549 1.929 2 4.072-.64 4.594-4.372"
        ></path>
        <path
            stroke="#8D4AFC"
            strokeLinecap="round"
            strokeWidth="2"
            d="M8.434 17.549c0-1.594 1.867-2.549 3.797-.549 1.929 2 4.072-.64 4.594-4.372"
        ></path>
    </svg>
);

export const InvoiceIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            fill="#DAC2F2"
            fillRule="evenodd"
            d="M7 6h6a2 2 0 012 2v8.207a.728.728 0 01-1.25.507l-.533-.548a1 1 0 00-1.434 0l-.534.549a1.743 1.743 0 01-2.5 0l-.532-.549a1 1 0 00-1.434 0l-.533.548A.728.728 0 015 16.207V8a2 2 0 012-2z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#8D4AFC"
            fillRule="evenodd"
            d="M4.5 3h11A2.5 2.5 0 0118 5.5V7a1 1 0 01-1 1 .974.974 0 01-.973-1l.014-.5A1.46 1.46 0 0014.58 5H5.5A1.5 1.5 0 004 6.5V7a1 1 0 11-2 0V5.5A2.5 2.5 0 014.5 3z"
            clipRule="evenodd"
        ></path>
        <path
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="5"
            d="M9.434 17.549c0-1.594 1.867-2.549 3.797-.549 1.929 2 4.072-.64 4.594-4.372"
        ></path>
        <path
            stroke="#8D4AFC"
            strokeLinecap="round"
            strokeWidth="2"
            d="M9.434 17.549c0-1.594 1.867-2.549 3.797-.549 1.929 2 4.072-.64 4.594-4.372"
        ></path>
    </svg>
);

export const FirstNameIcon = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.1919 12C15.4363 12 17.3295 13.4604 17.6079 15.4065L17.9944 18.1072L17.5816 18.378C16.9701 18.7792 16.2258 19 15.4456 19H6.54495C5.76378 19 5.01862 18.7787 4.40676 18.3766L3.99445 18.1057L4.38109 15.4065C4.65947 13.4604 6.55264 12 8.79703 12H8.99999C8.99999 13.1046 9.89542 14 11 14C12.1046 14 13 13.1046 13 12H13.1919Z"
            fill="#DAC2F2"
        ></path>
        <path
            d="M18 18H12C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20H18C18.5523 20 19 19.5523 19 19C19 18.4477 18.5523 18 18 18Z"
            fill="#8D4AFC"
        ></path>
        <path
            d="M14 15H12C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17H14C14.5523 17 15 16.5523 15 16C15 15.4477 14.5523 15 14 15Z"
            fill="#8D4AFC"
        ></path>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 3C12.6569 3 14 4.34315 14 6V6.99566L13.4846 9.05959C13.1998 10.1999 12.1753 11 11 11C9.82459 11 8.80001 10.2 8.51493 9.05971L8 7V6C8 4.34315 9.34315 3 11 3Z"
            fill="#8D4AFC"
        ></path>
    </svg>
);

export const SubscriptionIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            fill="#8D4AFC"
            d="M15 5c.552 0 1 .336 1 .75V7h2a1 1 0 110 2H6a1 1 0 010-2h2V5.75c0-.382.381-.698.875-.744L9 5c.552 0 1 .336 1 .75V7h4V5.75c0-.382.382-.698.875-.744L15 5z"
        ></path>
        <path
            fill="#DAC2F2"
            fillRule="evenodd"
            d="M6 10h12a1 1 0 011 1v5a2 2 0 01-2 2H7a2 2 0 01-2-2v-5a1 1 0 011-1z"
            clipRule="evenodd"
        ></path>
    </svg>
);

export const RatingIcon = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.30528 19.4112C8.39871 20.0423 7.15223 19.8189 6.5212 18.9123C6.17171 18.4103 6.07123 17.7758 6.24846 17.1903L7.08027 14.4422L4.79204 12.7079C3.91174 12.0407 3.739 10.7862 4.4062 9.90594C4.77571 9.41841 5.34802 9.1268 5.95964 9.11442L8.83027 9.05632L9.7726 6.34415C10.1351 5.30076 11.2748 4.74881 12.3182 5.11133C12.8961 5.3121 13.3503 5.76629 13.551 6.34415L14.4934 9.05632L17.364 9.11442C18.4683 9.13678 19.3455 10.0501 19.3231 11.1545C19.3107 11.7661 19.0191 12.3384 18.5316 12.7079L16.2434 14.4422L17.0752 17.1903C17.3952 18.2475 16.7976 19.364 15.7404 19.684C15.1549 19.8612 14.5204 19.7607 14.0184 19.4112L11.6618 17.7709L9.30528 19.4112Z"
            fill="#DAC2F2"
        ></path>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.36891 12.2465C8.97839 11.856 8.34522 11.856 7.9547 12.2465C7.56417 12.637 7.56417 13.2702 7.9547 13.6607L9.9547 15.6607C10.3452 16.0512 10.9784 16.0512 11.3689 15.6607L15.3689 11.6607C15.7594 11.2702 15.7594 10.637 15.3689 10.2465C14.9784 9.85598 14.3452 9.85598 13.9547 10.2465L10.6618 13.5394L9.36891 12.2465Z"
            fill="#8D4AFC"
        ></path>
    </svg>
);

export const BookingIcon = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4 6V9C4 10.1046 4.89543 11 6 11H18C19.1046 11 20 10.1046 20 9V6C20 4.89543 19.1046 4 18 4L6 4C4.89543 4 4 4.89543 4 6Z"
            fill="#DAC2F2"
        ></path>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.237 8.26952L10.0521 16.1241C10.0391 16.6762 10.4762 17.1343 11.0283 17.1473C11.2717 17.153 11.5088 17.0698 11.6952 16.9132L12.8689 15.9269L14.4437 19.7971C14.4782 19.8817 14.5422 19.9438 14.618 19.9757C14.6937 20.0076 14.7811 20.0094 14.8622 19.9734L16.7505 19.1374C16.8284 19.1029 16.89 19.0375 16.9217 18.9557C16.9534 18.8738 16.9526 18.7821 16.9195 18.7008L15.3447 14.8307L16.9329 14.623C17.4805 14.5513 17.8664 14.0493 17.7948 13.5017C17.7652 13.276 17.6596 13.0672 17.4954 12.9097L11.9289 7.57131C11.5303 7.18904 10.8972 7.20228 10.515 7.60089C10.342 7.78122 10.2429 8.01974 10.237 8.26952Z"
            fill="#8D4AFC"
        ></path>
    </svg>
);
