import { useReducer, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionGetListSchedule } from 'common/redux/actions/calendar/scheduleAction';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import IconLoading from 'assets/icon/IconLoading';
import IconCircleClose from 'assets/icon/IconCircleClose';
import { reducer } from 'app/const/Reducer';
import { ACCOUNT_ROLE } from 'app/const/App';
import IconPrimary from 'assets/icon/IconPrimary';
import TabSwitcher from 'app/components/tabs/TabSwitcher';
import CrewTab from './CrewTab';
import { TYPE_SCHEDULE_ASSIGNEE, TYPE_USER_SETTING } from 'app/modules/settings/users/const';
import { isShowCrew } from 'common/utils/PermissionUtils';

const ListSchedules = ({
    selected,
    crewSelected,
    isVisible,
    onSelect,
    onClose,
    changePrimary,
    isWorkPool = false,
    onSelectCrew = () => {}
}) => {
    const dispatch = useDispatch();

    const schedulesReducer = useSelector((state) => state.schedules);
    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);
    const isSupportCrew = useSelector(({ auth }) => auth.user.company.support_crew);

    const [state, dispatchState] = useReducer(reducer, {
        isLoading: !!schedulesReducer?.isFirstTime,
        tabId: TYPE_USER_SETTING.USER.id
    });
    const listSchedules = schedulesReducer?.data?.schedules || [];

    const refDropDown = useRef(null);

    const { isLoading, tabId } = state;
    const isCrewTab = tabId === TYPE_USER_SETTING.CREW.id;

    useEffect(() => {
        if (listSchedules.length === 1 && !selected.length) {
            onSelect(listSchedules[0]);
        }
    }, [listSchedules]);

    useEffect(() => {
        if (!selected.length) {
            _handleFetchSchedules();
        }
    }, [selected]);

    useEffect(() => {
        if (isVisible) {
            if (schedulesReducer?.isFirstTime) {
                _handleFetchSchedules();
            } else {
                if (isLoading) {
                    dispatchState({
                        isLoading: false
                    });
                }
            }
        }

        if (isVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [isVisible]);

    function _handleFetchSchedules() {
        if (schedulesReducer?.isFirstTime) {
            !isLoading && dispatchState({ isLoading: true });
            dispatch(actionGetListSchedule({}, actionGetListScheduleSuccess, actionGetListScheduleFailed));
        }
    }

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_list_schedule_job_preview');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_list_schedule_job_preview');

        if (
            refDropDown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropDown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        onClose();
    }

    function _handleOpenSchedule(e, schedule, isPrimary) {
        e.stopPropagation();

        if (isWorkPool) {
            if (!isPrimary) {
                changePrimary(schedule, isWorkPool);
            }

            return false;
        }

        onSelect(schedule);
    }

    function actionGetListScheduleSuccess() {
        dispatchState({ isLoading: false });
    }

    function actionGetListScheduleFailed() {
        dispatchState({ isLoading: false });
    }

    const _selectPrimary = (e, newPrimary, isActive) => {
        e.stopPropagation();

        if (isActive) {
            return false;
        }
        changePrimary(newPrimary, isWorkPool);
    };

    function _renderLoadingList() {
        if (!isLoading) return null;

        return (
            <div className="items justify-center">
                <div className="loading -ajaxbar">
                    <IconLoading />
                </div>
            </div>
        );
    }

    function _renderAvatar(scheduleDetail) {
        const userDetail = companyUsers.find((user) => user.id === scheduleDetail.user_id);

        if (userDetail?.avatar) {
            return <img src={userDetail.avatar} alt="" width="24" height="24" />;
        }
        return <div className="avt fs-11">{scheduleDetail.name.substring(0, 2)}</div>;
    }

    const _renderListSchedule = () => {
        if (isLoading) return null;

        let finalListschedules = [...listSchedules];

        if (!finalListschedules.length) return null;

        finalListschedules = finalListschedules.map((item) => {
            const isActive = selected.find((assign) => assign.id === item.id);
            if (isActive) {
                return {
                    ...item,
                    is_primary: isActive?.is_primary || 0,
                    isActive: true
                };
            }

            return {
                ...item,
                is_primary: 0
            };
        });

        finalListschedules = finalListschedules.sort(function (a, b) {
            return b.is_primary - a.is_primary;
        });

        return finalListschedules.map((item) => {
            const isPrimary = item.is_primary === 1;
            const isActive = item.isActive;

            return (
                <li
                    className={`btn-modal items ${isPrimary ? 'primary-active' : ''} ${isActive ? 'active' : ''} `}
                    key={item.id}
                    onClick={(e) => _handleOpenSchedule(e, item, isPrimary)}
                >
                    <div className="name">
                        <span className="avt-img relative">
                            {item.role === ACCOUNT_ROLE.SUPERADMIN && (
                                <span className="primary-user-icon">
                                    <IconPrimary />
                                </span>
                            )}
                            {_renderAvatar(item)}{' '}
                        </span>
                        <span className="text-name">{item.name}</span>
                    </div>
                    <div className="btn-group">
                        {_renderFlagPrimary()}
                        {isActive && !isPrimary && (
                            <span className="btn-close-name svg-clear-field">
                                <IconCircleClose isHasColor />
                            </span>
                        )}
                    </div>
                </li>
            );

            function _renderFlagPrimary() {
                return (
                    <span onClick={(e) => _selectPrimary(e, item, isPrimary)} className="primary">
                        Primary
                    </span>
                );
            }
        });
    };

    const _handleChangeTab = (id) => {
        dispatchState((prev) => ({ ...prev, tabId: id }));
    };

    const _handleSelectCrew = (data) => {
        onSelectCrew(data);
        onClose();
    };

    if (!isVisible) {
        return false;
    }

    return (
        <div ref={refDropDown} className="v2-dropdown__menu content-checked">
            {!isSupportCrew || !isShowCrew() ? null : (
                <TabSwitcher
                    activeTab={tabId}
                    onChange={_handleChangeTab}
                    keyGetLabel="title"
                    keyGetValue="id"
                    buttonClassName="tab-items flex-centeritem"
                    listTabs={TYPE_SCHEDULE_ASSIGNEE}
                />
            )}

            {!isCrewTab ? (
                <ul id={'show_list_schedule_job_preview'} className="scrolls">
                    {_renderLoadingList()}
                    {_renderListSchedule()}
                </ul>
            ) : (
                <CrewTab crewSelected={crewSelected} onSelectCrew={_handleSelectCrew} />
            )}
        </div>
    );
};

export default ListSchedules;
