import classNames from 'classnames';
import React, { useEffect, useId, useReducer, useRef } from 'react';

import GdTags from 'app/components/tags';
import { LIMIT_TAGS, TYPE_TAGS } from 'app/const/Customers';
import { reducer } from 'app/const/Reducer';
import TagsDisplay from 'app/modules/customer/detail/accounts/components/TagsDisplay';
import IconPlus from 'assets/icon/IconPlus';
import { changeStyleRowsDropdown } from 'common/utils/GridViewUtils';

const ColumnTags = ({
    idRow = '',
    item = {},
    style = '',
    typeTag = TYPE_TAGS.TAG_CUSTOMER,
    typeValue = TYPE_TAGS.TAG_CUSTOMER,
    keyGetValue = 'tags',
    onHandleSaveTags = () => {}
}) => {
    const limit = typeValue === TYPE_TAGS.TAG_INVOICE ? LIMIT_TAGS.TAG_INVOICE : 0;
    const [state, dispatchState] = useReducer(reducer, { isAddTags: false });
    const isAddTags = state.isAddTags;
    const refTags = useRef(null);
    const tags = item[keyGetValue] || [];

    const isConversationTag = typeTag === TYPE_TAGS.TAG_CONVERSATION;
    const finalId = useId();

    useEffect(() => {
        if (!isAddTags) {
            document.addEventListener('click', handleClickOutside, true);
            _handleClose();
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            _handleAddTags();
            refTags.current.onEdit();
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const _handleAddTags = () => {
        dispatchState({ isAddTags: true });
    };

    const _handleClose = () => {
        dispatchState({ isAddTags: false });
        _handleChangeStyleRow(false);
    };

    const handleClickOutside = (event) => {
        const elPrevent = document.getElementById(finalId);
        const listTags = refTags.current?.getValue() || [];
        const isOverLimit = refTags.current?.isOverLimit() || false;

        if (elPrevent && !elPrevent.contains(event.target)) {
            if ((limit && listTags.length > limit) || isOverLimit) return;
            _handleClose();
            onHandleSaveTags(listTags, item.id, typeValue);
        }
    };

    const _handleChangeStyleRow = (isOpen) => {
        changeStyleRowsDropdown(idRow, isOpen);
    };

    return (
        <div className={style}>
            {!isAddTags || item.deleted ? (
                <ListTags
                    data={tags.map((item) => (isConversationTag ? item : item.name))}
                    isDisable={item.deleted}
                    onClick={_handleAddTags}
                />
            ) : (
                <div id={finalId}>
                    <GdTags
                        ref={refTags}
                        defaultTags={tags.map((item) => ({
                            id: isConversationTag ? item : item.name,
                            name: isConversationTag ? item : item.name
                        }))}
                        limit={limit}
                        isTagsSideMenu
                        classWrapper="tags__info flexcenter flex-wrap"
                        autoFocus
                        isCheckHidden
                        onOpen={_handleChangeStyleRow}
                        deleteWithId
                        type={typeValue}
                    />
                </div>
            )}
        </div>
    );
};

const ListTags = ({ data = [], isDisable = false, onClick = () => {} }) => {
    return (
        <>
            <TagsDisplay
                isTagsSideMenu
                data={data.map((item) => item.name || item)}
                classRow="tags__info flexcenter flex-wrap"
                classWrapper="tags"
            />
            <div
                className={classNames('v2-btn-default --icon-sm btn-plus', { 'is-disable': isDisable })}
                onClick={onClick}
            >
                <IconPlus />
            </div>
        </>
    );
};

export default ColumnTags;
