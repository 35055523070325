/* eslint-disable no-unused-vars */
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useReducer, useRef } from 'react';
import Status from 'app/modules/job/status';
import IconBigArrowUp from 'assets/icon/IconBigArrowUp';
import { reducer } from 'app/const/Reducer';
import DataRepeatContextProvider from 'app/modules/jobdetail/contexts/DataRepeatContext';
import Scheduling from 'app/modules/jobdetail/tabs/settingschedule/Scheduling';
import ScheduleTime from 'app/modules/jobdetail/tabs/settingschedule/time';
import Assign from 'app/modules/jobdetail/tabs/settingschedule/assign';
import TimeWindow from '../timewindow';
import Commission from 'app/modules/addjob/commission';
import AssignPreview from './Assign';
import { ACTIONS_RECURRING, JOB_CANCELED, JOB_COMPLETED, JOB_EXCLUDE } from 'app/const/Job';
import { useDispatch, useSelector } from 'react-redux';
import { convertPhpFormatToMoment, convertTimeToISO } from 'common/utils/DateUtils';
import moment from 'moment';
import { actionCreateJobPlan } from 'common/redux/actions/job/create';
import { DEFAULT_VALUE, TABS_INOIVE_REPEAT } from 'app/modules/jobdetail/const/Invoice';
import { JOB_STATE } from 'app/modules/jobdetail/const';
import ButtonCustomize from './Customize';
import ButtonSave from 'app/components/button/ButtonSave';
import { clientQuery } from 'common/utils/ApiUtils';
import { updateScheduleJob } from 'app/const/Api';
import { LIST_STATUS } from 'app/const/Status';
import StatusBar from 'app/components/status/statusbar';
import ModalJobRecuring from 'app/modules/calendar/components/modal/JobRecuring';

function PlanDetail({ data, index, onUpdateDate, defaultStart, shouldUpdate, onTriggerActiveJob = () => {} }, ref) {
    const [state, dispatchState] = useReducer(reducer, {
        jobDetail: data,
        isReload: 0,
        isFetchedServiceDetail: data.isFetchedServiceDetail || false,
        isInitJob: !index,
        isFetched: false
    });
    const contentRef = useRef(null);
    const refModalRecurring = useRef(null);

    const dateFormat = useSelector(({ auth }) => auth?.user?.company?.date_format);
    const formatDisplay = convertPhpFormatToMoment(dateFormat);
    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({
        getValue: _getJobParams
    }));

    const { jobDetail, isReload, isFetchedServiceDetail, isInitJob } = state;

    const {
        start,
        timeStart,
        id: jobId,
        job_state: jobState,
        plan_item_id: planItemId,
        isNexJob,
        repeat: jobRepeat,
        trigger_service: haveTriggerOther
    } = jobDetail;

    const isActiveJob = jobState === JOB_STATE.ACTIVE;
    const isCreateJob = jobState === JOB_STATE.CREATING;
    const isInactiveJob = jobState === JOB_STATE.IN_ACTIVE;

    const refCommission = useRef(null);
    const refScheduleTime = useRef(null);
    const refAssign = useRef(null);
    const refButtonSave = useRef(null);
    const refStatusBar = useRef(null);

    useEffect(() => {
        if (defaultStart && defaultStart !== start) {
            dispatchState((prev) => ({
                ...prev,
                jobDetail: {
                    ...prev.jobDetail,
                    start: defaultStart
                },
                isReload: Date.now()
            }));
        }
    }, [defaultStart]);

    useEffect(() => {
        if (shouldUpdate) {
            dispatchState((prev) => ({
                ...prev,
                jobDetail: data,
                isReload: Date.now()
            }));
        }
    }, [shouldUpdate]);

    function _getDataInvoice(refValue, isInvoice) {
        if (!refValue || isActiveJob) {
            return null;
        }
        const { items: listItems, type: estimateType, packages: estimatePackages } = refValue;
        const itemsSubmit = [];
        const finalDeposit = { ...(refValue.deposit || DEFAULT_VALUE.deposit) };

        listItems.forEach((element) => {
            const tax1Detail = element.tax1 || {};
            const tax2Detail = element.tax2 || {};
            const checkItemCost = element?.cost?.value?.length !== 0;
            const { item_id } = element;

            if (checkItemCost && item_id) {
                itemsSubmit.push({
                    item_id: item_id,
                    name: element.name,
                    quantity: element.quantity,
                    cost: element.cost?.value || 0,
                    tax1_id: tax1Detail.id || '',
                    tax2_id: tax2Detail.id || '',
                    tax1_name: tax1Detail.name || '',
                    tax2_name: tax2Detail.name || '',
                    tax1_rate: tax1Detail.rate || '',
                    tax2_rate: tax2Detail.rate || '',
                    description: element.description,
                    one_time: element.one_time || 0,
                    type: element.type || '',
                    package_id: element.package_id || ''
                });
            }
        });

        const recurrence = { ...refValue.recurrence };
        const offset = recurrence.offset;
        const finalDiscount = { ...refValue.discount };

        return {
            customer_id: refValue.customer_id,
            discount: finalDiscount,
            deposit: isInvoice ? undefined : finalDeposit,
            number: refValue.number,
            po_number: refValue.po_number,
            date: refValue.date,
            items: itemsSubmit,
            subtotal: refValue.subtotal,
            total: refValue.total,
            trigger_action: refValue.trigger_action,
            recurrence: {
                action: recurrence.action,
                offset: offset
            },
            location_id: refValue.location_id,
            terms: refValue.terms,
            note: refValue.note,
            note_repeat: !isInvoice || offset.frequency === TABS_INOIVE_REPEAT.OFF ? 0 : refValue.note_repeat,
            packages: estimatePackages,
            type: isInvoice ? '' : estimateType
        };
    }

    const _getJobParams = () => {
        const timeSchedule = refScheduleTime.current.getValue();
        const scheduleAddition = [];
        const { jobLength, timePicker: time } = timeSchedule;
        const newJobData = _getJobData();

        const {
            start,
            documents: finalDocuments = [],
            locked,
            schedule: schedules,
            materials: finalMaterials = [],
            crew_ids,
            commission_selected: commissionSelected = []
        } = newJobData || {};

        let schedulePrimary = '';

        schedules.forEach((item) => {
            if (item.is_primary === 1) {
                schedulePrimary = item.id;
            } else {
                scheduleAddition.push(item.id);
            }
        });

        if (isActiveJob) {
            return {
                recurrence: newJobData.recurrence.offset || null,
                exception: newJobData.exception,
                date: convertTimeToISO(
                    moment(`${moment(start).utc().format('DD MMMM YYYY')} ${time.hour}:${time.minute} ${time.midDay}`)
                )
            };
        }

        return {
            service_id: newJobData.id || '',
            primary_schedule_id: schedulePrimary,
            schedule_ids: scheduleAddition,
            crew_ids,
            note: newJobData.note || '',
            date: convertTimeToISO(
                moment(`${moment(start).utc().format('DD MMMM YYYY')} ${time.hour}:${time.minute} ${time.midDay}`)
            ),
            hours: jobLength.hour,
            minutes: jobLength.minute,
            recurrence: newJobData.recurrence.offset || null,
            exception: newJobData.exception,
            invoice: _getDataInvoice(newJobData.invoice, true),
            estimate_items: newJobData.estimate_items,
            estimate: _getDataInvoice(newJobData.estimate, false),
            documents: finalDocuments.map((item) => item.parent_id),
            materials: finalMaterials.filter((item) => item.paramAdd).map((itemMap) => itemMap.paramAdd),
            buildings: newJobData.buildings || [],
            units: newJobData.units || [],
            status: newJobData.status,
            always_confirmed: newJobData.always_confirmed,
            locked: locked || 0,
            estimate_id: newJobData.isFromEstimate || '',
            time_window: newJobData.time_window,
            sold_by_ids: [...new Set(commissionSelected.map((item) => item.id) || [])],
            repeat: newJobData.repeat,
            notify_customer: newJobData.notify_customer || {},
            notify_tech: newJobData.notify_customer || 0,
            editable: isFetchedServiceDetail ? 1 : 0
        };
    };

    const _getJobData = () => {
        const currentData = { ...refScheduleTime.current.getJobData() };
        const dayOfStartISO = currentData.start.split('T')[0];
        const timeOfTimeStartISO = currentData.timeStart.split('T')[1];
        const { schedules, crew_ids } = refAssign.current.getValue();

        delete currentData.delay;
        delete currentData.reloadDateJob;

        return {
            ...currentData,
            changeService: isFetchedServiceDetail ? Date.now() : 0,
            start: `${dayOfStartISO}T${timeOfTimeStartISO}`,
            location_id: currentData.location_id,
            commission_selected: [...refCommission.current.getValue()],
            crew: [],
            schedule: schedules,
            crew_ids,
            status: jobDetail.status,
            isFetchedServiceDetail,
            isInitJob,
            haveTriggerOther: haveTriggerOther
        };
    };

    const _toggleExpand = () => {
        contentRef.current.classList.toggle('active');
    };

    const _handleUpdateTime = (newDate) => {
        dispatchState((prev) => ({
            ...prev,
            jobDetail: {
                ...prev.jobDetail,
                start: newDate
            }
        }));

        if (isCreateJob) {
            setTimeout(() => {
                onUpdateDate(planItemId, newDate, index, haveTriggerOther);
            }, 0);
        }
    };

    const _handleTimeStart = (newTime) => {
        dispatchState((prev) => ({
            ...prev,
            jobDetail: {
                ...prev.jobDetail,
                timeStart: newTime
            }
        }));
    };

    const _handleAssign = (newAssign) => {
        dispatchState((prev) => ({
            ...prev,
            jobDetail: {
                ...prev.jobDetail,
                schedule: newAssign
            }
        }));
    };

    const _handleChangeStatus = (newData) => {
        const newStatus = typeof newData === 'string' ? newData : newData.new_status;
        dispatchState((prev) => ({
            ...prev,
            jobDetail: {
                ...prev.jobDetail,
                status: newStatus
            }
        }));

        if (isActiveJob && newStatus === JOB_COMPLETED) {
            setTimeout(() => {
                onTriggerActiveJob({ status: newStatus, job_id: jobId, plan_item_id: planItemId });
            }, 500);
        }
    };

    const _goBackSaveJob = (newData) => {
        const newDate = newData.date;
        dispatchState((prev) => ({
            ...prev,
            jobDetail: {
                ...prev.jobDetail,
                ...newData,
                start: newDate,
                timeStart: newDate
            },
            isFetchedServiceDetail: true,
            isReload: Date.now()
        }));

        setTimeout(() => {
            if (isCreateJob || newData.shouldUpdateRecurring) {
                onUpdateDate(planItemId, newDate, index, true);
            }
        }, 0);
    };

    const _openJobPlanDetail = (e) => {
        e && e.stopPropagation();
        dispatch(actionCreateJobPlan(_getJobData(), _goBackSaveJob));
    };

    const _updateScheduleSuccess = (res, dateStart) => {
        if (haveTriggerOther && dateStart !== data.start) {
            onUpdateDate(planItemId, dateStart, index, true);
        }

        refStatusBar.current.showStatusBar('show_error', 'Update successfully', LIST_STATUS.SUCCESS);
        _removeLoading();
    };

    const _handleScheduleFailed = (response) => {
        refStatusBar.current.showStatusBar('show_error', response?.message?.toString(), LIST_STATUS.ERROR);
        _removeLoading();
    };

    const _removeLoading = () => {
        refButtonSave.current.removeLoading();
    };

    const _submitData = (actionAll) => {
        const { schedule: listSchedule, event_id, start: dateStart, crew_ids, time_window } = _getJobData();
        const { jobLength, timePicker: time } = refScheduleTime.current.getValue();
        const newDateStart = moment(dateStart).isValid() ? moment(dateStart).utc().format('DD MMMM YYYY') : '';

        let schedulePrimary = '';
        let scheduleAddition = [];

        listSchedule.forEach((item) => {
            if (item.is_primary === 1) {
                schedulePrimary = item.id;
            } else {
                scheduleAddition = scheduleAddition.concat(item.id);
            }
        });

        clientQuery(
            updateScheduleJob(jobId),
            {
                method: 'PUT',
                data: {
                    event: {
                        id: event_id,
                        date: newDateStart,
                        time: `${time.hour}:${time.minute} ${time.midDay}`,
                        hours: jobLength.hour,
                        minutes: jobLength.minute,
                        primary_schedule_id: schedulePrimary
                    },
                    schedules: scheduleAddition,
                    crew_ids: crew_ids || [],
                    time_window: time_window,
                    all: actionAll + 1,
                    fast_form: 1
                }
            },
            (res) => _updateScheduleSuccess(res, dateStart),
            _handleScheduleFailed
        );
    };

    const _callBack = (all) => {
        _submitData(all);
    };

    const _handleUpdateJob = () => {
        if ((start !== defaultStart && haveTriggerOther) || !!jobRepeat) {
            refModalRecurring.current._showOptions({}, _callBack, _handleClose);
        } else {
            _submitData(ACTIONS_RECURRING.ONLY);
        }
    };

    const _handleClose = () => {
        _removeLoading();
    };

    return (
        <div ref={contentRef} className={`detail-plan__items flex-column gap-16 `}>
            {isNexJob && <div className="next-job budget --green">Next job</div>}
            <StatusBar ref={refStatusBar} />

            <div className="flexcenter gap-8">
                <div className="flexcenter gap-4 flex-1">
                    <div className="numerical-order text-center">{index + 1}</div>
                    <Status
                        isCreate={isCreateJob}
                        jobData={{ ...jobDetail, recurrence: '' }}
                        filterParent={isCreateJob || isInactiveJob ? JOB_EXCLUDE.NEW_JOB : JOB_EXCLUDE.JOB_DETAIL}
                        isInclude={isCreateJob || isInactiveJob}
                        onChange={_handleChangeStatus}
                    />
                </div>
                <div className="item-right flexcenter gap-8" onClick={_toggleExpand}>
                    <div className="list-techs flexcenter gap-4 flex-1">
                        <div className="purple-default txt-ellipsis">{jobDetail.name}</div>
                        <AssignPreview schedules={jobDetail.schedule} />
                    </div>
                    <p className="date">{moment(start).utc().format(formatDisplay)}</p>
                    <p className="time">{moment(timeStart).utc().format('h:mm A')}</p>

                    <ButtonCustomize
                        onOpenFormDetail={_openJobPlanDetail}
                        isPlanCreateForm={isCreateJob}
                        isActiveJob={isActiveJob}
                        jobId={jobId}
                    />

                    <div className="v2-btn-default --icon-sm">
                        <IconBigArrowUp />
                    </div>
                </div>
            </div>
            <DataRepeatContextProvider defaultData={{ ...jobDetail }} triggerReload={isReload}>
                <Scheduling onUpdate={_handleUpdateTime} />
                <ScheduleTime ref={refScheduleTime} onUpdate={_handleTimeStart} isAddJob />
                <TimeWindow isAddJob />
                <Assign
                    triggerReload={isReload}
                    ref={refAssign}
                    onUpdate={_handleAssign}
                    jobAssign={jobDetail.schedule}
                    crewIds={jobDetail.crew_ids || []}
                />
                <Commission parentSelected={jobDetail.commission_selected || []} ref={refCommission} />
            </DataRepeatContextProvider>

            <ModalJobRecuring ref={refModalRecurring} />

            {!isCreateJob && (
                <div className="d-flex justify-end">
                    <ButtonSave ref={refButtonSave} onSave={_handleUpdateJob} />
                </div>
            )}
        </div>
    );
}

export default forwardRef(PlanDetail);
