import React, { Fragment, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';

import CustomerInfo from 'app/modules/jobdetail/tabs/detail/CustomerInfo';
import Location from 'app/modules/jobdetail/tabs/detail/location';
import PlanJobs from 'app/modules/addjob/service/Jobs';
import TabDetailContextProvider from 'app/modules/jobdetail/contexts/TabDetailContext';
import HeaderJobDetail from 'app/modules/jobdetail/header';
import IconNote from 'assets/icon/IconNote';
import { actionCloseServicePlan } from 'common/redux/actions/servicePlanAction';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import { getJobPlanDetail } from 'app/const/Api';
import ServiceTrigger from './ServiceTrigger';
import { JOB_STATE } from 'app/modules/jobdetail/const';
import Loading from 'app/modules/addjob/service/Loading';
import { CUSTOMERS } from 'app/const/Route';
import { addBranchPath } from 'app/const/Branch';
import { useHistory } from 'react-router-dom';

const ServicePlanDetail = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        servicePlanData: {},
        isLoading: true,
        triggerUpdate: 0,
        name: ''
    });

    const { isLoading, servicePlanData, isVisible: finalIsVisible, triggerUpdate, name, servicePlanId } = state;

    const { jobId, servicePlan = [], customer = {}, location: locationInfo = {} } = servicePlanData || {};

    useEffect(() => {
        if (!!jobId) {
            clientQuery(getJobPlanDetail(jobId), { method: 'GET' }, _getServiceSettingDataSuccess);
        }
    }, [jobId]);

    const _checkIsNextJob = (planCheckNext, trigger_plan_item_id) => {
        return planCheckNext.some(
            (item) => item.job_state === JOB_STATE.ACTIVE && item.job.service_plan.plan_item_id === trigger_plan_item_id
        );
    };

    const _getServiceSettingDataSuccess = ({ data }) => {
        const { services, name: serviceName, id } = data || {};

        dispatchState((prev) => ({
            ...prev,
            isLoading: false,
            name: serviceName,
            servicePlanId: id,
            servicePlanData: {
                ...prev.servicePlanData,
                servicePlan: services.map((item, index) => {
                    const { job, event, job_state } = item;
                    const currentServicePlan = job.service_plan || {};
                    const { trigger_plan_item_id } = currentServicePlan;

                    return {
                        ...job,
                        ...item,
                        isNexJob:
                            index !== 0 &&
                            job_state === JOB_STATE.IN_ACTIVE &&
                            _checkIsNextJob(services, trigger_plan_item_id),
                        customer: customer,
                        customer_id: customer.id || '',
                        location: locationInfo,
                        location_id: locationInfo.id,
                        repeat: event.repeat || 0,
                        start: event.start,
                        timeStart: event.start,
                        time_window: event.time_window,
                        recurrence: event.recurrence,
                        length: event.length,
                        trigger_service: currentServicePlan.trigger_service,
                        plan_item_id: currentServicePlan.plan_item_id,
                        trigger_plan_item_id: trigger_plan_item_id,
                        event_id: event.id,
                        serviceData: job.service_data || {},
                        isFetchedServiceDetail: true,
                        delay: (currentServicePlan || {}).delay,
                        commission_selected: item.sold_by
                    };
                })
            }
        }));
    };

    const _onOpenServicePlan = (newData) => {
        dispatchState((prev) => ({
            ...prev,
            servicePlanData: {
                ...prev.servicePlanData,
                ...newData
            },
            isVisible: true
        }));
    };

    const _forceCloseServicePlan = () => {
        dispatch(actionCloseServicePlan());
    };

    const _closeServicePlan = () => {
        dispatchState((prev) => ({
            ...prev,
            servicePlanData: {},
            isVisible: false,
            isLoading: true
        }));
    };

    const _handleGoBack = () => {
        _forceCloseServicePlan();
    };

    const _goCustomerDetail = (e) => {
        e.stopPropagation();
        history.push(addBranchPath(`${CUSTOMERS}/${servicePlanData.customer?.id}`));
    };

    const _renderForm = () => {
        if (!finalIsVisible) {
            return false;
        }

        return (
            <div className="modal container-modal c-job-details open">
                <div className="modal__overlay bg-fixed" />
                <div className="modal__container service-plan-container">
                    <HeaderJobDetail
                        onGoBackJob={_handleGoBack}
                        isServicePlan
                        jobData={servicePlanData}
                        onClose={_forceCloseServicePlan}
                        servicePlanName={name}
                    />
                    <div className="body-modal">
                        <div className="contents accessible-tabs-container">
                            <div className="tab-contents scrolls">
                                <div id="tab_job_detail" className="tab-conts dashboard-job-detail tab-content-active">
                                    <div className="container-column details-wrap">
                                        <div className="details-job">
                                            <TabDetailContextProvider
                                                jobData={{ ...servicePlanData }}
                                                onUpdate={() => {}}
                                            >
                                                <CustomerInfo
                                                    isJobDetail
                                                    isLoading={false}
                                                    customerDetail={servicePlanData.customer}
                                                />
                                                <Location
                                                    isLoading={false}
                                                    jobData={servicePlanData}
                                                    onUpdateSuccess={() => {}}
                                                />
                                            </TabDetailContextProvider>
                                            {isLoading ? (
                                                <div className="details-job__content detail-plan schedule-wrapper wrap-loading">
                                                    <Loading />
                                                    <Loading />
                                                    <Loading />
                                                </div>
                                            ) : (
                                                <PlanJobs
                                                    servicePlanId={servicePlanId}
                                                    servicePlan={servicePlan}
                                                    triggerUpdate={triggerUpdate}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="contents__menu scrolls">
                                <div className="customer-name">
                                    <div onClick={_goCustomerDetail} className="txt-ellipsis cursor-pointer">
                                        {servicePlanData.customer?.full_name || ''}
                                    </div>
                                </div>
                                <ul className="tab-selectors --service-plan">
                                    <li className="tab-items active-tab-selector">
                                        <IconNote />
                                        Service Plan
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Fragment>
            {_renderForm()}
            <ServiceTrigger onOpenServicePlan={_onOpenServicePlan} onCloseServicePlan={_closeServicePlan} />
        </Fragment>
    );
};

export default ServicePlanDetail;
