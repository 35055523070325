import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import GdGridView from 'app/components/grid/GdGridView';
import { MATERIAL_USE_SELECT_OPTIONS } from 'app/const/App';
import {
    getGidColumnsTableCompletedJob,
    getGidColumnsTableMaterial,
    getGridColumnsTableDetailed,
    getGridColumnsTableNewYork
} from 'app/const/report/MaterialUse';

function TableMaterial({ data, isShow, isLoading, orderStatus, isShowCountyColumn, onClickHeaderCallBack }) {
    const country = useSelector(({ auth }) => auth.user.company.country);

    return (
        <>
            {isShow && (
                <GdGridView
                    isLoading={isLoading}
                    classTable="table-multi-column has-footer --material scrolls-x has-text-ellipsis"
                    classTableContent=""
                    content={data}
                    fileTranslation={'report'}
                    handleClick={() => {}}
                    handleClickHeader={(event) => {
                        onClickHeaderCallBack(event);
                    }}
                    {...getGidColumnsTableMaterial(orderStatus, isShowCountyColumn, country)}
                />
            )}
        </>
    );
}

function TableCompletedJob({ data, isShow, isLoading, handleClick }) {
    const { t } = useTranslation(['report']);
    return (
        <>
            {isShow && (
                <GdGridView
                    isLoading={isLoading}
                    classTable="--material scrolls-x"
                    classTableContent=""
                    content={data}
                    fileTranslation={'report'}
                    handleClick={handleClick}
                    title={t('report:completed_job_w_o_a_county_match')}
                    {...getGidColumnsTableCompletedJob()}
                />
            )}
        </>
    );
}

function TableDetailed({
    data,
    isShow,
    isLoading,
    orderStatus,
    columnStatus,
    countyParam = null,
    isLoadMore = false,
    onClickHeaderCallBack = () => {},
    handleClick = () => {},
    onScrollToEnd = () => {}
}) {
    const { t } = useTranslation(['report']);
    const country = useSelector(({ auth }) => auth.user.company.country);
    const isNYPRL = countyParam === MATERIAL_USE_SELECT_OPTIONS.NY_PRL;
    const gridColumn = isNYPRL
        ? getGridColumnsTableNewYork(orderStatus, columnStatus)
        : getGridColumnsTableDetailed(orderStatus, columnStatus, country);

    return (
        isShow && (
            <GdGridView
                isEmptyFlat
                isLoading={isLoading}
                classTable="table-multi-column --material scrolls-x has-text-ellipsis"
                classTableContent=""
                content={data}
                fileTranslation={'report'}
                title={t(`report:${isNYPRL ? 'new_york_material_use' : 'all_detailed_material_use'}`)}
                {...gridColumn}
                isScroll
                isLoadmore={isLoadMore}
                handleClick={handleClick}
                handleClickHeader={onClickHeaderCallBack}
                onScrollToEnd={onScrollToEnd}
            />
        )
    );
}

export { TableMaterial, TableCompletedJob, TableDetailed };
