import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import MultiSelect from 'app/components/select/multiSelect';
import { JOB_EXCLUDE } from 'app/const/Job';
import { reducer } from 'app/const/Reducer';
import { ADDONS_CALENDAR_SETTINGS } from 'app/const/api/V2';
import { clientQuery } from 'common/utils/ApiUtils';
import useJobStatus from 'common/hooks/useJobStatus';
import { DEFAULT_ALL } from 'app/const/App';
import { OPTION_TIMEOFF_STATUS } from 'app/const/addons';

const CalendarSelectJobSync = ({ selectedStatuses = [] }) => {
    const { t } = useTranslation('addons');
    const [state, dispatchState] = useReducer(reducer, { selected: selectedStatuses });
    const { selected } = state;
    const getListJobStatus = useJobStatus({ filter: JOB_EXCLUDE.SYNC_GOOGLE_CALENDAR, isIncludeDeleted: true });
    const listJobStatus = [...getListJobStatus, OPTION_TIMEOFF_STATUS];

    const _handleSelect = (value, selected) => {
        dispatchState({ selected });
        clientQuery(ADDONS_CALENDAR_SETTINGS, { data: { job_statuses: selected }, method: 'PUT' });
    };

    return (
        <div className="boxs-selection flex-1 px-4">
            <p className="purple-default mr-2">{t('job_to_sync')}:</p>
            <MultiSelect
                name={t('job_statuses')}
                fileTranslation="addons"
                titleSelectAllOption="all_statuses"
                options={listJobStatus}
                selected={selected === DEFAULT_ALL ? listJobStatus.map((status) => status.id) : selected}
                classWrapper="list-job-status --no-bg"
                isJobStatus
                isPopper
                isUseToggle
                onSelect={_handleSelect}
            />
        </div>
    );
};

export default CalendarSelectJobSync;
