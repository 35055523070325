import classNames from 'classnames';
import React, { useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import GdSearch from 'app/components/search/GdSearch';
import { reducer } from 'app/const/Reducer';

export const VOIPDropdownUser = ({ item = {}, children, companyUsers = [], isReload = true, onHide = () => {} }) => {
    const { id, assignees } = item;
    const { t } = useTranslation();
    const refAssignees = useRef(assignees);
    const isFirstTime = useRef(true);
    const isChanged = useRef(false);

    const [state, dispatchState] = useReducer(reducer, { keyword: '', assignees });

    const { keyword, assignees: finalAssignees } = state;

    const _handleChange = (e) => {
        if (!isChanged.current) {
            if (isReload) refAssignees.current = assignees;
            isChanged.current = true;
        }
        const { value, checked } = e.target;
        const newAssignees = [...refAssignees.current];
        refAssignees.current = checked ? [...newAssignees, value * 1] : newAssignees.filter((id) => id !== value * 1);
        dispatchState((prev) => ({ ...prev, assignees: refAssignees.current }));
    };

    const _handleSearch = (keyword) => {
        dispatchState({ keyword });
    };

    const _handleOnHide = () => {
        !isFirstTime.current && isChanged.current && onHide(id, refAssignees.current);
        dispatchState({ keyword: '' });
        isChanged.current = false;
    };

    const _handleVisible = () => {
        if (isFirstTime.current) isFirstTime.current = false;
    };

    const _renderListUser = () => {
        const listUser = companyUsers.filter((user) =>
            user.full_name.trim().toLowerCase().includes(keyword.trim().toLowerCase())
        );
        return !!listUser.length ? (
            listUser.map((user) => {
                const { id: userId, full_name, avatar } = user;
                const ele = `customer_${id}_${userId}`;
                return (
                    <li
                        key={userId}
                        className={classNames('items', { active: finalAssignees.includes(Number(userId)) })}
                    >
                        <div className="check-items w-100">
                            <input
                                id={ele}
                                type="checkbox"
                                value={Number(userId)}
                                onChange={_handleChange}
                                defaultChecked={finalAssignees.includes(Number(userId))}
                            />
                            <div className="item-checkbox">
                                <label htmlFor={ele}>
                                    <div className="avt-img">
                                        <img src={avatar} width={24} height={24} />
                                    </div>
                                    <span className="txt-ellipsis" title={full_name}>
                                        {full_name}
                                    </span>
                                </label>
                            </div>
                        </div>
                    </li>
                );
            })
        ) : (
            <li key={'empty'} className="items pointer-events-none">
                <div className="w-100">
                    {keyword
                        ? t('header:search_not_match')
                        : t('common:no_data_to_display', { title: t('common:users') })}
                </div>
            </li>
        );
    };

    return (
        <DropdownPopper
            id={id}
            customButton={children}
            buttonClassName=""
            wrapperListClass="v2-dropdown__menu content-search"
            wrapperClassName="v2-dropdown search-member"
            isUseToggle
            onClose={_handleOnHide}
            onOpen={_handleVisible}
        >
            <div className="container-column">
                <div className="text-uppercase grey-generic border-bottom-border-color-grey p-2 fs-12">
                    {t('addons:manage_activated_users')}
                </div>
                <div className="flex-auto header-search">
                    <GdSearch onSearch={_handleSearch} isSearchOnClear />
                </div>
                <ul className="box-auto">{_renderListUser()}</ul>
            </div>
        </DropdownPopper>
    );
};
