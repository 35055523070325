import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import GdGridView from 'app/components/grid/GdGridView';
import ModalLoading from 'app/components/loading/ModalLoading';
import SearchQBModal from 'app/components/quickbooks/SearchQBModal';
import { ADDONS_QUICKBOOKS_SYNC_LOG } from 'app/config/routes';
import { CUSTOMER_INVOICES, CUSTOMER_INVOICES_PERMANENT_DELETE, QUICK_BOOK_CUSTOMER_INVOICE } from 'app/const/Api';
import { COMMON, DEFAULT_ALL, INVOICES_ACTION_OPTIONS, KEY_REPORT_LOCAL_STORAGE, LIST_STATUS } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { getGridInvoiceTable } from 'app/const/customer/CustomerInvoices';
import { QB_SYNC_STATUS, QUICK_BOOK_CUS_INVOICE } from 'app/const/quickbook';
import { reducer } from 'app/const/Reducer';
import { getDefaultParams } from 'app/const/report/ReportParams';
import { REPORT_TYPE } from 'app/const/Reports';
import { KEY_LOCATION_FILTER_SELECTED } from 'app/modules/customer/const';
import { CustomerDetailContext } from 'app/modules/customer/detail/context/CustomerDetailContext';
import { resetPagingLocation } from 'app/modules/customer/utils';
import NewInvoice from 'app/modules/quickadd/invoice';
import ReportPagination from 'app/modules/report/components/ReportPagination';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import RealtimeInvoiceList from '../RealtimeInvoice';
import CustomerInvoiceActions from './CustomerInvoiceActions';
import CustomerInvoiceConfirmDelete from './CustomerInvoiceConfirmDelete';
import { INVOICE_STATUS } from 'app/const/Filters';

const keyLocalStore = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.CUSTOMER_INVOICES);

const CustomerInvoiceTable = ({ onShowAlert = () => {}, reloadInvoicesRecurring = () => {} }) => {
    const { t } = useTranslation('customers');
    const dispatch = useDispatch();
    const history = useHistory();
    const { id: customer_id } = useParams();
    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        checkedItems: { is_check_all: false, ids: [] },
        totalPage: 1,
        total: 0,
        isLoading: true,
        deleted: 0
    });
    const refLoading = useRef(null);
    const refConfirmDelete = useRef(null);
    const refFormNewInvoice = useRef(null);
    const refQBModal = useRef(null);
    const refOldLocation = useRef(getLocalStorage(KEY_LOCATION_FILTER_SELECTED)?.location_ids || -1);
    const refOldUnit = useRef(null);
    const refDuplicateInvoiceQB = useRef(true);
    const {
        customer: customerDataContext,
        isLoading: isLoadCustomerSuccess,
        unitSelected,
        location_ids,
        reloadCustomerPage
    } = useContext(CustomerDetailContext);

    const checkedIds = state.checkedItems.ids;

    let paramsInvoices = getLocalStorage(keyLocalStore);

    if (!paramsInvoices || paramsInvoices.customer_id !== customer_id) {
        paramsInvoices = getDefaultParams(REPORT_TYPE.CUSTOMER_INVOICES);
        paramsInvoices.customer_id = customer_id;
        setLocalStorage(keyLocalStore, paramsInvoices);
    }

    const quickBooksAddons = useSelector(({ auth }) => auth.user.settings.addons.quickbooks);

    useEffect(() => {
        if (checkReload(location_ids, unitSelected, customer_id) || reloadCustomerPage) {
            refOldLocation.current = location_ids;
            refOldUnit.current = unitSelected.id;
            _handleFetchData();
        }
    }, [location_ids, unitSelected, reloadCustomerPage, customer_id]);

    const checkReload = (location_ids, unitSelected, customer_id) => {
        if (!location_ids || !unitSelected || !customer_id) return false;
        return location_ids !== refOldLocation.current || unitSelected?.id !== refOldUnit.current || !!customer_id;
    };

    const _handleRealTimeStatus = (responseStatus = {}) => {
        dispatchState((prev) => {
            return {
                ...prev,
                data: prev.data.map((item) => {
                    if (item.id === responseStatus.id.toString()) return { ...item, status: responseStatus.status };
                    return item;
                })
            };
        });
    };

    const _handleFetchData = () => {
        if (!isLoadCustomerSuccess) dispatchState((prev) => ({ ...prev, isLoading: true }));
        _getListInvoices(resetPagingLocation({ params: paramsInvoices, key: keyLocalStore }));
    };

    const _getListInvoices = (data, notLoading = true) => {
        const params = data || {};
        delete params?.filterTab;

        if (notLoading) dispatchState((prev) => ({ ...prev, isLoading: true }));

        const _success = ({ data, total }) => {
            refDuplicateInvoiceQB.current = true;
            dispatchState((prev) => ({
                ...prev,
                data,
                isLoading: false,
                totalPage: Math.ceil(total / params.limit),
                total,
                checkedItems: { is_check_all: false, ids: [] }
            }));
        };
        const _failed = () => dispatchState((prev) => ({ ...prev, data: [], isLoading: false }));

        clientQuery(
            CUSTOMER_INVOICES,
            {
                data: {
                    ...params,
                    location_ids: location_ids || refOldLocation.current || DEFAULT_ALL,
                    unit_ids: unitSelected?.id || DEFAULT_ALL,
                    customer_id
                },
                method: 'GET'
            },
            _success,
            _failed
        );
    };

    const _handleSelect = (checkedItems) => {
        dispatchState((prev) => ({ ...prev, checkedItems }));
    };

    const _handleUpdate = () => {
        const params = getLocalStorage(keyLocalStore);
        _getListInvoices(params);
        reloadInvoicesRecurring(params);
    };

    const _handleQBSyncIds = () => {
        let notAllVoidOrWriteOff = false;
        checkedIds.forEach((id) => {
            const status = state.data.find((dataItem) => dataItem.id === id)?.status;
            switch (status) {
                case INVOICE_STATUS.STATUS_VOID:
                case INVOICE_STATUS.STATUS_WRITE_OFF:
                    break;
                default:
                    notAllVoidOrWriteOff = true;
                    return;
            }
        });

        if (!notAllVoidOrWriteOff) {
            refLoading.current?._close();
            alert(t('warning_no_items_selected'));
            return;
        }

        clientQuery(
            QUICK_BOOK_CUSTOMER_INVOICE,
            { data: { ids: checkedIds }, method: 'POST' },
            null,
            null,
            _redirectQBLogs
        );
    };

    const _redirectQBLogs = () => {
        history.push(addBranchPath(ADDONS_QUICKBOOKS_SYNC_LOG));
    };

    const _handleActionClick = (key) => {
        refLoading.current._open();

        switch (key) {
            case COMMON.DELETE:
                _handleDeleteInvoice(checkedIds);
                break;
            case COMMON.ARCHIVED:
                _handleActionTypeInvoice(checkedIds, INVOICES_ACTION_OPTIONS.ARCHIVED);
                break;
            case COMMON.ACTIVATE:
                _handleActionTypeInvoice(checkedIds, INVOICES_ACTION_OPTIONS.ACTIVATE);
                break;
            case COMMON.UNDELETE:
                _handleActionTypeInvoice(checkedIds, INVOICES_ACTION_OPTIONS.UNDELETE);
                break;
            case COMMON.PERMANENTLY_DELETE:
                _handlePermanentlyDelete(checkedIds);
                break;
            case COMMON.SYNC_TO_QB:
                _handleQBSyncIds();
                break;
            default:
                break;
        }
    };

    const _handleDeleteInvoice = (ids) => {
        const _deleteSuccess = ({ data_payment, is_payments, message, error }) => {
            // If `is_payments` key is true open modal confirm `Convert to credit` for `Delete Permanently`.
            if (is_payments) return refConfirmDelete.current._open({ data_payment, ids });
            _handleDeleteInvoiceSuccess({ message, error }, null, ids);
            const params = getLocalStorage(keyLocalStore);
            reloadInvoicesRecurring(params);
        };

        clientQuery(CUSTOMER_INVOICES, { data: { ids }, method: 'DELETE' }, _deleteSuccess, null, _handleFinally);
    };

    const _handleActionTypeInvoice = (ids, type) => {
        clientQuery(
            CUSTOMER_INVOICES,
            { data: { ids, type }, method: 'PUT' },
            ({ message, error }) => _handleDeleteInvoiceSuccess({ message, error }, null, ids),
            null,
            _handleFinally
        );
    };

    const _handlePermanentlyDelete = (ids) => {
        clientQuery(
            CUSTOMER_INVOICES_PERMANENT_DELETE,
            { data: { ids }, method: 'DELETE' },
            ({ message, error }) => _handleDeleteInvoiceSuccess({ message, error }, null, ids),
            null,
            _handleFinally
        );
    };

    const _handleDeleteInvoiceSuccess = ({ message, error }, messageSuccess, ids) => {
        const params = getLocalStorage(keyLocalStore);
        reloadInvoicesRecurring(params);
        if (messageSuccess) return;
        let idsSuccess = [];

        if (error?.message?.length) {
            ids.forEach((item) => {
                if (!error.items.includes(item)) idsSuccess.push(item);
            });

            onShowAlert(LIST_STATUS.ERROR, {
                id: LIST_STATUS.ERROR,
                message: error.message,
                type: LIST_STATUS.ERROR
            });
        } else {
            idsSuccess = ids;
        }

        if (message.length) {
            onShowAlert(LIST_STATUS.SUCCESS, { id: LIST_STATUS.SUCCESS, message, type: LIST_STATUS.SUCCESS });
        }

        if (idsSuccess.length) {
            const { currentPage, limit } = paramsInvoices;
            const finalTotal = state.total - idsSuccess.length;
            const totalPage = Math.ceil(finalTotal / limit);
            if (!!finalTotal && totalPage < currentPage) {
                paramsInvoices.currentPage = totalPage;
                paramsInvoices.offset = (totalPage - 1) * limit;
                setLocalStorage(keyLocalStore, paramsInvoices);
            }
            _getListInvoices(paramsInvoices, false);
        }
    };

    const _handleFinally = () => {
        refLoading.current._close();
    };

    const _handleAddInvoice = () => {
        refFormNewInvoice.current._handleOpenForm();
    };

    const _handleOpenInvoice = (data) => {
        dispatch(
            actionOpenInvoice({
                id: data.id,
                status: data.status,
                total: data.total.format,
                isRecurring: false,
                onDeleteSuccess: () => _getListInvoices(paramsInvoices, false),
                onEditSuccess: () => _getListInvoices(paramsInvoices)
            })
        );
    };

    const _handleClickHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: REPORT_TYPE.CUSTOMER_INVOICES,
            columnsTarget,
            currentValue,
            paramsReport: paramsInvoices,
            callBack: () => _getListInvoices(getLocalStorage(keyLocalStore))
        });
    };

    const _handleFindQuickBook = (data) => {
        refQBModal.current._open(null, null, data?.id, data?.number);
    };

    const _handleDeleteInvoiceSuccessQB = (itemChecked, isErrorDuplicate) => {
        refDuplicateInvoiceQB.current = isErrorDuplicate;
        const newData = [...state.data].map((item) => {
            if (item.number === itemChecked.number) item.quickbooks.status = QB_SYNC_STATUS.FAIL;
            return item;
        });
        dispatchState((prev) => ({ ...prev, data: newData }));
    };

    return (
        <div className="tab-content__list wrap-tables relative">
            <CustomerInvoiceActions
                totalSelected={checkedIds.length || 0}
                isArchived={paramsInvoices?.archived}
                isDeleted={paramsInvoices?.deleted}
                onActionClick={_handleActionClick}
                onAddInvoice={_handleAddInvoice}
                quickBooksAddons={quickBooksAddons}
                isLoadCustomerSuccess={isLoadCustomerSuccess}
                checkedItems={state.checkedItems}
                totalItems={state.total}
            />
            <GdGridView
                msgEmpty={t('no_invoices')}
                content={state.data}
                isLoading={state.isLoading}
                classTable="table-invoice has-footer has-checkbox scrolls-x"
                fileTranslation="report"
                checkedItems={state.checkedItems}
                onChangeStateCheckedItems={_handleSelect}
                onOpenInvoice={_handleOpenInvoice}
                handleClick={_handleOpenInvoice}
                handleClickHeader={_handleClickHeader}
                typeQuickBook={QUICK_BOOK_CUS_INVOICE}
                quickbookActive={!!quickBooksAddons && !paramsInvoices?.deleted}
                styleQBSync={'col --qb flex-none'}
                showNumberQB={false}
                showCheckBox
                onSearchQB={_handleFindQuickBook}
                isDuplicateInvoiceQB={refDuplicateInvoiceQB.current}
                {...getGridInvoiceTable(paramsInvoices)}
                isScroll
                isShowToolTip
            />
            <ModalLoading ref={refLoading} />
            <CustomerInvoiceConfirmDelete ref={refConfirmDelete} onDeleteSuccess={_handleDeleteInvoiceSuccess} />
            <ReportPagination
                isCustomerPage
                reportType={REPORT_TYPE.CUSTOMER_INVOICES}
                totalPage={state.totalPage || 1}
                onSelect={_handleUpdate}
            />
            {customerDataContext && !state.isLoading && (
                <NewInvoice
                    ref={refFormNewInvoice}
                    defaultCustomer={{ full_name: customerDataContext.full_name, id: customerDataContext.id }}
                    onAddSuccess={_handleUpdate}
                />
            )}
            {!!quickBooksAddons && (
                <SearchQBModal ref={refQBModal} onHandleDeleteInvoiceSuccessQB={_handleDeleteInvoiceSuccessQB} />
            )}
            <RealtimeInvoiceList onUpdate={_handleRealTimeStatus} />
        </div>
    );
};

export default CustomerInvoiceTable;
