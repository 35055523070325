import { CREATE_JOB_ACTIONS } from 'common/redux/actions/job/create';

const createJob = (
    state = {
        // jobData: {
        //     start: '2024-08-27T16:00:00Z',
        //     schedule: {
        //         id: '21737',
        //         name: 'Documents',
        //         user_id: '83946877',
        //         is_primary: 1
        //     },
        //     customer: null,
        //     customer_id: ''
        // },
        jobData: null,
        newJobData: null,
        newJobPlanData: null,
        actionBackPlanDetail: () => {}
    },
    action
) => {
    switch (action.type) {
        case CREATE_JOB_ACTIONS.CREATE_JOB:
            return {
                ...state,
                jobData: action.payload,
                createLocation: action.createLocation,
                updateUnit: action.updateUnit
            };
        case CREATE_JOB_ACTIONS.CREATE_JOB_PLAN:
            return {
                ...state,
                newJobPlanData: action.payload,
                actionBackPlanDetail: action.actionBack
            };
        case CREATE_JOB_ACTIONS.CREATE_JOB_SUCCESS:
            return { ...state, newJobData: action.payload };
        default:
            return state;
    }
};

export default createJob;
