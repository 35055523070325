import classNames from 'classnames';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from 'app/components/checkbox';
import { DEFAULT_ALL } from 'app/const/App';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { TYPE_SELECT } from 'app/const/report/ReportFilter';
import ReportListDropdown from 'app/modules/report/components/ReportListDropdown';
import IconArrowDown from 'assets/icon/IconArrowDown';
import DropdownPopper from '../dropdown/DropdownPopper';
import TitleJobStatus from 'app/modules/job/status/TitleJobStatus';

const MultiSelect = ({
    name = '',
    icon: Icon = null,
    options = [],
    selected: defaultSelected = [],
    title = '',
    titlePlural = '',
    onSelect = () => {},
    fileTranslation = '',
    titleSelectAllOption,
    onGetAsyncOptions = () => {},
    forceRerender = false,
    isShowBarge = true,
    isPopper = false,
    classWrapper = '',
    isShowAvatar = false,
    onCloseSelect = () => {},
    isUseToggle = false,
    nullIsAll = false,
    enableSelectedAll = true,
    isJobStatus = false,
    classListWrapper = 'v2-dropdown__menu content-checked content-full content-user',
    titleComponent: TitleComponent
}) => {
    const { t } = useTranslation([fileTranslation]);
    const refDropDown = useRef(null);
    const isFirstTime = useRef(true);
    const refFirstTime = useRef(true);

    const [isOpen, setIsOpen] = useState(false);
    const dropdownId = `multi_select_dropdown_${name}`;
    const selected =
        Array.isArray(defaultSelected) && isJobStatus
            ? defaultSelected.map((item) => item.toString())
            : defaultSelected;
    const isCheckAll =
        selected === DEFAULT_ALL || (options.length && selected.length && options.length === selected.length);

    useEffect(() => {
        return () => {
            isFirstTime.current = true;
        };
    }, [forceRerender]);

    //Dropdow
    useEffect(() => {
        if (isOpen) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            if (refFirstTime.current) refFirstTime.current = false;
            else onCloseSelect();

            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [isOpen]);

    const handleHideDropdown = (event) => {
        const elPrevent = document.getElementById(dropdownId);
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeDropdown();
        }
    };

    const handleClickOutside = (event) => {
        const elPrevent = document.getElementById(dropdownId);
        if (
            refDropDown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropDown.current.contains(event.target)
        ) {
            _closeDropdown();
        }
    };

    const _handleOpenDropdown = () => {
        if (isFirstTime.current) {
            onGetAsyncOptions &&
                onGetAsyncOptions({
                    item: name,
                    callBack: () => {
                        isFirstTime.current = false;
                    }
                });
        }
        setIsOpen(!isOpen);
    };

    const _closeDropdown = () => {
        isOpen && setIsOpen(false);
    };

    // case all not equal -1 --> this statement below is wrong
    if (!Array.isArray(options) || (!Array.isArray(selected) && selected !== DEFAULT_ALL)) return null;

    const handleSelectOption = (e, value) => {
        // e.preventDefault();

        let newSelected = selected === DEFAULT_ALL ? options.map((i) => i?.id || i?.value) : [...selected];

        if (newSelected.includes(value)) {
            newSelected = newSelected.filter((item) => item !== value);
        } else {
            newSelected.push(value);
        }

        if (newSelected.length === options.length) {
            newSelected = DEFAULT_ALL;
        }

        onSelect(name, newSelected);
    };

    const handleSelectAll = () => {
        const newSelected = selected === DEFAULT_ALL || selected.length === options.length ? [] : DEFAULT_ALL;
        onSelect(name, newSelected);
    };

    const _handleSelectAll = (e) => {
        e.preventDefault();
        handleSelectAll();
    };

    const renderSelectAllOption = () => {
        if (!titleSelectAllOption) return null;

        return (
            <div className={classNames('items', { active: isCheckAll })} onClick={_handleSelectAll}>
                <div className="check-items">
                    <Checkbox
                        label={t(`${titleSelectAllOption}`)}
                        checked={!!isCheckAll}
                        id={`check-box-${title}-all`}
                        onChangeValue={handleSelectAll}
                        value={selected?.length || 0}
                    />
                </div>
            </div>
        );
    };

    const renderButton = () => {
        const isNullIsAll = nullIsAll && !selected.length;
        const isPlural =
            !!titlePlural && (isCheckAll || (Array.isArray(selected) && selected.length > 1) || isNullIsAll);

        const _renderTitle = () => {
            if (TitleComponent) return <TitleComponent />;
            if (isJobStatus && Array.isArray(selected) && !title) {
                return (
                    <div className="wrap-tags">
                        {!!selected.length ? (
                            <TitleJobStatus data={selected} excludeWrapper />
                        ) : (
                            <span className="txt-ellipsis">{t('job_status')}</span>
                        )}
                    </div>
                );
            }

            return <span className="txt-ellipsis">{t(!isPlural ? title : titlePlural)}</span>;
        };

        return (
            <Fragment>
                <div className="dropbtn__label">
                    {Icon && <Icon />}
                    {_renderTitle()}
                    {isShowBarge && (
                        <span className={classNames('budget', { '--grey': isCheckAll || isNullIsAll })}>
                            {isCheckAll || isNullIsAll ? t('common:all') : selected.length}
                        </span>
                    )}
                </div>
                <span className="arrow">
                    <IconArrowDown />
                </span>
            </Fragment>
        );
    };

    const _renderReportListDropdown = () => {
        return (
            <ReportListDropdown
                options={options}
                isShowAvatar={isShowAvatar}
                title={title}
                fileTranslation={fileTranslation}
                typeDropdown={TYPE_SELECT.MULTI}
                selected={selected}
                isFirstTime={!!isFirstTime.current}
                renderSelectAllOption={renderSelectAllOption}
                handleSelectOption={handleSelectOption}
                enableSelectedAll={enableSelectedAll}
                isJobStatus={isJobStatus}
            />
        );
    };

    if (isPopper)
        return (
            <DropdownPopper
                ref={refDropDown}
                placement="bottom-start"
                customStyle={{ width: 'auto', maxWidth: 'auto' }}
                buttonClassName="dropbtn v2-btn-default selection"
                wrapperListClass={classListWrapper}
                wrapperClassName={classNames(classWrapper, 'v2-dropdown', {
                    active: isOpen,
                    'has-img-user': isShowAvatar
                })}
                isUseToggle={isUseToggle}
                isCalculateWidth
                customButton={renderButton()}
                onOpen={_handleOpenDropdown}
                onClose={_closeDropdown}
            >
                {_renderReportListDropdown()}
            </DropdownPopper>
        );
    return (
        <div
            ref={refDropDown}
            id={dropdownId}
            className={classNames(classWrapper, 'v2-dropdown', { active: isOpen, 'has-img-user': isShowAvatar })}
        >
            <div className="dropbtn v2-btn-default selection" onClick={_handleOpenDropdown}>
                <div className="dropbtn__label">{renderButton()}</div>
            </div>
            <div className="v2-dropdown__menu content-checked content-full content-user">
                {_renderReportListDropdown()}
            </div>
        </div>
    );
};

export default MultiSelect;
