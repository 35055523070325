import { API_VERSION, URL_EXPORT_DATA } from 'app/const/URL';

export const URL_PRINT_WORK_ORDER = URL_EXPORT_DATA.concat('workorder/print');

export const URL_PDF_WORK_ORDER = URL_EXPORT_DATA.concat('workorder/pdf');

export const URL_PRINT_DOCUMENT = URL_EXPORT_DATA.concat('document/print');

export const URL_PDF_DOCUMENT = URL_EXPORT_DATA.concat('document/pdf');
export const URL_PDF_DOCUMENT_TEMPLATE = URL_EXPORT_DATA.concat('document/pdf/preview');

export const URL_REPORT_RECURRING_INVOICE = URL_EXPORT_DATA.concat('reports/recurrences');

export const URL_REPORT_CUSTOMERS_WO_ACTIVE_JOB = URL_EXPORT_DATA.concat('reports/customerswoactivejobs');

export const URL_REPORT_ACCOUNTS_AGING = URL_EXPORT_DATA.concat('reports/accountsaging');
export const URL_REPORT_ACCOUNTS_AGING_PDF_STATEMENT = URL_EXPORT_DATA.concat('statements/pdf');
export const URL_REPORT_ACCOUNTS_AGING_PRINT_STATEMENT = URL_EXPORT_DATA.concat('statements/print');

export const URL_REPORT_SALES_TAX = URL_EXPORT_DATA.concat('reports/salestaxsummary');

export const URL_REPORT_SALES_FORECAST = URL_EXPORT_DATA.concat('reports/salesforecast');

export const URL_REPORT_REVENUE_BY_CLIENT = URL_EXPORT_DATA.concat('revenue/client');

export const URL_PDF_SCHEDULE = URL_EXPORT_DATA.concat('schedule/pdf');
export const URL_PRINT_SCHEDULE = URL_EXPORT_DATA.concat('schedule/print');

export const URL_EXPORT_REVIEW_RATINGS = URL_EXPORT_DATA.concat('rating');

export const exportDataInvoiceEsign = (token) => `${URL_EXPORT_DATA}invoice/pdf?token=${token}`;
export const exportDataEstimateEsign = (token) => `${URL_EXPORT_DATA}estimate/pdf?token=${token}`;
export const exportDataDocumentEsign = (token) => `${URL_EXPORT_DATA}document/pdf?token=${token}`;
export const URL_PREVIEW_INVOICE = URL_EXPORT_DATA.concat('invoice/pdf/preview');
export const URL_PREVIEW_ESTIMATE = URL_EXPORT_DATA.concat('estimate/pdf/preview');
export const URL_PREVIEW_WORK_ORDER = URL_EXPORT_DATA.concat('workorder/pdf/preview');
export const CUSTOMER_LIST_EXPORT = URL_EXPORT_DATA.concat('customers');

export const URL_REPORT_ESTIMATE_PRINT = URL_EXPORT_DATA.concat('estimate/pdf/list');
export const URL_REPORT_INVOICE_PRINT = URL_EXPORT_DATA.concat('invoices/print');
export const URL_REPORT_INVOICE_PDF = URL_EXPORT_DATA.concat('invoices/pdf');

export const URL_EXPORT_INVOICE = URL_EXPORT_DATA.concat('reports/invoices');
export const URL_PRINT_INVOICE_EMAIL = URL_EXPORT_DATA.concat('reports/invoices/print');
export const URL_EXPORT_EMAIL_LOG = URL_EXPORT_DATA.concat('reports/emaillogs');
export const URL_PRINT_EMAIL_LOG = URL_EXPORT_DATA.concat('reports/emaillogs/print');
export const URL_EXPORT_COMMISSION = URL_EXPORT_DATA.concat('reports/commissions');
export const URL_PRINT_COMMISSION = URL_EXPORT_DATA.concat('commissions/print');
export const URL_EXPORT_CREDIT = URL_EXPORT_DATA.concat('reports/credits');

export const URL_EXPORT_DOCUMENT = URL_EXPORT_DATA.concat('reports/documents');
export const URL_EXPORT_ESTIMATE = URL_EXPORT_DATA.concat('reports/estimates');
export const URL_PRINT_ESTIMATE = URL_EXPORT_DATA.concat('estimates/pdf');
export const URL_EXPORT_PAYMENT = URL_EXPORT_DATA.concat('reports/payments');
export const URL_PRINT_PAYMENT = URL_EXPORT_DATA.concat('reports/payments/print');
export const URL_EXPORT_SUBSCRIPTION = URL_EXPORT_DATA.concat('reports/subscriptions');
export const URL_EXPORT_LOCATIONS_WO_ACTIVE_JOBS = URL_EXPORT_DATA.concat('reports/locationswoactivejobs');
export const URL_PRINT_LOCATIONS_WO_ACTIVE_JOBS = URL_EXPORT_DATA.concat('reports/locationwojobs/print');
export const URL_EXPORT_SERVICE_LOOKUP = URL_EXPORT_DATA.concat('reports/servicelookup');
export const URL_PRINT_SERVICE_LOOKUP = URL_EXPORT_DATA.concat('reports/servicelookup/print');
export const URL_EXPORT_PAYMENT_COLLECTED = URL_EXPORT_DATA.concat('reports/paymentscollected');
export const URL_EXPORT_TOTAL_SALES = URL_EXPORT_DATA.concat('reports/totalsales');
export const URL_EXPORT_TAX_RATE = URL_EXPORT_DATA.concat('reports/taxrate');
export const URL_EXPORT_REVENUES_SERVICE = URL_EXPORT_DATA.concat('revenue/service');
export const URL_EXPORT_REVENUES_ITEM = URL_EXPORT_DATA.concat('revenue/item');
export const URL_EXPORT_REVENUES_STAFF = URL_EXPORT_DATA.concat('revenue/staff');
export const URL_EXPORT_REVENUES_SOURCE = URL_EXPORT_DATA.concat('revenue/source');
export const URL_EXPORT_REVENUES_BY_TAGS = URL_EXPORT_DATA.concat('revenue/tag');
export const URL_EXPORT_MATERIAL_USE = URL_EXPORT_DATA.concat('reports/materialuse');
export const URL_EXPORT_MATERIAL_USE_DETAIL = URL_EXPORT_DATA.concat('reports/materials/detail');
export const URL_EXPORT_HISTORY_MAIL = URL_EXPORT_DATA.concat('reports/emaillogs');
export const URL_EXPORT_LEAD = URL_EXPORT_DATA.concat('reports/leads');
export const URL_EXPORT_PAYMENT_SERVICE = URL_EXPORT_DATA.concat('revenues/services/payment');
export const URL_EXPORT_PAYMENT_CLIENT = URL_EXPORT_DATA.concat('revenues/clients/payment');
export const URL_EXPORT_PAYMENT_ITEMS = URL_EXPORT_DATA.concat('revenues/items/payment');
export const URL_EXPORT_NEW_CUSTOMERS = URL_EXPORT_DATA.concat('reports/newcustomers');
export const REPORT_INVOICE_EXPORT = `${API_VERSION}invoices/export`;
export const URL_EXPORT_PAYMENT_REVENUES_STAFF = URL_EXPORT_DATA.concat('revenues/staffs/payment');
export const URL_EXPORT_PAYMENT_TAGS = URL_EXPORT_DATA.concat('revenues/tags/payment');
export const URL_EXPORT_PAYMENT_REVENUES_SOURCE = URL_EXPORT_DATA.concat('revenues/sources/payment');
export const URL_EXPORT_LOCATIONS_CUSTOMER = URL_EXPORT_DATA.concat('customers/locations');
export const URL_EXPORT_LEAD_ONLINE_BOOKING = URL_EXPORT_DATA.concat('reports/bookings');
export const URL_EXPORT_WORK_LOGS = URL_EXPORT_DATA.concat('reports/worklogs');

export const urlExportLocationsCustomer = (userId) => {
    return URL_EXPORT_DATA.concat(`customers/locations/${userId}`);
};

export const URL_PRINT_MASTER_KEY = URL_EXPORT_DATA.concat('material/print/usage');
export const URL_EXPORT_ACTIVITY_COMPARISON = URL_EXPORT_DATA.concat('reports/activity-comparison');
export const URL_EXPORT_SMART_VIEW = URL_EXPORT_DATA.concat('smartviews');
export const URL_EXPORT_STATUS_CHANGES = URL_EXPORT_DATA.concat('inbox/opportunity/status');
export const URL_EXPORT_NEW_JOB = URL_EXPORT_DATA.concat('reports/newjob');
export const URL_EXPORT_CALL_ACTIVITY = URL_EXPORT_DATA.concat('reports/activity-comparison-calls');
export const URL_EXPORT_DEVICE_TRACKING = URL_EXPORT_DATA.concat('reports/devices/tracking');
export const REPORT_CUSTOMER_EXPORT = `${API_VERSION}customers/export`;
