import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import { getIconTypeFile } from 'app/const/icon/IconsType';
import { LINK_CDN_IMG_BROKEN } from 'app/const/URL';
import IconClose from 'assets/icon/IconClose';

function Attachment({
    id,
    name,
    type,
    url,
    mime = '',
    keyObj,
    isImage = false,
    thumbnail_url = '',
    public_url = '',
    isUploading = false,
    isHideClose = false,
    onRemove = () => {}
}) {
    const refCount = useRef(0);

    function handleImageError(event) {
        if (refCount.current < 1) {
            refCount.current += 1;
            event.target.parentNode.style.backgroundImage = `url('${public_url}')`;
        } else {
            event.target.parentNode.style.backgroundImage = `url('${LINK_CDN_IMG_BROKEN}')`;
        }
    }

    return (
        <div key={id} className="attachment">
            <div
                className={classnames('file', {
                    'is-loading': isUploading,
                    'file-img': isImage,
                    'file-other file-pdf': !isImage
                })}
                style={{
                    backgroundImage: isImage
                        ? `url('${thumbnail_url || public_url || url || LINK_CDN_IMG_BROKEN}')`
                        : 'unset'
                }}
            >
                {!isImage ? (
                    <ContentAttachment name={name} type={type || mime} />
                ) : (
                    <img src={thumbnail_url || url} alt={name} style={{ display: 'none' }} onError={handleImageError} />
                )}
            </div>

            {!isHideClose ? (
                <span className="close-file svg-remove white" onClick={() => onRemove(keyObj)}>
                    <IconClose isSmall />
                </span>
            ) : null}
        </div>
    );
}

const ContentAttachment = ({ name, type }) => {
    return (
        <>
            <div className="content-file p-1">{getIconTypeFile(type)}</div>
            <div className="name">{name}</div>
        </>
    );
};

Attachment.propTypes = {
    id: PropTypes.string,
    url: PropTypes.string,
    name: PropTypes.string,
    isUploading: PropTypes.bool,
    isImage: PropTypes.bool,
    onRemove: PropTypes.func
};

export default React.memo(Attachment);
