import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ADDONS_MULTIPLE_BRANCH_DETAIL } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import ErrorPage from 'app/modules/error';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkAccessFail, getPlanUser } from 'common/utils/PermissionUtils';
import AddonHeaderDetail from '../components/AddonHeaderDetail';
import AddonsBoxHeader from '../components/AddonsBoxHeader';
import MultipleBranchLoading from './components/MultipleBranchLoading';
import MultipleBranchOptions from './components/MultipleBranchOptions';

const AddonsMultipleBranch = () => {
    const { t } = useTranslation();
    const isBranch = useSelector(({ auth }) => auth.user.company.is_branch);
    const profileData = useSelector(({ auth }) => auth.user.profile);
    const { isTrial } = getPlanUser(profileData);

    const [state, dispatchState] = useReducer(reducer, { data: {}, isLoading: true });
    const { data, isLoading } = state;

    useEffect(() => {
        if (isBranch) return;
        _getMultipleBranchAddons();
    }, []);

    const _getMultipleBranchAddons = () => {
        const _getSuccess = ({ data }) => dispatchState({ data, isLoading: false });

        clientQuery(ADDONS_MULTIPLE_BRANCH_DETAIL, { data: {}, method: 'GET' }, _getSuccess, checkAccessFail);
    };

    if (isBranch) return <ErrorPage errorMessage={t('auth:no_permission_access')} />;

    return (
        <div className="addons-wrap__container">
            <AddonHeaderDetail isLoading={isLoading} tutorial={data.tutorial} />
            <div className="scrolls">
                {isLoading ? (
                    <MultipleBranchLoading />
                ) : (
                    <div className="boxs-wrapper">
                        <div className="boxs boxs--no-border has-svg-color">
                            <AddonsBoxHeader
                                keyword={data.keyword}
                                name={data.name}
                                status={isTrial ? 0 : data.status}
                                upgrade={data.upgrade}
                                level={data.level}
                                isHideCheckbox
                            />

                            <div className="boxs__contents mt-3">
                                <p className="description mt-3">{data.sub_title}</p>
                                <span className="--hasline" />
                                <MultipleBranchOptions options={data.options} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddonsMultipleBranch;
