import classNames from 'classnames';
import React, { cloneElement, forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import { LIST_STATUS } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';
import StatusBar from '../status/statusbar';
import ButtonSave from '../button/ButtonSave';

const GDModalWarning = forwardRef(
    (
        {
            title,
            id,
            description,
            footer = null,
            isDisplayClose = true,
            isLargeContent = true,
            modalClassName = 'modal container-modal open',
            headerClassName = 'header-modal btn-close',
            bodyClassName = 'body-modal scrolls',
            descriptionClassName = 'fs-14',
            subDescription = '',
            buttonConfirm = false,
            buttonSave = false,
            titleButtonConfirm = '',
            titleClose = '',
            onConfirm = () => {},
            onClose = () => {}
        },
        ref
    ) => {
        const { t } = useTranslation();
        const [state, dispatchState] = useReducer(reducer, {
            isOpen: false,
            title,
            description,
            isLargeContent,
            subDescription,
            onConfirm,
            dataConfirm: null
        });

        const refStatus = useRef(null);
        const refBtnSave = useRef(null);

        useImperativeHandle(ref, () => ({
            _open,
            _close,
            _setStatusAlert,
            _clearStatusAlert,
            _resetDataConfirm,
            _setLoadingBtnSave
        }));

        /**
         * Open modal warning.
         * @param {Object} data Info warning contains `title` and `description`
         */
        const _open = (data) => {
            dispatchState({ isOpen: true, ...data });
        };

        const _setStatusAlert = (message, status = LIST_STATUS.ERROR) => {
            refStatus.current.showStatusBar('message_warning', message, status);
            _setLoadingBtnSave(false);
        };

        const _clearStatusAlert = () => {
            refStatus.current?.clearAllStatusBar();
        };

        const _close = () => {
            onClose();
            dispatchState({ isOpen: false });
        };

        const _resetDataConfirm = () => {
            dispatchState((prev) => ({ ...prev, dataConfirm: null }));
        };

        const _handleConfirm = () => {
            state.onConfirm(state.id, state.dataConfirm);
        };

        const _setLoadingBtnSave = (value = false) => {
            refBtnSave.current?.setLoading(value);
        };

        if (!state.isOpen) return null;
        return (
            <ReactModal
                isOpen={state.isOpen}
                style={{ overlay: { background: 'transparent' } }}
                id={id}
                className={classNames(modalClassName)}
                portalClassName="ReactModalPortal_customer"
                onRequestClose={_close}
            >
                <div className="modal__overlay bg-fixed" onClick={_close} />
                <div className={classNames('modal__container', { large: state.isLargeContent })}>
                    <div className={classNames(headerClassName)}>
                        <h3 className="header-modal__label">{state.title}</h3>
                        {isDisplayClose && (
                            <span onClick={_close} className="v2-btn-default --icon-lg --transparent">
                                <IconClose />
                            </span>
                        )}
                    </div>
                    <div className={classNames(bodyClassName)}>
                        <StatusBar ref={refStatus} />
                        <p className={descriptionClassName}>{state.description}</p>
                        {!!state.subDescription && <p className={descriptionClassName}>{state.subDescription}</p>}
                    </div>
                    {footer ? (
                        cloneElement(footer, {
                            dataConfirm: state.dataConfirm || {},
                            onConfirm: state.onConfirm,
                            onClose: _close
                        })
                    ) : (
                        <div className="footer-modal btn-close">
                            <span className="v2-btn-default --noborder --label" onClick={_close}>
                                {titleClose || t('cancel')}
                            </span>
                            {buttonConfirm && (
                                <div className="v2-btn-main" onClick={_handleConfirm}>
                                    {titleButtonConfirm || t('confirm')}
                                </div>
                            )}
                            {buttonSave ? (
                                <ButtonSave ref={refBtnSave} title={titleButtonConfirm} onSave={_handleConfirm} />
                            ) : null}
                        </div>
                    )}
                </div>
            </ReactModal>
        );
    }
);

GDModalWarning.displayName = 'GDModalWarning';

export default GDModalWarning;
