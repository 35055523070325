import { forwardRef, useEffect, useImperativeHandle, useReducer } from 'react';

import { GD_COUNTDOWN_TYPE } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { convertSecondsToMMSS } from 'common/utils/TimeUtils';
import { DEFAULT_TIME_COUNT_DOWN } from 'app/modules/profileAuthentication/constants';

const GdCountDown = (
    {
        type = GD_COUNTDOWN_TYPE.TIME_MS,
        startTime = DEFAULT_TIME_COUNT_DOWN,
        endTime = 0,
        onHandleEndCountDown = () => {}
    },
    ref
) => {
    const [state, dispatchState] = useReducer(reducer, { timeCountDown: startTime });
    const { timeCountDown } = state;

    useImperativeHandle(ref, () => ({
        _getTimeCountDown: () => timeCountDown
    }));

    useEffect(() => {
        if (timeCountDown > endTime) {
            const countdownTimer = setInterval(() => {
                dispatchState((prev) => ({ ...prev, timeCountDown: prev.timeCountDown - 1 }));
            }, 1000);

            return () => clearInterval(countdownTimer);
        } else {
            onHandleEndCountDown();
        }
    }, [timeCountDown]);

    const _renderContent = () => {
        switch (type) {
            case GD_COUNTDOWN_TYPE.TIME_MS:
                return `in ${convertSecondsToMMSS(timeCountDown)}`;
            default:
                return null;
        }
    };

    return _renderContent();
};

export default forwardRef(GdCountDown);
