import React, { createContext, useEffect, useMemo, useReducer } from 'react';
import {
    getDayOfWeekModMonth,
    getSummaryWeekly,
    getSummaryDaily,
    getDayOfWeekModYear
} from 'app/modules/jobdetail/const/Utils';
import moment from 'moment';
import { TABS_REPEAT, TABS_ENDS } from 'app/modules/jobdetail/const';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { convertPhpFormatToMoment } from 'common/utils/DateUtils';
import { reducer } from 'app/const/Reducer';

export const DataRepeatContext = createContext();

const DataRepeatContextProvider = ({ defaultData, children, onSaveService = () => {}, triggerReload }) => {
    const { t } = useTranslation(['jobDetail']);
    const date_format = useSelector(({ auth }) => auth?.user?.company?.date_format);

    const _preData = useMemo(() => {
        const jobCurrentceDetail = { ...defaultData.recurrence.offset };

        const anyDayActive =
            jobCurrentceDetail.MO === '1' ||
            jobCurrentceDetail.TU === '1' ||
            jobCurrentceDetail.WE === '1' ||
            jobCurrentceDetail.TH === '1' ||
            jobCurrentceDetail.FR === '1' ||
            jobCurrentceDetail.SA === '1' ||
            jobCurrentceDetail.SU === '1';

        if (!anyDayActive && defaultData.recurrence.offset) {
            defaultData.recurrence.offset.MO = '1';
        }

        if (!jobCurrentceDetail.frequency) {
            defaultData.recurrence.offset = {
                ...defaultData.recurrence.offset,
                frequency: TABS_REPEAT.OFF,
                interval: '1',
                bymonthday: 'dayofweek',
                byyearday: 'dayofyear',
                SU: '0',
                MO: '1',
                TU: '1',
                WE: '0',
                TH: '0',
                FR: '0',
                SA: '0',
                countChecked: '2',
                ends: defaultData.haveTriggerOther ? TABS_ENDS.MODE_AFTER : TABS_ENDS.MODE_NEVER,
                occurances: 1,
                endDate: ''
            };
        }

        return defaultData;
    }, [triggerReload]);

    const [state, dispatchState] = useReducer(reducer, _preData);

    useEffect(() => {
        if (triggerReload) {
            dispatchState({
                ..._preData
            });
        }
    }, [triggerReload]);

    function _handleChangeException(value) {
        dispatchState((prev) => {
            return { ...prev, ...value };
        });
    }

    function _handleChangeData(newData) {
        const refData = { ...newData };
        refData.recurrence.summary = `${_renderRecurrenceSummary(refData)}${_renderEndSummary(refData)}`;
        dispatchState((prev) => {
            return {
                ...prev,
                ...refData
            };
        });
    }

    function _renderRecurrenceSummary(recurringData) {
        if (recurringData.repeat) {
            const dataRepeat =
                typeof recurringData.recurrence.offset === 'object' ? recurringData.recurrence.offset : {};
            const type = dataRepeat?.frequency;
            const interval = dataRepeat?.interval;
            switch (type) {
                case TABS_REPEAT.MONTHLY:
                    return getDayOfWeekModMonth(t, dataRepeat.bymonthday, recurringData.start, interval);
                case TABS_REPEAT.DAILY:
                    return getSummaryDaily(t, interval);
                case TABS_REPEAT.YEARLY:
                    return getDayOfWeekModYear(t, dataRepeat.byyearday, recurringData.start, interval);
                case TABS_REPEAT.WEEKLY:
                    const days = {
                        MO: dataRepeat.MO,
                        TU: dataRepeat.TU,
                        WE: dataRepeat.WE,
                        TH: dataRepeat.TH,
                        FR: dataRepeat.FR,
                        SA: dataRepeat.SA,
                        SU: dataRepeat.SU
                    };
                    return getSummaryWeekly(t, interval, days);
                default:
                    return 'Once';
            }
        }
        return 'Once';
    }

    function _renderEndSummary(summaryData) {
        if (summaryData.repeat) {
            const dataRepeat = summaryData.recurrence.offset || {};

            const typeEnd = dataRepeat.ends;
            switch (typeEnd) {
                case TABS_ENDS.MODE_NEVER:
                    return '';
                case TABS_ENDS.MODE_AFTER:
                    return `, ${dataRepeat.occurances} ${t(`jobDetail:times`)}`;
                case TABS_ENDS.MODE_ON_DATE:
                    return `, ${t(`jobDetail:until`)} ${moment(dataRepeat.endDate)
                        .utc()
                        .format(convertPhpFormatToMoment(date_format))}`;
                default:
                    break;
            }
        }
        return '';
    }

    const DataRepeatContextData = {
        repeatSettings: state,
        onChangeException: _handleChangeException,
        onChangeDataContext: _handleChangeData,
        onChangeDataRepeatContext: _handleChangeException,
        onChangeState: dispatchState,
        onSaveService: onSaveService
    };

    return <DataRepeatContext.Provider value={DataRepeatContextData}>{children}</DataRepeatContext.Provider>;
};

export default DataRepeatContextProvider;
