import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import TabJobDetail from 'app/modules/jobdetail/tabs/detail';
import { TABS } from 'app/modules/jobdetail/const';
import TabDetailContextProvider from 'app/modules/jobdetail/contexts/TabDetailContext';
import loadable from '@loadable/component';

const SettingSchedule = loadable(() => import('app/modules/jobdetail/tabs/settingschedule'));
const TabWorkOrder = loadable(() => import('app/modules/jobdetail/tabs/workorder'));
const TabInvoice = loadable(() => import('app/modules/jobdetail/tabs/invoice'));
const TabEstimate = loadable(() => import('app/modules/jobdetail/tabs/estimate'));
const TabDevices = loadable(() => import('app/modules/jobdetail/tabs/devices'));
const TabMaterials = loadable(() => import('app/modules/jobdetail/tabs/materials'));
const TabDocuments = loadable(() => import('app/modules/jobdetail/tabs/documents'));
const AddInVoice = loadable(() => import('app/modules/jobdetail/tabs/addinvoice'));
const AddEstimate = loadable(() => import('app/modules/jobdetail/tabs/addestimate'));
const TabUnit = loadable(() => import('app/modules/jobdetail/tabs/unit'));

const TabContent = ({ isLoading, jobData, onAddSuccess, onUpdate, activeAddons }, ref) => {
    const { id: jobId = '', parent_job_id: isJobRecurring } = jobData?.job || {};
    const locationId = jobData?.location?.id || '';
    const refWorkOrder = useRef(null);
    const refInvoiceDetail = useRef(null);
    const refTabDetailContext = useRef(null);

    const { reloadJobDetail, reloadSoldBy, reloadSchedule, tab: tabActive } = jobData;

    const {
        units: activeUnit,
        materials: activeMaterials,
        documents: activeDocuments,
        devices: activeDevices
    } = activeAddons;

    useImperativeHandle(ref, () => ({
        getValue: _getValue
    }));

    const _getValue = () => {
        return refTabDetailContext.current.getValue();
    };

    function _handleUpdateJobData(newJobData) {
        onUpdate(newJobData);
    }

    function _editInvoiceSuccess() {
        refWorkOrder.current && refWorkOrder.current.reload();
    }

    function _editInvoiceNote(newNote) {
        refInvoiceDetail.current && refInvoiceDetail.current.setNote(newNote);
        if (refWorkOrder.current) refWorkOrder.current.setChangeInvoiceNote(newNote);
    }

    function _handleUpdateWONote(newNote) {
        refWorkOrder.current && refWorkOrder.current.setNote(newNote);
        if (refInvoiceDetail.current) refInvoiceDetail.current.setChangeWONote(newNote);
    }

    function onCloseSettingsSchedule() {
        onUpdate({
            tab: TABS.DETAIL,
            activeTabScheduling: false
        });
    }

    function onOpenSettingsSchedule() {
        onUpdate({
            tab: TABS.SCHEDULING,
            activeTabScheduling: true
        });
    }

    return (
        <div className="tab-contents scrolls">
            <TabDetailContextProvider
                ref={refTabDetailContext}
                jobData={{ ...jobData }}
                onUpdate={_handleUpdateJobData}
            >
                <TabJobDetail
                    onOpenSettingsSchedule={onOpenSettingsSchedule}
                    isLoading={isLoading}
                    isActive={tabActive === TABS.DETAIL}
                    jobData={{ ...jobData }}
                />
                {jobData.activeTabScheduling && (
                    <SettingSchedule
                        isActive={tabActive === TABS.SCHEDULING}
                        onClose={onCloseSettingsSchedule}
                        onUpdateSuccess={onCloseSettingsSchedule}
                    />
                )}
            </TabDetailContextProvider>
            {jobData.activeTabWorkOrder && (
                <TabWorkOrder
                    ref={refWorkOrder}
                    jobId={jobId}
                    jobData={jobData}
                    isActive={tabActive === TABS.WORK_ORDER}
                    onUpdateWONote={_handleUpdateWONote}
                />
            )}
            {jobData.activeTabInvoiceDetail && (
                <TabInvoice
                    ref={refInvoiceDetail}
                    onUpdate={_handleUpdateJobData}
                    isActive={tabActive === TABS.INVOICE}
                    jobData={jobData}
                    reloadJobDetail={reloadJobDetail}
                    reloadSoldBy={reloadSoldBy}
                    reloadSchedule={reloadSchedule}
                    onEditSuccess={_editInvoiceSuccess}
                    onUpdateInvoiceNote={_editInvoiceNote}
                />
            )}
            {jobData.activeTabEstimateDetail && (
                <TabEstimate
                    onUpdate={_handleUpdateJobData}
                    isActive={tabActive === TABS.ESTIMATE}
                    jobData={jobData}
                    reloadJobDetail={reloadJobDetail}
                />
            )}
            {activeMaterials && (
                <TabMaterials
                    isJobRecurring={isJobRecurring}
                    reloadJobDetail={reloadJobDetail}
                    isActive={tabActive === TABS.MATERIALS}
                    jobId={jobId}
                />
            )}
            {activeDevices && (
                <TabDevices
                    isActive={tabActive === TABS.DEVICES}
                    jobId={jobId}
                    locationId={locationId}
                    reloadJobDetail={reloadJobDetail}
                    jobData={jobData}
                    onUpdate={_handleUpdateJobData}
                />
            )}
            {activeDocuments && (
                <TabDocuments
                    onUpdate={_handleUpdateJobData}
                    isActive={tabActive === TABS.DOCUMENTS}
                    jobId={jobId}
                    customerId={jobData?.customer?.id}
                    reloadJobDetail={reloadJobDetail}
                    jobData={jobData}
                />
            )}
            {activeUnit && (
                <TabUnit isActive={tabActive === TABS.UNIT} onUpdate={_handleUpdateJobData} jobData={jobData} />
            )}
            {jobData.activeTabInvoice && (
                <AddInVoice
                    onDelete={_handleUpdateJobData}
                    isActive={tabActive === TABS.ADD_INVOICE}
                    jobData={jobData}
                    onAddSuccess={onAddSuccess}
                    onReloadWO={_editInvoiceSuccess}
                />
            )}
            {jobData.activeTabEstimate && (
                <AddEstimate
                    onDelete={_handleUpdateJobData}
                    isActive={tabActive === TABS.ADD_ESTIMATE}
                    jobData={jobData}
                    onAddSuccess={onAddSuccess}
                />
            )}
        </div>
    );
};

export default forwardRef(TabContent);
