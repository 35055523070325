import { actionCloseJobDetail, actionOpenJobDetail } from 'common/redux/actions/job/detail';
import { actionCloseServicePlan } from 'common/redux/actions/servicePlanAction';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function ButtonCustomize({ isActiveJob, isPlanCreateForm, onOpenFormDetail, jobId }) {
    const dispatch = useDispatch();
    const { jobDetail } = useSelector(({ jobDetailReducer }) => jobDetailReducer);
    const { servicePlanDetail } = useSelector(({ servicePlanReducer }) => servicePlanReducer);

    const _handleCustomize = (e) => {
        if (isPlanCreateForm) {
            onOpenFormDetail(e);
            return false;
        }

        if (isActiveJob) {
            if (jobDetail) {
                dispatch(actionCloseJobDetail());
            }
            if (servicePlanDetail) {
                dispatch(actionCloseServicePlan());
            }
            setTimeout(() => {
                dispatch(actionOpenJobDetail({ id: jobId }));
            }, 0);
            return false;
        } else {
            onOpenFormDetail(e);
            // Open form add job
        }
    };

    return (
        <div
            onClick={_handleCustomize}
            className="v2-btn-default btn-bg-purple border-purple-default --sm fs-13 fw-500"
        >
            {isPlanCreateForm ? 'Detail' : 'Customize'}
        </div>
    );
}
