import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';

const GdVideo = ({ src = '', type = '', handleOpen = () => {} }) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { isVideoError: false });
    const { isVideoError } = state;

    const _handleOpenVideo = (e) => {
        e && e.stopPropagation();
        if (isVideoError) {
            alert(t('common:message_wrong_link_video'));
            return;
        }
        handleOpen();
    };

    const _handleError = () => {
        dispatchState({ isVideoError: true });
    };

    return (
        <div onClick={_handleOpenVideo} className="video-attach relative">
            <video controls onError={_handleError}>
                <source src={src} type={type} />
            </video>
            <span className="play-icon absolute flex-centeritem"></span>
        </div>
    );
};

export default GdVideo;
