import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';
import { COMMON, DEFAULT_ALL, LIST_TABLE_ITEM_TYPE } from '../App';
import { JOB_TERMINATE_SERVICE } from '../Job';

export const orderByStatusJobs = {
    [DEFAULT_ALL]: 'orderByAll',
    1: 'orderByActive',
    2: 'orderByComplete',
    3: 'orderByCanceled',
    4: 'orderByDeleted'
};

export const getGridColumns = (params, view) => {
    const GRID_CUSTOMER_JOBS = {
        [DEFAULT_ALL]: {
            header: {
                job_status: {
                    title: 'status',
                    style: 'col'
                },
                name: {
                    title: 'all_jobs',
                    style: 'col',
                    actionType: COMMON.ORDER,
                    currentValue: COMMON.NOT_SET
                },
                job_recurrence: {
                    title: 'job_frequency',
                    style: 'col col-md-plus'
                },
                invoice_recurrence: {
                    title: 'invoice_frequency',
                    style: 'col col-md-plus'
                },
                next_service: {
                    title: 'date_or_next_service',
                    style: 'col col-md-plus',
                    actionType: COMMON.ORDER,
                    currentValue: COMMON.NOT_SET
                },
                assignees: {
                    title: 'assigned_to',
                    style: 'col col-md'
                },
                invoice_total: {
                    title: 'invoice_total',
                    style: 'col col-sm',
                    actionType: COMMON.ORDER,
                    currentValue: COMMON.NOT_SET
                },
                invoice_number: {
                    title: 'invoice_#',
                    style: 'col',
                    actionType: COMMON.ORDER,
                    currentValue: COMMON.NOT_SET
                },
                invoice_status: {
                    title: 'invoice_status',
                    style: 'col col-md',
                    actionType: COMMON.ORDER,
                    currentValue: COMMON.NOT_SET
                }
            },
            columns: [
                {
                    id: 'job_status',
                    title: 'status',
                    status: true
                },
                {
                    id: 'name',
                    title: 'all_job',
                    status: true
                },
                {
                    id: 'job_recurrence',
                    title: 'job_frequency',
                    status: true
                },
                {
                    id: 'next_service',
                    title: 'date_or_next_service',
                    status: true
                },
                {
                    id: 'invoice_recurrence',
                    title: 'invoice_frequency',
                    status: true
                },
                {
                    id: 'assignees',
                    title: 'assigned_to',
                    status: true
                },
                { id: 'invoice_number', title: 'invoice_#', status: true },
                {
                    id: 'invoice_total',
                    title: 'invoice_total',
                    status: true
                },
                { id: 'invoice_status', title: 'invoice_status', status: true }
            ],
            contentConfig: {
                job_status: {
                    title: 'job_status',
                    style: 'col',
                    type: LIST_TABLE_ITEM_TYPE.JOB_STATUS_TAB
                },
                name: {
                    title: 'all_job',
                    style: 'col --name',
                    type: LIST_TABLE_ITEM_TYPE.JOB
                },
                job_recurrence: {
                    title: 'job_frequency',
                    style: 'col col-md-plus',
                    type: LIST_TABLE_ITEM_TYPE.JOB_FREQUENCY
                },
                invoice_recurrence: {
                    title: 'invoice_frequency',
                    style: 'col col-md-plus',
                    type: LIST_TABLE_ITEM_TYPE.TWO_ROWS,
                    firstKey: 'invoice_recurrence.frequency',
                    secondKey: 'invoice_recurrence.repeat_text'
                },
                next_service: {
                    title: 'date_or_next_service',
                    style: 'col col-md-plus',
                    type: LIST_TABLE_ITEM_TYPE.TWO_ROWS,
                    firstKey: 'date',
                    secondKey: 'time'
                },
                assignees: {
                    title: 'assign_to',
                    style: 'col col-md',
                    type: LIST_TABLE_ITEM_TYPE.LIST_ASSIGNEE
                },
                invoice_total: {
                    title: 'invoice_total',
                    style: 'col col-sm'
                },
                invoice_number: {
                    title: 'invoice_#',
                    style: 'col',
                    type: LIST_TABLE_ITEM_TYPE.LINK,
                    styleText: 'name'
                },
                invoice_status: {
                    title: 'invoice_status',
                    style: 'col col-md',
                    type: LIST_TABLE_ITEM_TYPE.STATUS
                }
            }
        },
        1: {
            header: {
                active_jobs: {
                    title: 'active_jobs',
                    style: 'col',
                    actionType: COMMON.ORDER,
                    currentValue: COMMON.NOT_SET
                },
                job_recurrence: {
                    title: 'Job Frequency',
                    style: 'col'
                },
                invoice_recurrence: {
                    title: 'invoice_frequency',
                    style: 'col'
                },
                next_service: {
                    title: 'next_service',
                    style: 'col',
                    actionType: COMMON.ORDER,
                    currentValue: COMMON.NOT_SET
                },
                assignees: {
                    title: 'Assigned To',
                    style: 'col'
                },
                invoice_total: {
                    title: 'total',
                    style: 'col',
                    actionType: COMMON.ORDER,
                    currentValue: COMMON.NOT_SET
                }
            },
            columns: [
                {
                    id: 'active_jobs',
                    title: 'active_job',
                    status: true
                },
                {
                    id: 'job_recurrence',
                    title: 'job_recurrence',
                    status: true
                },
                {
                    id: 'invoice_recurrence',
                    title: 'invoice_frequency',
                    status: true
                },
                {
                    id: 'next_service',
                    title: 'next_service',
                    status: true
                },
                {
                    id: 'assignees',
                    title: 'assign_to',
                    status: true
                },
                {
                    id: 'invoice_total',
                    title: 'total',
                    status: true
                }
            ],
            contentConfig: {
                active_jobs: {
                    title: 'active_job',
                    style: 'col --name',
                    type: LIST_TABLE_ITEM_TYPE.JOB
                },
                job_recurrence: {
                    title: 'job_recurrence',
                    style: 'col',
                    type: LIST_TABLE_ITEM_TYPE.JOB_FREQUENCY
                },
                invoice_recurrence: {
                    title: 'invoice_frequency',
                    style: 'col',
                    type: LIST_TABLE_ITEM_TYPE.TWO_ROWS,
                    firstKey: 'invoice_recurrence.frequency',
                    secondKey: 'invoice_recurrence.repeat_text'
                },
                next_service: {
                    title: 'next_service',
                    style: 'col',
                    type: LIST_TABLE_ITEM_TYPE.TWO_ROWS,
                    firstKey: 'date',
                    secondKey: 'time'
                },
                assignees: {
                    title: 'assign_to',
                    style: 'col',
                    type: LIST_TABLE_ITEM_TYPE.LIST_ASSIGNEE
                },
                invoice_total: {
                    title: 'total',
                    style: 'col'
                }
            }
        },
        2: {
            header: {
                name: {
                    title: 'completed_jobs',
                    style: 'col',
                    actionType: COMMON.ORDER,
                    currentValue: COMMON.NOT_SET
                },
                location: {
                    title: 'service_location',
                    style: 'col col-lg'
                },
                date: {
                    title: 'Date',
                    style: 'col',
                    actionType: COMMON.ORDER,
                    currentValue: COMMON.NOT_SET
                },
                assignees: {
                    title: 'Assign To',
                    style: 'col col-md'
                },
                invoice_number: {
                    title: 'Invoice #',
                    style: 'col',
                    actionType: COMMON.ORDER,
                    currentValue: COMMON.NOT_SET
                },
                invoice_total: {
                    title: 'Total',
                    style: 'col',
                    actionType: COMMON.ORDER,
                    currentValue: COMMON.NOT_SET
                },
                invoice_status: {
                    title: 'Status',
                    style: 'col',
                    actionType: COMMON.ORDER,
                    currentValue: COMMON.NOT_SET
                }
            },
            columns: [
                {
                    id: 'name',
                    title: 'completed_jobs',
                    status: true
                },
                {
                    id: 'location',
                    title: 'service_location',
                    status: true
                },
                {
                    id: 'date',
                    title: 'frequency',
                    status: true
                },
                {
                    id: 'assignees',
                    title: 'assign_to',
                    status: true
                },
                {
                    id: 'invoice_number',
                    title: 'total',
                    status: true
                },
                {
                    id: 'invoice_total',
                    title: 'total',
                    status: true
                },
                {
                    id: 'invoice_status',
                    title: 'total',
                    status: true
                }
            ],
            contentConfig: {
                name: {
                    title: 'completed_jobs',
                    style: 'col --name',
                    type: LIST_TABLE_ITEM_TYPE.JOB,
                    noneLocation: true
                },
                location: {
                    title: 'service_location',
                    style: 'col col-lg'
                },
                date: {
                    title: 'date',
                    style: 'col'
                },
                assignees: {
                    title: 'invfrequence',
                    style: 'col col-md',
                    type: LIST_TABLE_ITEM_TYPE.LIST_ASSIGNEE
                },
                invoice_number: {
                    title: 'service',
                    style: 'col --name',
                    type: LIST_TABLE_ITEM_TYPE.LINK,
                    styleText: 'name'
                },
                invoice_total: {
                    title: 'total',
                    style: 'col'
                },
                invoice_status: {
                    title: 'status',
                    style: 'col',
                    type: LIST_TABLE_ITEM_TYPE.STATUS
                }
            }
        },
        3: {
            header: {
                name: {
                    title: 'canceled_job',
                    style: 'col',
                    actionType: COMMON.ORDER,
                    currentValue: COMMON.NOT_SET
                },
                location: {
                    title: 'service_location',
                    style: 'col col-lg'
                },
                date: {
                    title: 'Date',
                    style: 'col',
                    actionType: COMMON.ORDER,
                    currentValue: COMMON.NOT_SET
                },
                assignees: {
                    title: 'Assign To',
                    style: 'col'
                },
                invoice_number: {
                    title: 'Invoice #',
                    style: 'col col-sm',
                    actionType: COMMON.ORDER,
                    currentValue: COMMON.NOT_SET
                },
                invoice_total: {
                    title: 'Total',
                    style: 'col col-sm',
                    actionType: COMMON.ORDER,
                    currentValue: COMMON.NOT_SET
                },
                invoice_status: {
                    title: 'Status',
                    style: 'col',
                    actionType: COMMON.ORDER,
                    currentValue: COMMON.NOT_SET
                }
            },
            columns: [
                {
                    id: 'name',
                    title: 'canceled_job',
                    status: true
                },
                {
                    id: 'location',
                    title: 'service_location',
                    status: true
                },
                {
                    id: 'date',
                    title: 'frequency',
                    status: true
                },
                {
                    id: 'assignees',
                    title: 'assign_to',
                    status: true
                },
                {
                    id: 'invoice_number',
                    title: 'total',
                    status: true
                },
                {
                    id: 'invoice_total',
                    title: 'total',
                    status: true
                },
                {
                    id: 'invoice_status',
                    title: 'total',
                    status: true
                }
            ],
            contentConfig: {
                name: {
                    title: 'canceled_job',
                    style: 'col --name',
                    type: LIST_TABLE_ITEM_TYPE.JOB,
                    noneLocation: true
                },
                location: {
                    title: 'service_location',
                    style: 'col col-lg'
                },
                date: {
                    title: 'date',
                    style: 'col'
                },
                assignees: {
                    title: 'invfrequence',
                    style: 'col',
                    type: LIST_TABLE_ITEM_TYPE.LIST_ASSIGNEE
                },
                invoice_number: {
                    title: 'service',
                    style: 'col col-sm --name',
                    styleText: 'name',
                    type: LIST_TABLE_ITEM_TYPE.LINK
                },
                invoice_total: {
                    title: 'total',
                    style: 'col col-sm'
                },
                invoice_status: {
                    title: 'status',
                    style: 'col',
                    type: LIST_TABLE_ITEM_TYPE.STATUS
                }
            }
        },
        4: {
            header: {
                name: {
                    title: 'deleted_job',
                    style: 'col',
                    actionType: COMMON.ORDER,
                    currentValue: COMMON.NOT_SET
                },
                date: {
                    title: 'date',
                    style: 'col'
                },
                invoice_number: {
                    title: 'Invoice #',
                    style: 'col'
                },
                invoice_total: {
                    title: 'total',
                    style: 'col',
                    actionType: COMMON.ORDER,
                    currentValue: COMMON.NOT_SET
                },
                invoice_status: {
                    title: 'status',
                    style: 'col'
                }
            },
            columns: [
                {
                    id: 'name',
                    title: 'canceled_job',
                    status: true
                },
                {
                    id: 'date',
                    title: 'date',
                    status: true
                },
                {
                    id: 'invoice_number',
                    title: 'invoice_number',
                    status: true
                },
                {
                    id: 'invoice_total',
                    title: 'invoice_total',
                    status: true
                },
                {
                    id: 'invoice_status',
                    title: 'invoice_status',
                    status: true
                }
            ],
            contentConfig: {
                name: {
                    title: 'canceled_job',
                    style: 'col'
                },
                date: {
                    title: 'service_location',
                    style: 'col'
                },
                invoice_number: {
                    title: 'date',
                    style: 'col'
                },
                invoice_total: {
                    title: 'service',
                    style: 'col'
                },
                invoice_status: {
                    title: 'total',
                    style: 'col',
                    type: LIST_TABLE_ITEM_TYPE.STATUS
                }
            }
        }
    };

    const grids = GRID_CUSTOMER_JOBS[view];
    const newHeader = mapCurrentValueHeader(params[orderByStatusJobs[view]], { ...grids.header });

    return {
        ...grids,
        header: newHeader
    };
};

export const JOB_STATUS_VIEW_ACTIVE = 1;
export const JOB_STATUS_VIEW_COMPLETED = 2;
export const JOB_STATUS_VIEW_CANCELLED = 3;
export const JOB_STATUS_VIEW_DELETED = 4;

export const LIST_BUTTONS_STATUS = [
    { id: DEFAULT_ALL, name: COMMON.ALL, value: DEFAULT_ALL },
    { id: 1, name: COMMON.ACTIVE, value: JOB_STATUS_VIEW_ACTIVE },
    { id: 2, name: COMMON.COMPLETED, value: JOB_STATUS_VIEW_COMPLETED },
    { id: 3, name: COMMON.CANCELED, value: JOB_STATUS_VIEW_CANCELLED }
];

export const JOB_STATUS_OPTION_TAB = {
    2: 'completed',
    5: 'canceled',
    [JOB_TERMINATE_SERVICE]: 'canceled'
};

export const CLASS_TABLES_CUSTOMER_JOBS = {
    [DEFAULT_ALL]: 'table-multi-column has-checkbox has-footer scrolls-x',
    1: 'has-checkbox has-footer active-jobs scrolls-x',
    2: 'has-checkbox has-footer scrolls-x',
    3: 'has-checkbox has-footer scrolls-x',
    4: 'has-checkbox has-footer scrolls-x tab-delete-content'
};
