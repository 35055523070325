import moment from 'moment';
import React, { Fragment, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_CURRENCY_SYMBOL, RECHARGE_AMOUNT, RECHARGE_THRESHOLD } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import AddonBoxRange from 'app/modules/addons/components/AddonBoxRange';
import {
    FORMAT_MONTH_CHATBOT_PLAN,
    LIST_CLASS_DIVIDE,
    LIST_RECHARGE_AMOUNT,
    LIST_RECHARGE_THRESHOLD
} from '../../constants';

export const ChatbotAddonsModifyPlan = ({
    allowUpdateValue = true,
    currencySymbol = DEFAULT_CURRENCY_SYMBOL,
    data = {},
    onChangePlan = () => {}
}) => {
    const { t } = useTranslation('addons');

    const [state, dispatchState] = useReducer(reducer, { ...data, isChanged: false });
    const { account: finalAccount } = state;
    const { recharge_amount, recharge_threshold, usage: finalUsage } = finalAccount || {};
    const { this_month, last_month } = finalUsage || {};
    const finalRechargeAmount = recharge_amount || LIST_RECHARGE_AMOUNT[0];
    const finalRechargeThreshold = recharge_threshold || LIST_RECHARGE_THRESHOLD[0];
    const finalThisMonth = this_month || 0;
    const finalLastMonth = last_month || 0;
    const finalTotalAmount = allowUpdateValue ? finalRechargeAmount : 0;

    useEffect(() => {
        dispatchState({ ...(data || {}) });
    }, [data]);

    const _handleChangeRange = (data) => {
        dispatchState((prev) => ({ ...prev, account: { ...prev.account, ...(data || {}) } }));
        onChangePlan(data);
    };

    return (
        <Fragment>
            <h3 className="fs-14 fw-600 black-2 mb-2">{t('title_manage_plan_chatbot')}</h3>
            <div className="credit-content">
                <p>{t('manage_plan_chatbot_content')}</p>
                <p className="dots">{t('manage_plan_chatbot_desc_one')}</p>
                <p className="dots">{t('manage_plan_chatbot_desc_two')}</p>
                <h3 className="mt-4 fs-14 fw-600 black-2 mb-1">{t('cost')}</h3>
                <p>{t('price_completed_static_conversation', { symbol: currencySymbol })}</p>
                <p>{t('price_completed_dynamic_conversation', { symbol: currencySymbol })}</p>
            </div>
            <div className="is-divider --horizontal my-3" />
            <div className="wrap-plans flex-column gap-12">
                <h3 className="fs-14 fw-600 black-2">{t('credits_non_refundable')}</h3>
                <AddonBoxRange
                    currencySymbol={currencySymbol}
                    wrapperClassName="wrap-plans__cost flexcenter gap-20"
                    listClassDivide={LIST_CLASS_DIVIDE}
                    keyLeftRange={RECHARGE_THRESHOLD}
                    valueLeftRange={finalRechargeThreshold}
                    listLeftRange={LIST_RECHARGE_THRESHOLD}
                    keyRightRange={RECHARGE_AMOUNT}
                    valueRightRange={finalRechargeAmount}
                    listRightRange={LIST_RECHARGE_AMOUNT}
                    onChangeRange={_handleChangeRange}
                />
                <div className="box-plans --lg flex-column gap-10 black fs-14">
                    <div className="flex-betweenitems">
                        <span>{t('title_starting_ai_conversation')}</span>
                        <span className="blue-default fw-600">{`${currencySymbol}${finalTotalAmount}`}</span>
                    </div>
                    <div className="is-divider --horizontal"></div>
                    <div className="flex-betweenitems fw-600">
                        <span>{t('total')}</span>
                        <span className="blue-default fs-16">{`${currencySymbol}${finalTotalAmount}`}</span>
                    </div>
                </div>
                <div className="box-plans --lg flex-column gap-10 black fs-14">
                    <div className="flex-betweenitems">
                        <span>{t('usage_this_month', { month: `${moment().format(FORMAT_MONTH_CHATBOT_PLAN)}` })}</span>
                        <span className="blue-default fw-600">{`${currencySymbol}${finalThisMonth}`}</span>
                    </div>
                    <div className="is-divider --horizontal"></div>
                    <div className="flex-betweenitems">
                        <span>
                            {t('usage_last_month', {
                                month: `${moment().subtract(1, 'months').format(FORMAT_MONTH_CHATBOT_PLAN)}`
                            })}
                        </span>
                        <span className="blue-default fw-600">{`${currencySymbol}${finalLastMonth}`}</span>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
