import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonSave from 'app/components/button/ButtonSave';
import IconTrash from 'assets/icon/IconTrash';

const ModalFooter = (
    { isEdit = false, isShowDelete = false, onClose = () => {}, onSave = () => {}, onDelete = () => {} },
    ref
) => {
    const { t } = useTranslation('common');

    const refButtonSave = useRef(null);

    useImperativeHandle(ref, () => ({
        _setLoadingBtnSave: _handleSetLoadingSave
    }));

    const _handleSetLoadingSave = (value) => {
        refButtonSave.current?.setLoading(value);
    };

    return (
        <div className="footer-modal">
            {isEdit && isShowDelete ? (
                <div className="v2-btn-default has-bg-red has-icon btn-modal" onClick={onDelete}>
                    <IconTrash />
                    {t('delete')}
                </div>
            ) : null}
            <div className="flexcenter flex-1 justify-end">
                <span className="v2-btn-default --transparent" onClick={onClose}>
                    {t('cancel')}
                </span>
                <ButtonSave ref={refButtonSave} wrapClass="v2-btn-main ml-2n" onSave={onSave} />
            </div>
        </div>
    );
};

export default forwardRef(ModalFooter);
