export const SERVICE_PLAN_ACTIONS = {
    OPEN_SERVICE_PLAN: 'OPEN_SERVICE_PLAN',
    CLOSE_SERVICE_PLAN: 'CLOSE_SERVICE_PLAN'
};

export const actionOpenServicePlan = (data) => {
    return { type: SERVICE_PLAN_ACTIONS.OPEN_SERVICE_PLAN, payload: data };
};

export const actionCloseServicePlan = () => {
    return { type: SERVICE_PLAN_ACTIONS.CLOSE_SERVICE_PLAN };
};
