import { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from 'app/components/checkbox';
import {
    KEY_OPTIONS_CHECK_NOTIFY_TECHNICIAN_JOB_CALENDAR,
    KEY_OPTIONS_CHECK_RECURRING_JOB_CALENDAR
} from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';
import { setLocalStorage } from 'common/utils/LocalStorageUtils';

const ModalJobRecuring = forwardRef((props, ref) => {
    const { t } = useTranslation(['calendar, common']);
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        checked: false,
        infoDisplay: null,
        showOptionNotifyTech: false,
        isNotifyTechnician: false,
        isActiveJob: false
    });
    const {
        infoDisplay,
        showOptionNotifyTech,
        isNotifyTechnician,
        checked: finalChecked,
        isVisible: finalIsVisible,
        isActiveJob: finalIsActiveJob
    } = state;
    const refEvents = useRef(null);

    function _showOptions(
        infoEvent,
        functionCallBack,
        customCallBack,
        infoDisplay = null,
        isCheckShowOptionNotifyTech = false,
        isActiveJob = false
    ) {
        refEvents.current = { infoEvent, functionCallBack, customCallBack };

        dispatchState({
            isVisible: true,
            infoDisplay,
            showOptionNotifyTech: isCheckShowOptionNotifyTech,
            isActiveJob
        });
    }

    function _closeOptions(e) {
        e.stopPropagation();
        _resetState();
        if (refEvents.current) {
            if (refEvents.current.infoEvent.revert) refEvents.current.infoEvent.revert();
            if (refEvents.current.customCallBack) refEvents.current.customCallBack();
        }
    }

    function _onChangeSession(e) {
        dispatchState((prev) => ({ ...prev, checked: !!e.target.checked }));
    }

    function _onChangeNotifyTech(e) {
        dispatchState((prev) => ({ ...prev, isNotifyTechnician: !!e.target.checked }));
    }

    useImperativeHandle(ref, () => ({ _showOptions }));

    function _handleClickOption(all = 0) {
        if (finalChecked) {
            setLocalStorage(KEY_OPTIONS_CHECK_RECURRING_JOB_CALENDAR, all);
            setLocalStorage(KEY_OPTIONS_CHECK_NOTIFY_TECHNICIAN_JOB_CALENDAR, isNotifyTechnician ? 1 : 0);
        }
        refEvents.current && refEvents.current.functionCallBack(all, refEvents.current.infoEvent, isNotifyTechnician);

        _resetState();
    }

    const _resetState = () => {
        dispatchState((prev) => ({
            ...prev,
            isVisible: false,
            checked: false,
            showOptionNotifyTech: false,
            isNotifyTechnician: false,
            isActiveJob: false
        }));
    };

    function _renderRecuring() {
        if (!finalIsVisible) {
            return false;
        }

        return (
            <div className="modal container-modal batch-job --recurring open">
                <div className="modal__overlay bg-fixed" onClick={_closeOptions} />
                <div className="modal__container">
                    <div className="header-modal">
                        <h3 className="header-modal__label">
                            {infoDisplay?.title ||
                                t(`calendar:${finalIsActiveJob ? 'update_active_job' : 'update_recurring_job'}`)}
                        </h3>
                        <div className="v2-btn-default --icon-lg --transparent" onClick={_closeOptions}>
                            <IconClose />
                        </div>
                    </div>
                    <div className="body-modal scrolls">
                        <div className="rows">
                            <Checkbox
                                id="auto_apply"
                                label={t('calendar:auto_apply_this_option_for_the_rest_of_my_session')}
                                checked={finalChecked}
                                onChangeValue={_onChangeSession}
                            />
                        </div>
                        {showOptionNotifyTech ? (
                            <div className="rows">
                                <Checkbox
                                    id="notify_assigned_technician"
                                    label={t('calendar:notify_assigned_technician')}
                                    checked={isNotifyTechnician}
                                    onChangeValue={_onChangeNotifyTech}
                                />
                            </div>
                        ) : null}
                        <div className="rows" onClick={() => _handleClickOption(0)}>
                            <div className="v2-btn-default">
                                {infoDisplay?.onlyText || t('calendar:move_this_job_only')}
                            </div>
                        </div>

                        <div className="rows" onClick={() => _handleClickOption(1)}>
                            <div className="v2-btn-default">
                                {infoDisplay?.recurringText || t('calendar:move_this_job_and_all_recurring')}
                            </div>
                        </div>
                    </div>
                    <div className="footer-modal btn-close" onClick={(e) => _closeOptions(e)}>
                        <span className="v2-btn-default --transparent w-100">{t('common:cancel')}</span>
                    </div>
                </div>
            </div>
        );
    }

    return _renderRecuring();
});

export default ModalJobRecuring;
