import React, { useReducer, useRef, useEffect, Fragment, useId } from 'react';
import { INVOICE_STATUS } from 'app/const/Filters';
import { useTranslation } from 'react-i18next';
import { KEY_CODE_ESCAPE, KEY_OPTIONS_CHECK_CHANGE_STATUS_JOB } from 'app/const/App';
import { useDispatch, useSelector } from 'react-redux';
import { actionUpdateJobStatus } from 'common/redux/actions/job';
import ChangeStatus from 'app/modules/calendar/components/modal/ChangeStatus';
import ChangeStatusCancel from 'app/modules/calendar/components/modal/ChangeStatusCancel';
import StatusToCancel from 'app/modules/calendar/components/modal/StatusToCancel';
import ModalRecurringJob from 'app/modules/calendar/components/modal/RecurringJob';
import { updateGetJobActive } from 'app/const/Api';
import { clientQuery } from 'common/utils/ApiUtils';
import { getLocalStorageValue } from 'common/utils/LocalStorageUtils';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconCheck from 'assets/icon/IconCheck';
import { actionOpenJobDetail } from 'common/redux/actions/job/detail';
import { reducer } from 'app/const/Reducer';
import GdConfirm from 'app/components/confirm';
import ItemJobStatus from 'app/components/job/ItemJobStatus';
import { getContrastColor, getJobStatus, getListJobStatus } from 'common/utils/JobStatusUtils';
import { JOB_EXCLUDE, JOB_PENDING_BOOKING, JOB_CANCELED, JOB_COMPLETED, JOB_TERMINATE_SERVICE } from 'app/const/Job';
import { refreshListJobStatus } from 'common/redux/actions/authAction';
import useJobStatus from 'common/hooks/useJobStatus';
import Terminate from '../components/Terminate';

const Status = ({
    jobData,
    finalJobPastActive,
    onChange = () => {},
    isCreate = false,
    filterParent = JOB_EXCLUDE.JOB_DETAIL,
    isInclude = false
}) => {
    const parentStatus = jobData.status;

    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        statusId: parentStatus
    });
    const { color } = useSelector(({ calendar }) => calendar);

    const dispatch = useDispatch();
    const refChangeStatus = useRef(null);
    const refChangeStatusCancel = useRef(null);
    const refCancelChangeStatus = useRef(null);
    const refModalRecurringJob = useRef(null);
    const refTerminateConfirm = useRef(null);

    const refConfirm = useRef(null);
    const refIsFetching = useRef(false);
    const dropdownId = useId();
    const listJobStatus = useJobStatus({ filter: filterParent, isInclude });

    const { statusId: finalStatusId, isVisible: finalIsVisible } = state;
    const finalStatus = finalStatusId.toString();

    useEffect(() => {
        if (!getJobStatus(parentStatus) && !refIsFetching.current) {
            refIsFetching.current = true;
            dispatch(
                refreshListJobStatus({
                    callback: () => {
                        handleChangeStatus();
                        refIsFetching.current = false;
                    }
                })
            );
            return;
        }

        handleChangeStatus();
    }, [parentStatus]);

    const refResult = useRef(null);
    const { t } = useTranslation(['calendar', 'customers']);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    const jobStatus = getJobStatus(finalStatus);

    const { color: finalColor, type: finalType, name, label } = jobStatus || {};
    const isBlocking = finalType === JOB_TERMINATE_SERVICE;

    function handleChangeStatus() {
        if (parentStatus?.toString() !== finalStatus) {
            dispatchState({ statusId: parentStatus });
        }
    }

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById(dropdownId);
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _handleClose();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById(dropdownId);

        if (
            refResult.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refResult.current.contains(event.target)
        ) {
            _handleClose();
        }
    }

    function _handleClose() {
        finalIsVisible && dispatchState({ isVisible: false });
    }

    function _getJobActiveOpen(response) {
        _openRecurringJob(response.data);
    }

    function _openRecurringJob(finalData) {
        refModalRecurringJob.current &&
            refModalRecurringJob.current._showOptions(finalData, () => {
                dispatch(actionOpenJobDetail({ ...finalData }));
            });
    }

    function _handleSelectStatus(e, status) {
        const { id: statusId, type: typeStatus } = status;
        const { id: currentStatusId, type: currentTypeStatus } = getJobStatus(finalStatus.toString()) || {};
        e.stopPropagation();

        if (statusId === currentStatusId) return false;

        if (jobData?.recurrence && jobData.recurrence.length !== 0) {
            if (finalJobPastActive) {
                _openRecurringJob(finalJobPastActive);
            } else {
                clientQuery(updateGetJobActive(jobData.id), { method: 'GET' }, _getJobActiveOpen);
            }
            return false;
        }

        if (typeStatus === JOB_TERMINATE_SERVICE) {
            refTerminateConfirm.current.showReason(status);
            return false;
        }

        dispatchState({ isVisible: false, statusId });

        const isDifferenceType = typeStatus !== currentTypeStatus;

        if ((typeStatus === JOB_COMPLETED || (typeStatus === JOB_CANCELED && !jobData.invoice)) && isDifferenceType) {
            _conFirmChangeStatus(status);
            return false;
        }

        if (typeStatus === JOB_CANCELED && isDifferenceType) {
            const jobInvoiceStatus = jobData.invoice.status;

            jobData.revert = _revertOldStatus;

            if (
                jobInvoiceStatus === INVOICE_STATUS.STATUS_DRAFT ||
                jobInvoiceStatus === INVOICE_STATUS.STATUS_SENT ||
                jobInvoiceStatus === INVOICE_STATUS.STATUS_VOID ||
                jobInvoiceStatus === INVOICE_STATUS.STATUS_WRITE_OFF
            ) {
                refChangeStatusCancel.current &&
                    refChangeStatusCancel.current._showOptions(jobData, status, _conFirmChangeStatus);
                return false;
            }

            if (jobInvoiceStatus === INVOICE_STATUS.STATUS_PAID || jobInvoiceStatus === INVOICE_STATUS.STATUS_PARTIAL) {
                refCancelChangeStatus.current && refCancelChangeStatus.current._showOptions(jobData, status);
                return false;
            }
        }
        _updateJobStatus(status);
    }

    const _handleTerminate = (statusTerminate) => {
        dispatchState({ isVisible: false, statusId: statusTerminate.id });
        _updateJobStatus(statusTerminate);
    };

    function _conFirmChangeStatus(status = {}) {
        const optionStogare = getLocalStorageValue(KEY_OPTIONS_CHECK_CHANGE_STATUS_JOB);
        if (optionStogare !== '1') {
            jobData.revert = _revertOldStatus;
            refChangeStatus.current && refChangeStatus.current._showOptions(jobData, status, _updateJobStatus);
        } else {
            _updateJobStatus(status);
        }
    }

    /**
     * We don't use socket_id because api will response data slower to socket data
     */
    function _updateJobStatus(status = {}) {
        if (isCreate) {
            onChange(status.id);
            return false;
        }
        dispatch(
            actionUpdateJobStatus(
                {
                    jobId: jobData.id,
                    status: status.id,
                    note: status.reason || '',
                    color_id: color
                    // eslint-disable-next-line no-undef
                    // socket_id: isJobDetail ? global.socketIdJobDetail : global.jobPreviewId
                },
                (res) => onChange({ ...res, new_status: status.id }),
                _updateJobStatusFailed
            )
        );
    }

    const _updateJobStatusFailed = (response) => {
        refConfirm.current.open(null, response?.message?.toString() || t('customers:please_try_again'));
        dispatchState({ statusId: parentStatus });
    };

    function _revertOldStatus() {
        dispatchState({ statusId: parentStatus });
    }

    function _handleOpenList(e) {
        e.stopPropagation();
        dispatchState({ isVisible: !finalIsVisible });
    }

    function _renderListStatus() {
        let finalListStatus = listJobStatus;

        if (finalStatus === JOB_PENDING_BOOKING) {
            finalListStatus = [
                ...getListJobStatus({ filter: [JOB_PENDING_BOOKING], isInclude: true }),
                ...finalListStatus
            ];
        }

        return finalListStatus.map((item) => {
            const statusId = item.id;
            const isActive = finalStatus === statusId;
            return (
                <li
                    key={statusId}
                    onClick={(e) => _handleSelectStatus(e, item)}
                    className={`items has-icon ${isActive ? 'active' : ''}`}
                >
                    <IconCheck />
                    <ItemJobStatus defaultStatus={item} />
                </li>
            );
        });
    }

    function _renderStatusName() {
        return (
            <div className="flexcenter gap-4 flex-1">
                <div className="txt-job-status">{name}</div>
                {label ? (
                    <div className="tag tag-label" title={label}>
                        {label}
                    </div>
                ) : null}
            </div>
        );
    }

    return (
        <Fragment>
            <div
                ref={refResult}
                className={`v2-dropdown list-job-status is-job-color ${finalIsVisible ? 'active' : ''}`}
                id={dropdownId}
            >
                <div
                    onClick={(e) => _handleOpenList(e)}
                    className={`dropbtn items status-btn ${isBlocking ? 'is-disable' : ''}`}
                    style={{ '--bg-job-status': finalColor, '--color-job-status': getContrastColor(finalColor) }}
                >
                    {_renderStatusName()}
                    <div className="arrow">
                        <IconArrowDown />
                    </div>
                </div>
                <div className="v2-dropdown__menu scrolls">
                    {finalIsVisible && <ul id="show_list_status_job_detail">{_renderListStatus()}</ul>}
                </div>
            </div>

            <ChangeStatus ref={refChangeStatus} />

            <ChangeStatusCancel ref={refChangeStatusCancel} />
            <StatusToCancel ref={refCancelChangeStatus} />
            <ModalRecurringJob ref={refModalRecurringJob} />
            <Terminate ref={refTerminateConfirm} onConfirm={_handleTerminate} />
            <GdConfirm
                ref={refConfirm}
                title={t('customers:confirm')}
                listButton={{ confirm: true, cancel: false }}
                titleConfirm={t('customers:confirm')}
            />
        </Fragment>
    );
};

export default Status;
