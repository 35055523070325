import { RatingIcon } from 'app/modules/notifications/components/IconNotification';
import i18n from 'assets/i18n';
import IconCompany from 'assets/icon/IconCompany';
import IconDocument from 'assets/icon/IconDocument';
import IconInvoice from 'assets/icon/IconInvoice';
import IconServiceTemplate from 'assets/icon/IconServiceTemplate';
import IconUser from 'assets/icon/IconUser';
import IconYoutube from 'assets/icon/IconYoutube';

export const ADD_CUSTOMER = 'add_customer';
export const ADD_JOB = 'add_job';
export const ADD_SERVICE_TEMPLATES = 'add_service_templates';
export const NEXT_STEP_DEMO = 'NEXT_STEP_DEMO';
export const NEXT_STEP_ADD_CUSTOMER = 'NEXT_STEP_ADD_CUSTOMER';
export const ADD_INVOICE = 'ADD_INVOICE';
export const PLANS_AND_PRICING = 'PLANS_AND_PRICING';
export const BOOK_A_COACHING_CALL = 'https://gorilladesk.com/academy/getting-started/coaching-call/';
export const ADD_DOCUMENT = 'add_document';
export const UPLOAD_YOUR_LOGO = 'upload_your_logo';
export const ONBOARDING_POPULAR_VIDEOS = [
    {
        id: 1,
        title: i18n.t('onboarding:welcome_video'),
        icon: <IconYoutube isOnBoarding />,
        type: NEXT_STEP_DEMO,
        link: 'https://fast.wistia.net/embed/iframe/0y28tgj7ii',
        isHideStartOnBoarding: true,
        isNextStep: true
    },
    {
        id: 2,
        title: i18n.t('onboarding:watch_the_demo_video'),
        icon: <IconYoutube isOnBoarding />,
        type: NEXT_STEP_ADD_CUSTOMER,
        link: 'https://fast.wistia.net/embed/iframe/i8pda6gmye',
        isHideStartOnBoarding: true,
        isNextStep: true
    },
    {
        id: 3,
        title: i18n.t('onboarding:add_customer'),
        icon: <IconUser isOnBoarding />,
        type: ADD_CUSTOMER,
        link: 'https://fast.wistia.net/embed/iframe/zj1g2b0bv4'
    },
    {
        id: 4,
        title: i18n.t('onboarding:add_job'),
        icon: <IconServiceTemplate isHasColor />,
        type: ADD_JOB,
        link: 'https://fast.wistia.net/embed/iframe/hq1k3zx2wy'
    },
    {
        id: 5,
        title: i18n.t('onboarding:add_an_invoice'),
        icon: <IconInvoice isPurple />,
        type: ADD_INVOICE,
        link: 'https://fast.wistia.net/embed/iframe/cxtdknrwq1'
    },
    {
        id: 6,
        title: i18n.t('onboarding:plans_and_pricing'),
        icon: <RatingIcon />,
        type: PLANS_AND_PRICING,
        link: 'https://fast.wistia.net/embed/iframe/zf28brlxny'
    },
    {
        id: 7,
        title: i18n.t('onboarding:add_document'),
        icon: <IconDocument isOnBoarding />,
        type: ADD_DOCUMENT,
        link: 'https://fast.wistia.net/embed/iframe/oopzhioi4m'
    },
    {
        id: 8,
        title: i18n.t('onboarding:upload_your_logo'),
        icon: <IconCompany isOnBoarding />,
        type: UPLOAD_YOUR_LOGO,
        link: 'https://fast.wistia.net/embed/iframe/khyuu7v8xt'
    }
];
export const LIST_ON_BOARDING_FAQ = [
    {
        id: 1,
        title: i18n.t('onboarding:import_your_own_customer_list'),
        link_article: 'https://intercom.help/gorilladesk/en/articles/2845405-import-your-own-customer-list'
    },
    {
        id: 2,
        title: i18n.t('onboarding:job_import'),
        link_article: 'https://intercom.help/gorilladesk/en/articles/7061830-job-import'
    },
    {
        id: 3,
        title: i18n.t('onboarding:config_setting_title'),
        link: 'https://fast.wistia.net/embed/iframe/x8pacuoduu',
        description: i18n.t('onboarding:config_setting_desc'),
        isHideStartOnBoarding: true
    },
    {
        id: 4,
        title: i18n.t('onboarding:create_service_template_title'),
        link: 'https://fast.wistia.net/embed/iframe/50t8jg8vw3',
        description: i18n.t('onboarding:create_service_template_desc'),
        type: ADD_SERVICE_TEMPLATES
    },
    {
        id: 5,
        title: i18n.t('onboarding:customize_your_paperwork_title'),
        link: 'https://fast.wistia.net/embed/iframe/h1ai12alp0',
        description: i18n.t('onboarding:customize_your_paperwork_desc'),
        isHideStartOnBoarding: true
    },
    {
        id: 6,
        title: i18n.t('onboarding:integrate_stripe_square_title'),
        link: 'https://fast.wistia.net/embed/iframe/d54u4a1t7q',
        description: i18n.t('onboarding:integrate_stripe_square_desc'),
        isHideStartOnBoarding: true
    }
];
export const LIST_VIDEO_LIBRARY = [
    {
        id: 1,
        title: i18n.t('onboarding:square_stripe_title'),
        link: 'https://fast.wistia.net/embed/iframe/d54u4a1t7q'
    },
    {
        id: 2,
        title: i18n.t('onboarding:commission_tracking_title'),
        link: 'https://fast.wistia.net/embed/iframe/1xs2t5lje7'
    },
    {
        id: 3,
        title: i18n.t('onboarding:custom_fields_title'),
        link: 'https://fast.wistia.net/embed/iframe/i2rg4gvinp'
    },
    {
        id: 4,
        title: i18n.t('onboarding:custom_job_status_title'),
        link: 'https://fast.wistia.net/embed/iframe/v3nnn6lm0b'
    },
    {
        id: 5,
        title: i18n.t('onboarding:customer_portal_title'),
        link: 'https://fast.wistia.net/embed/iframe/zyv1908lso'
    },
    {
        id: 6,
        title: i18n.t('onboarding:device_tracking_title'),
        link: 'https://fast.wistia.net/embed/iframe/ri3apnts5r'
    },
    {
        id: 7,
        title: i18n.t('onboarding:documents_title'),
        link: 'https://fast.wistia.net/embed/iframe/oopzhioi4m'
    },
    {
        id: 8,
        title: i18n.t('onboarding:custom_documents_title'),
        link: 'https://fast.wistia.net/embed/iframe/2gp9nl3ozh'
    },
    {
        id: 9,
        title: i18n.t('onboarding:dynamic_estimate_title'),
        link: 'https://fast.wistia.net/embed/iframe/bl0mxq2l2h'
    },
    {
        id: 10,
        title: i18n.t('onboarding:gps_tracking_title'),
        link: 'https://fast.wistia.net/embed/iframe/bhlvl86qda'
    },
    {
        id: 11,
        title: i18n.t('onboarding:material_usage_title'),
        link: 'https://fast.wistia.net/embed/iframe/6xy49kza6y'
    },
    {
        id: 12,
        title: i18n.t('onboarding:multi_unit_tracking_title'),
        link: 'https://fast.wistia.net/embed/iframe/dc7epbh5q5'
    },
    {
        id: 13,
        title: i18n.t('onboarding:online_booking_title'),
        link: 'https://fast.wistia.net/embed/iframe/urtje6fk49'
    },
    {
        id: 14,
        title: i18n.t('onboarding:opportunity_pipeline_title'),
        link: 'https://fast.wistia.net/embed/iframe/7xbhk2elbd'
    },
    {
        id: 15,
        title: i18n.t('onboarding:review_engine_title'),
        link: 'https://fast.wistia.net/embed/iframe/1ce9pkhpro'
    },
    {
        id: 16,
        title: i18n.t('onboarding:route_optimization_title'),
        link: 'https://fast.wistia.net/embed/iframe/mjqa5cpez8'
    },
    {
        id: 17,
        title: i18n.t('onboarding:best_available_time_title'),
        link: 'https://fast.wistia.net/embed/iframe/ya559pe1k9'
    },
    {
        id: 18,
        title: i18n.t('onboarding:job_magnet_title'),
        link: 'https://fast.wistia.net/embed/iframe/jkkdohr10h'
    },
    {
        id: 19,
        title: i18n.t('onboarding:smart_views_title'),
        link: 'https://fast.wistia.net/embed/iframe/mktlppqlim'
    },
    {
        id: 20,
        title: i18n.t('onboarding:triggers_title'),
        link: 'https://www.youtube.com/embed/sgmgsye3GnI'
    },
    {
        id: 21,
        title: i18n.t('onboarding:voip_by_gorilladesk_title'),
        link: 'https://fast.wistia.net/embed/iframe/1hjylr6cut'
    },
    {
        id: 22,
        title: i18n.t('onboarding:send_receive_calls_title'),
        link: 'https://fast.wistia.net/embed/iframe/1hjylr6cut'
    },
    {
        id: 23,
        title: i18n.t('onboarding:zapier_title'),
        link: 'https://fast.wistia.net/embed/iframe/nvu28sfmz9'
    },
    {
        id: 24,
        title: i18n.t('onboarding:branch_manager_title'),
        link: 'https://fast.wistia.net/embed/iframe/bx963yfgum'
    },
    {
        id: 25,
        title: i18n.t('onboarding:company_settings_title'),
        link: 'https://fast.wistia.net/embed/iframe/x8pacuoduu'
    },
    {
        id: 26,
        title: i18n.t('onboarding:tax_line_settings_title'),
        link: 'https://fast.wistia.net/embed/iframe/cevo9m9mc6'
    },
    {
        id: 27,
        title: i18n.t('onboarding:service_template_title'),
        link: 'https://fast.wistia.net/embed/iframe/ti6vstwk94'
    },
    {
        id: 28,
        title: i18n.t('onboarding:adding_jobs_desktop_title'),
        link: 'https://fast.wistia.net/embed/iframe/7qruqbrwza'
    },
    {
        id: 29,
        title: i18n.t('onboarding:adding_jobs_mobile_title'),
        link: 'https://fast.wistia.net/embed/iframe/lqcp6kec40'
    },
    {
        id: 30,
        title: i18n.t('onboarding:appointment_confirmations_title'),
        link: 'https://fast.wistia.net/embed/iframe/ht2jlv01lh'
    },
    {
        id: 31,
        title: i18n.t('onboarding:appointment_reminders_title'),
        link: 'https://fast.wistia.net/embed/iframe/po5nw3d3su'
    },
    {
        id: 32,
        title: i18n.t('onboarding:appointment_followups_title'),
        link: 'https://fast.wistia.net/embed/iframe/neba5ijzy5'
    },
    {
        id: 33,
        title: i18n.t('onboarding:late_payment_reminders_title'),
        link: 'https://fast.wistia.net/embed/iframe/1w2hsmqdzj'
    },
    {
        id: 34,
        title: i18n.t('onboarding:messaging_preferences_title'),
        link: 'https://fast.wistia.net/embed/iframe/e2c12fgxgj'
    },
    {
        id: 35,
        title: i18n.t('onboarding:completing_jobs_desktop_title'),
        link: 'https://fast.wistia.net/embed/iframe/floii131os'
    },
    {
        id: 36,
        title: i18n.t('onboarding:completing_jobs_mobile_title'),
        link: 'https://fast.wistia.net/embed/iframe/xkvjj85pqz'
    },
    {
        id: 37,
        title: i18n.t('onboarding:note_templates_title'),
        link: 'https://fast.wistia.net/embed/iframe/xzhz1rdjo3'
    },
    {
        id: 38,
        title: i18n.t('onboarding:add_users_permissions_title'),
        link: 'https://fast.wistia.net/embed/iframe/5qkq2m0hnw'
    },
    {
        id: 39,
        title: i18n.t('onboarding:create_assign_schedules_title'),
        link: 'https://fast.wistia.net/embed/iframe/ip0h6uh4gm'
    },
    {
        id: 40,
        title: i18n.t('onboarding:paperwork_templates_title'),
        link: 'https://fast.wistia.net/embed/iframe/h1ai12alp0'
    },
    {
        id: 41,
        title: i18n.t('onboarding:schedule_groups_title'),
        link: 'https://fast.wistia.net/embed/iframe/9jlmt0jw5s'
    },
    {
        id: 42,
        title: i18n.t('onboarding:tags_tagging_title'),
        link: 'https://fast.wistia.net/embed/iframe/ypjgwg9qq7'
    },
    {
        id: 43,
        title: i18n.t('onboarding:email_templates_title'),
        link: 'https://www.youtube.com/embed/dNgxCGa_J34'
    },
    {
        id: 44,
        title: i18n.t('onboarding:stripe_square_subscription_billing_title'),
        link: 'https://fast.wistia.net/embed/iframe/wt9je966d3'
    },
    {
        id: 45,
        title: i18n.t('onboarding:sketch_title'),
        link: 'https://fast.wistia.net/embed/iframe/cmk8nbamm7'
    },
    {
        id: 46,
        title: i18n.t('onboarding:map_drawing_tool_title'),
        link: 'https://www.youtube.com/embed/P5yu5h12pfA'
    }
];
