import React, { useState } from 'react';
import Schedules from 'app/modules/jobdetail/tabs/settingschedule/assign/Schedules';
import IconPlus from 'assets/icon/IconPlus';

const AddSchedules = ({
    selected,
    crewSelected,
    onSelect,
    isWorkPool = false,
    changePrimary = () => {},
    onSelectCrew = () => {}
}) => {
    const [isVisible, setIsVisible] = useState(false);

    function _toggleSchedule(e) {
        e.stopPropagation();
        setIsVisible(!isVisible);
    }

    function _handleClose() {
        setIsVisible(false);
    }

    return (
        <div
            id="show_list_schedule_job_preview"
            className={`v2-dropdown dropdown-listname --has-crew ${isVisible ? 'active' : ''}`}
        >
            <div className="dropbtn v2-btn-default --icon-lg" onClick={_toggleSchedule}>
                <IconPlus />
            </div>

            <Schedules
                isVisible={isVisible}
                isWorkPool={isWorkPool}
                selected={selected}
                crewSelected={crewSelected}
                onSelect={onSelect}
                onSelectCrew={onSelectCrew}
                changePrimary={changePrimary}
                onClose={_handleClose}
            />
        </div>
    );
};

export default AddSchedules;
