import React, { useRef } from 'react';
import { AVATAR_DEFAULT } from 'app/const/App';
import PropsTypes from 'prop-types';
import { LINK_CDN_IMG_BROKEN } from 'app/const/URL';

function GdImage({ src = AVATAR_DEFAULT, alt = 'avatar', isLoading = false, width, height, className }) {
    return isLoading ? (
        <div className="is-loading-box">
            <div className="preloader-small">
                <span className="preloader-small__items"></span>
            </div>
        </div>
    ) : (
        <img src={src} alt={alt} width={width} height={height} className={className} />
    );
}

export const GDGlobalImg = ({ thumbnailUrl = '', src = '', ...props }) => {
    const refCount = useRef(0);

    const _handleImageLoadError = (event) => {
        if (refCount.current < 1) {
            refCount.current += 1;
            event.target.src = src;
        } else {
            event.target.src = LINK_CDN_IMG_BROKEN;
        }
    };

    return (
        <img
            loading="lazy"
            src={thumbnailUrl || src || LINK_CDN_IMG_BROKEN}
            onError={_handleImageLoadError}
            {...props}
        />
    );
};

GdImage.propsTypes = {
    src: PropsTypes.string,
    alt: PropsTypes.string,
    isLoading: PropsTypes.bool
};
export default GdImage;
