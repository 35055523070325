import { STATUS_ACCOUNT } from 'app/const/Account';
import {
    ANNUAL_BASIC_ACCOUNT_USER,
    ANNUAL_PRO_ACCOUNT_USER,
    BASIC_ACCOUNT_USER,
    PRO_ACCOUNT_USER,
    FREE_TRIAL_KEY,
    GROWTH_ACCOUNT_USER,
    ANNUAL_GROWTH_ACCOUNT_USER,
    ERROR_CODE
} from 'app/const/App';
import { ACCESS_ADDONS, PERMISSIONS } from 'app/const/Permissions';
import { VOIP_PLAN_BASIC } from 'app/const/Voip';
import { CLEANING, INDUSTRIES, LAWN, LIST_INDUSTRY } from 'app/modules/auth/signup/components/freeTrial/const';
import { actionFailPermission } from 'common/redux/actions/authAction';
import store from 'common/redux/store/configureStore';
import { checkAddon } from 'common/utils/AddonUtils';

/**
 *
 * @param {Array} permissions
 * @param {string} value
 * @returns boolean
 */
export const checkPermission = (permissions, value) => {
    return permissions.includes(value);
};

export const getPermissionCommission = (commissionTracking, isAdminPermission = false) => {
    const permission = { canModify: true, canDisplaySoldBy: true, canDisplayCommission: true };

    if (typeof commissionTracking !== 'object')
        return { canModify: false, canDisplaySoldBy: false, canDisplayCommission: false };

    permission['canModify'] = !!commissionTracking.sold_by_sales && !!isAdminPermission;
    permission['canDisplaySoldBy'] = !!commissionTracking.sold_by_sales;
    permission['canDisplayCommission'] = !!commissionTracking.invoice_commission_preview;

    return permission;
};

export const getPlanUser = (profile) => {
    let isProPlan = false;
    let isBasicPlan = false;
    let isGrowthPlan = false;
    const isTrial = false;
    const typeAccount = profile.plan.type;
    const proTypes = [PRO_ACCOUNT_USER, ANNUAL_PRO_ACCOUNT_USER];
    const basicTypes = [BASIC_ACCOUNT_USER, ANNUAL_BASIC_ACCOUNT_USER];
    const growthTypes = [GROWTH_ACCOUNT_USER, ANNUAL_GROWTH_ACCOUNT_USER];

    if (profile.plan.status === FREE_TRIAL_KEY) return { isTrial: true, isProPlan, isBasicPlan };

    if (proTypes.includes(typeAccount)) isProPlan = true;
    if (basicTypes.includes(typeAccount)) isBasicPlan = true;
    if (growthTypes.includes(typeAccount)) isGrowthPlan = true;

    return { isTrial, isProPlan, isBasicPlan, isGrowthPlan };
};

export const getPermissionReport = (addons = {}) => {
    const permissionLeads =
        checkAddon(addons.customer_portal) || checkAddon(addons.online_booking) || checkAddon(addons.zapier);
    const permissionReviews = checkAddon(addons.review_engine);
    return {
        documents: checkAddon(addons.documents),
        stripe_subscriptions: checkAddon(addons.stripe?.subscription),
        square_subscriptions: checkAddon(addons.square?.square_subscription),
        material_use: checkAddon(addons.material),
        likely_rating: permissionReviews,
        service_rating: permissionReviews,
        inbound_leads: permissionLeads,
        online_bookings: permissionLeads,
        time_tracking: checkAddon(addons.work_log),
        device_tracking: checkAddon(addons.device_tracking)
    };
};

export const getPermissionSetings = (permissions = []) => {
    const addons = store.getState().auth.user?.settings?.addons || {};
    const isAccessAccount = checkPermission(permissions, PERMISSIONS.accessAccount);
    const isAccessSetting = checkPermission(permissions, PERMISSIONS.accessSettingsTab);
    return {
        your_account: isAccessAccount,
        account: isAccessAccount,
        plans: isAccessAccount,
        invoices: isAccessAccount,
        system_settings: isAccessSetting,
        company: isAccessSetting,
        users: isAccessSetting,
        schedules: isAccessSetting,
        taxes: isAccessSetting,
        line_items: isAccessSetting,
        paperwork_templates: isAccessSetting,
        payment_methods: isAccessSetting,
        service_templates: isAccessSetting,
        service_plan: isAccessSetting && checkAddon(addons.service_plan),
        sources: isAccessSetting,
        tags: isAccessSetting,
        tiles: isAccessSetting,
        notes_and_todo_list:
            isAccessSetting &&
            (checkPermission(permissions, PERMISSIONS.manageTodoTemplate) ||
                checkPermission(permissions, PERMISSIONS.manageNoteTemplate)),
        sms_and_email_templates: isAccessSetting,
        systems: isAccessSetting,
        customs: isAccessSetting,
        broadcast: isAccessSetting,
        email_inbox: isAccessSetting,
        global_override: isAccessSetting
    };
};

export const getPermissionCustomerDetail = (addons = {}) => {
    return {
        customer_portal: checkAddon(addons.customer_portal),
        documents: checkAddon(addons.documents)
    };
};

export const getPermissionTemplateManager = (permissions = []) => {
    const notePermission = checkPermission(permissions, PERMISSIONS.manageNoteTemplate);
    const settingsPermission = checkPermission(permissions, PERMISSIONS.accessSettingsTab);

    return {
        all_templates: notePermission,
        customer_notes: notePermission,
        estimate_notes: notePermission,
        invoice_notes: notePermission,
        job_notes: notePermission,
        top_notes: notePermission,
        work_order_notes: notePermission,
        line: notePermission,
        todo_list: checkPermission(permissions, PERMISSIONS.manageTodoTemplate),
        sms_templates: !!store.getState()?.auth?.user?.settings?.addons?.smsplivo && settingsPermission,
        basic_estimates: settingsPermission,
        dynamic_estimates: settingsPermission,
        estimate_packages: settingsPermission
    };
};

export const isNotPermissionValue = (permissions = {}, value) =>
    typeof permissions[value] === 'boolean' && !permissions[value];

export const checkPermissionManageNotesTemplate = (permissions) => {
    return (
        checkPermission(permissions, PERMISSIONS.accessSettingsTab) &&
        checkPermission(permissions, PERMISSIONS.manageNoteTemplate)
    );
};

export const checkIsExpiredAccount = (profile) => {
    return profile?.plan?.status === STATUS_ACCOUNT.EXPIRED;
};

// isCheckActivateUser = true: check user is activate(VoIP Users)
export const checkIsEnableVoip = (settings, isCheckActivateUser = true) => {
    const isHaveAddonVoip = !!settings?.addons?.voip;
    return isCheckActivateUser ? !!settings?.voip && isHaveAddonVoip : isHaveAddonVoip;
};

export const checkIsHaveOptionVoip = (profile, plan_voip = VOIP_PLAN_BASIC) => {
    return profile?.voip_plan?.plan_id >= parseInt(plan_voip);
};

export const checkAccessFail = (error, keyword = ACCESS_ADDONS) => {
    if (error.statusCode !== ERROR_CODE.PERMISSION_DENIED) return;
    store.dispatch(actionFailPermission(keyword));
};

export const isVisibleManageTemplate = ({ permissions = [], additionalAccess = [] }) => {
    if (!additionalAccess?.length) return false;
    return additionalAccess.every((key) => checkPermission(permissions, key));
};

export const isShowCrew = () => {
    const { company: { industry } = {}, profile } = store.getState().auth.user || {};
    const { isBasicPlan } = getPlanUser(profile);

    return (
        !isBasicPlan &&
        [...LIST_INDUSTRY[CLEANING], ...LIST_INDUSTRY[LAWN], ...Object.values(INDUSTRIES)].some(
            (item) => item.value === industry
        )
    );
};

export const isActiveFeature = (feature) => {
    const { inactive_features: inactiveFeatures = [] } = store.getState().auth.user?.company || {};
    return !inactiveFeatures.includes(feature);
};

export const isActiveFeatures = (features = []) => {
    return features.every((feature) => isActiveFeature(feature));
};
