import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import IconPlay from 'assets/icon/IconPlay';

const ButtonAIChatBot = ({ onOpen = () => {} }, ref) => {
    const { t } = useTranslation('addons');
    const [state, dispatchState] = useReducer(reducer, { isDisable: false });
    const { isDisable } = state;

    useImperativeHandle(ref, () => ({ setDisable: _handleSetDisable }));

    const _handleSetDisable = (value) => {
        dispatchState((prev) => ({ ...prev, isDisable: value }));
    };

    const _handleClick = () => {
        onOpen();
        _handleSetDisable(true);
    };

    return (
        <div className="box-test-sms boxs-notification w-100">
            <div
                className={classNames('v2-btn-main has-icon --bg-green w-100 justify-center', {
                    'is-disable': isDisable
                })}
                onClick={_handleClick}
            >
                <IconPlay isWhite />
                {t('test_chatbot')}
            </div>
        </div>
    );
};

export default forwardRef(ButtonAIChatBot);
