import React, { useEffect, useReducer } from 'react';

import { ADDONS_OPPS_DETAIL, UPDATE_ADDONS_OPPORTUNITY_OPTIONS } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import AddonHeaderDetail from '../components/AddonHeaderDetail';
import AddonsBoxHeader from '../components/AddonsBoxHeader';
import AddonsOptions from '../components/AddonsOptions';
import Loading from './components/Loading';

const AddonsOpportunity = () => {
    const [state, dispatchState] = useReducer(reducer, { opps: {}, isLoading: true });
    const { isLoading, opps } = state;

    useEffect(() => {
        _getSettingDetail();
    }, []);

    const _getSettingDetail = () => {
        const _handleSuccess = ({ data }) => dispatchState({ opps: data, isLoading: false });
        clientQuery(ADDONS_OPPS_DETAIL, { method: 'GET' }, _handleSuccess, checkAccessFail);
    };

    const _handleUpdateSuccess = (checked) => {
        dispatchState((prevState) => ({ ...prevState, opps: { ...prevState.opps, status: checked } }));
    };

    return (
        <div className="addons-wrap__container">
            <AddonHeaderDetail isLoading={isLoading} tutorial={opps.tutorial} />
            <div className="scrolls">
                <div className="boxs-wrapper">
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <div className="boxs boxs--no-border --hasline --mr-line">
                                <AddonsBoxHeader
                                    upgrade={opps.upgrade}
                                    keyword={opps.keyword}
                                    name={opps.name}
                                    status={opps.status}
                                    level={opps.level}
                                    onUpdateStatusSuccess={_handleUpdateSuccess}
                                    urlUpdateStatus={UPDATE_ADDONS_OPPORTUNITY_OPTIONS}
                                />
                                {opps.sub_title ? (
                                    <div className="boxs__contents mt-3">
                                        <p className="description">{opps.sub_title}</p>
                                    </div>
                                ) : null}
                            </div>

                            <AddonsOptions
                                data={opps?.options || []}
                                status={opps?.status || false}
                                upgrade={opps?.upgrade}
                                urlUpdate={UPDATE_ADDONS_OPPORTUNITY_OPTIONS}
                                handleManagerSuccess={() => {}}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddonsOpportunity;
