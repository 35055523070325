import { React } from 'react';
import { useTranslation } from 'react-i18next';

import { LIST_API_UPDATE_ADDONS_OPTIONS } from 'app/const/addons';
import AddonsBoxHeader from '../../components/AddonsBoxHeader';

const RoutingAddonsContent = ({ keyword, name, upgrade, level, status }) => {
    const { t } = useTranslation(['addons', 'common']);

    return (
        <div className="boxs boxs--no-border --hasline">
            <AddonsBoxHeader
                keyword={keyword}
                name={name}
                upgrade={upgrade}
                status={status}
                level={level}
                urlUpdateStatus={LIST_API_UPDATE_ADDONS_OPTIONS[keyword]}
            />
            <div className="boxs__contents mt-3">
                <p className="description mt-5">{t('addons:route_content_desc_one')}</p>
                <p className="description mt-5">{t('addons:route_content_desc_two')}</p>
            </div>
        </div>
    );
};

export default RoutingAddonsContent;
