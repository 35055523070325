import moment from 'moment';
import React, { createContext, useContext, useLayoutEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import GdConfirm from 'app/components/confirm';
import { getJobsMagnet, JOBS_BATCH_MOVE } from 'app/const/api/V2';
import { reducer } from 'app/const/Reducer';
import { ROUTING_UNITS } from 'app/modules/addons/advanceRouting/constants';
import { clientQuery } from 'common/utils/ApiUtils';
import { MAGNET_ACTIONS } from './components/constants';
import { jobMagnetConvert } from './utils';

const MagnetContext = createContext();
const initializeState = ({ defaultEventSelected = {}, defaultMagnetValues, schedules = [] }) => {
    const state = { jobs: [], isLoading: true, defaultMagnetValues, eventSelected: defaultEventSelected };
    const idsSet = new Set(defaultEventSelected.schedule_ids || []);
    state['schedules'] = schedules.filter((item) => idsSet.has(item.id));
    state['date'] = moment(defaultEventSelected?.event?.start).utc().format('YYYY-MM-DD');
    return state;
};
export function MagnetProvider({
    defaultEventSelected,
    defaultMagnetValues = null,
    onClose = () => {},
    onSuccess = () => {},
    children
}) {
    const { t } = useTranslation('common');
    const schedulesList = useSelector(({ schedules }) => schedules.data.schedules || []);
    const [state, dispatchState] = useReducer(
        reducer,
        { schedules: schedulesList, defaultEventSelected, defaultMagnetValues },
        initializeState
    );

    const { eventSelected, schedules, isLoading, distanceRange, dayRange } = state;
    const refNoti = useRef(null);
    const refValues = useRef(null);

    useLayoutEffect(() => {
        fetchData();
    }, []);

    const fetchData = ({
        jobId = eventSelected.jobId,
        scheduleIds = schedules.map((item) => item.id),
        distanceRange = defaultMagnetValues?.distance || { value: 0, unit: ROUTING_UNITS.MI['value'] },
        dayRange = defaultMagnetValues?.day_range || 0,
        forceUpdateSchedule = false
    } = {}) => {
        if (!isLoading || forceUpdateSchedule) {
            dispatchState((prevState) => {
                const newState = { ...prevState, isLoading: true };
                if (forceUpdateSchedule) newState['schedules'] = scheduleIds;
                return newState;
            });
        }

        const fetchSuccess = ({ data }) => {
            dispatchState((prevState) => ({
                ...prevState,
                distanceRange,
                dayRange,
                isLoading: false,
                jobs: jobMagnetConvert(data || [], distanceRange.value || 0)
            }));
        };

        const fetchFail = ({ message }) => {
            dispatchState((prevState) => ({ ...prevState, isLoading: false, jobs: [] }));
            handleOpenConfirm({ message });
        };

        const params = {
            toFormData: false,
            method: 'GET',
            data: {
                schedules: forceUpdateSchedule ? scheduleIds.map((item) => item.id).toString() : scheduleIds.toString(),
                distance: JSON.stringify(distanceRange),
                day: dayRange
            }
        };

        clientQuery(getJobsMagnet(jobId), params, fetchSuccess, fetchFail);
    };

    const handleReSelectMagnet = (eventSelect) => {
        dispatchState((prevState) => ({ ...prevState, isLoading: true, eventSelected: eventSelect }));
        fetchData({
            jobId: eventSelect.jobId,
            distanceRange,
            dayRange,
            scheduleIds: schedules,
            forceUpdateSchedule: true
        });
    };

    const handleStoreValues = ({ type, payload }) => {
        switch (type) {
            case MAGNET_ACTIONS.ADD:
                refValues.current = [...(refValues.current || []), payload];
                break;
            case MAGNET_ACTIONS.REMOVE:
                refValues.current = (refValues.current || []).filter((item) => item.id !== payload.id);
                break;
            case MAGNET_ACTIONS.MOVE:
                refValues.current = (refValues.current || []).map((item) => {
                    if (item.id === payload.id) return { ...item, ...(payload || {}) };
                    return item;
                });
                break;
            default:
                break;
        }
    };

    const handleSave = ({ all = 0 } = {}, callback = () => {}) => {
        if (!refValues.current) {
            callback();
            return;
        }
        const dataSubmit = { all, jobs: refValues.current || [] };
        const saveSuccess = () => {
            onClose();
            onSuccess();
        };
        const saveFail = ({ message }) => {
            callback();
            handleOpenConfirm({ message: message || 'Save failed' });
        };
        clientQuery(JOBS_BATCH_MOVE, { data: dataSubmit, toFormData: false, method: 'PUT' }, saveSuccess, saveFail);
    };

    const getCurrentJobs = () => {
        return refValues.current || [];
    };

    const handleOpenConfirm = ({ value = '', message, title = t('notification') }) => {
        refNoti.current?.open(value, message, title);
    };

    const handleCloseConfirm = () => {
        refNoti.current?.close();
    };

    const valueContext = {
        ...state,
        getCurrentJobs,
        handleOpenConfirm,
        handleFetch: fetchData,
        handleReSelectMagnet,
        handleStoreValues,
        handleSave
    };

    return (
        <MagnetContext.Provider value={valueContext}>
            {children}
            <GdConfirm
                ref={refNoti}
                title={t('notification')}
                listButton={{ confirm: true }}
                onConfirm={handleCloseConfirm}
            />
        </MagnetContext.Provider>
    );
}

export function useMagnet() {
    const context = useContext(MagnetContext);
    if (!context) throw new Error('useMagnet must be used within a MagnetProvider');
    return context;
}
