import React from 'react';

import { ROUTING_UNITS } from 'app/modules/addons/advanceRouting/constants';
import { convertSecondsToETA } from 'common/utils/TimeUtils';

const BoxDistance = ({ distance = {}, time }) => {
    return (
        <div className="box-distance">
            <span>
                {distance.value}
                {ROUTING_UNITS[distance.unit]?.label?.toLowerCase()}
            </span>
            <span className="dots" />
            {convertSecondsToETA(time || 0)}
        </div>
    );
};

export default BoxDistance;
