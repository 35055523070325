import { LIST_TABLE_ITEM_TYPE, TYPE_BUTTON_ACTIONS } from 'app/const/App';
import { PAYMENT_CARD_CUSTOMER } from 'app/const/Customers';
import IconTrash from 'assets/icon/IconTrash';
import IconTime from 'assets/icon/IconTime';
import IconDoubleCheck from 'assets/icon/IconDoubleCheck';
import IconSync from 'assets/icon/IconSync';
import i18n from 'assets/i18n';

export const REPORT_TYPE = {
    INVOICE: 'invoice',
    INBOX: 'inbox',
    ESTIMATE: 'estimate',
    DOCUMENT: 'document',
    DOCUMENT_CUSTOMER_LIST: 'document_customer_list',
    CUSTOMER_INVOICES: 'customer_invoices',
    CUSTOMER_JOBS: 'customer_jobs',
    STATEMENT_CUSTOMER_LIST: 'statement_customer_list',
    LOCATION_CUSTOMER_LIST: 'location_customer_list',
    CREDIT_CUSTOMER_LIST: 'credit_customer_list',
    ESTIMATE_CUSTOMER_LIST: 'estimate_customer_list',
    LOCATION: 'location_statement',
    PAYMENT: 'payment',
    CREDIT: 'credit',
    RECURRING_INVOICE: 'recurring_invoice',
    SUBSCRIPTION: {
        [PAYMENT_CARD_CUSTOMER.STRIPE]: 'stripe_subscription',
        [PAYMENT_CARD_CUSTOMER.SQUARE]: 'square_subscription'
    },
    SERVICE_LOOKUP: 'service_lookup',
    LOCATIONS_WO_ACTIVE_JOBS: 'location_wo',
    CUSTOMERS_WO_ACTIVE_JOBS: 'customers_wo',
    ACCOUNTS_AGING: 'accounts_aging',
    SALES_FORECAST: 'sales_forecast',

    POPUP_DETAIL: 'popup_detail',

    SALES_TAX_SUMMARY: 'sales_tax_summary',
    SALES_TAX_SUMMARY_SALES_TAX_TAB: 'sales_tax_tab',
    SALES_TAX_SUMMARY_TAX_SUMMARY_TAB: 'tax_summary_tab',

    REVENUE_BY_CLIENT: 'revenue_by_client',
    REVENUE_BY_CLIENT_MONTH_TAB: 'revenue_by_client_mouth_tab',
    REVENUE_BY_CLIENT_DETAIL_TAB: 'revenue_by_client_detail_tab',

    REVENUE_BY_SERVICE: 'revenue_by_service',
    REVENUE_BY_SERVICE_MONTH_TAB: 'revenue_by_service_mouth_tab',
    REVENUE_BY_SERVICE_DETAIL_TAB: 'revenue_by_service_detail_tab',

    REVENUE_BY_ITEM: 'revenue_by_item',
    REVENUE_BY_ITEM_MONTH_TAB: 'revenue_by_item_mouth_tab',
    REVENUE_BY_ITEM_DETAIL_TAB: 'revenue_by_item_detail_tab',

    REVENUE_BY_STAFF: 'revenue_by_staff',
    REVENUE_BY_STAFF_MONTH_TAB: 'revenue_by_staff_mouth_tab',
    REVENUE_BY_STAFF_DETAIL_TAB: 'revenue_by_staff_detail_tab',

    REVENUE_BY_SOURCE: 'revenue_by_source',
    REVENUE_BY_SOURCE_MONTH_TAB: 'revenue_by_source_mouth_tab',
    REVENUE_BY_SOURCE_DETAIL_TAB: 'revenue_by_source_detail_tab',

    REVENUE_BY_TAGS: 'revenue_by_tags',
    REVENUE_BY_TAGS_MONTH_TAB: 'revenue_by_tags_mouth_tab',
    REVENUE_BY_TAGS_DETAIL_TAB: 'revenue_by_tags_detail_tab',

    EMAIL_HISTORY: 'email_history',
    MATERIAL_USE: 'material_use',
    MATERIAL_USE_DETAIL: 'material_use_detail',
    MATERIAL_NEW_YORK: 'material_new_york',
    TOTAL_SALES: 'totals_sales',
    PAYMENTS_COLLECTED: 'payments_collected',
    NEW_CUSTOMERS: 'new_customers',
    LEADS: {
        inbound_leads: 'inbound_leads',
        online_bookings: 'online_bookings'
    },
    CUSTOMERS: 'customers',
    CUSTOMER_PAYMENTS: 'customer_payments',
    COMMISSIONS: 'commissions',
    REVIEW: 'review',
    REVIEW_PAGE_PARAMS_SERVICE: 'reviews_page_params_service',
    REVIEW_PAGE_PARAMS_LIKELY: 'reviews_page_params_likely',
    USER_SESSION_LIST: 'user_session_list',
    CUSTOMER_LOCATION: 'customer_location',
    ACTIVITY_COMPARISON: 'activity_comparison',
    OPPORTUNITIES_PIPELINE: 'opportunities_pipeline',
    MANAGE_MULTIPLE_BRANCH: 'manage_multiple_branch',
    WORK_LOG: 'worklogs',
    SALES_FUNNEL: 'sales_funnel',
    BLOCK_NUMBERS: 'block_numbers',
    STATUS_CHANGES: 'status_changes',
    OPPORTUNITIES_PERMISSIONS: 'opportunity_permissions',
    CUSTOM_FIELDS_PERMISSIONS: 'custom_fields_permissions',
    CUSTOM_FIELDS_MANAGE: 'custom_fields_manage',
    CUSTOM_JOB_STATUS_PERMISSIONS: 'custom_job_status_permissions',
    CUSTOM_JOB_STATUS_MANAGE_DELETE: 'custom_job_status_manage_delete',
    NEW_JOB: 'new_job',
    DEVICE_TRACKING: 'device_tracking',
    CHATBOT_REPORT: 'chatbot_report',
    CHATBOT_TRAIN_AI: 'chatbot_train_ai',
    CHATBOT_CUSTOM_ANSWER: 'chatbot_custom_answer',
    CHATBOT_CONTENT: 'chatbot_content'
};

// List Batch Actions
export const REPORT_BATCH_ACTION_ITEMS = {
    INVOICES_EMAIL: 1,
    INVOICES_PRINT: 2,
    INVOICES_GENERATE_PDF: 3,
    INVOICES_GENERATE_PDFS: 14,
    INVOICES_SYNC_TO_QB: 4,
    ESTIMATES_EMAIL: 5,
    ESTIMATES_PRINT: 6,
    SERVICE_LOOKUP_REASSIGN_JOBS: 7,
    ACCOUNTS_AGING_PRINT_INVOICE: 8,
    ACCOUNTS_AGING_GENERATE_INVOICE_PDF: 9,
    ACCOUNTS_AGING_PRINT_STATEMENT: 10,
    ACCOUNTS_AGING_GENERATE_STATEMENT_PDF: 11,
    ACCOUNTS_AGING_EMAIL_LATE_PAYMENT_REMINDER: 12,
    ACCOUNTS_AGING_SMS_LATE_PAYMENT_REMINDER: 13,
    ACCOUNTS_AGING_EMAIL_STATEMENT: 14,
    MARK_AS_SENT: 15,
    MARK_AS_DRAFT: 16
};

export const REPORT_LIST_BATCH_ACTIONS = {
    INVOICE: [
        {
            id: REPORT_BATCH_ACTION_ITEMS.INVOICES_EMAIL,
            name: i18n.t('report:email_invoices'),
            value: REPORT_BATCH_ACTION_ITEMS.INVOICES_EMAIL
        },
        {
            id: REPORT_BATCH_ACTION_ITEMS.INVOICES_PRINT,
            name: i18n.t('report:print_invoices'),
            value: REPORT_BATCH_ACTION_ITEMS.INVOICES_PRINT
        },
        {
            id: REPORT_BATCH_ACTION_ITEMS.INVOICES_GENERATE_PDF,
            name: i18n.t('report:generate_pdf'),
            value: REPORT_BATCH_ACTION_ITEMS.INVOICES_GENERATE_PDF
        },
        {
            id: REPORT_BATCH_ACTION_ITEMS.INVOICES_GENERATE_PDFS,
            name: i18n.t('report:generate_pdfs'),
            value: REPORT_BATCH_ACTION_ITEMS.INVOICES_GENERATE_PDFS
        },
        {
            id: REPORT_BATCH_ACTION_ITEMS.INVOICES_SYNC_TO_QB,
            name: i18n.t('report:sync_to_qb'),
            value: REPORT_BATCH_ACTION_ITEMS.INVOICES_SYNC_TO_QB
        },
        {
            id: REPORT_BATCH_ACTION_ITEMS.MARK_AS_SENT,
            name: i18n.t('report:mark_as_sent'),
            value: REPORT_BATCH_ACTION_ITEMS.MARK_AS_SENT
        },
        {
            id: REPORT_BATCH_ACTION_ITEMS.MARK_AS_DRAFT,
            name: i18n.t('report:mark_as_draft'),
            value: REPORT_BATCH_ACTION_ITEMS.MARK_AS_DRAFT
        }
    ],
    ESTIMATE: [
        {
            id: REPORT_BATCH_ACTION_ITEMS.ESTIMATES_EMAIL,
            name: 'email_estimates',
            value: REPORT_BATCH_ACTION_ITEMS.ESTIMATES_EMAIL
        },
        {
            id: REPORT_BATCH_ACTION_ITEMS.ESTIMATES_PRINT,
            name: 'print_estimates',
            value: REPORT_BATCH_ACTION_ITEMS.ESTIMATES_PRINT
        }
    ],
    SERVICE_LOOKUP: [
        {
            id: REPORT_BATCH_ACTION_ITEMS.SERVICE_LOOKUP_REASSIGN_JOBS,
            name: 'reassign_jobs',
            value: REPORT_BATCH_ACTION_ITEMS.SERVICE_LOOKUP_REASSIGN_JOBS
        }
    ],
    ACCOUNTS_AGING: [
        {
            id: REPORT_BATCH_ACTION_ITEMS.ACCOUNTS_AGING_PRINT_INVOICE,
            name: 'print_invoices',
            value: REPORT_BATCH_ACTION_ITEMS.ACCOUNTS_AGING_PRINT_INVOICE
        },
        {
            id: REPORT_BATCH_ACTION_ITEMS.ACCOUNTS_AGING_GENERATE_INVOICE_PDF,
            name: 'generate_invoices_pdf',
            value: REPORT_BATCH_ACTION_ITEMS.ACCOUNTS_AGING_GENERATE_INVOICE_PDF
        },
        {
            id: REPORT_BATCH_ACTION_ITEMS.ACCOUNTS_AGING_PRINT_STATEMENT,
            name: 'print_statements',
            value: REPORT_BATCH_ACTION_ITEMS.ACCOUNTS_AGING_PRINT_STATEMENT
        },
        {
            id: REPORT_BATCH_ACTION_ITEMS.ACCOUNTS_AGING_GENERATE_STATEMENT_PDF,
            name: 'generate_statements_pdf',
            value: REPORT_BATCH_ACTION_ITEMS.ACCOUNTS_AGING_GENERATE_STATEMENT_PDF
        },
        {
            id: REPORT_BATCH_ACTION_ITEMS.ACCOUNTS_AGING_EMAIL_LATE_PAYMENT_REMINDER,
            name: 'email_late_payment_reminders',
            value: REPORT_BATCH_ACTION_ITEMS.ACCOUNTS_AGING_EMAIL_LATE_PAYMENT_REMINDER
        },
        {
            id: REPORT_BATCH_ACTION_ITEMS.ACCOUNTS_AGING_SMS_LATE_PAYMENT_REMINDER,
            name: 'sms_late_payment_reminders',
            value: REPORT_BATCH_ACTION_ITEMS.ACCOUNTS_AGING_SMS_LATE_PAYMENT_REMINDER
        },
        {
            id: REPORT_BATCH_ACTION_ITEMS.ACCOUNTS_AGING_EMAIL_STATEMENT,
            name: 'email_statements',
            value: REPORT_BATCH_ACTION_ITEMS.ACCOUNTS_AGING_EMAIL_STATEMENT
        }
    ]
};

const BUTTON_DELETE = {
    id: 1,
    label: 'delete',
    className: 'v2-btn-default has-icon header-items btn-delete',
    iconClassName: 'svg-delete-grey',
    value: TYPE_BUTTON_ACTIONS.DELETE,
    iconSvg: <IconTrash />
};

const BUTTON_ARCHIVE = {
    id: 2,
    label: 'archive',
    className: 'v2-btn-default has-icon header-items',
    iconClassName: 'mr-1',
    value: TYPE_BUTTON_ACTIONS.ARCHIVE,
    iconSvg: <IconTime />
};

const BUTTON_ACTIVE = {
    id: 3,
    label: 'active',
    className: 'v2-btn-default has-icon header-items',
    iconClassName: 'mr-1',
    value: TYPE_BUTTON_ACTIONS.ACTIVE,
    iconSvg: <IconDoubleCheck />
};

const BUTTON_UNDELETE = {
    id: 4,
    label: 'un_delete',
    className: 'v2-btn-default has-icon header-items',
    iconClassName: 'mr-1',
    value: TYPE_BUTTON_ACTIONS.UNDELETE,
    iconSvg: <IconDoubleCheck />
};

const BUTTON_TRASH = {
    id: 5,
    label: 'permanently_delete',
    className: 'v2-btn-default has-icon header-items',
    iconClassName: 'svg-delete-grey',
    value: TYPE_BUTTON_ACTIONS.TRASH,
    iconSvg: <IconTrash />
};

const BUTTON_SYNC_QB = {
    id: 3,
    label: 'sync_to_qb',
    className: 'v2-btn-default has-icon header-items',
    iconClassName: 'svg-delete-grey',
    value: TYPE_BUTTON_ACTIONS.SYNC_QB,
    iconSvg: <IconSync />
};

export const MODE_VIEW_REPORT = {
    ACTIVE: 'active',
    ARCHIVE: 'archive',
    DELETE: 'delete'
};

export const getListButtonInvoice = (mode) => {
    switch (mode) {
        case MODE_VIEW_REPORT.ACTIVE:
            return [BUTTON_DELETE, BUTTON_ARCHIVE];
        case MODE_VIEW_REPORT.ARCHIVE:
            return [BUTTON_DELETE, BUTTON_ACTIVE];
        case MODE_VIEW_REPORT.DELETE:
            return [BUTTON_UNDELETE, BUTTON_ARCHIVE];
        default:
            return [];
    }
};

export const getListButtonEstimate = (mode) => {
    switch (mode) {
        case MODE_VIEW_REPORT.ACTIVE:
            return [BUTTON_DELETE, BUTTON_ARCHIVE];
        case MODE_VIEW_REPORT.ARCHIVE:
            return [BUTTON_DELETE, BUTTON_ACTIVE];
        case MODE_VIEW_REPORT.DELETE:
            return [BUTTON_UNDELETE, BUTTON_ARCHIVE, BUTTON_TRASH];
        default:
            return [];
    }
};

export const getListButtonDocument = (mode) => {
    switch (mode) {
        case MODE_VIEW_REPORT.ACTIVE:
            return [BUTTON_DELETE];
        case MODE_VIEW_REPORT.DELETE:
            return [BUTTON_UNDELETE, BUTTON_TRASH];
        default:
            return [];
    }
};

// List Button
export const REPORT_LIST_BUTTON = {
    INVOICE: [BUTTON_DELETE, BUTTON_ARCHIVE, BUTTON_ACTIVE, BUTTON_UNDELETE],
    ESTIMATE: [BUTTON_DELETE, BUTTON_ARCHIVE],
    PAYMENT: [BUTTON_DELETE, BUTTON_SYNC_QB],
    DOCUMENT: [BUTTON_DELETE],
    CREDIT: [BUTTON_DELETE],
    SERVICE_LOOKUP: [BUTTON_DELETE]
};

export const REPORT_CONTENT_MATERIAL_USE_DETAIL = {
    customer_name: {
        id: 'customer_name',
        style: 'd-flex align-center text-left',
        type: LIST_TABLE_ITEM_TYPE.AVATAR_LINK
    },
    service_type: {
        id: 'service_name',
        style: 'text-left',
        type: LIST_TABLE_ITEM_TYPE.LINK
    },
    check_in: { id: 'check_in', style: 'text-left' },
    check_out: { id: 'check_out', style: 'text-left' },
    weather: { id: 'weather', style: 'text-left' },
    epa: { id: 'epa', style: 'text-left' },
    material: { id: 'material', style: 'text-left' },
    qty: { id: 'qty', style: 'text-left' },
    units: { id: 'units', style: 'text-left' },
    date: { id: 'date', style: 'text-left' },
    county: { id: 'county', style: 'text-left fw-bold' },
    address: {
        id: 'address',
        style: 'text-left',
        type: LIST_TABLE_ITEM_TYPE.PARSE_HTML
    },
    address_1: { id: 'address_1', style: 'text-left' },
    zip: { id: 'zip', style: 'text-left' },
    dilution: { id: 'dilution', style: 'text-left' },
    method: { id: 'method', style: 'text-left' },
    target: { id: 'target', style: 'text-left' },
    location: { id: 'location', style: 'text-left' },
    area: { id: 'area', style: 'text-left' },
    assigned_to: { id: 'assigned_to', style: 'text-left' }
};

export const REVIEW_LIKELY_TAB = 'likely';
export const REVIEW_SERVICE_TAB = 'service';
export const REPORT_TRACKER_TAB = 'tracker';

export const EXCLUDE_REPORT_LOADING = [
    REPORT_TYPE.CUSTOMERS_WO_ACTIVE_JOBS,
    REPORT_TYPE.REVENUE_BY_CLIENT,
    REPORT_TYPE.SALES_TAX_SUMMARY,
    REPORT_TYPE.SALES_FORECAST
];
