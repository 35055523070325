import { takeLatest } from 'redux-saga/effects';

import { REPORT_BATCH_ACTION_ITEMS } from 'app/const/report/ReportTypeContent';

import { sendMailInvoices, generatePdfInvoices, sendMailEstimates, reassignJobsServiceLookup } from './batch';

import {
    getListReportInvoice,
    searchReportInvoice,
    deleteInvoices,
    archiveInvoices,
    getInvoiceServiceFilter
} from './invoice';

import {
    getListReportEstimatesRequest,
    activeReportEstimatesRequest,
    archiveReportEstimatesRequest,
    deleteReportEstimatesRequest,
    unDeleteReportEstimatesRequest,
    trashReportEstimatesRequest
} from './estimate';

import {
    getListReportPayment,
    searchReportPayment,
    deleteReportPayment,
    syncQbReport,
    getListMethodFilter
} from './payment';

import {
    getListReportDocument,
    deleteReportDocument,
    unDeleteReportDocument,
    trashReportDocumentRequest,
    getListTypeFilter
} from './document';

import { getListReportCredit, deleteReportCredit } from './credit';

import { getListReportRecurringInvoice } from './recurringInvoice';

import { getListReportSubscription, getListFlansSubscriptionFilter } from './subscription';

import {
    getListReportServiceLookupRequest,
    getListScheduleServiceLookupRequest,
    getListServiceTypeRequest
} from './serviceLookup';

import { getListReportLocationsWoActiveJobsRequest } from './locationsWoActiveJobs';

import { getListReportCustomersWoActiveJobs } from './customersWoActiveJobs';

import {
    getListReportAccountsAging,
    printInvoices,
    generateInvoicesPdf,
    emailLatePaymentReminders,
    smsLatePaymentReminders,
    emailStatements
} from './accountsAging';

import { getListReportSalesForecast } from './salesForecast';

import { getListReportSalesTax, getListReportTaxSummary, getListMethodTaxSummary } from './salesTaxSummary';

import {
    getListReportRevenueByServiceMonth,
    getListReportRevenueByServiceDetail,
    getListMethodFilterRevenueByService,
    getListServiceFilterRevenueByService
} from './revenueByService';

import {
    getListReportRevenueByClientMonth,
    getListReportRevenueByClientDetail,
    getListYearReportRevenue
} from './revenueByClient';

import {
    getListReportRevenueByItemMonth,
    getListReportRevenueByItemDetail,
    getListMethodFilterRevenueByItem,
    getListServiceFilterRevenueByItem
} from './revenueByItem';

import {
    getListReportRevenueByStaffMonth,
    getListReportRevenueStaffPayment,
    getListMethodFilterRevenueByStaff,
    getListServiceFilterRevenueByStaff
} from './revenueByStaff';

import {
    getListReportRevenueBySourceMonth,
    getListReportRevenueBySourceDetail,
    getListMethodFilterRevenueBySource,
    getListSourceFilterRevenueBySource
} from './revenueBySource';

import {
    getListReportRevenueTags,
    getListReportRevenueTagsPayment,
    getListMethodFilterRevenueByTags
} from './revenueByTags';

import { exportEmailHistory, getListReportEmailHistory } from './emailHistory';

import {
    getListReportMaterialUse,
    getListCountyMaterialUseFilter,
    getListCompletedJobWoCounty,
    getListReportMaterialSimplify
} from './materialUse';

import { getListReportMaterialUseDetail } from './materialUseDetail';

import { getListReportTotalSales, getListDataChartTotalSales, getListYearTotalSales } from './totalSales';

import {
    getListReportPaymentsCollected,
    getDataChartPaymentsCollected,
    getListYearsPaymentsCollected,
    getListStaffFilterPaymentsCollected,
    getListMethodFilterPaymentsCollected
} from './paymentsCollected';

import {
    getListReportNewCustomers,
    getListYearNewCustomers,
    getListDataChartNewCustomers,
    getListSourceNewCustomerFilter
} from './newCustomers';
import {
    getListReportCustomerLeads,
    getListReportBookingJobs,
    getListDataOnlineBookingsChart,
    getListDataInboundLeadsChart,
    getListYearsInboundLeadsChart,
    getListYearsOnlineBookingsChart
} from './leads';

// TYPE
import { BATCH_ACTIONS_TYPE } from 'common/redux/actions/reports/batchAction';
import { TYPE_REPORT_INVOICE } from 'common/redux/actions/reports/invoiceAction';
import { TYPE_REPORT_ESTIMATE } from 'common/redux/actions/reports/estimateAction';
import { TYPE_REPORT_PAYMENT } from 'common/redux/actions/reports/paymentAction';
import { TYPE_REPORT_DOCUMENT } from 'common/redux/actions/reports/documentAction';
import { TYPE_REPORT_CREDIT } from 'common/redux/actions/reports/creditAction';
import { TYPE_REPORT_RECURRING_INVOICE } from 'common/redux/actions/reports/recurringInvoiceAction';
import { TYPE_REPORT_SUBSCRIPTION } from 'common/redux/actions/reports/subscriptionAction';
import { TYPE_REPORT_SERVICE_LOOKUP } from 'common/redux/actions/reports/serviceLookupAction';
import { TYPE_REPORT_LOCATIONS_WO_ACTIVE_JOBS } from 'common/redux/actions/reports/locationsWoActiveJobsAction';
import { TYPE_REPORT_CUSTOMERS_WO_ACTIVE_JOB } from 'common/redux/actions/reports/customersWoActiveJobsAction';
import { TYPE_REPORT_ACCOUNTS_AGING } from 'common/redux/actions/reports/accountsAgingAction';
import { TYPE_REPORT_SALES_FORECAST } from 'common/redux/actions/reports/salesForecastAction';
import { TYPE_REPORT_SALES_TAX } from 'common/redux/actions/reports/salesTaxSummaryAction';
import { TYPE_REPORT_REVENUE_SERVICE } from 'common/redux/actions/reports/revenueByServiceAction';
import { TYPE_REPORT_REVENUE_CLIENT } from 'common/redux/actions/reports/revenueByClientAction';
import { TYPE_REPORT_REVENUE_ITEM } from 'common/redux/actions/reports/revenueByItemAction';
import { TYPE_REPORT_REVENUE_STAFF } from 'common/redux/actions/reports/revenueByStaffAction';
import { TYPE_REPORT_REVENUE_SOURCE } from 'common/redux/actions/reports/revenueBySourceAction';
import { TYPE_REPORT_REVENUE_TAGS } from 'common/redux/actions/reports/revenueByTagsAction';
import { TYPE_REPORT_EMAIL_HISTORY } from 'common/redux/actions/reports/emailHistoryAction';
import { TYPE_REPORT_MATERIAL_USE } from 'common/redux/actions/reports/materialUseAction';
import { TYPE_REPORT_MATERIAL_USE_DETAIL } from 'common/redux/actions/reports/materialUseDetailAction';
import { TYPE_REPORT_TOTAL_SALES } from 'common/redux/actions/reports/totalSalesAction';
import { TYPE_REPORT_PAYMENTS_COLLECTED } from 'common/redux/actions/reports/paymentsCollectedAction';
import { TYPE_REPORT_NEW_CUSTOMERS } from 'common/redux/actions/reports/newCustomersAction';
import { TYPE_REPORT_LEADS } from 'common/redux/actions/reports/leadsAction';

// Batch Actions
export function* reportInvoiceSendMailWatcher() {
    yield takeLatest(BATCH_ACTIONS_TYPE[REPORT_BATCH_ACTION_ITEMS.INVOICES_EMAIL], sendMailInvoices);
}
export function* reportInvoiceGeneratePdfWatcher() {
    yield takeLatest(BATCH_ACTIONS_TYPE[REPORT_BATCH_ACTION_ITEMS.INVOICES_GENERATE_PDF], generatePdfInvoices);
}
export function* reportEstimateSendMailWatcher() {
    yield takeLatest(BATCH_ACTIONS_TYPE[REPORT_BATCH_ACTION_ITEMS.ESTIMATES_EMAIL], sendMailEstimates);
}
export function* reportServiceLookReassignJobsupWatcher() {
    yield takeLatest(
        BATCH_ACTIONS_TYPE[REPORT_BATCH_ACTION_ITEMS.SERVICE_LOOKUP_REASSIGN_JOBS],
        reassignJobsServiceLookup
    );
}

// Invoice
export function* reportInvoiceGetListWatcher() {
    yield takeLatest(TYPE_REPORT_INVOICE.GET_LIST, getListReportInvoice);
}
export function* reportInvoiceSearchWatcher() {
    yield takeLatest(TYPE_REPORT_INVOICE.SEARCH, searchReportInvoice);
}
export function* reportInvoiceDeleteWatcher() {
    yield takeLatest(TYPE_REPORT_INVOICE.DELETE_INVOICE_REQUEST, deleteInvoices);
}
export function* reportInvoiceArchiveWatcher() {
    yield takeLatest(TYPE_REPORT_INVOICE.ARCHIVE_INVOICE_REQUEST, archiveInvoices);
}
export function* reportInvoiceGetServiceWatcher() {
    yield takeLatest(TYPE_REPORT_INVOICE.GET_INVOICE_SERVICE_FILTER, getInvoiceServiceFilter);
}

// Estimate
export function* getListReportEstimatesWatcher() {
    yield takeLatest(TYPE_REPORT_ESTIMATE.GET_LIST_REPORT_ESTIMATE_REQUEST, getListReportEstimatesRequest);
}
export function* deleteReportEstimatesWatcher() {
    yield takeLatest(TYPE_REPORT_ESTIMATE.DELETE_REPORT_ESTIMATE_REQUEST, deleteReportEstimatesRequest);
}
export function* activeReportEstimatesWatcher() {
    yield takeLatest(TYPE_REPORT_ESTIMATE.ACTIVE_REPORT_ESTIMATE_REQUEST, activeReportEstimatesRequest);
}
export function* archiveReportEstimatesWatcher() {
    yield takeLatest(TYPE_REPORT_ESTIMATE.ARCHIVE_REPORT_ESTIMATE_REQUEST, archiveReportEstimatesRequest);
}
export function* unDeleteReportEstimatesWatcher() {
    yield takeLatest(TYPE_REPORT_ESTIMATE.UNDELETE_REPORT_ESTIMATE_REQUEST, unDeleteReportEstimatesRequest);
}
export function* trashReportEstimatesWatcher() {
    yield takeLatest(TYPE_REPORT_ESTIMATE.TRASH_REPORT_ESTIMATE_REQUEST, trashReportEstimatesRequest);
}

// Payment
export function* reportPaymentGetListWatcher() {
    yield takeLatest(TYPE_REPORT_PAYMENT.GET_LIST, getListReportPayment);
}
export function* reportPaymentSearchWatcher() {
    yield takeLatest(TYPE_REPORT_PAYMENT.SEARCH, searchReportPayment);
}
export function* reportPaymentDeleteWatcher() {
    yield takeLatest(TYPE_REPORT_PAYMENT.DELETE_REQUEST, deleteReportPayment);
}
export function* reportPaymentSyncQbWatcher() {
    yield takeLatest(TYPE_REPORT_PAYMENT.SYNCQB_REQUEST, syncQbReport);
}
export function* reportPaymentGetListMethodWatcher() {
    yield takeLatest(TYPE_REPORT_PAYMENT.GET_LIST_METHOD_FILTER_REQUEST, getListMethodFilter);
}

// Document
export function* reportDocumentGetListWatcher() {
    yield takeLatest(TYPE_REPORT_DOCUMENT.GET_LIST, getListReportDocument);
}
export function* reportDocumentDeleteWatcher() {
    yield takeLatest(TYPE_REPORT_DOCUMENT.DELETE_REQUEST, deleteReportDocument);
}
export function* reportDocumentUndeleteWatcher() {
    yield takeLatest(TYPE_REPORT_DOCUMENT.UNDELETE_REQUEST, unDeleteReportDocument);
}
export function* reportDocumentTrashWatcher() {
    yield takeLatest(TYPE_REPORT_DOCUMENT.TRASH_REQUEST, trashReportDocumentRequest);
}
export function* reportDocumentGetListTypeWatcher() {
    yield takeLatest(TYPE_REPORT_DOCUMENT.GET_LIST_TYPE_FILTER_REQUEST, getListTypeFilter);
}

// Credit
export function* reportCreditGetListWatcher() {
    yield takeLatest(TYPE_REPORT_CREDIT.GET_LIST, getListReportCredit);
}
export function* reportCreditDeleteWatcher() {
    yield takeLatest(TYPE_REPORT_CREDIT.DELETE_REQUEST, deleteReportCredit);
}

// RecurringInvoice
export function* reportRecurringInvoiceGetListWatcher() {
    yield takeLatest(TYPE_REPORT_RECURRING_INVOICE.GET_LIST, getListReportRecurringInvoice);
}

// Subscriptions
export function* reportSubscriptionGetListWatcher() {
    yield takeLatest(TYPE_REPORT_SUBSCRIPTION.GET_LIST, getListReportSubscription);
}
export function* reportSubscriptionGetListFlansFilterWatcher() {
    yield takeLatest(TYPE_REPORT_SUBSCRIPTION.PLANS, getListFlansSubscriptionFilter);
}

// Service Lookup
export function* reportServiceLookupGetListWatcher() {
    yield takeLatest(
        TYPE_REPORT_SERVICE_LOOKUP.GET_LIST_REPORT_SERVICE_LOOKUP_REQUEST,
        getListReportServiceLookupRequest
    );
}
export function* reportServiceLookupGetListScheduleWatcher() {
    yield takeLatest(
        TYPE_REPORT_SERVICE_LOOKUP.GET_LIST_SCHEDULE_SERVICE_LOOKUP_REQUEST,
        getListScheduleServiceLookupRequest
    );
}
export function* reportServiceLookupGetListServiceTypeWatcher() {
    yield takeLatest(TYPE_REPORT_SERVICE_LOOKUP.GET_LIST_SERVICE_SERVICE_LOOKUP_REQUEST, getListServiceTypeRequest);
}
// Location w/o Active
export function* reportlocationsWoActiveJobsGetListWatcher() {
    yield takeLatest(TYPE_REPORT_LOCATIONS_WO_ACTIVE_JOBS.GET_LIST, getListReportLocationsWoActiveJobsRequest);
}

// Customer w/o Active
export function* reportCustomersWoActiveJobGetListWatcher() {
    yield takeLatest(TYPE_REPORT_CUSTOMERS_WO_ACTIVE_JOB.GET_LIST, getListReportCustomersWoActiveJobs);
}

// Account Aging
export function* reportAccountsAgingGetListWatcher() {
    yield takeLatest(TYPE_REPORT_ACCOUNTS_AGING.GET_LIST_REPORT_ACCOUNTS_AGING_REQUEST, getListReportAccountsAging);
}
export function* reportAccountsAgingPrintInvoicesWatcher() {
    yield takeLatest(TYPE_REPORT_ACCOUNTS_AGING.PRINT_INVOICE, printInvoices);
}
export function* reportAccountsAgingGenerateInvoicesPdfWatcher() {
    yield takeLatest(TYPE_REPORT_ACCOUNTS_AGING.GENERATE_INVOICE_PDF, generateInvoicesPdf);
}
export function* reportAccountsAgingEmailLatePaymentRemindersWatcher() {
    yield takeLatest(TYPE_REPORT_ACCOUNTS_AGING.EMAIL_LATE_PAYMENT_REMINDERS, emailLatePaymentReminders);
}
export function* reportAccountsAgingSmsLatePaymentRemindersWatcher() {
    yield takeLatest(TYPE_REPORT_ACCOUNTS_AGING.SMS_LATE_PAYMENT_REMINDERS, smsLatePaymentReminders);
}
export function* reportAccountsAgingEmailStatementsWatcher() {
    yield takeLatest(TYPE_REPORT_ACCOUNTS_AGING.EMAIL_STATEMENTS, emailStatements);
}

// Sales Forecast
export function* reportSalesForecastGetListWatcher() {
    yield takeLatest(TYPE_REPORT_SALES_FORECAST.GET_LIST, getListReportSalesForecast);
}

// Sales Tax
export function* reportSalesTaxGetListWatcher() {
    yield takeLatest(TYPE_REPORT_SALES_TAX.GET_LIST, getListReportSalesTax);
}
export function* reportTaxSummaryGetListWatcher() {
    yield takeLatest(TYPE_REPORT_SALES_TAX.GET_LIST_TAX_SUMMARY, getListReportTaxSummary);
}
export function* reportFilterMethodTaxSummaryWatcher() {
    yield takeLatest(TYPE_REPORT_SALES_TAX.GET_LIST_METHOD_TAX_SUMMARY, getListMethodTaxSummary);
}

export function* reportRevenueServiceGetListMethodFilterWatcher() {
    yield takeLatest(
        TYPE_REPORT_REVENUE_SERVICE.GET_LIST_METHOD_FILTER_REVENUE_BY_SERVICE,
        getListMethodFilterRevenueByService
    );
}
export function* reportRevenueServiceGetListServiceFilterWatcher() {
    yield takeLatest(
        TYPE_REPORT_REVENUE_SERVICE.GET_LIST_SERVICE_FILTER_REVENUE_BY_SERVICE,
        getListServiceFilterRevenueByService
    );
}

// Revenue by Client
export function* reportRevenueClientGetListWatcher() {
    yield takeLatest(TYPE_REPORT_REVENUE_CLIENT.GET_LIST_MONTH_TAB, getListReportRevenueByClientMonth);
}
export function* reportRevenueClientPaymentGetListWatcher() {
    yield takeLatest(TYPE_REPORT_REVENUE_CLIENT.GET_LIST_DETAIL_TAB, getListReportRevenueByClientDetail);
}
export function* reportRevenueGetListYearWatcher() {
    yield takeLatest(TYPE_REPORT_REVENUE_CLIENT.GET_LIST_YEAR, getListYearReportRevenue);
}

// Revenue By Service
export function* reportRevenueServiceGetListWatcher() {
    yield takeLatest(TYPE_REPORT_REVENUE_SERVICE.GET_LIST_MONTH_TAB, getListReportRevenueByServiceMonth);
}
export function* reportRevenueServicePaymentGetListWatcher() {
    yield takeLatest(TYPE_REPORT_REVENUE_SERVICE.GET_LIST_DETAIL_TAB, getListReportRevenueByServiceDetail);
}

// Revenue by Item
export function* reportRevenueItemGetListWatcher() {
    yield takeLatest(TYPE_REPORT_REVENUE_ITEM.GET_LIST_MONTH_TAB, getListReportRevenueByItemMonth);
}
export function* reportRevenueItemPaymentGetListWatcher() {
    yield takeLatest(TYPE_REPORT_REVENUE_ITEM.GET_LIST_DETAIL_TAB, getListReportRevenueByItemDetail);
}
export function* reportRevenueItemGetListMethodFilterWatcher() {
    yield takeLatest(TYPE_REPORT_REVENUE_ITEM.GET_LIST_METHOD_FILTER_REVENUE_BY_ITEM, getListMethodFilterRevenueByItem);
}
export function* reportRevenueItemGetListServiceFilterWatcher() {
    yield takeLatest(
        TYPE_REPORT_REVENUE_ITEM.GET_LIST_SERVICE_FILTER_REVENUE_BY_ITEM,
        getListServiceFilterRevenueByItem
    );
}

// Revenue by Staff
export function* reportRevenueStaffGetListWatcher() {
    yield takeLatest(TYPE_REPORT_REVENUE_STAFF.GET_LIST_MONTH_TAB, getListReportRevenueByStaffMonth);
}
export function* reportRevenueStaffPaymentGetListWatcher() {
    yield takeLatest(TYPE_REPORT_REVENUE_STAFF.GET_LIST_DETAIL_TAB, getListReportRevenueStaffPayment);
}
export function* reportRevenueStaffGetListMethodFilterWatcher() {
    yield takeLatest(
        TYPE_REPORT_REVENUE_STAFF.GET_LIST_METHOD_FILTER_REVENUE_BY_STAFF,
        getListMethodFilterRevenueByStaff
    );
}
export function* reportRevenueStaffGetListServiceFilterWatcher() {
    yield takeLatest(
        TYPE_REPORT_REVENUE_STAFF.GET_LIST_SERVICE_FILTER_REVENUE_BY_STAFF,
        getListServiceFilterRevenueByStaff
    );
}

// Revenue by Source
export function* reportRevenueSourceGetListWatcher() {
    yield takeLatest(TYPE_REPORT_REVENUE_SOURCE.GET_LIST_MONTH_TAB, getListReportRevenueBySourceMonth);
}
export function* reportRevenueSourcePaymentGetListWatcher() {
    yield takeLatest(TYPE_REPORT_REVENUE_SOURCE.GET_LIST_DETAIL_TAB, getListReportRevenueBySourceDetail);
}
export function* reportRevenueSourceGetListMethodFilterWatcher() {
    yield takeLatest(
        TYPE_REPORT_REVENUE_SOURCE.GET_LIST_METHOD_FILTER_REVENUE_BY_SOURCE,
        getListMethodFilterRevenueBySource
    );
}
export function* reportRevenueSourceGetListSourceFilterWatcher() {
    yield takeLatest(
        TYPE_REPORT_REVENUE_SOURCE.GET_LIST_SERVICE_FILTER_REVENUE_BY_SOURCE,
        getListSourceFilterRevenueBySource
    );
}

// Revenue by Tags
export function* reportRevenueTagsGetListWatcher() {
    yield takeLatest(TYPE_REPORT_REVENUE_TAGS.GET_LIST, getListReportRevenueTags);
}
export function* reportRevenueTagsPaymentGetListWatcher() {
    yield takeLatest(TYPE_REPORT_REVENUE_TAGS.GET_LIST_PAYMENT, getListReportRevenueTagsPayment);
}
export function* reportRevenueTagsGetListMethodFilterWatcher() {
    yield takeLatest(TYPE_REPORT_REVENUE_TAGS.GET_LIST_METHOD_FILTER_REVENUE_BY_TAGS, getListMethodFilterRevenueByTags);
}

// Email History
export function* reportEmailHistoryGetListWatcher() {
    yield takeLatest(TYPE_REPORT_EMAIL_HISTORY.GET_LIST, getListReportEmailHistory);
}

export function* exportEmailHistoryWatcher() {
    yield takeLatest(TYPE_REPORT_EMAIL_HISTORY.EXPORT_EMAIL_HISTORY, exportEmailHistory);
}

// Material Use
export function* reportMaterialUseGetListWatcher() {
    yield takeLatest(TYPE_REPORT_MATERIAL_USE.GET_LIST, getListReportMaterialUse);
}
export function* reportMaterialUseListCountyWatcher() {
    yield takeLatest(TYPE_REPORT_MATERIAL_USE.COUNTY, getListCountyMaterialUseFilter);
}
export function* reportMaterialUseGetListJobsWoCountyWatcher() {
    yield takeLatest(TYPE_REPORT_MATERIAL_USE.GET_LIST_JOBS_WO_COUNTY, getListCompletedJobWoCounty);
}
export function* reportMaterialGetListWatcher() {
    yield takeLatest(TYPE_REPORT_MATERIAL_USE.GET_LIST_MATERIAL, getListReportMaterialSimplify);
}
// Material Use Detail
export function* reportMaterialUseDetailGetListWatcher() {
    yield takeLatest(TYPE_REPORT_MATERIAL_USE_DETAIL.GET_LIST, getListReportMaterialUseDetail);
}

// Total Sales
export function* reportTotalSalesGetListWatcher() {
    yield takeLatest(TYPE_REPORT_TOTAL_SALES.GET_LIST, getListReportTotalSales);
}
export function* reportTotalSalesGetListDataChartWatcher() {
    yield takeLatest(TYPE_REPORT_TOTAL_SALES.GET_LIST_CHART, getListDataChartTotalSales);
}
export function* reportTotalSalesGetListYearWatcher() {
    yield takeLatest(TYPE_REPORT_TOTAL_SALES.GET_LIST_YEAR, getListYearTotalSales);
}

// Payments Collected
export function* reportPaymentsCollectedGetListWatcher() {
    yield takeLatest(TYPE_REPORT_PAYMENTS_COLLECTED.GET_LIST, getListReportPaymentsCollected);
}
export function* reportPaymentsCollectedGetListDataChartWatcher() {
    yield takeLatest(TYPE_REPORT_PAYMENTS_COLLECTED.GET_LIST_CHART, getDataChartPaymentsCollected);
}
export function* reportPaymentsCollectedGetListYearsWatcher() {
    yield takeLatest(TYPE_REPORT_PAYMENTS_COLLECTED.GET_LIST_YEARS, getListYearsPaymentsCollected);
}
export function* reportPaymentsCollectedGetListStaffsFilterWatcher() {
    yield takeLatest(TYPE_REPORT_PAYMENTS_COLLECTED.GET_LIST_STAFFS_FILTER, getListStaffFilterPaymentsCollected);
}
export function* reportPaymentsCollectedGetListMethodFilterWatcher() {
    yield takeLatest(TYPE_REPORT_PAYMENTS_COLLECTED.GET_LIST_METHOD_FILTER, getListMethodFilterPaymentsCollected);
}

// New Customers
export function* reportNewCustomersGetListWatcher() {
    yield takeLatest(TYPE_REPORT_NEW_CUSTOMERS.GET_LIST, getListReportNewCustomers);
}
export function* reportNewCustomersGetListDataChartWatcher() {
    yield takeLatest(TYPE_REPORT_NEW_CUSTOMERS.GET_LIST_CHART, getListDataChartNewCustomers);
}
export function* reportNewCustomersGetListYearWatcher() {
    yield takeLatest(TYPE_REPORT_NEW_CUSTOMERS.GET_LIST_YEAR, getListYearNewCustomers);
}
export function* reportNewCustomersGetListSourceFilterWatcher() {
    yield takeLatest(TYPE_REPORT_NEW_CUSTOMERS.SOURCE, getListSourceNewCustomerFilter);
}

// Leads
export function* reportCustomerLeadsGetListWatcher() {
    yield takeLatest(TYPE_REPORT_LEADS.GET_LIST_CUSTOMER_LEADS, getListReportCustomerLeads);
}
export function* reportBookingJobsGetListWatcher() {
    yield takeLatest(TYPE_REPORT_LEADS.GET_LIST_REPORT_BOOKING_JOBS, getListReportBookingJobs);
}
export function* reportGetYearInboundLeadsWatcher() {
    yield takeLatest(TYPE_REPORT_LEADS.GET_LIST_YEARS_INBOUND_LEADS_CHART, getListYearsInboundLeadsChart);
}
export function* reportInboundLeadsWatcher() {
    yield takeLatest(TYPE_REPORT_LEADS.GET_LIST_INBOUND_LEADS_CHART, getListDataInboundLeadsChart);
}
export function* reportGetYearOnlineBookingsChartWatcher() {
    yield takeLatest(TYPE_REPORT_LEADS.GET_LIST_YEARS_ONLINE_BOOKING_CHART, getListYearsOnlineBookingsChart);
}
export function* reportOnlineBookingsChartWatcher() {
    yield takeLatest(TYPE_REPORT_LEADS.GET_LIST_ONLINE_BOOKINGS_CHART, getListDataOnlineBookingsChart);
}
