import React from 'react';
import PropTypes from 'prop-types';

import GdPageSize from 'app/components/pagination/GdPageSize';
import GdPager from 'app/components/pagination/GdPager';

import { DEFAULT_OFFSET, DEFAULT_PAGE } from 'app/const/App';
import { CustomerPageSize } from 'app/modules/customer/components/CustomerPagination';

function Pagination({
    pageSize,
    onChange,
    currentPageSize,
    currentPage,
    totalPage,
    children,
    isCustomerPage = false,
    isMultipleBranchPage = false
}) {
    if (!totalPage && !isMultipleBranchPage) return null;

    const handleChangePage = (page) => {
        onChange({
            offset: (page - 1) * currentPageSize,
            limit: currentPageSize,
            currentPage: page
        });
    };

    const handleChangePageSize = (size) => {
        onChange({
            offset: DEFAULT_OFFSET,
            limit: size,
            currentPage: DEFAULT_PAGE
        });
    };

    return (
        <div className="footer-pages">
            <div className="flex-betweenitems flex-wrap row-gap-16 col-gap-8">
                <div className="d-flex align-center flex-1 gap-4">
                    {!isMultipleBranchPage ? (
                        isCustomerPage ? (
                            <CustomerPageSize
                                pageSize={pageSize}
                                onChangePageSize={handleChangePageSize}
                                currentPageSize={currentPageSize}
                            />
                        ) : (
                            <GdPageSize
                                pageSize={pageSize}
                                onChangePageSize={handleChangePageSize}
                                currentPageSize={currentPageSize}
                            />
                        )
                    ) : null}
                    {children}
                </div>

                {(isCustomerPage && totalPage <= 1) || isMultipleBranchPage ? null : (
                    <GdPager currentPage={currentPage} totalPage={totalPage} onChangePage={handleChangePage} />
                )}
            </div>
        </div>
    );
}

Pagination.propTypes = {
    currentPage: PropTypes.number,
    totalPage: PropTypes.number,
    currentPageSize: PropTypes.number,
    pageSize: PropTypes.array,
    onChange: PropTypes.func
};

export default Pagination;
