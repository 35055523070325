import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import { addBranchPath } from 'app/const/Branch';
import IconEdit from 'assets/icon/IconEdit';
import IconThreeDots from 'assets/icon/IconThreeDots';

const ItemSmartView = ({
    pathTo,
    name = '',
    createdBy = '',
    definition,
    permission = false,
    isSearching = false,
    dragHandleProps = {},
    onEdit = () => {}
}) => {
    const history = useHistory();
    const url = addBranchPath(pathTo);
    const dataHistory = { pathname: url, state: { isLoadingSideBar: false, definition, name, createdBy } };
    const _handleClick = () => {
        history.push(dataHistory);
    };
    return (
        <>
            {!isSearching ? (
                <div className="v2-btn-default --icon-sm --transparent --drag" {...dragHandleProps}>
                    <IconThreeDots isDouble />
                </div>
            ) : null}
            <NavLink
                to={dataHistory}
                onTouchEnd={_handleClick}
                activeClassName="fw-600"
                className="txt-ellipsis flex-1"
            >
                {name}
            </NavLink>
            {permission ? (
                <div className="v2-btn-default --icon-sm --transparent btn-modal" onClick={onEdit}>
                    <IconEdit />
                </div>
            ) : null}
        </>
    );
};

export default ItemSmartView;
