import classNames from 'classnames';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ADDONS_VOIP_PLAN } from 'app/config/routes';
import { KEY_VOIP } from 'app/const/addons';
import { ADDONS_VOIP_DETAIL, ADDONS_VOIP_UPDATE_OPTION, UPDATE_ADDONS_VOIP_OPTIONS } from 'app/const/Api';
import { ACCOUNT_ROLE } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { OPTIONS_ADDONS_VOIP_ONLY_OWNER } from 'app/const/Voip';
import ErrorPage from 'app/modules/error';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import AddonHeaderDetail from '../components/AddonHeaderDetail';
import AddonsBoxHeader from '../components/AddonsBoxHeader';
import AddonsOptions from '../components/AddonsOptions';
import { VOIPAddonsLoading } from './components/loadings/VOIPAddonsLoading';
import VOIPAddonsGetStarted from './components/VOIPAddonsGetStarted';

export const AddonsVOIP = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const refHeader = useRef(null);
    const refGetStart = useRef(null);

    const {
        profile: { role: userRole },
        settings: { addons, voip: voipSetting }
    } = useSelector(({ auth }) => auth.user);

    const { voip: voipReducer } = addons;
    if (!(KEY_VOIP in addons)) return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;

    const isSupperAdmin = userRole === ACCOUNT_ROLE.SUPERADMIN;

    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        isEnableGroupNumbers: false,
        voip: {}
    });
    const { isLoading, voip } = state;
    const { keyword, level, status, name, upgrade, account, options, tutorial } = voip;
    const isActive = !!account?.user_quantity && !!voipReducer;

    useEffect(() => {
        _getSettingDetail();
    }, []);

    const _handleUpdateActive = (value) => {
        dispatchState((prev) => ({ ...prev, voip: { ...prev.voip, status: value ? 1 : 0 } }));
    };

    const _getSettingDetail = () => {
        const _handleSuccess = (res) => {
            const resData = res.data;
            dispatchState((prev) => ({
                ...prev,
                voip: {
                    ...resData,
                    options: (resData?.options || [])
                        .filter((item) => isSupperAdmin || !OPTIONS_ADDONS_VOIP_ONLY_OWNER.includes(item.keyword))
                        .map((item) => {
                            return { ...item, onUpgrade: _handleOpenUpgrade };
                        })
                },
                isLoading: false
            }));
        };

        clientQuery(ADDONS_VOIP_DETAIL, { method: 'GET' }, _handleSuccess, checkAccessFail);
    };

    const _handleConfirmChange = () => {
        if (!account?.user_quantity) {
            history.push(addBranchPath(ADDONS_VOIP_PLAN));
            return;
        }
    };

    const _handleOpenUpgrade = () => {
        refGetStart.current._openManage();
    };

    return (
        <div className="addons-wrap__container">
            <AddonHeaderDetail isLoading={isLoading} tutorial={tutorial} />
            <div className="scrolls">
                <div className="boxs-wrapper">
                    {isLoading ? (
                        <VOIPAddonsLoading />
                    ) : (
                        <Fragment>
                            <div className="boxs boxs--no-border mb-3 ">
                                <AddonsBoxHeader
                                    ref={refHeader}
                                    keyword={keyword}
                                    name={name}
                                    status={status}
                                    level={level}
                                    upgrade={upgrade}
                                    urlUpdateStatus={UPDATE_ADDONS_VOIP_OPTIONS}
                                    onUpdateStatusSuccess={_handleUpdateActive}
                                    onConfirmChange={_handleConfirmChange}
                                    shouldConfirm
                                    isHideCheckbox
                                />
                            </div>
                            {isSupperAdmin && (
                                <VOIPAddonsGetStarted ref={refGetStart} voip={voip} onChangePlan={_getSettingDetail} />
                            )}
                            <div
                                className={classNames({
                                    'is-disable': !isActive || (!isSupperAdmin && !voipSetting) || !status
                                })}
                            >
                                <AddonsOptions data={options} urlUpdate={ADDONS_VOIP_UPDATE_OPTION} />
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};
