export default function IconOutbox() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.5 14.5V15.5C10.5 16.2398 10.9017 16.8858 11.4989 17.2318C11.879 17.4254 12.0976 17.4887 12.5 17.5H14.946C15.3085 17.5 15.6642 17.5985 15.975 17.785L18.9855 19.5913C19.4591 19.8755 20.0733 19.7219 20.3575 19.2483C20.4507 19.0929 20.5 18.9151 20.5 18.7338V12.5C20.5 11.3954 19.6046 10.5 18.5 10.5H17.5"
                stroke="#4D5054"
                strokeWidth="1.3"
            ></path>
            <path
                d="M10.2016 14.5C9.74742 14.5 9.3068 14.6546 8.95217 14.9383L6.1247 17.2002C5.69343 17.5453 5.06414 17.4753 4.71913 17.0441C4.57728 16.8668 4.5 16.6464 4.5 16.4194V7.5C4.5 6.39543 5.39543 5.5 6.5 5.5H15.5C16.6046 5.5 17.5 6.39543 17.5 7.5V12.5C17.5 13.6046 16.6046 14.5 15.5 14.5H10.2016Z"
                stroke="#4D5054"
                strokeWidth="1.3"
            ></path>
        </svg>
    );
}
