import classNames from 'classnames';
import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { reducer } from 'app/const/Reducer';
import SearchOption from 'app/modules/report/filter/SearchOption';
import IconLoading from 'assets/icon/IconLoading';
import { getListSettingService } from 'common/redux/actions/settings/serviceAction';
import { isScrollToEndBottom } from 'common/utils/FunctionUtils';

const ListService = (
    {
        id = '',
        autoFocus = false,
        serviceSelected = null,
        isCheckNeverEnd = false,
        triggerPlanItemOptions = [],
        onChangeService = () => {}
    },
    ref
) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const { list_service, first_time, total_service } = useSelector((store) => store.serviceReducer);
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        isLoading: true,
        keyword: '',
        options: [],
        isFirstTime: true,
        totalSearch: 0,
        openFirstTime: false,
        serviceSelected,
        optionsPlan: []
    });
    const {
        keyword: finalKeyWord,
        options: listService,
        isLoading: finalIsLoading,
        isVisible: finalIsVisible,
        serviceSelected: finalServiceSelected,
        totalSearch: finalTotalSearch,
        optionsPlan
    } = state;
    const { id: finalServiceSelectedId } = finalServiceSelected || {};
    const finalListServices = isCheckNeverEnd ? optionsPlan.filter((item) => item.isNotInfiniteService) : listService;

    const refDropDown = useRef(null);
    const refFirstTime = useRef(true);
    const refOptionsPlan = useRef([]);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    useEffect(() => {
        if (finalIsVisible) {
            if (list_service.length === 0 && first_time) {
                _getListServices({ limit: 20, offset: 0, localSaveStore: true, localResetData: true, total: 1 });
            } else {
                dispatchState((prev) => ({ ...prev, isLoading: false, options: list_service }));
            }
        }
    }, [finalIsVisible]);

    useEffect(() => {
        if (serviceSelected && refFirstTime.current) {
            refFirstTime.current = false;
            dispatchState((prev) => ({ ...prev, serviceSelected, keyword: serviceSelected.name }));
        }
    }, [serviceSelected]);

    useEffect(() => {
        if (isCheckNeverEnd) {
            refOptionsPlan.current = triggerPlanItemOptions;
            dispatchState((prev) => {
                const stringValue = String(finalServiceSelectedId);
                const isReset =
                    !!prev.keyword &&
                    !triggerPlanItemOptions.some(
                        ({ service_id, id }) => String(service_id) === stringValue || String(id) === stringValue
                    );
                return {
                    ...prev,
                    optionsPlan: triggerPlanItemOptions,
                    isLoading: false,
                    keyword: isReset ? '' : prev.keyword,
                    serviceSelected: isReset ? {} : prev.serviceSelected
                };
            });
        }
    }, [triggerPlanItemOptions, finalIsVisible]);

    useImperativeHandle(ref, () => ({ _getValue: _getValue }));

    const _getValue = () => {
        return Number(finalServiceSelectedId);
    };

    const _getListServices = (params) => {
        const _getListServicesSuccess = (response) => {
            dispatchState((prev) => ({
                ...prev,
                options: [...prev.options, ...response.data],
                isFirstTime: false,
                isLoading: false,
                totalSearch: finalKeyWord !== '' ? response?.total : finalTotalSearch
            }));
        };
        const _getListServicesFailed = () => {
            dispatchState((prev) => ({ ...prev, isFirstTime: false, isLoading: false }));
        };
        dispatch(
            getListSettingService({ ...params, fields: 'recurrence' }, _getListServicesSuccess, _getListServicesFailed)
        );
    };

    const _handleFocus = () => {
        dispatchState((prev) => ({ ...prev, isVisible: true }));
    };

    const _handleChangeSearch = (keyword) => {
        if (isCheckNeverEnd) {
            dispatchState((prev) => ({
                ...prev,
                keyword,
                optionsPlan: refOptionsPlan.current.filter((item) =>
                    item.name.toLowerCase().includes(keyword.toLowerCase())
                )
            }));
        } else if (keyword === '') {
            const isEmptyList = !!!list_service.length;
            dispatchState((prev) => ({
                ...prev,
                isLoading: isEmptyList,
                options: list_service,
                isFirstTime: false,
                keyword: '',
                isVisible: true,
                serviceSelected: {}
            }));
            onChangeService({ id: 0 });
            if (isEmptyList) {
                _getListServices({ limit: 20, offset: 0, localSaveStore: true, localResetData: true, total: 1 });
            }
            refDropDown.current.scrollTo(0, 0);
        } else {
            dispatchState((prev) => ({ ...prev, isLoading: true, isVisible: true, options: [], keyword }));
            _getListServices({ limit: 20, offset: 0, total: 1, localSaveStore: false, localResetData: true, keyword });
        }
    };

    const handleHideDropdown = (event) => {
        const elPrevent = document.getElementById(id);
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeDropdown();
        }
    };

    const handleClickOutside = (event) => {
        const elPrevent = document.getElementById(id);

        if (
            refDropDown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropDown.current.contains(event.target)
        ) {
            _closeDropdown();
        }
    };

    const _closeDropdown = () => {
        finalIsVisible &&
            dispatchState((prev) => ({ ...prev, isVisible: false, keyword: prev.serviceSelected?.name || '' }));
    };

    const _handleChangeService = (serviceData = {}) => {
        dispatchState((prev) => ({
            ...prev,
            isVisible: false,
            serviceSelected: serviceData,
            keyword: serviceData.name
        }));
        onChangeService(serviceData);
    };

    const _handleOnScrollContent = (e) => {
        if (isCheckNeverEnd) return;
        const isSearching = finalKeyWord !== '';
        const lengthServiceCurrent = finalListServices.length;
        const finalTotalCompare = isSearching ? finalTotalSearch : total_service;

        if (!finalIsLoading && isScrollToEndBottom(e.target) && lengthServiceCurrent < finalTotalCompare) {
            dispatchState((prev) => ({ ...prev, isLoading: true }));
            _getListServices({
                limit: 20,
                offset: lengthServiceCurrent,
                localSaveStore: !isSearching,
                localResetData: false,
                keyword: finalKeyWord
            });
        }
    };

    return (
        <div
            ref={refDropDown}
            id={id}
            className={classNames('v2-dropdown dropdown-select-color', { active: finalIsVisible })}
        >
            <SearchOption
                placeholder={t('setting:initial_service')}
                defaultValue={finalKeyWord}
                style="search-input grey-extradark"
                parentStyle="dropbtn items"
                autoFocus={autoFocus}
                isShowClose={!isCheckNeverEnd}
                onSearch={_handleChangeSearch}
                onFocus={_handleFocus}
            />
            {finalIsVisible ? (
                <div className="v2-dropdown__menu content-full scrolls" onScroll={_handleOnScrollContent}>
                    <ul>
                        {finalListServices.map((item) => (
                            <li
                                key={item.id}
                                className={classNames('items', { active: finalServiceSelectedId === item.id })}
                                onClick={() => _handleChangeService(item)}
                            >
                                <div className="word-break">{item.name}</div>
                            </li>
                        ))}
                    </ul>
                    {finalListServices.length === 0 && !finalIsLoading ? (
                        <li className="items justify-center pointer-events-none">
                            <div className="loading -ajaxbar">{t('no_data_to_display', { title: t('services') })}</div>
                        </li>
                    ) : null}
                    {finalIsLoading && !isCheckNeverEnd ? (
                        <li className="items justify-center">
                            <div className="loading -ajaxbar">
                                <IconLoading />
                            </div>
                        </li>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

export default forwardRef(ListService);
