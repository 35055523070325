import { ADDONS_FOOTER_OPTIONS } from 'app/const/addons';
import { AVAILABLE_PERMISSION, AVAILABLE_SETTINGS, MAGNET_PERMISSION, MAGNET_SETTINGS } from 'app/const/Api';
import { DEFAULT_ALL } from 'app/const/App';
import { AVAILABLE_TIME, JOB_MAGNET } from 'app/const/Permissions';
import { ROUTE_OPTIMIZER_DRIVE_BUFFER_OPTIONS } from 'app/modules/calendar/const/Header';
import i18n from 'assets/i18n';

export const TYPE_ADVANCE_ROUTING = {
    MAGNET: 'magnet',
    AVAILABLE: 'available'
};
export const ROUTING_UNITS = {
    MI: { value: 'MI', label: 'Miles' },
    KM: { value: 'KM', label: 'Km' },
    MET: { value: 'MET', label: 'Meters' }
};
export const ROUTING_LIMITS = {
    MAX_DISTANCE: 1000,
    MAX_DAYS: 30
};
export const CONFIG_ADDON_ROUTING = {
    [TYPE_ADVANCE_ROUTING.MAGNET]: {
        endPointSetup: MAGNET_SETTINGS,
        endPointPermission: MAGNET_PERMISSION,
        keySetting: ADDONS_FOOTER_OPTIONS.JOB_MAGNET,
        permissionId: JOB_MAGNET,
        messageSaveSuccess: i18n.t('addons:job_magnet_default_values')
    },
    [TYPE_ADVANCE_ROUTING.AVAILABLE]: {
        endPointSetup: AVAILABLE_SETTINGS,
        endPointPermission: AVAILABLE_PERMISSION,
        keySetting: ADDONS_FOOTER_OPTIONS.BEST_AVAILABLE_TIME,
        permissionId: AVAILABLE_TIME,
        messageSaveSuccess: i18n.t('addons:available_default_values'),
        showTimeSlot: true
    }
};

export const BEST_TIME_KEYWORD = {
    h: 'hour',
    m: 'minute'
};

export const KEY_SCHEDULES_BEST_TIME = 'scheduleSelected';
export const AVAILABLE_CLASS_NAME_DROPDOWN_TIME = {
    wrapperClassName: 'from-time flex-1',
    buttonClassName: 'dropbtn v2-btn-default w-100',
    timeTextClassName: 'time-text flex-1',
    wrapperDropdownClassName: 'w-100'
};
export const BEST_TIME_DRIVE_BUFFER_OPTIONS = [
    { id: DEFAULT_ALL, value: DEFAULT_ALL, name: 'actual_drive_time', showIcon: true },
    ...ROUTE_OPTIMIZER_DRIVE_BUFFER_OPTIONS
];
