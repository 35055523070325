import React, { Fragment, useEffect, useReducer } from 'react';

import {
    ADDONS_CUSTOM_JOB_STATUS_DETAIL,
    UPDATE_ADDONS_OPPORTUNITY_OPTIONS,
    UPDATE_CUSTOM_JOB_STATUS_FIELDS_OPTIONS
} from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import AddonHeaderDetail from '../components/AddonHeaderDetail';
import AddonsBoxHeader from '../components/AddonsBoxHeader';
import AddonsOptions from '../components/AddonsOptions';
import LoadingCustomJobStatus from './components/LoadingJobStatus';

const AddonsJobStatus = () => {
    const [state, dispatchState] = useReducer(reducer, {
        jobStatus: {},
        isLoading: true
    });
    const { isLoading, jobStatus } = state;

    useEffect(() => {
        _getJobStatusDetail();
    }, []);

    const _getJobStatusDetail = () => {
        const _handleSuccess = ({ data }) => dispatchState({ jobStatus: data, isLoading: false });
        clientQuery(ADDONS_CUSTOM_JOB_STATUS_DETAIL, { method: 'GET' }, _handleSuccess, checkAccessFail);
    };

    const _handleUpdateSuccess = (checked) =>
        dispatchState((prevState) => ({ ...prevState, jobStatus: { ...prevState.jobStatus, status: checked } }));

    return (
        <div className="addons-wrap__container">
            <AddonHeaderDetail isLoading={isLoading} tutorial={jobStatus.tutorial} />
            <div className="scrolls">
                <div className="boxs-wrapper">
                    {isLoading ? (
                        <LoadingCustomJobStatus />
                    ) : (
                        <Fragment>
                            <div className="boxs boxs--no-border --hasline --mr-line">
                                <AddonsBoxHeader
                                    upgrade={jobStatus.upgrade}
                                    keyword={jobStatus.keyword}
                                    name={jobStatus.name}
                                    status={jobStatus.status}
                                    level={jobStatus.level}
                                    onUpdateStatusSuccess={_handleUpdateSuccess}
                                    urlUpdateStatus={UPDATE_CUSTOM_JOB_STATUS_FIELDS_OPTIONS}
                                />
                                {jobStatus?.sub_title ? (
                                    <div className="boxs__contents mt-3">
                                        <p className="description">{jobStatus.sub_title}</p>
                                    </div>
                                ) : null}
                            </div>
                            <AddonsOptions
                                data={jobStatus?.options || []}
                                status={jobStatus?.status || false}
                                upgrade={jobStatus?.upgrade}
                                urlUpdate={UPDATE_ADDONS_OPPORTUNITY_OPTIONS}
                                handleManagerSuccess={() => {}}
                            />
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddonsJobStatus;
