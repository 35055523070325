import classNames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';

import GDInputRange from 'app/components/input/InputRange';
import { CALENDAR_STORE } from 'app/const/Api';
import { TIME_LINE_MODE_VIEW, TIME_LINE_VIEW_VALUES } from 'app/modules/calendar/const';
import IconItem from 'assets/icon/IconItem';
import IconSearch from 'assets/icon/IconSearch';
import { updateTimeLineModeView } from 'common/redux/actions/calendar';
import { clientQuery } from 'common/utils/ApiUtils';

export function ResourceHeader({ timelineModeView, defaultValue, onScale = () => {} }) {
    const dispatch = useDispatch();

    function _handleChangeMode(e, mode) {
        e.stopPropagation();
        dispatch(updateTimeLineModeView({ horizontal_type: TIME_LINE_VIEW_VALUES[mode] }));
        clientQuery(CALENDAR_STORE, { data: { type: 17, value: TIME_LINE_VIEW_VALUES[mode] }, method: 'PUT' });
    }

    return (
        <>
            <span className="wrap-btn-view flexcenter">
                <span
                    className={classNames('btn-view v2-btn-default --icon-sm --transparent', {
                        active: timelineModeView === TIME_LINE_MODE_VIEW.SMALL
                    })}
                    onClick={(e) => _handleChangeMode(e, TIME_LINE_MODE_VIEW.SMALL)}
                >
                    <IconItem isSmall />
                </span>
                <span
                    className={classNames('btn-view v2-btn-default --icon-sm --transparent', {
                        active: timelineModeView === TIME_LINE_MODE_VIEW.REGULAR
                    })}
                    onClick={(e) => _handleChangeMode(e, TIME_LINE_MODE_VIEW.REGULAR)}
                >
                    <IconItem />
                </span>
                <span
                    className={classNames('btn-view v2-btn-default --icon-sm --transparent', {
                        active: timelineModeView === TIME_LINE_MODE_VIEW.LARGE
                    })}
                    onClick={(e) => _handleChangeMode(e, TIME_LINE_MODE_VIEW.LARGE)}
                >
                    <IconItem isLarge />
                </span>
            </span>
            <GDInputRange
                defaultValue={defaultValue}
                leftComponent={({ onClick }) => <ZoomOutIcon onClick={onClick} />}
                rightComponent={({ onClick }) => <ZoomInIcon onClick={onClick} />}
                onChange={onScale}
            />
        </>
    );
}

export const ZoomOutIcon = ({ onClick = () => {} }) => (
    <span className="btn-view btn-zoomout" onClick={onClick}>
        <IconSearch isHasMinus />
    </span>
);

export const ZoomInIcon = ({ onClick = () => {} }) => (
    <span className="btn-view btn-zoomin" onClick={onClick}>
        <IconSearch isHasPlus />
    </span>
);
