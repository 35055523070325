import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { reducer } from 'app/const/Reducer';

const IconMap = forwardRef(({ onToggleMap = () => {} }, ref) => {
    const { t } = useTranslation(['calendar']);
    const { openmap } = useSelector(({ map }) => map);
    const [state, dispatchState] = useReducer(reducer, { isChecked: !!openmap });
    const { isChecked } = state;

    useImperativeHandle(ref, () => ({ setChecked: (value) => dispatchState({ isChecked: value }) }));

    const _handleChange = () => {
        const newToggle = !openmap;
        onToggleMap(newToggle);
        dispatchState((prevState) => {
            return { ...prevState, isChecked: newToggle };
        });
    };

    return (
        <div
            id="btn-map-toggle"
            className={classNames('v2-btn-default btn-mapbox cursor', { active: !!isChecked })}
            data-close-sms="false"
            onClick={_handleChange}
        >
            {t('calendar:map')}
            <span className={classNames('switch-icon ml-1', { 'has-toggle': !!isChecked })}>
                <span className="switch-icon__dots"></span>
            </span>
        </div>
    );
});

export default IconMap;
