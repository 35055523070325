import { COMMON, LIST_TABLE_ITEM_TYPE } from 'app/const/App';
import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';

export const getGridTableDeviceTracking = (columns = [], actionsStatus) => {
    const HEADERS = {
        customer: {
            title: 'customer_name_title',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_type: {
            title: 'service_type',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        date: { title: 'date', style: 'col col-date', actionType: COMMON.ORDER, currentValue: COMMON.NOT_SET },
        service_address_1: {
            title: 'address_line_1',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_address_2: {
            title: 'address_line_2',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_address_city: {
            title: 'city',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_address_state: {
            title: 'state',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_address_zip: {
            title: 'zip',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        device_name: {
            title: 'device_name',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        barcode: { title: 'barcode', style: 'col col-sm', actionType: COMMON.ORDER, currentValue: COMMON.NOT_SET },
        coordinate: {
            title: 'coordinate',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        area: { title: 'area', style: 'col col-md', actionType: COMMON.ORDER, currentValue: COMMON.NOT_SET },
        device_type: {
            title: 'device_type',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        checkin: { title: 'check_in', style: 'col col-sm', actionType: COMMON.ORDER, currentValue: COMMON.NOT_SET },
        status: { title: 'status', style: 'col col-sm', actionType: COMMON.ORDER, currentValue: COMMON.NOT_SET },
        activity: { title: 'activity', style: 'col col-sm', actionType: COMMON.ORDER, currentValue: COMMON.NOT_SET },
        checkout: { title: 'check_out', style: 'col col-sm', actionType: COMMON.ORDER, currentValue: COMMON.NOT_SET },
        note: { title: 'finding_notes', style: 'col col-md', actionType: COMMON.ORDER, currentValue: COMMON.NOT_SET },
        assigned_to: {
            title: 'assigned_to',
            style: 'col col-note',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        }
    };
    const COLUMNS = [
        { id: 'customer', title: 'customer', status: true },
        { id: 'service_type', title: 'service_type', status: true },
        { id: 'date', title: 'date', status: true },
        { id: 'service_address_1', title: 'address_line_1', status: true },
        { id: 'service_address_2', title: 'address_line_2', status: true },
        { id: 'service_address_city', title: 'city', status: true },
        { id: 'service_address_state', title: 'state', status: true },
        { id: 'service_address_zip', title: 'zip', status: true },
        { id: 'device_name', title: 'device_name', status: true },
        { id: 'barcode', title: 'barcode', status: true },
        { id: 'coordinate', title: 'coordinate', status: true },
        { id: 'area', title: 'area', status: true, keyDisplayValue: 'name' },
        { id: 'device_type', title: 'device_type', status: true, keyDisplayValue: 'name' },
        { id: 'checkin', title: 'check_in', status: true },
        { id: 'status', title: 'status', status: true, keyDisplayValue: 'name' },
        { id: 'activity', title: 'activity', status: true, keyDisplayValue: 'name' },
        { id: 'checkout', title: 'check_out', status: true },
        { id: 'note', title: 'note', status: true },
        { id: 'assigned_to', title: 'assigned_to', status: true }
    ];
    const CONTENTS = {
        customer: { title: 'customer', style: 'col col-xl', type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO },
        service_type: { title: 'service_type', style: 'col col-lg' },
        date: { title: 'date', style: 'col col-date' },
        service_address_1: { title: 'service_address_1', style: 'col col-lg' },
        service_address_2: { title: 'service_address_2', style: 'col col-lg' },
        service_address_city: { title: 'service_address_city', style: 'col col-sm' },
        service_address_state: { title: 'service_address_state', style: 'col col-sm' },
        service_address_zip: { title: 'service_address_zip', style: 'col col-sm' },
        device_name: { title: 'device_name', style: 'col col-md' },
        barcode: { title: 'barcode', style: 'col col-sm' },
        coordinate: { title: 'coordinate', style: 'col col-md' },
        area: { title: 'area', style: 'col col-md' },
        device_type: { title: 'device_type', style: 'col col-md' },
        checkin: { title: 'checkin', style: 'col col-sm' },
        status: { title: 'status', style: 'col col-sm' },
        activity: { title: 'activity', style: 'col col-sm' },
        checkout: { title: 'checkout', style: 'col col-sm' },
        note: { title: 'note', style: 'col col-md' },
        assigned_to: { title: 'assigned_to', style: 'col col-note' }
    };

    const newHeader = mapCurrentValueHeader(actionsStatus, { ...HEADERS });
    const newContent = {};
    const newColumns = [];

    COLUMNS.forEach((col) => {
        const item = col.id;

        if (columns.includes(item)) {
            newHeader[item] = HEADERS[item];
            newContent[item] = CONTENTS[item];
            newColumns.push(col);
        }
    });

    return { header: newHeader, columns: newColumns, contentConfig: newContent };
};
