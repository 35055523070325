import React, { Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import IconSingleStep from 'assets/icon/IconSingleStep';
import { BOOK_A_COACHING_CALL, LIST_ON_BOARDING_FAQ } from '../../constant';
import ModalFAQ from './ModalFAQ';

const OnBoardingFAQ = () => {
    const { t } = useTranslation('onboarding');

    const refFAQModal = useRef(null);

    const _handleOpenModal = (data) => {
        if (!!data.link_article) {
            window.open(data.link_article);
            return;
        }
        refFAQModal.current._open(data);
    };

    const _handleBookSalesCall = () => {
        window.open(BOOK_A_COACHING_CALL);
    };

    return (
        <Fragment>
            <div className="onboarding-items">
                <h5 className="black fw-500 fs-15">{t('frequently_asked_questions')}</h5>
                <div className="flexcenter gap-8 flex-wrap mt-3">
                    {LIST_ON_BOARDING_FAQ.map((item) => {
                        return (
                            <div
                                key={item.id}
                                className="question-items flexcenter gap-8 cursor-pointer"
                                onClick={() => _handleOpenModal(item)}
                            >
                                <p className="fw-500 black txt-ellipsis flex-1" title={item.title}>
                                    {item.title}
                                </p>
                                <IconSingleStep />
                            </div>
                        );
                    })}
                    <div className="v2-btn-default w-100 --large black fs-13" onClick={_handleBookSalesCall}>
                        {t('have_more_question')}
                    </div>
                </div>
            </div>
            <ModalFAQ ref={refFAQModal} />
        </Fragment>
    );
};

export default OnBoardingFAQ;
