import classNames from 'classnames';
import React, { Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { SETTINGS_PLANS } from 'app/config/routes';
import { CONNECTION_USAGE, USAGE_MAXIMUM } from 'app/const/addons';
import { GROWTH_ACCOUNT_USER, PRO_ACCOUNT_USER } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import IconCogWheel from 'assets/icon/IconCogWheel';
import IconStar from 'assets/icon/IconStar';
import IconUpgrade from 'assets/icon/IconUpgrade';
import { getPlanUser } from 'common/utils/PermissionUtils';
import ModalRoutingAddon from './ModalRoutingAddon';

const RoutingAddonsOptions = ({ data = [], usage = {} }) => {
    const { t } = useTranslation(['addons']);
    const profileData = useSelector(({ auth }) => auth.user.profile);
    const { isGrowthPlan, isTrial } = getPlanUser(profileData);
    const finalMaximum = isGrowthPlan || isTrial ? CONNECTION_USAGE : USAGE_MAXIMUM;

    const refModalRoutingAddon = useRef(null);

    const _handleClickManage = () => {
        refModalRoutingAddon.current?._open({ ...usage, maximum: finalMaximum });
    };

    return (
        <Fragment>
            {data.map((item) => (
                <div
                    key={item.keyword}
                    className={classNames('boxs boxs--power-up boxs-has-footer', {
                        active: item.value
                    })}
                >
                    <div className="boxs__header">
                        <div className="header-title">
                            <div className="title">{item.name}</div>
                            <div className="dots powerups">
                                <IconUpgrade isFillOrange />
                                {t('power_up')}
                            </div>
                        </div>
                        {!!!item.upgrade ? (
                            <div className="switch large">
                                <p className="switch__label --active">{t('addons:enabled')}</p>
                            </div>
                        ) : (
                            <Link to={addBranchPath(SETTINGS_PLANS)} className="btn-upgrage svg-star-white">
                                <IconStar />
                                <span className="line">{t('addons:upgrade')}</span>
                            </Link>
                        )}
                    </div>
                    <div className="boxs__contents">
                        <p className="description">{item.description}</p>
                    </div>
                    <div className="boxs__footer">
                        <p className="grey-extrlight flex-1">
                            {t('addons:drive_matrix_title', {
                                connections: finalMaximum,
                                plan_type: isGrowthPlan ? GROWTH_ACCOUNT_USER : PRO_ACCOUNT_USER
                            })}
                        </p>
                        <div
                            className="v2-btn-default svg-purple btn-bg-purple --icon-r --sm btn-modal"
                            onClick={_handleClickManage}
                        >
                            {t('addons:manage')}
                            <IconCogWheel />
                        </div>
                    </div>
                </div>
            ))}
            <ModalRoutingAddon ref={refModalRoutingAddon} />
        </Fragment>
    );
};

export default RoutingAddonsOptions;
