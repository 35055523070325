import React, { Fragment } from 'react';

import GDTutorial from 'app/components/tutorial';
import AddonBack from './AddonBack';

const AddonHeaderDetail = ({ tutorial = {}, isLoading = false }) => {
    return (
        <Fragment>
            <GDTutorial tutorial={tutorial} isLoading={isLoading} />
            <div className="header">
                <AddonBack />
            </div>
        </Fragment>
    );
};

export default AddonHeaderDetail;
