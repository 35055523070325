import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { addBranchPath } from 'app/const/Branch';
import { ACCESS_SETTINGS_TAB } from 'app/const/Permissions';
import IconCogWheel from 'assets/icon/IconCogWheel';
import { checkPermission } from 'common/utils/PermissionUtils';
import { ListItemsUsers } from './CustomFieldsFooter';

const AdvanceRoutingFooter = ({ keyword = '', upgrade, pathManage, items = null }) => {
    const { t } = useTranslation('addons');
    const advanceRoutingAddon = useSelector(({ auth }) => auth.user.settings.addons.routing);
    const permissionsList = useSelector(({ auth }) => auth.user?.permissions?.enabled || []);

    const havePermissionSetting = !!advanceRoutingAddon && checkPermission(permissionsList, ACCESS_SETTINGS_TAB);
    const isDisableSetting = !havePermissionSetting || !!upgrade;
    const isDisable = isDisableSetting || !!!advanceRoutingAddon[keyword];

    return (
        <div className="boxs__footer">
            {items && !isNaN(items) && items.length ? (
                <p className="grey-extrlight txt-ellipsis flex-1">
                    {items} {t('fields')}
                </p>
            ) : null}
            {items && isNaN(items) ? <ListItemsUsers data={items} /> : null}
            <Link
                to={addBranchPath(pathManage)}
                className={classNames('v2-btn-default svg-purple btn-bg-purple --icon-r --sm', {
                    'is-disable': isDisable
                })}
            >
                {t('manage')}
                <IconCogWheel />
            </Link>
        </div>
    );
};

export default AdvanceRoutingFooter;
